import React, { useCallback, useRef, useState, useEffect } from "react";
import { Formik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import usePicketSupplementSupplyContext from "../../hooks/usePicketSupplementSupplyContext";

import {
  Col,
  DatePicker,
  Icon,
  Modal,
  notification,
  Row,
  Select,
  Spin,
} from "antd";
import { CardCustom, FormModalReportFeedingSheet, Title } from "./styles";

import ExportReportNutritionalConsumptionModal from "../../components/modals/exportReportNutritionalConsumption";
import ButtonStandard from "../../components/utils/button";
import { GenerateReport } from "../lot/listProduction/styles";
import PicketSupplementSupplyTable from "./table";

// Services
import { getPicketIndexDropDown } from "../../services/picketService";
import { findLatest } from "../../services/picketSupplementSupplyService";
import { processReportPicketFeedingAssessment } from "../../services/reportService";
import fileReader from "../../utils/fileReader";

const validateSchema = Yup.object().shape({
  reportDate: Yup.date().required(),
});

function ModalTroughFeedingAssessment({
  isModalReportFeedingSheetVisible,
  closeModal,
}) {
  const formikRef = useRef();
  const [formRequestFeedingSheetReport, setFormRequestFeedingSheetReport] =
    useState({
      reportDate: null,
      picketIds: [],
    });

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPicketList, setIsLoadingPicketList] = useState(false);
  const [picketsFiltered, setPicketsFiltered] = useState([]);

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const getAllPickets = useCallback(async () => {
    setIsLoadingPicketList(true);
    try {
      const {
        data: { results },
      } = await getPicketIndexDropDown({
        groupId,
        farmId,
      });
      const filterPicketsActiveOrPasturage = results.filter(
        (picket) =>
          (picket.status === "Pasturage" || picket.status === "P") &&
          picket.lotAnimalsCount > 0
      );
      setPicketsFiltered(filterPicketsActiveOrPasturage);
    } catch (error) {
    } finally {
      setIsLoadingPicketList(false);
    }
  }, [farmId, groupId]);

  const getLastestPicketSupply = useCallback(async () => {
    try {
      const {
        data: { results },
      } = await findLatest({
        groupId,
        farmId,
      });
      if (results) {
        return results;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }, [farmId, groupId]);

  const cleanFields = () => {
    setFormRequestFeedingSheetReport({
      reportDate: null,
      picketIds: [],
    });
  };

  const handleSubmit = async (values, actions) => {
    setIsLoading(true);
    notification.info({
      message:
        "O seu relatório está sendo preparado e logo será feito o download! Por favor, não feche esta aba do navegador.",
    });
    setTimeout(() => {
      setIsLoading(false);
      cleanFields();
      closeModal();
    }, 100);
    try {
      const { data } = await processReportPicketFeedingAssessment({
        groupId,
        farmId,
        body: values,
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      let link = document.createElement("a");
      link.href = downloadUrl;
      const dayFormat = moment().format("YYYYMMDD");
      const hourFormat = moment().format("HHmm");
      link.setAttribute(
        "download",
        `Planilha_de_Fornecimento_${dayFormat}_${hourFormat}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();

      notification.success({
        message: "Relatório gerado com sucesso.",
      });
    } catch (error) {
      if (error?.response) {
        try {
          const { data } = error.response;
          // Read file
          const file = await fileReader(data);

          if (typeof file === "string") {
            // Parse content and retrieve 'message'
            const result = JSON.parse(file);
            if (result.code === 5035) {
              notification.error({
                message:
                  "Não foram encontrados dados com os filtros informados.",
              });
            } else {
              notification.error({
                message:
                  "Houve um erro ao gerar relatório. Entre em contato com o suporte",
              });
            }
          }
        } catch (readError) {
          notification.error({
            message:
              "Houve um erro ao gerar relatório. Entre em contato com o suporte",
          });
        }
      } else {
        notification.error({
          message:
            "Houve um erro ao gerar relatório. Entre em contato com o suporte",
        });
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      getAllPickets();
      const latestTreatDate = await getLastestPicketSupply();
      if (latestTreatDate) {
        setFormRequestFeedingSheetReport((prev) => ({
          reportDate: moment(latestTreatDate.supplyDate).add(1, "days"),
          picketIds: [],
        }));
        formikRef.current.resetForm({
          reportDate: moment(latestTreatDate.supplyDate).add(1, "days"),
          picketIds: [],
        });
      } else {
        setFormRequestFeedingSheetReport((prev) => ({
          reportDate: null,
          picketIds: [],
        }));
        formikRef.current.resetForm({
          reportDate: null,
          picketIds: [],
        });
      }
    }
    if (isModalReportFeedingSheetVisible === true) {
      fetchData();
    }
  }, [getAllPickets, getLastestPicketSupply, isModalReportFeedingSheetVisible]);

  return (
    <Modal
      title={"Planilha de Fornecimento"}
      visible={isModalReportFeedingSheetVisible}
      centered
      closable
      maskClosable={false}
      footer={null}
      onCancel={closeModal}
    >
      <Formik
        ref={formikRef}
        initialValues={formRequestFeedingSheetReport}
        validationSchema={validateSchema}
        onSubmit={handleSubmit}
        render={({
          values,
          errors,
          setFieldValue,
          handleSubmit,
          submitCount,
        }) => (
          <FormModalReportFeedingSheet onSubmit={handleSubmit}>
            <Spin spinning={isLoading}>
              <Row type="flex" justify="center">
                <p>
                  Informe os dados abaixo para gerar a planilha de fornecimento.
                </p>
              </Row>
              {Object.entries(errors).length > 0 && submitCount > 0 && (
                <Row type="flex" justify="center" align="middle">
                  <label className="error">{translation.error.formError}</label>
                </Row>
              )}
              <Row type="flex" gutter={8}>
                <Col span={24}>
                  <Row>
                    <label
                      className={
                        errors.reportDate && submitCount > 0 ? "error" : ""
                      }
                    >
                      Data*
                    </label>
                  </Row>
                  <Row>
                    <DatePicker
                      value={values.reportDate || undefined}
                      format={"DD/MM/YYYY"}
                      disabledDate={(current) => current.isAfter(moment())}
                      onChange={(date) => setFieldValue("reportDate", date)}
                    />
                  </Row>
                </Col>
              </Row>
              <Row type="flex" gutter={8}>
                <Col span={24}>
                  <Row>
                    <label>Piquetes/Baias</label>
                  </Row>
                  <Row>
                    <Select
                      name="picketIds"
                      value={values.picketIds || undefined}
                      loading={isLoadingPicketList}
                      allowClear
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      mode="multiple"
                      placeholder={translation.defaultSelectPlaceholder}
                      onChange={(value) => setFieldValue("picketIds", value)}
                    >
                      {picketsFiltered.map((picket) => (
                        <Select.Option key={picket.id} value={picket.id}>
                          {picket.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
              </Row>
              <Row type="flex" style={{ paddingTop: 16 }}>
                <Col span={24}>
                  <Row type="flex" justify="end" align="middle" gutter={24}>
                    <Col>
                      <ButtonStandard
                        type="button"
                        buttonType="type4"
                        size="s"
                        onClick={closeModal}
                      >
                        {translation.buttons.cancel}
                      </ButtonStandard>
                    </Col>
                    <Col>
                      <ButtonStandard type="submit" buttonType="type1" size="s">
                        {translation.buttons.generate}
                      </ButtonStandard>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Spin>
          </FormModalReportFeedingSheet>
        )}
      />
    </Modal>
  );
}

function PicketSupplementSupplyLayout() {
  const [
    isModalReportFeedingSheetVisible,
    setIsModalReportFeedingSheetVisible,
  ] = useState(false);
  const [
    isModalExportReportNutritionalConsumptionVisible,
    setIsModalExportReportNutritionalConsumptionVisible,
  ] = useState(false);

  const { translation } = useSelector((state) => state.app);
  const { openDrawer, fetchData } = usePicketSupplementSupplyContext();

  const openModalExportReportNutritionalConsumption = useCallback(() => {
    setIsModalExportReportNutritionalConsumptionVisible(true);
  }, []);

  const closeModalExportReportNutritionalConsumption = useCallback(() => {
    setIsModalExportReportNutritionalConsumptionVisible(false);
  }, []);

  function handleRefresh() {
    fetchData();
  }

  function handleCreateNewRecord() {
    openDrawer();
  }

  async function handleOpenModalReportFeedingSheet() {
    setIsModalReportFeedingSheetVisible(true);
  }

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <CardCustom>
      <Row className="rowHeader" justify="space-between" align="middle">
        <Col span={8}>
          <Title>{translation.picketSupplementSupply.title}</Title>
        </Col>
        <Col span={16}>
          <Row type="flex" justify="end" gutter={8}>
            <Col>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                onClick={handleRefresh}
              >
                <Icon type="reload" />
              </ButtonStandard>
            </Col>
            <GenerateReport
              onClick={openModalExportReportNutritionalConsumption}
            >
              Tratos/Consumo de Ração/Suplemento
            </GenerateReport>
            <Col>
              <GenerateReport onClick={handleOpenModalReportFeedingSheet}>
                Planilha de Fornecimento
              </GenerateReport>
            </Col>
            <Col>
              <ButtonStandard
                name="buttonCreateBudget"
                buttonType="type8"
                onClick={handleCreateNewRecord}
              >
                {translation.picketSupplementSupply.buttonNew}
              </ButtonStandard>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <PicketSupplementSupplyTable />
        </Col>
      </Row>
      <ModalTroughFeedingAssessment
        isModalReportFeedingSheetVisible={isModalReportFeedingSheetVisible}
        closeModal={() => setIsModalReportFeedingSheetVisible(false)}
      />
      <ExportReportNutritionalConsumptionModal
        modalVisible={isModalExportReportNutritionalConsumptionVisible}
        handleCloseModal={closeModalExportReportNutritionalConsumption}
      />
    </CardCustom>
  );
}

function PicketSupplementSupply() {
  return <PicketSupplementSupplyLayout />;
}

export default PicketSupplementSupply;
