// Level 4
const MENU_DIET_STRATEGY = {
  id: "menu_diet_strategy",
  level: 4,
  link: "/admin/managements/diets/strategies",
  availableModules: ["INTERMEDIARIO", "AVANCADO"],
  children: [],
};
const MENU_PICKET_SUPPLEMENT_SUPPLY = {
  id: "menu_picket_supplement_supply",
  level: 4,
  link: "/admin/managements/picket/supplement/supply",
  availableModules: ["INTERMEDIARIO", "AVANCADO"],
  children: [],
};
const MENU_TROUGH_FEEDING_ASSESSMENT = {
  id: "menu_trough_feeding_assessment",
  level: 4,
  link: "/admin/managements/trough/feeding/assessment",
  availableModules: ["INTERMEDIARIO", "AVANCADO"],
  children: [],
};
// Level 3
const MENU_DASHBOARD = {
  id: "menu_dashboard",
  level: 3,
  link: "/admin",
  availableModules: ["BASICO", "INTERMEDIARIO", "AVANCADO"],
  children: [],
};
const MENU_PURCHASE_SCENARIO = {
  id: "menu_purchase_scenario",
  level: 3,
  link: "/admin/purchase/scenario",
  availableModules: ["AVANCADO"],
  children: [],
};
const MENU_SALE_SCENARIO = {
  id: "menu_sale_scenario",
  level: 3,
  link: "/admin/sale/scenario",
  availableModules: ["AVANCADO"],
  children: [],
};
const MENU_PURCHASE_SCENARIO_BOITEL = {
  id: "menu_purchase_scenario_boitel",
  level: 3,
  link: "/admin/boitel/purchase/scenario",
  availableModules: ["AVANCADO"],
  children: [],
};
const MENU_SALE_SCENARIO_BOITEL = {
  id: "menu_sale_scenario_boitel",
  level: 3,
  link: "/admin/boitel/sale/scenario",
  availableModules: ["AVANCADO"],
  children: [],
};
const MENU_NUTRITIONAL = {
  id: "menu_nutritional",
  level: 3,
  link: null,
  availableModules: ["BASICO", "INTERMEDIARIO", "AVANCADO"],
  children: [
    MENU_DIET_STRATEGY,
    MENU_PICKET_SUPPLEMENT_SUPPLY,
    MENU_TROUGH_FEEDING_ASSESSMENT,
  ],
};
const MENU_ENTRY = {
  id: "menu_entry",
  level: 3,
  link: null,
  availableModules: ["BASICO", "INTERMEDIARIO", "AVANCADO"],
  children: [],
};
const MENU_TECHNICIAN_VETERINARY = {
  id: "menu_technician_veterinary",
  level: 3,
  link: "/admin/managements/veterinary",
  availableModules: ["INTERMEDIARIO", "AVANCADO"],
  children: [],
};
const MENU_REPRODUCTIVE = {
  id: "menu_reproductive",
  level: 3,
  link: "/admin/managements/reproductive",
  availableModules: ["INTERMEDIARIO", "AVANCADO"],
  children: [],
};
const MENU_EXIT = {
  id: "menu_exit",
  level: 3,
  link: null,
  availableModules: ["BASICO", "INTERMEDIARIO", "AVANCADO"],
  children: [],
};
const MENU_WEIGHTS = {
  id: "menu_weights",
  level: 3,
  link: "/admin/managements/weights",
  availableModules: ["BASICO", "INTERMEDIARIO", "AVANCADO"],
  children: [],
};

// Level 2
const MENU_MANAGER = {
  id: "menu_manager",
  level: 2,
  link: null,
  availableModules: ["BASICO", "INTERMEDIARIO", "AVANCADO"],
  children: [MENU_DASHBOARD],
};
const MENU_PURCHASE_BOITEL = {
  id: "menu_purchase_boitel",
  level: 2,
  link: null,
  availableModules: ["AVANCADO"],
  children: [MENU_PURCHASE_SCENARIO_BOITEL],
};
const MENU_PURCHASE = {
  id: "menu_purchase",
  level: 2,
  link: null,
  availableModules: ["AVANCADO"],
  children: [MENU_PURCHASE_SCENARIO],
};
const MENU_HANDLING = {
  id: "menu_handling",
  level: 2,
  link: null,
  availableModules: ["BASICO", "INTERMEDIARIO", "AVANCADO"],
  children: [
    MENU_NUTRITIONAL,
    MENU_ENTRY,
    MENU_WEIGHTS,
    MENU_TECHNICIAN_VETERINARY,
    MENU_EXIT,
  ],
};
const MENU_HANDLING_GROWTH = {
  id: "menu_handling",
  level: 2,
  link: null,
  availableModules: ["BASICO", "INTERMEDIARIO", "AVANCADO"],
  children: [
    MENU_NUTRITIONAL,
    MENU_ENTRY,
    MENU_WEIGHTS,
    MENU_REPRODUCTIVE,
    MENU_EXIT,
  ],
};
const MENU_SALE_BOITEL = {
  id: "menu_sale_boitel",
  level: 2,
  link: null,
  availableModules: ["AVANCADO"],
  children: [MENU_SALE_SCENARIO_BOITEL],
};
const MENU_SALE = {
  id: "menu_sale",
  level: 2,
  link: null,
  availableModules: ["AVANCADO"],
  children: [MENU_SALE_SCENARIO],
};
// Level 1
const MENU_GROWTH = {
  id: "menu_growth",
  level: 1,
  order: 1,
  color: "#A02B93",
  link: null,
  availableModules: ["INTERMEDIARIO", "AVANCADO"],
  children: [MENU_MANAGER, MENU_PURCHASE, MENU_HANDLING_GROWTH, MENU_SALE],
};
const MENU_RECREATE_FATTEN_EXTENSIVE = {
  id: "menu_recreate_fatten_extensive",
  level: 1,
  order: 2,
  color: "#4EA72E",
  link: null,
  availableModules: ["BASICO", "INTERMEDIARIO", "AVANCADO"],
  children: [MENU_MANAGER, MENU_PURCHASE, MENU_HANDLING, MENU_SALE],
};
const MENU_CONFINEMENT = {
  id: "menu_confinement",
  level: 1,
  order: 3,
  color: "#FFC000",
  link: null,
  availableModules: ["BASICO", "INTERMEDIARIO", "AVANCADO"],
  children: [MENU_MANAGER, MENU_PURCHASE, MENU_HANDLING, MENU_SALE],
};
const MENU_BOITEL = {
  id: "menu_boitel",
  level: 1,
  order: 4,
  color: "#83CCEB",
  link: null,
  availableModules: ["BASICO", "INTERMEDIARIO", "AVANCADO"],
  children: [
    MENU_MANAGER,
    MENU_HANDLING,
    MENU_PURCHASE_BOITEL,
    MENU_SALE_BOITEL,
  ],
};

export {
  MENU_RECREATE_FATTEN_EXTENSIVE,
  MENU_GROWTH,
  MENU_CONFINEMENT,
  MENU_BOITEL,
};
