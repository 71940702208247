import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useHistory, useRouteMatch, withRouter } from "react-router-dom";

/* Redux libs */
import { useDispatch, useSelector } from "react-redux";
import { Creators as AnimalActions } from "../../../store/ducks/animal";
import { Creators as LotActions } from "../../../store/ducks/lot";

/** Icons */
import TrashIcon from "../../../components/utils/icons/trash";
import Loading from "../../../components/utils/loading";

/** Styles Components */
import { Card, Col, Empty, Icon, Row, Tabs, Tooltip, notification } from "antd";
import {
  AnimalDetailsCard,
  Container,
  TabAnimalContainer,
  TabAnimalPane,
  TabButtonsPaneContainer,
} from "./styles";

/** Components */
import {
  DeathAnimalCard,
  DiscardAnimalCard,
} from "../../../components/drawers/animal/edit/styles";
import BreadCrumbs from "../../../components/utils/breadCrumbs";
import StopIcon from "../../../components/utils/icons/stop";
import DrawerAnimalCurveParameter from "../animalCurveParameter";
import BarAverageDailyGainChart from "./charts/barAverageDailyGainChart";
import BarTotalWeightChart from "./charts/barTotalWeight";
import LineAverageDailyGainChart from "./charts/lineAverageDailyGainChart";
import ScatterWeaningWeightChart from "./charts/scatterWeaningWeightChart";

/** Services */
import { activateAnimal } from "../../../services/animalService";
import { getDietStrategyShow } from "../../../services/dietStrategyService";
import { findFarmSellInfoForAnimal } from "../../../services/farmSellService";
import { getAnimalsCoverageByAnimalId } from "../../../services/handlingReproductionCoverageService";
import { numberMask } from "../../../services/helpersMethodsService";
import { getLotShow } from "../../../services/lotService";
import { showAnimalSaleScenarioResultBaseline } from "../../../services/saleScenarioService";
import TabAnimalBreedCurveInfo from "./tabs/tabBreedCurveInfo";
import TabAnimalCoverageInfo from "./tabs/tabCoverageInfo";
import TabDefaultInfo from "./tabs/tabDefaultInfos";
import TabAnimalEconomicInfo from "./tabs/tabEconomicInfo";
import TabAnimalHandlingCommentsInfo from "./tabs/tabHandlingCommentsInfo";
import TabAnimalWeightsInfo from "./tabs/tabWeightsInfo";
import AnimalGeneology from "./animalGeneology";
import TabAnimalSupplementSupplyInfo from "./tabs/tabSupplementSupplyInfo";
import TabAnimalVaccinationsInfo from "./tabs/tabVaccinationsInfo";

const AnimalDetails = () => {
  const [tabCoveragePane, setTabCoveragePane] = useState(0);
  const [tabAnimalPane, setTabAnimalPane] = useState(1);
  const [loadingInternal, setLoadingInternal] = useState(false);

  const [loadingEconomicReturn, setLoadingEconomicReturn] = useState(true);
  const [economicValues, setEconomicValues] = useState(null);
  const [lotCarcassHarnessing, setLotCarcassHarnessing] = useState(null);
  const [dietStrategyCarcassHarnessing, setDietStrategyCarcassHarnessing] =
    useState(null);

  const [tabFarmFunction, setTabFarmFunction] = useState("1");
  const [listCoverages, setListCoverages] = useState([]);
  const [farmSellValue, setFarmSellValue] = useState(null);

  const customPanelStyle = {
    borderRadius: "5px !important",
    background: "#fff",
    boxShadow: "0px 0px 10px #d8d8d8",
  };
  const dateFormat = "DD/MM/YYYY";

  /** Redux variables */
  const {
    animal: { animalData, animalDailyWeightGainDashboard, isLoading },
    app: {
      translation,
      languageSelected,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  /** Variable props */
  const {
    params: { id: animalId },
  } = useRouteMatch();

  const history = useHistory();

  /** Memos */
  const accumulatedGMD = useMemo(() => {
    if (animalDailyWeightGainDashboard != null) {
      if (animalDailyWeightGainDashboard.length > 0) {
        const filteredList = animalDailyWeightGainDashboard.filter((x) =>
          moment().isSameOrAfter(moment(x.weightGainDate))
        );
        return filteredList.reduce((a, b) => {
          return a + (b.weightGain || 0);
        }, 0);
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }, [animalDailyWeightGainDashboard]);

  const avgGMD = useMemo(() => {
    if (animalDailyWeightGainDashboard != null) {
      if (accumulatedGMD > 0 && animalDailyWeightGainDashboard.length > 0) {
        const filteredList = animalDailyWeightGainDashboard.filter((x) =>
          moment().isSameOrAfter(moment(x.weightGainDate))
        );
        return accumulatedGMD / filteredList.length;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }, [accumulatedGMD, animalDailyWeightGainDashboard]);

  //  Methods

  function handleGoToLotDetails() {
    dispatch(LotActions.showAnimalInChartFromAnimalDetails(animalData));
    history.push(`/admin/lots/${animalData?.lotId}`);
  }

  async function handleActivateAnimal(revertDiscard = false) {
    setLoadingInternal(true);
    try {
      await activateAnimal({
        groupId,
        farmId,
        animalId,
        revertDiscard,
      });
      if (revertDiscard === true) {
        notification.success({
          message: `O animal (${animalData.handlingNumber}) teve seu descarte revertido com sucesso.`,
        });
      } else {
        notification.success({
          message: `O animal (${animalData.handlingNumber}) foi ativado com sucesso.`,
        });
      }
      dispatch(
        AnimalActions.indexAnimalDetails(groupId, farmId, animalId, true)
      );
      setLoadingInternal(false);
    } catch (error) {
      setLoadingInternal(false);
      notification.error({
        message: `O animal (${animalData.handlingNumber}) não foi ativado. Contate o suporte.`,
      });
    }
  }

  function handleTabFarmFunctionChange(key) {
    setTabFarmFunction(key);
    setTabAnimalPane(1);
  }

  /** Effects */
  useEffect(() => {
    dispatch(AnimalActions.indexAnimalDetails(groupId, farmId, animalId, true));
    return () => {
      dispatch(AnimalActions.indexAnimalDetailsSuccess(null));
    };
  }, [animalId, dispatch, farmId, groupId]);

  // Get Dashboards data
  useEffect(() => {
    function fetchData() {
      try {
        // dispatch(
        //   AnimalActions.indexAnimalDetails(groupId, farmId, animalId, true)
        // );
        dispatch(
          AnimalActions.getAnimalGMDDashboard(groupId, farmId, animalId)
        );
        dispatch(
          AnimalActions.getAnimalDailyWeightDashboard(groupId, farmId, animalId)
        );
        dispatch(
          AnimalActions.getAnimalDailyWeightGainDashboard(
            groupId,
            farmId,
            animalId
          )
        );
        dispatch(
          AnimalActions.getAnimalTotalDailyWeightDashboard(
            groupId,
            farmId,
            animalId
          )
        );
        dispatch(
          AnimalActions.indexAnimalWeaningWeightDashboard(
            groupId,
            farmId,
            animalId
          )
        );
        dispatch(
          AnimalActions.indexAnimalLotWeightDashboard(groupId, farmId, animalId)
        );
      } catch (error) {}
    }
    if (animalData !== null) fetchData();
    return () => {
      dispatch(AnimalActions.indexAnimalDailyWeightSuccess(null));
      dispatch(AnimalActions.indexAnimalDailyWeightPageableSuccess(null));
      dispatch(AnimalActions.indexAnimalDailyWeightGainSuccess(null));
      dispatch(AnimalActions.indexAnimalDailyWeightGainPageableSuccess(null));
      dispatch(AnimalActions.getAnimalTotalDailyWeightDashboardSuccess(null));
      dispatch(AnimalActions.getAnimalGMDDashboardSuccess(null));
      dispatch(AnimalActions.indexAnimalWeightHistoryError(null));
      dispatch(AnimalActions.indexAnimalWeaningWeightDashboardSuccess(null));
      dispatch(AnimalActions.indexAnimalHandlingCommentsSuccess(null));
      dispatch(AnimalActions.indexAnimalPicketSupplementSuppliesSuccess(null));
    };
  }, [groupId, farmId, animalId, dispatch, animalData]);

  /** Get lotCarcassHarnessing and dietStrategyCarcassHarnessing */
  useEffect(() => {
    async function fetchLotInfo() {
      if (animalData.lotId != null) {
        try {
          const {
            data: { results: lotResults },
          } = await getLotShow({ groupId, farmId, id: animalData.lotId });
          let lotCarcassHarnessing = 0;
          if (lotResults != null) {
            lotCarcassHarnessing =
              lotResults.carcassHarnessing == null ||
              lotResults.carcassHarnessing === 0
                ? null
                : lotResults.carcassHarnessing;
            setLotCarcassHarnessing(lotCarcassHarnessing);
            getSaleScenarioInfo(lotResults.saleScenarioId);
          } else {
            setLotCarcassHarnessing(0);
            setLoadingEconomicReturn(false);
          }
        } catch (error) {
          setLotCarcassHarnessing(0);
          setLoadingEconomicReturn(false);
        }
      } else {
        setLotCarcassHarnessing(0);
        setLoadingEconomicReturn(false);
      }
    }
    async function getSaleScenarioInfo(saleScenarioId) {
      if (saleScenarioId === null) {
        setEconomicValues(null);
        setLoadingEconomicReturn(false);
      } else {
        try {
          const {
            data: { results },
          } = await showAnimalSaleScenarioResultBaseline({
            groupId,
            farmId,
            saleScenarioId,
            animalId: animalId,
          });
          setEconomicValues(results);
        } catch (error) {
          setEconomicValues(null);
          setLoadingEconomicReturn(false);
        } finally {
          setLoadingEconomicReturn(false);
        }
      }
    }
    async function fetchFarmSellInfo(farmSellId, animalId) {
      try {
        const {
          data: { results },
        } = await findFarmSellInfoForAnimal({
          groupId,
          farmId,
          farmSellId,
          animalId,
        });

        const sellValue = {
          farmSellId: results.farmSellId,
          documentNumber: results.documentNumber,
          sellDate: results.sellDate,
          priceNegotiated: results.animals[0].priceNegotiated,
          priceNegotiatedUnit: results.priceNegotiatedUnit,
          weightSell: results.animals[0].weightSell,
          carcassHarnessing: results.animals[0].carcassHarnessing,
        };
        setFarmSellValue(sellValue);
      } catch (error) {
        console.error(error);
        setFarmSellValue(null);
      } finally {
      }
    }
    async function fetchDietStrategyInfo() {
      if (animalData.dietStrategyBaselineId != null) {
        try {
          const {
            data: { results: dsResults },
          } = await getDietStrategyShow({
            groupId,
            farmId,
            id: animalData.dietStrategyBaselineId,
          });
          let dietStrategyCarcassHarnessing = 0;
          let today = moment();
          if (dsResults != null) {
            let period = dsResults.dietPeriods.find(
              (period) =>
                moment(period.startDate, "YYYY-MM-DD") <= today &&
                moment(period.endDate, "YYYY-MM-DD") >= today
            );
            if (period != null) {
              dietStrategyCarcassHarnessing =
                period.carcassHarnessing == null ||
                period.carcassHarnessing === 0
                  ? null
                  : period.carcassHarnessing;
              setDietStrategyCarcassHarnessing(dietStrategyCarcassHarnessing);
            } else {
              setDietStrategyCarcassHarnessing(0);
            }
          } else {
            setDietStrategyCarcassHarnessing(0);
          }
        } catch (error) {
          setDietStrategyCarcassHarnessing(0);
        }
      } else {
        setDietStrategyCarcassHarnessing(0);
      }
    }
    //Load animal daily cost
    async function fetchAnimalDailyCost(sellDate, finalDate) {
      let limitDate = moment().format("YYYY-MM-DD");

      if (sellDate !== null && sellDate !== undefined) {
        limitDate = sellDate;
      }

      if (finalDate !== null && sellDate !== undefined) {
        limitDate = finalDate;
      }

      dispatch(
        AnimalActions.indexAnimalDailyCost(groupId, farmId, animalId, limitDate)
      );
    }

    async function fetchAllData() {
      await Promise.all([
        fetchLotInfo(),
        fetchDietStrategyInfo(),
        fetchAnimalDailyCost(animalData?.saleDate, animalData?.finalDate),
      ]);
    }
    if (animalData != null) {
      fetchAllData();
      if (animalData?.farmSellId !== null) {
        fetchFarmSellInfo(animalData.farmSellId, animalData.id);
      }
    }
    return () => {
      setLotCarcassHarnessing(null);
      setDietStrategyCarcassHarnessing(null);
      dispatch(AnimalActions.indexAnimalDailyCostSuccess(null));
    };
  }, [groupId, farmId, animalId, animalData, dispatch]);

  /** Clean pagination if navigate for other page */
  useEffect(() => {
    return () => {
      if (
        history.location.pathname != null &&
        !history.location.pathname?.includes("animals")
      ) {
        dispatch(AnimalActions.resetAnimalTablePagination());
        dispatch(AnimalActions.resetData());
      }
    };
  }, [dispatch, history]);

  // For cows open tab reproduction as default
  useEffect(() => {
    if (
      animalData != null &&
      animalData?.animalFarmFunction === "reproduction" &&
      animalData?.gender === "F"
    ) {
      setTabFarmFunction("2");
    }
  }, [animalData]);

  // Get coverages
  useEffect(() => {
    async function fetch() {
      try {
        const {
          data: { results },
        } = await getAnimalsCoverageByAnimalId({ groupId, farmId, animalId });
        setListCoverages(results);
      } catch (error) {
        setListCoverages([]);
      }
    }
    fetch();
  }, [groupId, farmId, animalId]);

  return (
    <Container>
      <BreadCrumbs marginTop="10px">
        <Row type="flex">
          <Col span={4}>
            <Link className="back-container" to="/admin/animals">
              <Icon type="left-circle" theme="filled" />{" "}
              {translation.buttons.back}
            </Link>
          </Col>
          <Col span={20} align="right">
            <Link to="/admin/animals">
              <span className="pageTreeInative">
                {translation.animal.details.animalProductionPageLink}
              </span>
            </Link>
            <span className="pageTreeActive">
              {translation.animal.details.animalPageDescription}
            </span>
          </Col>
        </Row>
      </BreadCrumbs>
      {(isLoading || loadingInternal) && <Loading />}
      {animalData === null ? (
        <Empty description="Animal não encontrado" />
      ) : (
        <>
          {animalData != null && animalData?.deathDate != null && (
            <DeathAnimalCard>
              <Row
                type="flex"
                className="header"
                justify="space-between"
                align="middle"
              >
                <Col>
                  <span className="title">
                    <StopIcon />
                    Animal em óbito -
                    <Tooltip title="Clique aqui para excluir apontamento de Registro de óbito.">
                      <button
                        type="button"
                        onClick={() => handleActivateAnimal()}
                      >
                        <TrashIcon />
                      </button>
                    </Tooltip>
                  </span>
                </Col>
                <Col>
                  <span className="date">
                    {moment(animalData?.deathDate).format(dateFormat)}
                  </span>
                </Col>
              </Row>
              <Row type="flex">
                <Col>
                  <p className="deathReasonName">
                    <b>Causa: </b>
                    {animalData?.deathReasonName}
                  </p>
                </Col>
              </Row>
              <Row type="flex">
                <Col>
                  <p className="discription">{animalData?.deathObservation}</p>
                </Col>
              </Row>
            </DeathAnimalCard>
          )}
          {/* Animal Discard Card */}
          {animalData.discard && (
            <DiscardAnimalCard>
              <Row type="flex" className="header">
                <Row type="flex" justify="space-between" align="middle">
                  <Col>
                    <span className="title">
                      <StopIcon />
                      Animal descartado -{" "}
                      <Tooltip title="Clique aqui para excluir apontamento de registro de descarte.">
                        <button
                          type="button"
                          onClick={() => handleActivateAnimal(true)}
                        >
                          <TrashIcon />
                        </button>
                      </Tooltip>
                    </span>
                  </Col>
                  <Col>
                    <span className="date">
                      {moment(animalData?.discardDate).format(dateFormat)}
                    </span>
                  </Col>
                </Row>
                <Row type="flex" justify="space-between" align="middle">
                  <Col>
                    <span className="date">
                      Causa:{" "}
                      {
                        translation.animal.discard.motives[
                          animalData?.discardMotive
                        ]
                      }
                    </span>
                  </Col>
                </Row>
              </Row>
              {animalData?.discardObservation && (
                <Row type="flex">
                  <Col>
                    <p className="discription">
                      {animalData?.discardObservation}
                    </p>
                  </Col>
                </Row>
              )}
            </DiscardAnimalCard>
          )}
          <Row type="flex" style={{ marginTop: 10 }}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <Tabs
                type="card"
                activeKey={tabFarmFunction}
                onChange={(key) => handleTabFarmFunctionChange(key)}
              >
                <Tabs.TabPane
                  tab={<span>{translation.animal.details.tab.production}</span>}
                  key="1"
                >
                  <Row type="flex">
                    <Col
                      style={{ padding: 8 }}
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <Card className="cardDashAnimalTotalGMDWeight">
                        <BarTotalWeightChart />
                      </Card>
                    </Col>
                    <Col
                      style={{ padding: 8 }}
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <Card className="cardDashAnimalTotalGMDWeight">
                        <BarAverageDailyGainChart />
                      </Card>
                    </Col>
                    <Col
                      style={{ padding: 8 }}
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                    >
                      <Card className="cardDashAnimalGPDGraphic">
                        <LineAverageDailyGainChart />
                      </Card>
                    </Col>
                  </Row>
                </Tabs.TabPane>
                {animalData?.animalFarmFunction === "reproduction" &&
                  animalData?.gender === "F" && (
                    <Tabs.TabPane
                      tab={
                        <span>
                          {translation.animal.details.tab.reproduction}
                        </span>
                      }
                      key="2"
                    >
                      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Card className="cardDashAnimalTotalGMDWeight">
                          <ScatterWeaningWeightChart />
                        </Card>
                      </Col>
                    </Tabs.TabPane>
                  )}
                <Tabs.TabPane
                  tab={<span>{translation.animal.details.tab.geneology}</span>}
                  key="3"
                >
                  <AnimalGeneology />
                </Tabs.TabPane>
              </Tabs>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={8}
              xl={8}
              className="columnWithCards"
            >
              {/* Card with animal details */}
              <Row type="flex">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <AnimalDetailsCard>
                    {animalData?.handlingNumber != null && (
                      <span className="title">{`${animalData?.handlingNumber} ${
                        animalData?.nickname != null
                          ? ` - ${animalData?.nickname}`
                          : ""
                      }`}</span>
                    )}
                    <span className="label" style={{ marginTop: 11 }}>
                      {`${translation.animal.details.animalFarmFunction}: `}
                      <span className="value">
                        {animalData?.animalFarmFunction === "reproduction"
                          ? "Reprodução"
                          : "Engorda"}
                      </span>
                    </span>
                    <span className="label" style={{ marginTop: 11 }}>
                      {`${translation.animal.details.breed}: `}
                      <span className="value">{animalData?.breedName}</span>
                    </span>
                    <span className="label">
                      {`${translation.animal.details.age}: `}
                      <span className="value">{`${
                        languageSelected === "en"
                          ? moment(animalData?.birthday).format("MM/DD/YYYY")
                          : moment(animalData?.birthday).format("DD/MM/YYYY")
                      } (${moment().diff(
                        animalData?.birthday,
                        "days"
                      )} d / ${moment().diff(
                        animalData?.birthday,
                        "months"
                      )} m / ${moment().diff(
                        animalData?.birthday,
                        "years"
                      )} a) - ${numberMask(
                        animalData?.birthdayWeight || 0,
                        false
                      )} kg`}</span>
                    </span>
                    <span className="label">
                      {`${translation.animal.details.source}: `}
                      <span className="value">
                        {animalData?.supplierName != null
                          ? animalData?.supplierName
                          : translation.notInformed}
                      </span>
                    </span>
                    <span className="label">
                      {`${translation.animal.details.farmOwner}: `}
                      <span className="value">
                        {animalData?.farmOwnerName != null
                          ? animalData?.farmOwnerName
                          : translation.notInformed}
                      </span>
                    </span>
                    <span className="label">
                      {`${translation.animal.details.sex}: `}
                      <span className="value">
                        {animalData?.gender === "M"
                          ? translation.animal.details.male
                          : translation.animal.details.female}
                      </span>
                    </span>
                    {(animalData?.animalFarmFunction !== "reproduction" ||
                      animalData?.gender !== "F") && (
                      <span className="label">
                        {`${translation.animal.details.castrated}: `}
                        <span className="value">
                          {animalData?.cap
                            ? translation.buttons.yes
                            : translation.buttons.no}
                        </span>
                      </span>
                    )}
                    {animalData?.animalFarmFunction === "reproduction" && (
                      <span className="label" style={{ marginTop: 11 }}>
                        {`${translation.animal.details.animalReproductionCategory}: `}
                        <span className="value">
                          {animalData?.animalReproductionCategory === "nulipara"
                            ? "Nulípara"
                            : animalData?.animalReproductionCategory ===
                              "novilha"
                            ? "Novilha"
                            : animalData?.animalReproductionCategory ===
                              "primipara"
                            ? "Primípara"
                            : animalData?.animalReproductionCategory ===
                              "secundipara"
                            ? "Secundípara"
                            : animalData?.animalReproductionCategory ===
                              "multipara"
                            ? "Multípara"
                            : animalData?.animalReproductionCategory ===
                              "reprodutor"
                            ? "Reprodutor"
                            : ""}
                        </span>
                      </span>
                    )}
                    <span className="label">
                      {`${translation.animal.details.acquisition}: `}
                      <span className="value">
                        {animalData?.acquisitionDate !== null
                          ? languageSelected === "en"
                            ? moment(animalData?.acquisitionDate).format(
                                "MM/DD/YYYY"
                              )
                            : moment(animalData?.acquisitionDate).format(
                                "DD/MM/YYYY"
                              )
                          : ""}
                        {` - ${numberMask(
                          animalData?.purchaseCost || 0,
                          true
                        )}`}
                      </span>
                    </span>
                    <span className="label">
                      {`${translation.animal.details.lot}: `}
                      {animalData?.lotIsReceipt ? (
                        <span className="value">{animalData?.lotName}</span>
                      ) : (
                        <button
                          className="linkLot"
                          type="button"
                          onClick={handleGoToLotDetails}
                        >
                          <span className="value link">
                            {animalData?.lotName}
                          </span>
                        </button>
                      )}
                    </span>
                    <span className="label">
                      {`${translation.animal.details.picket}: `}
                      <span className="value">{animalData?.picketName}</span>
                    </span>
                    <span className="label">
                      {`${translation.animal.details.sisbov}: `}
                      <span className="value">{animalData?.sisbov}</span>
                    </span>
                    <span className="label">
                      {`${translation.animal.details.tagId}: `}
                      <span className="value">{animalData?.tagId}</span>
                    </span>
                    {tabFarmFunction === "2" && (
                      <>
                        <span className="label">
                          {`${translation.animal.details.femaleSituation}: `}
                          <span className="value">
                            {animalData?.gender === "M"
                              ? ""
                              : animalData?.femaleSituation === "vazia"
                              ? "Vazia"
                              : animalData?.femaleSituation === "servida"
                              ? "Servida"
                              : animalData?.femaleSituation === "prenha"
                              ? "Prenha"
                              : ""}
                          </span>
                        </span>
                        <span className="label">
                          {`${translation.animal.details.femaleSituationTime}: `}
                          <span className="value">
                            {animalData?.gender === "M"
                              ? ""
                              : animalData?.femaleSituation === "servida" &&
                                animalData?.lastCoverageDate != null
                              ? `${
                                  moment().diff(
                                    moment(animalData?.lastCoverageDate),
                                    "days"
                                  ) + 1 || 0
                                } dias`
                              : animalData?.femaleSituation === "vazia" &&
                                animalData?.lastBirthDate != null
                              ? `${
                                  moment().diff(
                                    moment(animalData?.lastBirthDate),
                                    "days"
                                  ) + 1 || 0
                                } dias`
                              : animalData?.femaleSituation === "vazia" &&
                                animalData?.lastCoverageDiagnosisDate != null
                              ? `${
                                  moment().diff(
                                    moment(
                                      animalData?.lastCoverageDiagnosisDate
                                    ),
                                    "days"
                                  ) + 1 || 0
                                } dias`
                              : animalData?.femaleSituation === "vazia" &&
                                animalData?.lastCoverageDiagnosisDate === null
                              ? `${
                                  moment().diff(
                                    moment(animalData?.acquisitionDate),
                                    "days"
                                  ) + 1 || 0
                                } dias`
                              : animalData?.femaleSituation === "prenha" &&
                                animalData?.lastCoverageDiagnosisDate != null
                              ? `${
                                  moment().diff(
                                    moment(
                                      animalData?.lastCoverageDiagnosisDate
                                    ),
                                    "days"
                                  ) + 1 || 0
                                } dias`
                              : ""}
                          </span>
                        </span>
                        <span className="label">
                          {`Estado: `}
                          <span className="value">
                            {animalData?.animalReproductionState &&
                              translation.animalReproductionState[
                                animalData?.animalReproductionState
                              ]}
                          </span>
                        </span>
                        <span className="label">
                          {`IPP: `}
                          <span className="value">
                            {animalData?.idp ? `${animalData?.idp} meses` : ""}
                          </span>
                        </span>
                        <span className="label">
                          {`IEP: `}
                          <span className="value">
                            {animalData?.iep ? `${animalData?.iep} dias` : ""}
                          </span>
                        </span>
                      </>
                    )}
                  </AnimalDetailsCard>
                </Col>
              </Row>
              {tabFarmFunction === "2" ? (
                <TabAnimalContainer>
                  <TabButtonsPaneContainer>
                    {listCoverages.map((coverage, index) => (
                      <TabAnimalPane
                        key={index}
                        active={tabCoveragePane === index}
                        onClick={() => setTabCoveragePane(index)}
                      />
                    ))}
                  </TabButtonsPaneContainer>
                  {/* Tabs reproduction history */}
                  {listCoverages.map((coverage, index) => (
                    <TabAnimalCoverageInfo
                      index={index}
                      tabCoveragePane={tabCoveragePane}
                      coverage={coverage}
                    />
                  ))}
                </TabAnimalContainer>
              ) : (
                <TabAnimalContainer>
                  <TabButtonsPaneContainer>
                    <TabAnimalPane
                      active={tabAnimalPane === 1 ? true : false}
                      onClick={() => setTabAnimalPane(1)}
                    />
                    <TabAnimalPane
                      active={tabAnimalPane === 2 ? true : false}
                      onClick={() => setTabAnimalPane(2)}
                    />
                    <TabAnimalPane
                      active={tabAnimalPane === 3 ? true : false}
                      onClick={() => setTabAnimalPane(3)}
                    />
                    <TabAnimalPane
                      active={tabAnimalPane === 6 ? true : false}
                      onClick={() => setTabAnimalPane(6)}
                    />
                    <TabAnimalPane
                      active={tabAnimalPane === 7 ? true : false}
                      onClick={() => setTabAnimalPane(7)}
                    />
                    <TabAnimalPane
                      active={tabAnimalPane === 4 ? true : false}
                      onClick={() => setTabAnimalPane(4)}
                    />
                    <TabAnimalPane
                      active={tabAnimalPane === 5 ? true : false}
                      onClick={() => setTabAnimalPane(5)}
                    />
                  </TabButtonsPaneContainer>
                  {/* Tab default details */}
                  <TabDefaultInfo
                    lotCarcassHarnessing={lotCarcassHarnessing}
                    dietStrategyCarcassHarnessing={
                      dietStrategyCarcassHarnessing
                    }
                    accumulatedGMD={accumulatedGMD}
                    avgGMD={avgGMD}
                    animalData={animalData}
                    tabFarmFunction={tabFarmFunction}
                    tabAnimalPane={tabAnimalPane}
                    translation={translation}
                  />
                  {/* Tab weight history */}
                  <TabAnimalWeightsInfo
                    customPanelStyle={customPanelStyle}
                    tabAnimalPane={tabAnimalPane}
                    translation={translation}
                  />
                  {/* Tab with economic details and costs */}
                  <TabAnimalEconomicInfo
                    economicValues={economicValues}
                    farmSellValue={farmSellValue}
                    tabAnimalPane={tabAnimalPane}
                    translation={translation}
                    loadingEconomicReturn={loadingEconomicReturn}
                  />
                  {/* Tab with handling comment */}
                  <TabAnimalHandlingCommentsInfo
                    tabAnimalPane={tabAnimalPane}
                  />
                  {/* Tab with animal curve */}
                  <TabAnimalBreedCurveInfo tabAnimalPane={tabAnimalPane} />
                  {/* Tab with animal supplement supply */}
                  <TabAnimalSupplementSupplyInfo
                    tabAnimalPane={tabAnimalPane}
                  />
                  {/* Tab with animal vaccinations */}
                  <TabAnimalVaccinationsInfo tabAnimalPane={tabAnimalPane} />
                </TabAnimalContainer>
              )}
            </Col>
          </Row>
        </>
      )}
      <DrawerAnimalCurveParameter />
    </Container>
  );
};
export default withRouter(AnimalDetails);
