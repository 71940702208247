import apiFinancial from "../config/api_financial";
// import axios from "axios";
import getHeaders from "./getHeaders";

// const apiFinancial = axios.create({
//   baseURL: "http://localhost:8086",
// });
export function findAllAnimalsForSell(payload) {
  const {
    groupId,
    farmId,
    page,
    size,
    filters,
    sorter,
    withoutPagination,
    justIds,
    ids,
  } = payload;

  const baseUrl = `/bovexo/${groupId}/farms/${farmId}/sales/animals/list`;

  if (justIds != null && justIds === true) {
    return apiFinancial.post(
      `${baseUrl}?justIds=true${
        filters !== "" && filters != null ? `&search=${filters}` : ""
      }`,
      null,
      getHeaders(false, null)
    );
  } else if (withoutPagination != null && withoutPagination === true) {
    if (ids != null) {
      return apiFinancial.post(
        `${baseUrl}?withoutPagination=true`,
        ids,
        getHeaders(false, null)
      );
    }
    return apiFinancial.post(
      `${baseUrl}?withoutPagination=true`,
      null,
      getHeaders(false, null)
    );
  } else {
    let field = null;
    let order = null;

    if (sorter) {
      let { field: fieldSorter, order: orderSorter } = sorter;

      field =
        fieldSorter === "handlingNumber"
          ? "handling_number"
          : fieldSorter === "lastDailyWeight"
          ? "last_daily_weight"
          : fieldSorter;

      order = orderSorter === "descend" ? "DESC" : "ASC";
    }

    return apiFinancial.post(
      `${baseUrl}?withoutPagination=false${page ? `&page=${page - 1}` : ""}
        ${
          sorter != null && Object.entries(sorter).length > 0
            ? `&sort=${field}&direction=${order}`
            : ""
        }
        ${size && `&size=${size}`}
        ${filters !== "" && filters != null ? `&search=${filters}` : ""}`,
      null,
      getHeaders(false, null)
    );
  }
}

export function getFinancialNatureForSales(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/sales/nature`,
    getHeaders(false, signal)
  );
}

export function findAllFarmSells(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/sales`,
    getHeaders(false, signal)
  );
}

export function findFarmSell(payload) {
  const { groupId, farmId, farmSellId, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/sales/${farmSellId}`,
    getHeaders(false, signal)
  );
}

export function findFarmSellInfoForAnimal(payload) {
  const { groupId, farmId, farmSellId, animalId, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/sales/${farmSellId}?animalId=${animalId}`,
    getHeaders(false, signal)
  );
}

export function saveFarmSell(payload) {
  const { groupId, farmId, body, signal } = payload;

  return apiFinancial.post(
    `/bovexo/${groupId}/farms/${farmId}/sales/new`,
    body,
    getHeaders(false, signal)
  );
}

export function updateFarmSell(payload) {
  const { groupId, farmId, farmSellId, body, signal } = payload;

  return apiFinancial.put(
    `/bovexo/${groupId}/farms/${farmId}/sales/update/${farmSellId}`,
    body,
    getHeaders(false, signal)
  );
}

export function deleteFarmSell(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiFinancial.delete(
    `/bovexo/${groupId}/farms/${farmId}/sales/${id}`,
    getHeaders(false, signal)
  );
}

export function getResponseFarmSaleReport(payload) {
  const { groupId, farmId, body } = payload;

  return apiFinancial.post(
    `/bovexo/${groupId}/farms/${farmId}/sales/report`,
    body,
    getHeaders(false, null)
  );
}

export function downloadFarmSaleReport(payload) {
  const { groupId, farmId, body } = payload;

  return apiFinancial.post(
    `/bovexo/${groupId}/farms/${farmId}/sales/report/download/pdf`,
    body,
    getHeaders(false, null, true)
  );
}
