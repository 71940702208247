import { Button, Card, Divider, Menu } from "antd";
import styled from "styled-components";

const { Item } = Menu;

export const Container = styled.div`
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 10px !important;

  .ant-row > .ant-select {
    width: 100%;
  }

  .ant-row > label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;

    &.error {
      color: #d44c4c;
    }
  }

  div.pageHeader {
    padding-top: 24px;
    padding-right: 5px;
  }

  div.pageTree {
    margin-bottom: 9px;
  }

  div.inputWithIconButton {
    display: flex;
    align-items: center;

    position: relative;

    & > button {
      background: transparent;
      border: none;
      cursor: pointer;
      margin-left: 5px;
      margin-top: 5px;
    }
  }

  div.inputWithButton {
    display: flex;
    align-items: center;

    position: relative;

    & > button {
      cursor: pointer;
      margin-left: 5px;
    }
  }

  .maxWidthField {
    width: 100%;
  }

  .colWithBorderLeft {
    border-left: 1px solid #c4c4c4;
    box-sizing: border-box;
    margin-left: 5px;
    margin-right: -5px;
  }

  label.ant-radio-button-wrapper {
    margin-left: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 0px;
  }

  .ant-radio-group-solid .ant-radio-button-wrapper-checked {
    color: #fff !important;
  }

  .row-form {
    margin-top: 13px;
  }

  .row-total-value {
    height: 50%;
  }

  .label-total-value {
    color: #9074bf;
    font-weight: bold;

    svg {
      margin-left: 3px;
    }
  }

  .total-value {
    font-weight: bolder;
    font-size: 16px;
  }

  span.pageTreeInative {
    color: #c4c4c4;
    font-size: 16px;
  }

  span.pageTreeActive {
    color: #684e94;
    font-size: 16px;
  }

  .buttonsCol {
    button {
      &:first-of-type {
        margin-right: 19px;
      }
    }
  }

  .pageBody {
    padding: 20px 5px 0px 5px;
  }

  .buttonsTable {
    button:not(:first-of-type) {
      margin-left: 8px;
    }
  }

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
    label.ant-radio-button-wrapper {
      margin-left: 0px;
    }
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;

export const Title = styled.span`
  font-family: "Asap", sans-serif;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  color: #4b4b4b;

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;

export const DescriptionMessage = styled.span`
  font-family: "Asap", sans-serif;
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 18px;
  text-align: left;
  color: #4b4b4b;

  svg {
    margin-left: 6px;
  }

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;

export const ButtonSelectAllAnimals = styled(Button)`
  border-radius: 5px !important;
  background: transparent !important;
  border: 1px solid #4a85ae !important;
  font-family: Asap !important;
  font-weight: normal !important;
  font-size: 14px !important;
  text-align: left !important;
  color: #4a85ae !important;
  cursor: pointer;
`;

export const ButtonDeselectAllAnimals = styled(Button)`
  border-radius: 5px !important;
  background: transparent !important;
  border: 1px solid #d44c4c !important;
  font-family: Asap !important;
  font-weight: normal !important;
  font-size: 14px !important;
  text-align: left !important;
  color: #d44c4c !important;
  cursor: pointer;
  margin-right: 5px;
`;

export const ColSelectAnimals = styled.div`
  height: auto;
  /* min-height: 562px; */
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  background: #fff;
  box-shadow: 0px 0px 10px #d8d8d8;
  .ant-table-pagination.ant-pagination {
    margin-right: 10px;
  }
  .header {
    width: 100%;
    height: 60px;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;

    span.listAnimals {
      font-family: Asap;
      font-weight: bold;
      font-size: 18px;
      text-align: left;
      color: #4b4b4b;
    }
    .ant-radio-button-wrapper {
      color: #684e94 !important;
      background: #fff;
      border-color: #684e94 !important;
      box-shadow: -1px 0 0 0 #684e94 !important;
      font-size: 14px;
      font-weight: bold;
    }
    .ant-radio-button-wrapper-checked {
      color: #fff !important;
      background: #684e94;
      border-color: #684e94 !important;
      box-shadow: -1px 0 0 0 #684e94 !important;
      font-size: 14px;
      font-weight: bold;
    }
  }
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;

export const CustomDivider = styled(Divider)`
  &.ant-divider-dashed {
    border-color: #a5a5a5 !important;
    border-width: 2px 0 0 !important;
  }
`;

export const CardLotDismembered = styled(Card)`
  border-radius: 10px !important;
  background: #fff !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important;

  .ant-card-head-title {
    height: 40px !important;
    background: #4b4b4b !important;
    color: #fff;
    padding: 12px 0;
  }

  .ant-card-head {
    background: #4b4b4b !important;
    border-radius: 10px 10px 0 0 !important;
  }
`;

export const ButtonProperty = styled(Button)`
  background: #c4c4c4 !important;
  border-color: #c4c4c4 !important;
  font-family: Asap !important;
  font-weight: bolder !important;
  font-size: 14px !important;
  color: #ffffff !important;
  text-align: center !important;
  margin-top: 6px;
  border-radius: 10px !important;
  cursor: not-allowed !important;

  &.enabled {
    background: #684e94 !important;
    border-color: none !important;
    color: #fff !important;
    font-weight: bolder !important;
    cursor: pointer !important;
  }
`;

export const ContainerDropdown = styled.div`
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 17px;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-radius: 29px !important;
  outline: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const ItemMenu = styled(Item)`
  height: 120px !important;
  width: 415px !important;
  border-bottom: 1px solid #eeeeee;

  &.ant-dropdown-menu-item:hover {
    background-color: #f3f3f3 !important;
  }

  .title {
    font-family: Asap;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    color: #684e94;
  }

  .client {
    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #a9c133;
  }

  .info {
    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #4b4b4b;
  }

  .info-bold {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    color: #4b4b4b;
  }
`;

export const ItemMenuSmall = styled(Item)`
  height: 90px !important;
  width: 415px !important;
  border-bottom: 1px solid #eeeeee;

  &.ant-dropdown-menu-item:hover {
    background-color: #f3f3f3 !important;
  }

  .title {
    font-family: Asap;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    color: #684e94;
  }

  .client {
    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #a9c133;
  }

  .info {
    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #4b4b4b;
  }

  .info-bold {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    color: #4b4b4b;
  }
`;

export const InputCard = styled.div`
  background: #f4f4f4;
  z-index: ${(props) => (props.active === true ? "2" : "0")};

  opacity: ${(props) => (props.active === true ? "1" : "0")};

  width: 320px;
  display: flex;
  flex-direction: column;

  position: absolute;
  padding: 0.5rem 0.5rem 0.752rem;
  top: 100%;
  margin-top: 0.5rem;

  box-shadow: 0px 14px 18px -3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  transition: all 0.2s ease-in-out;

  table {
    width: 100%;
    border-collapse: collapse;
    td {
      padding: 0.25rem 0;
      &:nth-last-of-type(1) {
        text-align: right;
      }
    }
  }

  & > .footer {
    margin-top: 1rem;
    & > button {
      width: 100%;
    }
  }
`;
