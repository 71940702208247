import styled from "styled-components";

export const Container = styled.div`
  .ant-row > label {
    color: #9074bf;
    font-family: "Asap", sans-serif;
    font-size: 14px;
    font-weight: bold;
  }
  .error {
    color: #d44c4c !important;
  }
  .ant-select {
    width: 100%;
  }

  .body {
    margin-bottom: 16px;
    .ant-row-flex {
      margin-top: 8px;
    }
    .ant-col {
      text-align: center;
    }

    label.ant-radio-button-wrapper {
      margin-left: 5px;
      padding-left: 10px;
      padding-right: 10px;
      margin-left: 0px;
    }

    .ant-radio-group-solid .ant-radio-button-wrapper-checked {
      color: #fff !important;
    }

    .ant-radio-button-wrapper {
      color: #684e94 !important;
      background: #fff;
      border-color: #684e94 !important;
      box-shadow: -1px 0 0 0 #684e94 !important;
      font-size: 14px;
      font-weight: bold;
    }
    .ant-radio-button-wrapper-checked {
      color: #fff !important;
      background: #684e94;
      border-color: #684e94 !important;
      box-shadow: -1px 0 0 0 #684e94 !important;
      font-size: 14px;
      font-weight: bold;
    }
  }
`;
