import styled from "styled-components";

export const DrawerArrobaPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  width: 100%;

  .ant-row,
  .ant-row-flex {
    width: 100%;
  }
`;
