import PT_BR from "../../languages/pt_br";
import moment from "moment";

export const Types = {
  RESET_ALL_DATAS: "app/RESET_ALL_DATAS",
  RESET_DATA: "app/RESET_DATA",
  AUTHENTICATE: "app/AUTHENTICATE",
  AUTHENTICATE_SUCCESS: "app/AUTHENTICATE_SUCCESS",
  AUTHENTICATE_ERROR: "app/AUTHENTICATE_ERROR",
  LOGOUT_USER: "app/LOGOUT_USER",
  COLLAPSE_MENU_ADMIN: "app/COLLAPSE_MENU_ADMIN",
  SET_GROUP_SELECTED: "app/SET_GROUP_SELECTED",
  SET_FARM_SELECTED: "app/SET_FARM_SELECTED",
  CHANGE_LANGUAGE: "app/CHANGE_LANGUAGE",
  OPEN_TOP_BAR_MENU: "app/OPEN_TOP_BAR_MENU",
  SESSION_EXPIRED: "app/SESSION_EXPIRED",
  SHOW_ALMOST_EXPIRED: "app/SHOW_ALMOST_EXPIRED",
  HIDE_ALMOST_EXPIRED: "app/HIDE_ALMOST_EXPIRED",
  SET_ALERT_SELECTED: "app/SET_ALERT_SELECTED",
  SET_NOTIFICATIONS: "app/SET_NOTIFICATIONS",
  SHOW_HIDE_DRAWER_NOTIFICATIONS: "app/SHOW_HIDE_DRAWER_NOTIFICATIONS",
  UPDATE_ONE_NOTIFICATION_VISUALIZATION:
    "app/UPDATE_ONE_NOTIFICATION_VISUALIZATION",
  UPDATE_ALL_NOTIFICATIONS_VISUALIZATION:
    "app/UPDATE_ALL_NOTIFICATIONS_VISUALIZATION",
  ADD_NEW_NOTIFICATION_FROM_WS: "app/ADD_NEW_NOTIFICATION_FROM_WS",
  SHOW_MODAL_GROUP_AND_FARM: "app/SHOW_MODAL_GROUP_AND_FARM",
  HIDE_MODAL_GROUP_AND_FARM: "app/HIDE_MODAL_GROUP_AND_FARM",
  SHOW_MODAL_ANIMAL_WEIGHTS_UPLOAD: "app/SHOW_MODAL_ANIMAL_WEIGHTS_UPLOAD",
  HIDE_MODAL_ANIMAL_WEIGHTS_UPLOAD: "app/HIDE_MODAL_ANIMAL_WEIGHTS_UPLOAD",
  SHOW_MODAL_ANIMAL_UPLOAD: "app/SHOW_MODAL_ANIMAL_UPLOAD",
  HIDE_MODAL_ANIMAL_UPLOAD: "app/HIDE_MODAL_ANIMAL_UPLOAD",
  SHOW_MODAL_SUPPLEMENT_UPLOAD: "app/SHOW_MODAL_SUPPLEMENT_UPLOAD",
  HIDE_MODAL_SUPPLEMENT_UPLOAD: "app/HIDE_MODAL_SUPPLEMENT_UPLOAD",
  UPDATE_FARMS_IN_GROUP_SELECTED: "app/UPDATE_FARMS_IN_GROUP_SELECTED",
  SHOW_MODAL_CONTACT_US: "app/SHOW_MODAL_CONTACT_US",
  HIDE_MODAL_CONTACT_US: "app/HIDE_MODAL_CONTACT_US",
  SHOW_DRAWER_WEATHER_SEASONS_PARAMETER:
    "app/SHOW_DRAWER_WEATHER_SEASONS_PARAMETER",
  HIDE_DRAWER_WEATHER_SEASONS_PARAMETER:
    "app/HIDE_DRAWER_WEATHER_SEASONS_PARAMETER",
  SHOW_HIDE_MODAL_SYSTEM_ABOUT: "app/SHOW_HIDE_MODAL_SYSTEM_ABOUT",
  UPDATE_USER_INFO: "app/UPDATE_USER_INFO",
  GET_ALL_ROLES: "app/GET_ALL_ROLES",
  GET_ALL_ROLES_SUCCESS: "app/GET_ALL_ROLES_SUCCESS",
  SHOW_HIDE_MODAL_WELCOME_TRIAL: "app/SHOW_HIDE_MODAL_WELCOME_TRIAL",
  SHOW_MODAL_EXPORT_FINANCIAL_TRANSACTIONS:
    "app/SHOW_MODAL_EXPORT_FINANCIAL_TRANSACTIONS",
  HIDE_MODAL_EXPORT_FINANCIAL_TRANSACTIONS:
    "app/HIDE_MODAL_EXPORT_FINANCIAL_TRANSACTIONS",
  SHOW_MODAL_FINANCIAL_TRANSACTION_UPLOAD:
    "app/SHOW_MODAL_FINANCIAL_TRANSACTION_UPLOAD",
  HIDE_MODAL_FINANCIAL_TRANSACTION_UPLOAD:
    "app/HIDE_MODAL_FINANCIAL_TRANSACTION_UPLOAD",
  ACTIVE_TOP_MENU_NAVIGATION: "app/ACTIVE_TOP_MENU_NAVIGATION",
  INACTIVE_TOP_MENU_NAVIGATION: "app/INACTIVE_TOP_MENU_NAVIGATION",
  SHOW_OR_HIDE_DRAWER_WEATHER: "app/SHOW_OR_HIDE_DRAWER_WEATHER",
  SHOW_OR_HIDE_DRAWER_ARROBA_PRICE: "app/SHOW_OR_HIDE_DRAWER_ARROBA_PRICE",
};

export const Creators = {
  resetAllDatas: () => ({
    type: Types.RESET_ALL_DATAS,
  }),
  resetData: () => ({
    type: Types.RESET_DATA,
  }),
  authenticate: (username, password, remember_me) => ({
    type: Types.AUTHENTICATE,
    payload: {
      username,
      password,
      remember_me,
    },
  }),
  authenticate_success: (data, appVersion) => ({
    type: Types.AUTHENTICATE_SUCCESS,
    payload: { data, appVersion },
  }),
  authenticate_error: (data) => ({
    type: Types.AUTHENTICATE_ERROR,
    payload: { data },
  }),
  logout_user: () => ({
    type: Types.LOGOUT_USER,
    payload: {},
  }),
  collapse_menu_admin: () => ({
    type: Types.COLLAPSE_MENU_ADMIN,
    payload: {},
  }),
  indexGroups: () => ({
    type: Types.GET_GROUPS,
    payload: {},
  }),
  indexGroupsSuccess: (data) => ({
    type: Types.GET_GROUPS_SUCCESS,
    payload: { data },
  }),
  indexGroupsError: (error) => ({
    type: Types.GET_GROUPS_SUCCESS,
    payload: { error },
  }),
  changeLanguage: (lang, translation) => ({
    type: Types.CHANGE_LANGUAGE,
    payload: { lang, translation },
  }),
  setGroupSelected: (group) => ({
    type: Types.SET_GROUP_SELECTED,
    payload: { group },
  }),
  setFarmSelected: (farm) => ({
    type: Types.SET_FARM_SELECTED,
    payload: { farm },
  }),
  setAlertSelected: (alert) => ({
    type: Types.SET_ALERT_SELECTED,
    payload: { alert },
  }),
  openTopBarMenu: (data) => ({
    type: Types.OPEN_TOP_BAR_MENU,
    payload: { data },
  }),
  setNotifications: (notifications) => ({
    type: Types.SET_NOTIFICATIONS,
    payload: { notifications },
  }),
  showHideDrawerNotifications: () => ({
    type: Types.SHOW_HIDE_DRAWER_NOTIFICATIONS,
    payload: {},
  }),
  updateOneNotificationVisualization: (notification) => ({
    type: Types.UPDATE_ONE_NOTIFICATION_VISUALIZATION,
    payload: {
      notification,
    },
  }),
  updateAllNotificationsVisualization: (notifications) => ({
    type: Types.UPDATE_ALL_NOTIFICATIONS_VISUALIZATION,
    payload: {
      notifications,
    },
  }),
  addNewNotificationFromWS: (notification) => ({
    type: Types.ADD_NEW_NOTIFICATION_FROM_WS,
    payload: {
      notification,
    },
  }),
  showModalGroupAndFarm: () => ({
    type: Types.SHOW_MODAL_GROUP_AND_FARM,
  }),
  hideModalGroupAndFarm: () => ({
    type: Types.HIDE_MODAL_GROUP_AND_FARM,
  }),
  showAlmostExpired: () => ({
    type: Types.SHOW_ALMOST_EXPIRED,
  }),
  hideAlmostExpired: () => ({
    type: Types.HIDE_ALMOST_EXPIRED,
  }),
  showModalAnimalWeightUpload: () => ({
    type: Types.SHOW_MODAL_ANIMAL_WEIGHTS_UPLOAD,
  }),
  hideModalAnimalWeightUpload: () => ({
    type: Types.HIDE_MODAL_ANIMAL_WEIGHTS_UPLOAD,
  }),
  showModalAnimalUpload: () => ({
    type: Types.SHOW_MODAL_ANIMAL_UPLOAD,
  }),
  hideModalAnimalUpload: () => ({
    type: Types.HIDE_MODAL_ANIMAL_UPLOAD,
  }),
  showModalSupplementUpload: () => ({
    type: Types.SHOW_MODAL_SUPPLEMENT_UPLOAD,
  }),
  hideModalSupplementUpload: () => ({
    type: Types.HIDE_MODAL_SUPPLEMENT_UPLOAD,
  }),
  updateFarmsInGroupSelected: (farms) => ({
    type: Types.UPDATE_FARMS_IN_GROUP_SELECTED,
    payload: { farms },
  }),
  showModalContactUs: () => ({
    type: Types.SHOW_MODAL_CONTACT_US,
  }),
  hideModalContactUs: () => ({
    type: Types.HIDE_MODAL_CONTACT_US,
  }),
  showDrawerWeatherSeasonsParameterVisible: () => ({
    type: Types.SHOW_DRAWER_WEATHER_SEASONS_PARAMETER,
  }),
  hideDrawerWeatherSeasonsParameterVisible: () => ({
    type: Types.HIDE_DRAWER_WEATHER_SEASONS_PARAMETER,
  }),
  showOrHideSystemAboutModal: () => ({
    type: Types.SHOW_HIDE_MODAL_SYSTEM_ABOUT,
  }),
  updateUserInfo: (user) => ({
    type: Types.UPDATE_USER_INFO,
    payload: { user },
  }),
  getAllRoles: (userId, groupId, farmId, isInputSupplier) => ({
    type: Types.GET_ALL_ROLES,
    payload: { userId, groupId, farmId, isInputSupplier },
  }),
  getAllRolesSuccess: (data) => ({
    type: Types.GET_ALL_ROLES_SUCCESS,
    payload: { data },
  }),
  showOrHideModalWelcomeTrial: () => ({
    type: Types.SHOW_HIDE_MODAL_WELCOME_TRIAL,
  }),
  showModalExportTransactions: () => ({
    type: Types.SHOW_MODAL_EXPORT_FINANCIAL_TRANSACTIONS,
  }),
  hideModalExportTransactions: () => ({
    type: Types.HIDE_MODAL_EXPORT_FINANCIAL_TRANSACTIONS,
  }),
  showModalFinancialTransactionUpload: () => ({
    type: Types.SHOW_MODAL_FINANCIAL_TRANSACTION_UPLOAD,
  }),
  hideModalFinancialTransactionUpload: () => ({
    type: Types.HIDE_MODAL_FINANCIAL_TRANSACTION_UPLOAD,
  }),
  activeTopMenuNavigation: (id, level) => ({
    type: Types.ACTIVE_TOP_MENU_NAVIGATION,
    payload: { id, level },
  }),
  inactiveTopMenuNavigation: (level) => ({
    type: Types.INACTIVE_TOP_MENU_NAVIGATION,
    payload: { level },
  }),
  showOrHideDrawerWeather: () => ({
    type: Types.SHOW_OR_HIDE_DRAWER_WEATHER,
    payload: {},
  }),
  showOrHideDrawerArrobaPrice: () => ({
    type: Types.SHOW_OR_HIDE_DRAWER_ARROBA_PRICE,
    payload: {},
  }),
};

const INITIAL_STATE = {
  appVersion: localStorage.getItem("@Bovexo:app_version")
    ? localStorage.getItem("@Bovexo:app_version")
    : null,
  remember_me: localStorage.getItem("@Bovexo:remember_me")
    ? localStorage.getItem("@Bovexo:remember_me")
    : false,
  token: localStorage.getItem("@Bovexo:token")
    ? localStorage.getItem("@Bovexo:token")
    : "",
  refresh_token: localStorage.getItem("@Bovexo:refresh_token")
    ? localStorage.getItem("@Bovexo:refresh_token")
    : "",
  expires_in: localStorage.getItem("@Bovexo:expires_in")
    ? localStorage.getItem("@Bovexo:expires_in")
    : "",
  user: localStorage.getItem("@Bovexo:user")
    ? JSON.parse(localStorage.getItem("@Bovexo:user"))
    : {},
  groupSelected: localStorage.getItem("@Bovexo:group")
    ? JSON.parse(localStorage.getItem("@Bovexo:group"))
    : {},
  farmSelected: localStorage.getItem("@Bovexo:farm")
    ? JSON.parse(localStorage.getItem("@Bovexo:farm"))
    : {},
  languageSelected: localStorage.getItem("@Bovexo:language")
    ? localStorage.getItem("@Bovexo:language")
    : "pt_br",
  translation: localStorage.getItem("@Bovexo:translations")
    ? JSON.parse(localStorage.getItem("@Bovexo:translations"))
    : PT_BR,
  collapse_menu_admin: localStorage.getItem("@Bovexo:collapseOpen")
    ? localStorage.getItem("@Bovexo:collapseOpen") === "true"
    : true,
  error: null,
  gateway_url: localStorage.getItem("@Bovexo:gateway_url"),
  isLogged: localStorage.getItem("@Bovexo:logged")
    ? localStorage.getItem("@Bovexo:logged")
    : false,
  isLoading: false,
  attemptsLogin: 1,
  openTopBarMenu: "",
  sessionExpired: false,
  almostExpired: false,
  alertSelected: {},
  notifications: {
    content: [],
    totalElements: 0,
  },
  drawerNotificationVisible: false,
  modalGroupAndFarmsVisible: localStorage.getItem(
    "@Bovexo:modalGroupAndFarmsVisible"
  )
    ? localStorage.getItem("@Bovexo:modalGroupAndFarmsVisible") === "true"
    : false,
  modalAnimalWeightUploadVisible: false,
  modalAnimalUploadVisible: false,
  modalSupplementUploadVisible: false,
  modalContactUsVisible: false,
  drawerWeatherSeasonsParameterVisible: false,
  isModalSystemAboutVisible: false,
  skipModalWelcomeTrial: localStorage.getItem("@Bovexo:skipModalWelcomeTrial")
    ? localStorage.getItem("@Bovexo:skipModalWelcomeTrial") === "true"
    : false,
  isModalWelcomeTrialVisible: false,
  modalExportFinancialTransactionsVisible: false,
  modalFinancialTransactionUploadVisible: false,
  activeLevelOneMenu: localStorage.getItem("@Bovexo:activeLevelOneMenu")
    ? localStorage.getItem("@Bovexo:activeLevelOneMenu")
    : null,
  activeLevelTwoMenu: localStorage.getItem("@Bovexo:activeLevelTwoMenu")
    ? localStorage.getItem("@Bovexo:activeLevelTwoMenu")
    : null,
  activeLevelThreeMenu: localStorage.getItem("@Bovexo:activeLevelThreeMenu")
    ? localStorage.getItem("@Bovexo:activeLevelThreeMenu")
    : null,
  activeLevelFourMenu: localStorage.getItem("@Bovexo:activeLevelFourMenu")
    ? localStorage.getItem("@Bovexo:activeLevelFourMenu")
    : null,
  isDrawerWeatherVisible: false,
  isDrawerArrobaPriceVisible: false,
};

export default function app(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_ALL_DATAS:
      return state;
    case Types.RESET_DATA:
      localStorage.clear();
      return INITIAL_STATE;

    case Types.AUTHENTICATE:
      localStorage.setItem("@Bovexo:remember_me", action.payload.remember_me);
      return { ...state, isLoading: true };

    case Types.AUTHENTICATE_SUCCESS:
      localStorage.setItem("@Bovexo:app_version", action.payload.appVersion);
      localStorage.setItem("@Bovexo:token", action.payload.data.access_token);
      localStorage.setItem(
        "@Bovexo:refresh_token",
        action.payload.data.refresh_token
      );
      const now = new Date();
      localStorage.setItem(
        "@Bovexo:expires_in",
        new Date(now.getTime() + action.payload.data.expires_in * 1000)
      );
      localStorage.setItem("@Bovexo:logged", true);
      localStorage.setItem(
        "@Bovexo:gateway_url",
        "http://ec2-23-20-16-243.compute-1.amazonaws.com:8000"
      );
      const userAuthenticated = {
        id: action.payload.data.user_id,
        fullName: action.payload.data.user_fullname,
        email: action.payload.data.user_email,
        avatar: action.payload.data.user_avatar,
        access: action.payload.data.access,
        isInputSupplier: action.payload.data.user_is_input_supplier,
      };
      localStorage.setItem("@Bovexo:user", JSON.stringify(userAuthenticated));
      return {
        ...state,
        token: action.payload.data.access_token,
        refresh_token: action.payload.data.refresh_token,
        expires_in: action.payload.data.expires_in,
        user: userAuthenticated,
        isLoading: false,
        isLogged: true,
        appVersion: action.payload.appVersion,
      };

    case Types.AUTHENTICATE_ERROR:
      return {
        ...state,
        isLoading: false,
        attemptsLogin: 1,
        error: action.payload.data,
      };

    case Types.LOGOUT_USER:
      localStorage.clear();
      return INITIAL_STATE;

    case Types.SESSION_EXPIRED:
      return {
        ...state,
        sessionExpired: true,
      };

    case Types.COLLAPSE_MENU_ADMIN:
      let collapseStatus = !state.collapse_menu_admin;
      localStorage.setItem("@Bovexo:collapseOpen", collapseStatus);
      return {
        ...state,
        collapse_menu_admin: collapseStatus,
      };

    case Types.CHANGE_LANGUAGE:
      localStorage.setItem("@Bovexo:language", action.payload.lang);
      localStorage.setItem(
        "@Bovexo:translations",
        JSON.stringify(action.payload.translation)
      );
      switch (action.payload.lang) {
        case "pt_br":
          moment().locale("pt-br");
          break;
        case "en":
          moment().locale("en-nz");
          break;

        case "es":
          moment().locale("es");
          break;
        default:
          break;
      }
      return {
        ...state,
        languageSelected: action.payload.lang,
        translation: action.payload.translation,
      };

    case Types.SET_GROUP_SELECTED:
      localStorage.setItem(
        "@Bovexo:group",
        JSON.stringify(action.payload.group)
      );
      if (state.groupSelected.id !== action.payload.group.id) {
        localStorage.removeItem("@Bovexo:farm");
        return {
          ...state,
          groupSelected: action.payload.group,
          farmSelected: {},
          alertSelected: {},
        };
      }
      return { ...state, groupSelected: action.payload.group };

    case Types.SET_FARM_SELECTED:
      localStorage.setItem("@Bovexo:farm", JSON.stringify(action.payload.farm));
      return { ...state, farmSelected: action.payload.farm, alertSelected: {} };

    case Types.SET_ALERT_SELECTED:
      return { ...state, alertSelected: action.payload.alert };

    case Types.OPEN_TOP_BAR_MENU:
      return { ...state, openTopBarMenu: action.payload.data };

    case Types.SET_NOTIFICATIONS:
      return { ...state, notifications: action.payload.notifications };

    case Types.SHOW_HIDE_DRAWER_NOTIFICATIONS:
      return {
        ...state,
        drawerNotificationVisible: !state.drawerNotificationVisible,
      };

    case Types.UPDATE_ONE_NOTIFICATION_VISUALIZATION:
      const updateNotificationsOne = state.notifications.content.filter(
        (n) => n.id !== action.payload.notification.id
      );
      return {
        ...state,
        notifications: {
          content: updateNotificationsOne,
          totalElements: state.notifications.totalElements - 1,
        },
      };

    case Types.UPDATE_ALL_NOTIFICATIONS_VISUALIZATION:
      const updateNotificationsAll = state.notifications.content.filter((n) => {
        if (action.payload.notifications.find((n2) => n.id === n2.id) != null) {
          return false;
        }
        return true;
      });
      return {
        ...state,
        notifications: {
          content: updateNotificationsAll,
          totalElements: 0,
        },
      };

    case Types.ADD_NEW_NOTIFICATION_FROM_WS:
      return {
        ...state,
        // notifications: [action.payload.notification, ...state.notifications],
      };

    case Types.SHOW_MODAL_GROUP_AND_FARM:
      localStorage.setItem("@Bovexo:modalGroupAndFarmsVisible", true);
      return {
        ...state,
        modalGroupAndFarmsVisible: true,
      };

    case Types.HIDE_MODAL_GROUP_AND_FARM:
      localStorage.setItem("@Bovexo:modalGroupAndFarmsVisible", false);
      return {
        ...state,
        modalGroupAndFarmsVisible: false,
      };

    case Types.SHOW_MODAL_ANIMAL_WEIGHTS_UPLOAD:
      return {
        ...state,
        modalAnimalWeightUploadVisible: true,
      };

    case Types.HIDE_MODAL_ANIMAL_WEIGHTS_UPLOAD:
      return {
        ...state,
        modalAnimalWeightUploadVisible: false,
      };

    case Types.SHOW_MODAL_ANIMAL_UPLOAD:
      return {
        ...state,
        modalAnimalUploadVisible: true,
      };

    case Types.HIDE_MODAL_ANIMAL_UPLOAD:
      return {
        ...state,
        modalAnimalUploadVisible: false,
      };

    case Types.SHOW_MODAL_SUPPLEMENT_UPLOAD:
      return {
        ...state,
        modalSupplementUploadVisible: true,
      };

    case Types.HIDE_MODAL_SUPPLEMENT_UPLOAD:
      return {
        ...state,
        modalSupplementUploadVisible: false,
      };

    case Types.SHOW_ALMOST_EXPIRED:
      if (
        localStorage.getItem("@Bovexo:almostExpired") === false ||
        localStorage.getItem("@Bovexo:almostExpired") === "false"
      )
        return {
          ...state,
          almostExpired: false,
        };
      else
        return {
          ...state,
          almostExpired: true,
        };

    case Types.HIDE_ALMOST_EXPIRED:
      localStorage.setItem("@Bovexo:almostExpired", false);
      return {
        ...state,
        almostExpired: false,
      };

    case Types.UPDATE_FARMS_IN_GROUP_SELECTED:
      let group = state.groupSelected;
      group.farms = action.payload.farms;
      localStorage.setItem("@Bovexo:group", JSON.stringify(group));
      return {
        ...state,
        groupSelected: group,
        alertSelected: {},
      };

    case Types.SHOW_MODAL_CONTACT_US:
      return {
        ...state,
        modalContactUsVisible: true,
      };

    case Types.HIDE_MODAL_CONTACT_US:
      return {
        ...state,
        modalContactUsVisible: false,
      };
    case Types.SHOW_DRAWER_WEATHER_SEASONS_PARAMETER:
      return {
        ...state,
        drawerWeatherSeasonsParameterVisible: true,
      };

    case Types.HIDE_DRAWER_WEATHER_SEASONS_PARAMETER:
      return {
        ...state,
        drawerWeatherSeasonsParameterVisible: false,
      };

    case Types.SHOW_HIDE_MODAL_SYSTEM_ABOUT:
      return {
        ...state,
        isModalSystemAboutVisible: !state.isModalSystemAboutVisible,
      };

    case Types.UPDATE_USER_INFO:
      const updatedUser = {
        ...state.user,
        fullName: action.payload.user.fullname,
        avatar: action.payload.user.avatar,
      };
      localStorage.setItem("@Bovexo:user", JSON.stringify(updatedUser));
      return {
        ...state,
        user: updatedUser,
      };
    case Types.GET_ALL_ROLES:
      return {
        ...state,
        isLoading: false,
      };
    case Types.GET_ALL_ROLES_SUCCESS:
      const updatedUserWithRoles = {
        ...state.user,
        roles: action.payload.data,
      };
      localStorage.setItem(
        "@Bovexo:user",
        JSON.stringify(updatedUserWithRoles)
      );
      return {
        ...state,
        user: updatedUserWithRoles,
      };
    case Types.SHOW_HIDE_MODAL_WELCOME_TRIAL:
      if (!localStorage.getItem("@Bovexo:skipModalWelcomeTrial")) {
        localStorage.setItem(
          "@Bovexo:skipModalWelcomeTrial",
          !state.skipModalWelcomeTrial
        );
        return {
          ...state,
          skipModalWelcomeTrial: !state.skipModalWelcomeTrial,
          isModalWelcomeTrialVisible: !state.isModalWelcomeTrialVisible,
        };
      } else {
        return {
          ...state,
          isModalWelcomeTrialVisible:
            state.skipModalWelcomeTrial === true
              ? false
              : !state.isModalWelcomeTrialVisible,
        };
      }
    case Types.SHOW_MODAL_EXPORT_FINANCIAL_TRANSACTIONS:
      return {
        ...state,
        modalExportFinancialTransactionsVisible: true,
      };
    case Types.HIDE_MODAL_EXPORT_FINANCIAL_TRANSACTIONS:
      return {
        ...state,
        modalExportFinancialTransactionsVisible: false,
      };
    case Types.SHOW_MODAL_FINANCIAL_TRANSACTION_UPLOAD:
      return {
        ...state,
        modalFinancialTransactionUploadVisible: true,
      };
    case Types.HIDE_MODAL_FINANCIAL_TRANSACTION_UPLOAD:
      return {
        ...state,
        modalFinancialTransactionUploadVisible: false,
      };
    case Types.ACTIVE_TOP_MENU_NAVIGATION:
      const { level, id } = action.payload;
      let newActiveLevelOneMenu = state.activeLevelOneMenu;
      let newActiveLevelTwoMenu = state.activeLevelTwoMenu;
      let newActiveLevelThreeMenu = state.activeLevelThreeMenu;
      let newActiveLevelFourMenu = state.activeLevelFourMenu;
      switch (level) {
        case 1:
          newActiveLevelOneMenu = id;
          localStorage.setItem("@Bovexo:activeLevelOneMenu", id);
          newActiveLevelTwoMenu = null;
          localStorage.removeItem("@Bovexo:activeLevelTwoMenu");
          newActiveLevelThreeMenu = null;
          localStorage.removeItem("@Bovexo:activeLevelThreeMenu");
          newActiveLevelFourMenu = null;
          localStorage.removeItem("@Bovexo:activeLevelFourMenu");
          break;
        case 2:
          newActiveLevelTwoMenu = id;
          localStorage.setItem("@Bovexo:activeLevelTwoMenu", id);
          newActiveLevelThreeMenu = null;
          localStorage.removeItem("@Bovexo:activeLevelThreeMenu");
          break;
        case 3:
          newActiveLevelThreeMenu = id;
          localStorage.setItem("@Bovexo:activeLevelThreeMenu", id);
          newActiveLevelFourMenu = null;
          localStorage.removeItem("@Bovexo:activeLevelFourMenu");
          break;
        case 4:
          newActiveLevelFourMenu = id;
          localStorage.setItem("@Bovexo:activeLevelFourMenu", id);
          break;
        default:
          break;
      }
      return {
        ...state,
        activeLevelOneMenu: newActiveLevelOneMenu,
        activeLevelTwoMenu: newActiveLevelTwoMenu,
        activeLevelThreeMenu: newActiveLevelThreeMenu,
        activeLevelFourMenu: newActiveLevelFourMenu,
      };
    case Types.INACTIVE_TOP_MENU_NAVIGATION:
      const { level: levelToInactivate } = action.payload;

      let inactiveLevelOneMenu = state.activeLevelOneMenu;
      let inactiveLevelTwoMenu = state.activeLevelTwoMenu;
      let inactiveLevelThreeMenu = state.activeLevelThreeMenu;
      let inactiveLevelFourMenu = state.activeLevelFourMenu;

      if (levelToInactivate === 4 && inactiveLevelFourMenu === null) {
        inactiveLevelThreeMenu = null;
        localStorage.removeItem("@Bovexo:activeLevelThreeMenu");
      }

      switch (levelToInactivate) {
        case 1:
          inactiveLevelOneMenu = null;
          localStorage.removeItem("@Bovexo:activeLevelOneMenu");
          inactiveLevelTwoMenu = null;
          localStorage.removeItem("@Bovexo:activeLevelTwoMenu");
          inactiveLevelThreeMenu = null;
          localStorage.removeItem("@Bovexo:activeLevelThreeMenu");
          inactiveLevelFourMenu = null;
          localStorage.removeItem("@Bovexo:activeLevelFourMenu");
          break;
        case 2:
          inactiveLevelTwoMenu = null;
          localStorage.removeItem("@Bovexo:activeLevelTwoMenu");
          inactiveLevelThreeMenu = null;
          localStorage.removeItem("@Bovexo:activeLevelThreeMenu");
          inactiveLevelFourMenu = null;
          localStorage.removeItem("@Bovexo:activeLevelFourMenu");
          break;
        case 3:
          inactiveLevelThreeMenu = null;
          localStorage.removeItem("@Bovexo:activeLevelThreeMenu");
          inactiveLevelFourMenu = null;
          localStorage.removeItem("@Bovexo:activeLevelFourMenu");
          break;
        case 4:
          inactiveLevelFourMenu = null;
          localStorage.removeItem("@Bovexo:activeLevelFourMenu");
          break;
        default:
          break;
      }

      return {
        ...state,
        activeLevelOneMenu: inactiveLevelOneMenu,
        activeLevelTwoMenu: inactiveLevelTwoMenu,
        activeLevelThreeMenu: inactiveLevelThreeMenu,
        activeLevelFourMenu: inactiveLevelFourMenu,
      };
    case Types.SHOW_OR_HIDE_DRAWER_WEATHER:
      return {
        ...state,
        isDrawerWeatherVisible: !state.isDrawerWeatherVisible,
      };
    case Types.SHOW_OR_HIDE_DRAWER_ARROBA_PRICE:
      return {
        ...state,
        isDrawerArrobaPriceVisible: !state.isDrawerArrobaPriceVisible,
      };
    default:
      return state;
  }
}
