import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  AnimalReproductionContextProvider,
  useAnimalReproductionContext,
} from "./context";

import { Col, Icon, Radio, Row, Select, Tabs } from "antd";
import RadioGroup from "antd/lib/radio/group";
import ButtonStandard from "../../../components/utils/button";

import AnimalAgePregnanciesDash from "../dashboards/reproduction/animalAgePregnanciesDash";
import AnimalReproductionIndicatorDash from "../dashboards/reproduction/animalReproductionIndicatorDash";
import AnimalWeightScoreDash from "../dashboards/reproduction/animalWeightScoreDash";
import {
  AnimalReproductionFemaleReproductionEventsTable,
  AnimalReproductionFemaleTable,
  AnimalReproductionMaleTable,
} from "./table";
import useModuleContext from "../../../hooks/useModuleContext";

const AnimalReproductionLayout = () => {
  const [isDashboardVisible, setIsDashboardVisible] = useState(true);
  const {
    paginationFemaleReproductionEventTable: {
      page: reproductionEventsPage,
      size: reproductionEventsSize,
      tableSorters: reproductionEventsTableSorters,
      tableFilters: reproductionEventsTableFilters,
    },
    paginationFemaleTable: {
      page: fPage,
      size: fSize,
      tableSorters: fTableSorters,
      tableFilters: fTableFilters,
      ids: fIds,
    },
    paginationMaleTable: {
      page: mPage,
      size: mSize,
      tableSorters: mTableSorters,
      tableFilters: mTableFilters,
      ids: mIds,
    },
  } = useSelector((state) => state.animal);

  const {
    identificationSelected,
    handleChangeIdentification,
    femaleTableTypeSelected,
    handleChangeFemaleTableType,
    fetchReproductionMaleData,
    fetchReproductionFemaleData,
    fetchReproductionFemaleReproductionEventData,
  } = useAnimalReproductionContext();

  const { isAllowedFunctions } = useModuleContext();

  const handleRefresh = useCallback(() => {
    fetchReproductionMaleData(mPage, mTableSorters, mTableFilters, mSize, null);
    fetchReproductionFemaleData(
      fPage,
      fTableSorters,
      fTableFilters,
      fSize,
      null
    );
  }, [
    mPage,
    mTableSorters,
    mTableFilters,
    mSize,
    fPage,
    fTableSorters,
    fTableFilters,
    fSize,
    fetchReproductionMaleData,
    fetchReproductionFemaleData,
  ]);

  useEffect(() => {
    if (femaleTableTypeSelected === "register") {
      fetchReproductionFemaleData(
        fPage,
        fTableSorters,
        fTableFilters,
        fSize,
        fIds
      );
    } else if (femaleTableTypeSelected === "reproduction_events") {
      fetchReproductionFemaleReproductionEventData(
        moment().year(),
        reproductionEventsPage,
        reproductionEventsTableSorters,
        reproductionEventsTableFilters,
        reproductionEventsSize,
        null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    femaleTableTypeSelected,
    fetchReproductionFemaleData,
    fetchReproductionFemaleReproductionEventData,
  ]);

  useLayoutEffect(() => {
    fetchReproductionMaleData(mPage, mTableSorters, mTableFilters, mSize, mIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isAllowedFunctions(["REPRODUCTION_PARAMETERS"]) && (
        <>
          {/* Dashboard */}
          <Row type="flex" justify="end">
            <Col>
              <ButtonStandard
                type="button"
                buttonType="secondary"
                onClick={() => setIsDashboardVisible((old) => !old)}
              >
                {isDashboardVisible ? (
                  <>
                    <Icon type="fullscreen-exit" /> Ocultar painéis
                  </>
                ) : (
                  <>
                    <Icon type="fullscreen" /> Exibir painéis
                  </>
                )}
              </ButtonStandard>
            </Col>
          </Row>
          {isDashboardVisible && (
            <Row type="flex" gutter={16} style={{ marginBottom: 16 }}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={18}
                xl={18}
                xxl={18}
                style={{ marginTop: 8 }}
              >
                <Row type="flex" align="middle" gutter={16}>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    xxl={12}
                    style={{ marginTop: 8 }}
                  >
                    <AnimalAgePregnanciesDash />
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    xxl={12}
                    style={{ marginTop: 8 }}
                  >
                    <AnimalWeightScoreDash />
                  </Col>
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={6}
                xl={6}
                xxl={6}
                style={{ marginTop: 8 }}
              >
                <AnimalReproductionIndicatorDash />
              </Col>
            </Row>
          )}
        </>
      )}
      {/* Select identification */}
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        style={{ marginBottom: 16 }}
      >
        <Col>
          <Row type="flex" align="middle" gutter={16}>
            <Col>
              <strong>Identificação: </strong>
            </Col>
            <Col>
              <Select
                value={identificationSelected}
                allowClear={false}
                onChange={(value) => {
                  handleChangeIdentification(value);
                  fetchReproductionMaleData();
                  fetchReproductionFemaleData();
                }}
              >
                <Select.Option value={"handlingNumber"}>
                  Identificação
                </Select.Option>
                <Select.Option value={"tagId"}>Id. Eletrônica</Select.Option>
                <Select.Option value={"sisbov"}>SISBOV</Select.Option>
                <Select.Option value={"nickname"}>Nome</Select.Option>
              </Select>
            </Col>
            <Col>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                onClick={handleRefresh}
              >
                <Icon type="reload" />
              </ButtonStandard>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* Table */}
      <Row type="flex">
        <Col span={24}>
          <Tabs type="card" defaultActiveKey="females">
            <Tabs.TabPane tab="Fêmeas" key="females">
              <Row type="flex">
                <Col span={24}>
                  <RadioGroup
                    value={femaleTableTypeSelected}
                    onChange={(e) =>
                      handleChangeFemaleTableType(e.target.value)
                    }
                  >
                    <Radio value={"register"}>Animais</Radio>
                    <Radio value={"reproduction_events"}>
                      Eventos Reprodutivos
                    </Radio>
                  </RadioGroup>
                </Col>
              </Row>
              {femaleTableTypeSelected === "register" ? (
                <AnimalReproductionFemaleTable />
              ) : femaleTableTypeSelected === "reproduction_events" ? (
                <AnimalReproductionFemaleReproductionEventsTable />
              ) : null}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Machos" key="males">
              <AnimalReproductionMaleTable />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

const AnimalReproduction = () => {
  return (
    <AnimalReproductionContextProvider>
      <AnimalReproductionLayout />
    </AnimalReproductionContextProvider>
  );
};

export default AnimalReproduction;
