import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as PurchaseScenarioActions } from "../../../store/ducks/purchaseScenario";
import moment from "moment";
import Axios from "axios";
/** Components */
import {
  Container,
  Title,
  CustomDivider,
  FavoriteCard,
  InitialCard,
  SaleCardFavorite,
  FilterCard,
  PriceCard,
  SaleCard,
  ButtonStandard,
  MessageContainer,
  Header,
  Main,
} from "./styles";
import {
  Row,
  Col,
  Select,
  DatePicker,
  Popover,
  notification,
  Spin,
} from "antd";
import Loading from "../../../components/utils/loading";
import BreadCrumbs from "../../../components/utils/breadCrumbs";
import ArrowIcon from "../../../components/utils/icons/arrows/red/left";
import StarIcon from "../../../components/utils/icons/star";
import FilterIcon from "../../../components/utils/icons/filter";
import DisplayPurchaseScenario from "../../../components/drawers/purchaseScenario";
/** Services */
import {
  showPurchaseScenario,
  indexScenariosResultsItems,
  saveScenariosResultsItemsFavorite,
} from "../../../services/purchaseScenarioService";
import { numberMask } from "../../../services/helpersMethodsService";

function compareWeek(a, b) {
  let comparison = 0;
  if (a.week > b.week) {
    comparison = 1;
  } else if (a.week < b.week) {
    comparison = -1;
  }
  return comparison;
}

function compareAnimalValue(a, b) {
  let comparison = 0;
  if (a.animalValue > b.animalValue) {
    comparison = 1;
  } else if (a.animalValue < b.animalValue) {
    comparison = -1;
  }
  return comparison;
}

function compareAcquisitionPrice(a, b) {
  let comparison = 0;
  if (a.acquisitionPrice > b.acquisitionPrice) {
    comparison = 1;
  } else if (a.acquisitionPrice < b.acquisitionPrice) {
    comparison = -1;
  }
  return comparison;
}

class PurchaseScenarioDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerVisible: false,
      isLoading: false,
      purchaseScenario: null,
      selectedResultId: null,
      selectedOrder: "Data",
      selectedShow: "Compra",
      selectedInitialDate: null,
      selectedFinalDate: null,
      isLoadingFavorites: false,
      resultItems: [],
      favorites: [],
    };
  }

  signal = Axios.CancelToken.source();

  componentDidMount() {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;

    if (
      Object.entries(groupSelected).length > 0 &&
      Object.entries(farmSelected).length > 0
    ) {
      const { id: groupId } = groupSelected;
      const { id: farmId } = farmSelected;
      const { id: scenarioId } = this.props.match.params;
      this.getScenarioInfo(groupId, farmId, scenarioId);
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Canceling the request");
  }

  buildListOfFavorites = (favorites) => {
    try {
      if (favorites.length > 0) {
        let indexWithBetterAnimalMargin = null;
        let maxMargin = 0;
        favorites.forEach((f, index) => {
          const calcMargin = f.margin * f.numberAnimals;
          if (calcMargin > maxMargin) {
            indexWithBetterAnimalMargin = index;
            maxMargin = calcMargin;
          }
        });
        let copyFavorites = favorites.map((f) => {
          f.bestFavorite = false;
          return f;
        });
        if (indexWithBetterAnimalMargin != null) {
          copyFavorites[indexWithBetterAnimalMargin].bestFavorite = true;
        }
        return copyFavorites;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  getScenarioInfo = async (groupId, farmId, id) => {
    this.setState({ isLoading: true });
    try {
      const {
        data: { results: purchaseScenario },
      } = await showPurchaseScenario({
        groupId,
        farmId,
        id,
        signal: this.signal,
      });
      this.setState({
        purchaseScenario,
        isLoading: false,
      });
      if (
        purchaseScenario.results != null &&
        purchaseScenario.results.length > 0
      ) {
        this.setState({
          selectedResultId: purchaseScenario.results[0].id,
        });
        this.getScenarioResultItems(
          groupId,
          farmId,
          id,
          purchaseScenario.results[0].id
        );
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        selectedResultId: null,
        purchaseScenario: null,
        resultItems: [],
        favorites: [],
        selectedOrder: "Data",
        selectedShow: "Compra",
        selectedInitialDate: null,
        selectedFinalDate: null,
      });
    }
  };

  getScenarioResultItems = async (
    groupId,
    farmId,
    id,
    purchaseScenarioResultId
  ) => {
    this.setState({ isLoading: true });
    const { selectedResultId } = this.state;
    let resultId =
      purchaseScenarioResultId != null
        ? purchaseScenarioResultId
        : selectedResultId;
    try {
      const {
        data: { results: resultItems },
      } = await indexScenariosResultsItems({
        groupId,
        farmId,
        id,
        purchaseScenarioResultId: resultId,
        signal: this.signal,
      });

      const favorites = this.buildListOfFavorites(
        resultItems.filter((items) => items.favorite)
      );

      this.setState({
        resultItems,
        favorites,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        selectedResultId: null,
        resultItems: [],
        favorites: [],
        selectedOrder: "Data",
        selectedShow: "Compra",
        selectedInitialDate: null,
        selectedFinalDate: null,
      });
    }
  };

  saveScenarioResultItems = async (items) => {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;
    const { selectedResultId: purchaseScenarioResultId } = this.state;
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    const { id } = this.props.match.params;
    this.setState({
      isLoadingFavorites: true,
    });
    try {
      await saveScenariosResultsItemsFavorite({
        groupId,
        farmId,
        id,
        purchaseScenarioResultId,
        items,
        signal: this.signal,
      });
    } catch (error) {
      notification.error({
        message: "Houve um erro ao salvar/remover o item favoritado.",
      });
    } finally {
      this.setState({
        isLoadingFavorites: false,
      });
    }
  };

  valueInKg = (param) => {
    let value = 0;
    switch (param) {
      case "5.0@":
        value = 150;
        break;
      case "5.5@":
        value = 165;
        break;
      case "6.0@":
        value = 180;
        break;
      case "6.5@":
        value = 195;
        break;
      case "7.0@":
        value = 210;
        break;
      case "7.5@":
        value = 225;
        break;
      case "8.0@":
        value = 240;
        break;
      case "8.5@":
        value = 255;
        break;
      case "9.0@":
        value = 270;
        break;
      case "9.5@":
        value = 285;
        break;
      case "10.0@":
        value = 300;
        break;
      case "10.5@":
        value = 315;
        break;
      case "11.0@":
        value = 330;
        break;
      case "11.5@":
        value = 345;
        break;
      case "12.0@":
        value = 360;
        break;
      case "12.5@":
        value = 375;
        break;
      case "13.0@":
        value = 390;
        break;
      case "13.5@":
        value = 405;
        break;
      case "14.0@":
        value = 420;
        break;
      case "14.5@":
        value = 435;
        break;
      case "15.0@":
        value = 450;
        break;
      case "15.5@":
        value = 465;
        break;
      case "16.0@":
        value = 480;
        break;
      case "16.5@":
        value = 495;
        break;
      case "17.0@":
        value = 510;
        break;
      case "17.5@":
        value = 425;
        break;
      case "18.0@":
        value = 540;
        break;
      case "18.5@":
        value = 555;
        break;
      case "19.0@":
        value = 570;
        break;
      case "19.5@":
        value = 585;
        break;
      case "20.0@":
        value = 600;
        break;
      case "20.5@":
        value = 615;
        break;
      case "21.0@":
        value = 630;
        break;
      case "21.5@":
        value = 645;
        break;
      case "22.0@":
        value = 660;
        break;
      case "22.5@":
        value = 675;
        break;
      case "23.0@":
        value = 690;
        break;
      case "23.5@":
        value = 705;
        break;
      case "24.0@":
        value = 720;
        break;
      default:
        break;
    }
    return numberMask(value, false);
  };

  backPage = () => {
    this.props.history.push("/admin/purchase/scenario");
  };

  handleUpdateResultItems = (resultId) => {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;

    this.setState({ selectedResultId: resultId });

    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    const { id: scenarioId } = this.props.match.params;
    this.getScenarioResultItems(groupId, farmId, scenarioId, resultId);
    this.setState({
      selectedOrder: "Data",
      selectedShow: "Compra",
      selectedPeriod: null,
    });
  };

  handleAddRemoveFavorite = (itemId, value, animal) => {
    const { resultItems, favorites: oldFavorites } = this.state;

    // Update result itnes
    let x = resultItems
      .filter((item) => item.animal === animal)
      .find((item) => {
        return item.id === itemId;
      });
    x.favorite = !value;

    let itemsUpdated = oldFavorites;

    // Add or remove in/from favorite list
    if (x.favorite === true) {
      itemsUpdated.push(x);
    } else {
      itemsUpdated = itemsUpdated.map((item) => {
        if (item.id === itemId) item.favorite = false;
        return item;
      });
    }

    // Request update of the itens
    this.saveScenarioResultItems(itemsUpdated);

    const itemsUpdatedWithoutFalses = itemsUpdated.filter(
      (item) => item.favorite
    );

    const favorites = this.buildListOfFavorites(itemsUpdatedWithoutFalses);

    this.setState({
      resultItems,
      favorites,
    });
  };

  handleRemoveAllFavorite = () => {
    const { resultItems } = this.state;
    let itemsUpdated = [];
    resultItems.forEach((element) => {
      const favorite = element.favorite;
      element.favorite = false;
      if (favorite) itemsUpdated.push(element);
    });
    this.saveScenarioResultItems(itemsUpdated);
    this.setState({
      resultItems,
      favorites: resultItems.filter((items) => items.favorite),
    });
  };

  handleInitialDateChange = (value) => {
    const { selectedFinalDate } = this.state;
    if (
      value != null &&
      (selectedFinalDate == null ||
        (selectedFinalDate != null && value <= selectedFinalDate))
    ) {
      this.setState({ selectedInitialDate: value });
    } else {
      this.setState({ selectedInitialDate: null });
    }
  };

  handleFinalDateChange = (value) => {
    const { selectedInitialDate } = this.state;
    if (
      value != null &&
      (selectedInitialDate == null ||
        (selectedInitialDate != null && value >= selectedInitialDate))
    ) {
      this.setState({ selectedFinalDate: value });
    } else {
      this.setState({ selectedFinalDate: null });
    }
  };

  handleDrawerVisible = () => {
    this.setState({
      drawerVisible: true,
    });
  };

  handleCancelDrawerVisible = () => {
    this.setState({
      drawerVisible: false,
    });
  };

  render() {
    const {
      drawerVisible,
      purchaseScenario,
      resultItems,
      favorites,
      selectedResultId,
      selectedOrder,
      selectedShow,
      selectedInitialDate,
      selectedFinalDate,
      isLoading,
      isLoadingFavorites,
    } = this.state;

    const dateFormat = "DD/MM/YYYY";

    const greenMark = () => {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="11"
          viewBox="0 0 11 11"
        >
          <circle
            id="Ellipse_348"
            data-name="Ellipse 348"
            cx="5.5"
            cy="5.5"
            r="5.5"
            fill="#c4dd61"
          />
        </svg>
      );
    };

    const mountPopoverTitle = (info) => {
      return (
        <Row type="flex" justify="space-between">
          <span
            style={{
              fontFamily: "Asap",
              fontWeight: "bold",
              fontSize: "14px",
              textAlign: "left",
              color: "#4B4B4B",
            }}
          >
            Detalhes
          </span>
          <span
            style={{
              fontFamily: "Asap",
              fontWeight: "bold",
              fontSize: "14px",
              textAlign: "left",
              color: "#4B4B4B",
            }}
          >
            {info.bestBuy || info.bestMargin ? greenMark() : null}{" "}
            {moment(info.saleDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
          </span>
        </Row>
      );
    };

    const mountPopover = (info) => {
      return (
        <MessageContainer>
          <Row type="flex" justify="space-between">
            <span className="blueTitle">Compra</span>
          </Row>
          <Row type="flex" justify="space-between">
            <span className="graylabel">Peso / animal</span>
            <span>
              {info.animal} - {this.valueInKg(info.animal)} kg
            </span>
          </Row>
          <Row type="flex" justify="space-between">
            <span className="graylabel">Quantidade</span>
            <span>{info.numberAnimals} animais</span>
          </Row>
          <Row type="flex" justify="space-between">
            <span className="graylabel">Valor / animal</span>
            <span>{numberMask(info.acquisitionPrice || 0, true)} </span>
          </Row>
          <Row type="flex" justify="space-between">
            <span className="blueTitle">Venda</span>
          </Row>
          <Row type="flex" justify="space-between">
            <span className="graylabel">Peso / animal</span>
            <span>{numberMask(info.weight || 0, false)} kg</span>
          </Row>
          <Row type="flex" justify="space-between">
            <span className="graylabel">Valor da @</span>
            <span>{numberMask(info.weightPrice || 0, true)}</span>
          </Row>
          <Row type="flex" justify="space-between">
            <span className="graylabel">Valor / animal</span>
            <span>{numberMask(info.animalValue || 0, true)}</span>
          </Row>
          <Row type="flex" justify="space-between">
            <span className="graylabel">Margem / animal</span>
            <span>{numberMask(info.margin || 0, true)}</span>
          </Row>
        </MessageContainer>
      );
    };

    const mountPriceCard = (param) => {
      let resultItemsSorted = [];

      if (selectedOrder === "Data") {
        resultItemsSorted = resultItems
          .filter((result) => result.animal === param)
          .sort(compareWeek);
      } else if (selectedShow === "Venda") {
        resultItemsSorted = resultItems
          .filter((result) => result.animal === param)
          .sort(compareAnimalValue);
      } else if (selectedShow === "Compra") {
        resultItemsSorted = resultItems
          .filter((result) => result.animal === param)
          .sort(compareAcquisitionPrice);
      }

      if (selectedInitialDate != null && selectedFinalDate != null) {
        resultItemsSorted = resultItemsSorted.filter(
          (item) =>
            moment(item.saleDate, "YYYY-MM-DD") >= selectedInitialDate &&
            moment(item.saleDate, "YYYY-MM-DD") <= selectedFinalDate
        );
      }

      return (
        <PriceCard>
          <div className="initial-card">
            <span className="subtitle">{param}</span>
            <span className="date">{this.valueInKg(param)} kg</span>
          </div>
          <div className="flex">
            {resultItemsSorted.map((item) => (
              <SaleCard
                key={item.id}
                className={
                  item.bestMargin ? "green" : item?.violated ? "purple" : ""
                }
              >
                <Popover
                  placement="topRight"
                  title={mountPopoverTitle(item)}
                  content={mountPopover(item)}
                  trigger="click"
                  key={item.id}
                >
                  <Row>
                    <span className="date">
                      {moment(item.saleDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                    </span>
                  </Row>
                  <Row type="flex" justify="space-between">
                    <span className="price">
                      {selectedShow === "Venda"
                        ? numberMask(item.animalValue, true)
                        : numberMask(item.acquisitionPrice, true)}
                    </span>
                  </Row>
                </Popover>
                <button
                  className="favortiteStarButton"
                  onClick={() =>
                    this.handleAddRemoveFavorite(
                      item.id,
                      item.favorite,
                      item.animal
                    )
                  }
                >
                  <StarIcon
                    color={item.favorite ? "#FFD754" : "#c4c4c4"}
                    size="15"
                  />
                </button>
              </SaleCard>
            ))}
          </div>
        </PriceCard>
      );
    };

    return (
      <Container>
        {isLoading && <Loading />}
        <Header>
          {/* Title and bredcumbs */}
          <Row type="flex" justify="start" align="middle">
            <Col span={16}>
              <Row type="flex" justify="start" align="middle">
                <Col>
                  <div
                    className="back-container back"
                    onClick={() => this.props.history.goBack()}
                  >
                    <ArrowIcon />
                    <span className="back">Voltar</span>
                  </div>
                </Col>
                <Col>
                  <Row type="flex" align="middle">
                    <div className="divider" />
                    <Title>
                      {purchaseScenario != null ? purchaseScenario.name : null}
                    </Title>
                    <Select
                      value={
                        selectedResultId == null ? undefined : selectedResultId
                      }
                      onChange={(value) => {
                        this.handleUpdateResultItems(value);
                      }}
                      style={{ marginLeft: 10, paddingRight: 26 }}
                    >
                      {purchaseScenario != null &&
                        purchaseScenario.results.map((result, index) => (
                          <Select.Option key={result.id} value={result.id}>
                            {`Resultado ${
                              purchaseScenario.results.length - index
                            } - ${moment(
                              result.createdAt,
                              "YYYY-MM-DD HH:mm:ss"
                            ).format("DD/MM/YYYY HH:mm")}`}
                          </Select.Option>
                        ))}
                    </Select>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={8} align="right">
              <BreadCrumbs>
                <span
                  onClick={() => this.props.history.goBack()}
                  style={{ cursor: "pointer" }}
                >
                  <span className="pageTreeInative">Decisões</span>
                </span>
                <span className="pageTreeActive">
                  Detalhes: Cenário de Compra
                </span>
              </BreadCrumbs>
            </Col>
          </Row>
          <CustomDivider />
          {/* Favorite Row */}
          <Row type="flex">
            <Col xs={24} sm={16} md={24} lg={24} xl={24}>
              <FavoriteCard>
                <InitialCard>
                  <StarIcon color="#FFD754" size="30" />
                  <span className="subtitle">Favoritos</span>
                </InitialCard>
                <div className="flex">
                  <Spin spinning={isLoadingFavorites}>
                    {favorites.map((item) => (
                      <div className="infoContainer" key={item.id}>
                        <div className="infoGroup">
                          <Popover
                            placement="topRight"
                            title={mountPopoverTitle(item)}
                            content={mountPopover(item)}
                            trigger="click"
                          >
                            <SaleCardFavorite
                              key={item.id}
                              className={item.bestFavorite ? "green" : ""}
                            >
                              <Row>
                                <span className="date">
                                  {moment(item.saleDate, "YYYY-MM-DD").format(
                                    "DD/MM/YYYY"
                                  )}
                                </span>
                                <br />
                                <span className="price">
                                  {selectedShow === "Venda"
                                    ? numberMask(item.animalValue, true)
                                    : numberMask(item.acquisitionPrice, true)}
                                </span>
                              </Row>
                              <Row type="flex" justify="space-between">
                                <span className="weight">
                                  {item.animal} {this.valueInKg(item.animal)} kg
                                </span>
                                <button
                                  className="favortiteStarButton"
                                  onClick={() =>
                                    this.handleAddRemoveFavorite(
                                      item.id,
                                      item.favorite,
                                      item.animal
                                    )
                                  }
                                >
                                  <StarIcon color="#FFD754" size="15" />
                                </button>
                              </Row>
                            </SaleCardFavorite>
                          </Popover>
                        </div>
                      </div>
                    ))}
                  </Spin>
                </div>
                <div className="disfavorContainer">
                  <button
                    className="disfavor"
                    onClick={() => this.handleRemoveAllFavorite()}
                  >
                    desfavoritar todos
                  </button>
                </div>
              </FavoriteCard>
            </Col>
          </Row>
          {/* Filter Row */}
          <Row type="flex">
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ paddingBottom: 11 }}
            >
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                style={{ flexDirection: "row", alignItems: "center" }}
              >
                <FilterCard>
                  <div className="initial">
                    <FilterIcon id="no-cursor" />
                  </div>

                  <span className="label">Ordenação:</span>
                  <Select
                    value={selectedOrder}
                    onChange={(value) =>
                      this.setState({ selectedOrder: value })
                    }
                  >
                    <Select.Option value="Data">Data</Select.Option>
                    <Select.Option value="Valor">Valor</Select.Option>
                  </Select>

                  <span className="label">Período:</span>
                  <DatePicker
                    value={selectedInitialDate}
                    onChange={(value) => this.handleInitialDateChange(value)}
                    format={dateFormat}
                    allowClear={true}
                  />
                  <span className="label">Até:</span>
                  <DatePicker
                    value={selectedFinalDate}
                    onChange={(value) => this.handleFinalDateChange(value)}
                    format={dateFormat}
                    allowClear={true}
                  />

                  <span className="label">Exibir:</span>
                  <Select
                    value={selectedShow}
                    onChange={(value) => this.setState({ selectedShow: value })}
                    style={{ paddingRight: 5 }}
                  >
                    <Select.Option value="Venda">Valor de venda</Select.Option>
                    <Select.Option value="Compra">
                      Valor de compra
                    </Select.Option>
                  </Select>
                </FilterCard>

                <ButtonStandard
                  type="button"
                  disabled={false}
                  onClick={() => this.handleDrawerVisible()}
                >
                  EXIBIR PARAMETROS
                </ButtonStandard>
              </Row>
            </Col>
          </Row>
        </Header>
        <Main>
          {/* Main data */}
          <Row type="flex">
            <Col xs={24} sm={16} md={24} lg={24} xl={24}>
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "5.0@").length >
                0
                ? mountPriceCard("5.0@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "5.5@").length >
                0
                ? mountPriceCard("5.5@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "6.0@").length >
                0
                ? mountPriceCard("6.0@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "6.5@").length >
                0
                ? mountPriceCard("6.5@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "7.0@").length >
                0
                ? mountPriceCard("7.0@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "7.5@").length >
                0
                ? mountPriceCard("7.5@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "8.0@").length >
                0
                ? mountPriceCard("8.0@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "8.5@").length >
                0
                ? mountPriceCard("8.5@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "9.0@").length >
                0
                ? mountPriceCard("9.0@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "9.5@").length >
                0
                ? mountPriceCard("9.5@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "10.0@").length >
                0
                ? mountPriceCard("10.0@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "10.5@").length >
                0
                ? mountPriceCard("10.5@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "11.0@").length >
                0
                ? mountPriceCard("11.0@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "11.5@").length >
                0
                ? mountPriceCard("11.5@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "12.0@").length >
                0
                ? mountPriceCard("12.0@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "12.5@").length >
                0
                ? mountPriceCard("12.5@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "13.0@").length >
                0
                ? mountPriceCard("13.0@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "13.5@").length >
                0
                ? mountPriceCard("13.5@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "14.0@").length >
                0
                ? mountPriceCard("14.0@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "14.5@").length >
                0
                ? mountPriceCard("14.5@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "15.0@").length >
                0
                ? mountPriceCard("15.0@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "15.5@").length >
                0
                ? mountPriceCard("15.5@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "16.0@").length >
                0
                ? mountPriceCard("16.0@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "16.5@").length >
                0
                ? mountPriceCard("16.5@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "17.0@").length >
                0
                ? mountPriceCard("17.0@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "17.5@").length >
                0
                ? mountPriceCard("17.5@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "18.0@").length >
                0
                ? mountPriceCard("18.0@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "18.5@").length >
                0
                ? mountPriceCard("18.5@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "19.0@").length >
                0
                ? mountPriceCard("19.0@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "19.5@").length >
                0
                ? mountPriceCard("19.5@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "20.0@").length >
                0
                ? mountPriceCard("20.0@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "20.5@").length >
                0
                ? mountPriceCard("20.5@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "21.0@").length >
                0
                ? mountPriceCard("21.0@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "21.5@").length >
                0
                ? mountPriceCard("21.5@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "22.0@").length >
                0
                ? mountPriceCard("22.0@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "22.5@").length >
                0
                ? mountPriceCard("22.5@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "23.0@").length >
                0
                ? mountPriceCard("23.0@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "23.5@").length >
                0
                ? mountPriceCard("23.5@")
                : null}
              {resultItems != null &&
              resultItems.filter((result) => result.animal === "24.0@").length >
                0
                ? mountPriceCard("24.0@")
                : null}
            </Col>
          </Row>
        </Main>

        <DisplayPurchaseScenario
          drawerVisible={drawerVisible}
          data={purchaseScenario}
          onCancel={this.handleCancelDrawerVisible}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  purchaseScenario: state.purchaseScenario,
});

const mapDispatchToProps = (dispatch) => ({
  purchaseScenarioActions: bindActionCreators(
    PurchaseScenarioActions,
    dispatch
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PurchaseScenarioDetails)
);
