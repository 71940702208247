import React, { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as LotActions } from "../../../store/ducks/lot";
import { Creators as SupplierActions } from "../../../store/ducks/supplier";

import moment from "moment";
import "moment/locale/en-nz";
import "moment/locale/es";
import "moment/locale/pt-br";

import {
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Icon,
  Input,
  notification,
  Popconfirm,
  Row,
  Select,
  Spin,
  Switch,
  Table,
} from "antd";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import ButtonStandard from "../../utils/button";
import PencilIcon from "../../utils/icons/pencil";
import TrashIcon from "../../utils/icons/trash";
import NewLotItem from "../newLotItem";
import DrawerSupplier from "../supplier";
import { AnimalsAmount, Container, Footer } from "./styles";

const validationSchema = Yup.object().shape({
  supplierId: Yup.string().required(),
  name: Yup.string().required(),
  creationDate: Yup.date().required(),
});

const { Column } = Table;

const { Option } = Select;

const dateFormat = "DD/MM/YYYY";

const NewLotAnimalsReceipt = () => {
  const [form, setForm] = useState({
    supplierId: null,
    supplierName: "",
    name: "",
    creationDate: null,
    shouldCreateLotEntry: false,
    amountAnimalsReceived: null,
  });
  const [saveOptions, setSaveOptions] = useState("only_save");

  const formikRef = useRef();

  const {
    app: {
      translation,
      groupSelected,
      farmSelected,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
    lot: { drawerLotAnimalsReceiptVisible, lotItens, isLoading },
    supplier: {
      listDrowpDown: suppliers,
      isLoadingDropDown: isLoadingDropDownSuppliers,
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const history = useHistory();

  const amountAnimals = useMemo(() => {
    let amountAnimalsInLotItem = 0;

    lotItens.forEach(
      (item) =>
        (amountAnimalsInLotItem =
          parseInt(amountAnimalsInLotItem) + parseInt(item.amount))
    );

    return amountAnimalsInLotItem;
  }, [lotItens]);

  const fetchData = async (
    groupSelected,
    farmSelected,
    page = 0,
    sorter = {},
    filters = ""
  ) => {
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;

    dispatch(
      SupplierActions.getDropdownSuppliers(groupId, farmId, "CattleFarmer")
    );
  };

  const closeDrawer = () => {
    dispatch(LotActions.hideLotAnimalsReceipt());
  };

  const validateForm = (lotReceipt, lotItens = []) => {
    if (lotReceipt.creationDate && lotReceipt.shouldCreateLotEntry) {
      if (moment(lotReceipt.creationDate).isAfter(moment())) {
        openNotificationError(
          "Erro na Data de Entrada",
          'Como foi selecionado para criar animais ao lançar, é necessário informar uma "Data de Entrada" igual ou anterior a data de hoje. '
        );
        throw new Error("Lot Receipt with entry date on future.");
      }
    }
    if (lotReceipt.amountAnimalsReceived === 0) {
      openNotificationError(
        translation.lot.receipt.finalize.errors.noAnimalsAdd.title,
        translation.lot.receipt.finalize.errors.noAnimalsAdd.description
      );
      throw new Error("Lot Receipt should have animals");
    }
    if (
      lotReceipt.shouldCreateLotEntry === true &&
      lotItens.some(
        (item) =>
          item.prefixIdentification == null || item.lotDestinationId == null
      )
    ) {
      openNotificationError(
        "Erro ao cadastrar",
        'É necessário informar um "Prefixo para Identificação" e "Lote Destino".'
      );
      throw new Error(
        "Lot Receipt should have animals with lot destination id and prefix identifier"
      );
    }
  };

  const openNotificationError = useCallback((title, message) => {
    notification["error"]({
      message: title,
      description: message,
    });
  }, []);

  const handleRemoveLotItem = useCallback(
    (id) => {
      dispatch(LotActions.removeLotReceiptItem(id));
    },
    [dispatch]
  );

  const handleAddNewLotItem = () => {
    const { shouldCreateLotEntry, creationDate } =
      formikRef.current.state.values;

    if (creationDate && shouldCreateLotEntry) {
      if (moment(creationDate).isAfter(moment())) {
        notification.error({
          message:
            'Como foi selecionado para criar animais ao lançar, é necessário informar uma "Data de Entrada" igual ou anterior a data de hoje. ',
        });
        return;
      }
    }

    dispatch(LotActions.showLotItemReceipt(shouldCreateLotEntry));
  };

  const handleFinalizeLotReceipt = async (values, actions) => {
    const lotReceipt = values;
    let amountAnimalsInLotItem = 0;

    lotItens.forEach(
      (item) =>
        (amountAnimalsInLotItem =
          parseInt(amountAnimalsInLotItem) + parseInt(item.amount))
    );

    lotReceipt.amountAnimalsReceived = amountAnimalsInLotItem;

    try {
      validateForm(lotReceipt, lotItens);

      dispatch(
        LotActions.storeNewLotReceipt(
          groupId,
          farmId,
          lotReceipt,
          lotItens,
          history,
          saveOptions === "only_save" ? false : true
        )
      );

      actions.resetForm({
        supplierId: null,
        supplierName: "",
        name: "",
        creationDate: null,
        shouldCreateLotEntry: false,
        amountAnimalsReceived: null,
      });

      setForm({
        supplierId: null,
        supplierName: "",
        name: "",
        creationDate: null,
        shouldCreateLotEntry: false,
        amountAnimalsReceived: null,
      });
    } catch (error) {}
  };

  const handleEditLotItem = (record) => {
    const { shouldCreateLotEntry } = formikRef.current.state.values;
    dispatch(LotActions.getLotItem(record, shouldCreateLotEntry));
  };

  const handleDrawerVisible = (visible) => {
    if (visible) {
      if (
        Object.entries(groupSelected).length > 0 &&
        Object.entries(farmSelected).length > 0
      ) {
        formikRef.current.resetForm({
          supplierId: null,
          supplierName: "",
          name: "",
          creationDate: null,
          shouldCreateLotEntry: false,
          amountAnimalsReceived: null,
        });

        fetchData(groupSelected, farmSelected);
      }
    }
  };

  const handleCreateSupplier = () => {
    dispatch(SupplierActions.showDrawer("new_animal_supplier", null));
  };

  return (
    <Drawer
      title={translation.lot.receipt.finalize.newLotAnimalsReceipt.title}
      width={"70vw"}
      maskClosable={false}
      onClose={closeDrawer}
      visible={drawerLotAnimalsReceiptVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <Spin spinning={isLoading} size="large">
        <Container>
          <Formik
            ref={formikRef}
            initialValues={form}
            onSubmit={handleFinalizeLotReceipt}
            validationSchema={validationSchema}
            render={(props) => (
              <>
                <form onSubmit={props.handleSubmit} autoComplete="off">
                  {/* Supplier Info and Lot Animals Receipt info */}
                  <div>
                    <Row type="flex">
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row type="flex">
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "normal",
                              color: "#4B4B4B",
                            }}
                          >
                            {
                              translation.lot.receipt.finalize
                                .newLotAnimalsReceipt.description
                            }
                          </span>
                        </Row>
                        {Object.entries(props.errors).length > 0 &&
                        props.submitCount > 0 ? (
                          <Row type="flex" justify="center" align="middle">
                            <label className="error">
                              {translation.lot.receipt.supplier.messageError}
                            </label>
                          </Row>
                        ) : null}
                        <Row type="flex" style={{ width: "100%" }}>
                          <Row type="flex" style={{ width: "100%" }}>
                            <Col
                              xs={11}
                              sm={11}
                              md={11}
                              lg={11}
                              xl={11}
                              className="rowLabel"
                            >
                              <Row>
                                <label
                                  className={
                                    props.errors.supplierId &&
                                    props.submitCount > 0
                                      ? "error"
                                      : ""
                                  }
                                >
                                  {
                                    translation.lot.receipt.supplier
                                      .supplierTitle
                                  }
                                  *
                                </label>
                              </Row>
                              <Row type="flex" gutter={4}>
                                <Select
                                  style={{ width: "80%" }}
                                  value={
                                    props.values.supplierId != null
                                      ? props.values.supplierId
                                      : undefined
                                  }
                                  showSearch
                                  placeholder={
                                    translation.lot.receipt.supplier
                                      .supplierComboBoxPlaceHolder
                                  }
                                  loading={isLoadingDropDownSuppliers}
                                  name="supplierId"
                                  onChange={(value) => {
                                    props.setValues({
                                      ...props.values,
                                      supplierId: value,
                                      supplierName: suppliers.find(
                                        (supplier) => supplier.id === value
                                      ).name,
                                    });
                                  }}
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {suppliers !== null &&
                                    suppliers?.map((supplier) => (
                                      <Option
                                        key={supplier.id}
                                        value={supplier.id}
                                      >
                                        {supplier.name}
                                      </Option>
                                    ))}
                                </Select>
                                <Button
                                  shape="circle"
                                  icon="plus"
                                  style={{ marginLeft: 10 }}
                                  onClick={() => handleCreateSupplier()}
                                />
                              </Row>
                            </Col>
                            <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              offset={1}
                              className="rowLabel"
                            >
                              <Row>
                                <label
                                  className={
                                    props.errors.name && props.submitCount > 0
                                      ? "error"
                                      : ""
                                  }
                                >
                                  {translation.lot.receipt.supplier.lotName}*
                                </label>
                              </Row>
                              <Row>
                                <Input
                                  type="text"
                                  style={{ width: "100%" }}
                                  placeholder={
                                    translation.lot.receipt.supplier
                                      .lotNameInputPlaceHolder
                                  }
                                  value={props.values.name}
                                  name="name"
                                  autoComplete="chrome-off"
                                  onChange={props.handleChange}
                                />
                              </Row>
                            </Col>
                          </Row>
                          <Row type="flex" style={{ width: "100%" }} gutter={8}>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                              <Row type="flex" style={{ width: "100%" }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                  <label
                                    className={
                                      props.errors.creationDate &&
                                      props.submitCount > 0
                                        ? "error"
                                        : ""
                                    }
                                  >
                                    {translation.lot.receipt.supplier.dateTitle}
                                    *
                                  </label>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                  <DatePicker
                                    style={{ width: "100%" }}
                                    value={
                                      props.values.creationDate === null
                                        ? null
                                        : moment(
                                            props.values.creationDate,
                                            "YYYY-MM-DD"
                                          )
                                    }
                                    placeholder={
                                      translation.lot.receipt.supplier
                                        .datePlaceHolder
                                    }
                                    format={dateFormat}
                                    name="creationDate"
                                    allowClear={false}
                                    onChange={(dateMoment, dateString) => {
                                      props.setValues({
                                        ...props.values,
                                        creationDate: dateMoment,
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                              <Row type="flex" style={{ width: "100%" }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                  <label>
                                    {
                                      translation.lot.receipt.supplier
                                        .shouldCreateLotEntry
                                    }
                                    *
                                  </label>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                  <Switch
                                    checked={props.values.shouldCreateLotEntry}
                                    onChange={(checked) =>
                                      props.setFieldValue(
                                        "shouldCreateLotEntry",
                                        checked
                                      )
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Row type="flex" style={{ width: "100%" }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                  <label />
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                  <AnimalsAmount
                                    className={
                                      amountAnimals > 0 ? "haveAnimals" : ""
                                    }
                                  >
                                    <span>
                                      {
                                        translation.lot.receipt.supplier
                                          .qtyAnimalsTitle
                                      }
                                    </span>
                                    <span className="number">
                                      {amountAnimals}
                                    </span>
                                  </AnimalsAmount>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Row>
                      </Col>
                    </Row>
                    <Divider />
                    {/* Table with Lot Receipt Item */}
                    <Row type="flex" style={{ width: "100%" }}>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        className="tableButtonsDiv"
                      >
                        <ButtonStandard
                          buttonType="type2"
                          size="l"
                          background="#4A85AE"
                          type="button"
                          onClick={handleAddNewLotItem}
                        >
                          <Icon type="plus" /> Linha
                        </ButtonStandard>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Table
                          rowKey="id"
                          rowClassName={() => "editable-row"}
                          size="small"
                          style={{ width: "100%", marginBottom: "10px" }}
                          dataSource={lotItens}
                          pagination={{
                            hideOnSinglePage: true,
                            disabled: true,
                          }}
                        >
                          <Column
                            title={
                              translation.lot.receipt.finalize.grid.columns
                                .breed
                            }
                            dataIndex="breedName"
                            key="breedName"
                            sorter={(a, b) => {
                              return a.breedName.localeCompare(b.breedName);
                            }}
                          />
                          <Column
                            title={
                              translation.lot.receipt.finalize.grid.columns
                                .gender
                            }
                            dataIndex="gender"
                            key="gender"
                            sorter={(a, b) => {
                              return a.gender.localeCompare(b.gender);
                            }}
                            render={(text, record) => (
                              <span>
                                {record.gender === "M"
                                  ? translation.animal.details.male
                                  : translation.animal.details.female}
                              </span>
                            )}
                          />
                          <Column
                            title={
                              translation.lot.receipt.finalize.grid.columns
                                .birthday
                            }
                            dataIndex="birthday"
                            key="birthday"
                            sorter={(a, b) => {
                              return a.birthday.localeCompare(b.birthday);
                            }}
                            render={(text, record) =>
                              record.birthday !== null ? (
                                <span>
                                  {moment(record.birthday).format("MM/YYYY - ")}
                                  {moment(new Date())
                                    .diff(
                                      new Date(record.birthday),
                                      "months",
                                      true
                                    )
                                    .toFixed()}{" "}
                                  m
                                </span>
                              ) : (
                                ""
                              )
                            }
                          />
                          <Column
                            title={
                              translation.lot.receipt.finalize.grid.columns
                                .weight
                            }
                            dataIndex="estimatedWeight"
                            key="estimatedWeight"
                            sorter={(a, b) => {
                              return a.estimatedWeight - b.estimatedWeight;
                            }}
                            render={(text, record) => <span>{text} Kg</span>}
                          />
                          <Column
                            title={
                              translation.lot.receipt.finalize.grid.columns
                                .amount
                            }
                            dataIndex="amount"
                            key="amount"
                            sorter={(a, b) => {
                              return a.amount - b.amount;
                            }}
                          />
                          <Column
                            title={
                              translation.lot.receipt.finalize.grid.columns
                                .purchaseCost
                            }
                            dataIndex="purchaseCost"
                            key="purchaseCost"
                            sorter={(a, b) => {
                              return a.purchaseCost - b.purchaseCost;
                            }}
                          />
                          {/* <Column
                                  title={translation.lot.receipt.finalize.grid.columns.numbers}
                                  dataIndex="numbers"
                                  key="numbers"
                                  render={(text, record) => (
                                    <span style={{ color: record.amount === record.sum ? '#4A125AE' : '#D44C4C'}}>
                                      {text}
                                    </span>
                                  )}
                                /> */}
                          <Column
                            width="60px"
                            align="right"
                            render={(text, record) => {
                              return (
                                <>
                                  <Col
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    style={{ textAlign: "center" }}
                                  >
                                    <button
                                      type="button"
                                      style={{
                                        border: "none",
                                        background: "transparent",
                                      }}
                                      onClick={() => handleEditLotItem(record)}
                                    >
                                      <PencilIcon color="#4A85AE" />
                                    </button>
                                  </Col>
                                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Popconfirm
                                      title={
                                        translation.lot.receipt.finalize.grid
                                          .deleteBox.description
                                      }
                                      cancelText={
                                        translation.lot.receipt.finalize.grid
                                          .deleteBox.buttonCancel
                                      }
                                      okText={
                                        translation.lot.receipt.finalize.grid
                                          .deleteBox.buttonConfirm
                                      }
                                      placement="topRight"
                                      icon={
                                        <Icon
                                          type="question-circle-o"
                                          style={{ color: "red" }}
                                        />
                                      }
                                      onConfirm={() =>
                                        handleRemoveLotItem(record.id)
                                      }
                                    >
                                      <button
                                        type="button"
                                        style={{
                                          border: "none",
                                          background: "transparent",
                                        }}
                                      >
                                        <TrashIcon />
                                      </button>
                                    </Popconfirm>
                                  </Col>
                                </>
                              );
                            }}
                          />
                        </Table>
                      </Col>
                    </Row>
                    {/* Footer */}
                  </div>
                  <Footer>
                    <Row type="flex" justify="space-between">
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="selectDiv"
                      >
                        <Select
                          style={{ width: 250 }}
                          value={saveOptions}
                          onChange={(value) => setSaveOptions(value)}
                        >
                          <Option key="1" value="only_save">
                            {
                              translation.lot.receipt.finalize
                                .newLotAnimalsReceipt.saveOptions.only_save
                            }
                          </Option>
                          <Option key="2 " value="save_and_create_new">
                            {
                              translation.lot.receipt.finalize
                                .newLotAnimalsReceipt.saveOptions
                                .save_and_create_new
                            }
                          </Option>
                          <Option key="3 " value="save_and_handle">
                            {
                              translation.lot.receipt.finalize
                                .newLotAnimalsReceipt.saveOptions
                                .save_and_handle
                            }
                          </Option>
                        </Select>
                      </Col>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="buttonsDiv"
                      >
                        <ButtonStandard
                          type="button"
                          buttonType="type7"
                          onClick={closeDrawer}
                        >
                          {translation.buttons.cancel}
                        </ButtonStandard>

                        <ButtonStandard type="submit" buttonType="type6">
                          {translation.buttons.save}
                        </ButtonStandard>
                      </Col>
                    </Row>
                  </Footer>
                </form>
                <NewLotItem />
              </>
            )}
          />
        </Container>
      </Spin>
      <DrawerSupplier />
    </Drawer>
  );
};

export default NewLotAnimalsReceipt;
