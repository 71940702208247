import { Tabs } from "antd";
import React, { useState } from "react";
import useModuleContext from "../../hooks/useModuleContext";
import { useSelector } from "react-redux";
import FarmReadFeederTreatmentParameter from "./farmReadFeederTreatmentParameter";
import FarmDayScaleTreatmentParameter from "./farmDayScaleTreatmentParameter";

function HandlingParameters() {
  const [tabSelect, setTabSelect] = useState("1");
  const { isAllowedFunctions } = useModuleContext();
  const { translation } = useSelector((state) => state.app);

  const handleTabClick = (key, e) => {
    setTabSelect(key);
  };
  return (
    <Tabs
      type="card"
      defaultActiveKey="1"
      activeKey={tabSelect}
      onTabClick={handleTabClick}
    >
      <Tabs.TabPane
        tab={
          <span>
            {translation.parameters.tabs.farmDayScaleTreatmentParameter}
          </span>
        }
        key="1"
      >
        <FarmDayScaleTreatmentParameter />
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={
          <span>
            {translation.parameters.tabs.farmReadFeederTreatmentParameter}
          </span>
        }
        key="2"
      >
        <FarmReadFeederTreatmentParameter />
      </Tabs.TabPane>
    </Tabs>
  );
}

export default HandlingParameters;
