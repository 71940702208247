import { Card } from "antd";
import styled from "styled-components";

export const CardCustom = styled(Card)`
  margin-top: 13px !important;
  border-radius: 7px !important;
  background: #fff !important;
  box-shadow: 0px 0px 10px #d8d8d8 !important;

  padding: 0px !important;
  .ant-card-body {
    padding-top: 14px;
  }
  .rowHeader {
    margin-bottom: 17px;
  }
  @media screen and (max-height: 700px) {
    height: 420px;
    overflow-y: auto;
  }
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  font-family: "Asap", sans-serif;
`;

export const FormModalReportFeedingSheet = styled.form`
  .ant-row > label {
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;

    &.error {
      color: #d44c4c;
    }
  }

  .error {
    color: #d44c4c;
  }
`;
