import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const MenuNavigationButtonContainer = styled.button`
  cursor: pointer;
  position: relative;

  display: block;
  -webkit-animation: ${fadeIn} 0.5s ease;
  animation: ${fadeIn} 0.5s ease;
  background-color: transparent;

  padding: 4px 16px;
  border: 1px solid ${(props) => props.color ?? "#4b4b4b"};
  color: #4b4b4b;
  border-radius: 25px;
  background: transparent;

  opacity: 1;
  transition: opacity 0.5s ease-out, transform 0.7s ease;

  span {
    position: relative;
    color: #4b4b4b;
    font-weight: 700;
    font-family: "Asap", sans-serif;
    z-index: 1;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 0.5s ease;
    border-radius: 25px;
  }

  &::before {
    background: transparent;
    transition-duration: 0.5s;
    box-shadow: inset 0 0 0 1px ${(props) => props.color ?? "#4b4b4b"};
  }

  &:hover::before {
    transition-duration: 0.5s;
    box-shadow: inset 0 0 0 25px ${(props) => props.color ?? "#4b4b4b"};
  }

  &:hover {
    span {
      color: #f4f4f4;
    }
  }

  &.removed {
    display: none;
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }

  &.active {
    background: ${(props) => props.color ?? "#4b4b4b"};
    span {
      color: #f4f4f4;
    }
  }

  @media screen and (max-width: 576px) {
    padding: 2px 8px;
  }
`;
