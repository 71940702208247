import styled from "styled-components";

export const Container = styled.div`
  overflow: hidden;

  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Logo = styled.div`
  display: flex;
  position: relative;

  .alert-icon {
    position: absolute;
    top: 0;
    left: 230px;
  }
`;

export const SliderTrack = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-row-gap: 1rem;
`;

export const Slide = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;

  width: 180px;

  font-family: "Asap", sans-serif;

  i {
    font-size: 24px;
  }

  span.code {
    font-size: 14px;
    font-family: "Asap", sans-serif;
    color: #4b4b4b;
    font-weight: 500;
  }

  span.value {
    font-size: 14px;
  }

  .green {
    color: #33c162;
  }
  .red {
    color: #d44c4c;
  }
`;
