import { useContext } from "react";
import { TroughFeedingAssessmentContext } from "../../contexts/troughFeedingAssessmentContext";

const useTroughFeedingAssessmentContext = () => {
  const value = useContext(TroughFeedingAssessmentContext);

  return value;
};

export default useTroughFeedingAssessmentContext;
