import apiFarms from "../config/api_farms";
// import axios from "axios";
import getHeaders from "./getHeaders";

// const apiFarms = axios.create({
//   baseURL: "http://localhost:8082",
// });

export function getAllPicketsBySupplyDate(payload) {
  const { groupId, farmId, supplyDate } = payload;
  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/pickets/supplements/supply/list/pickets?supplyDate=${supplyDate}`,
    getHeaders(false, null)
  );
}

export function findAll(payload) {
  const { groupId, farmId } = payload;
  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/pickets/supplements/supply`,
    getHeaders(false, null)
  );
}

export function findLatest(payload) {
  const { groupId, farmId } = payload;
  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/pickets/supplements/supply/latest`,
    getHeaders(false, null)
  );
}

export function findAllBySupplyDate(payload) {
  const { groupId, farmId, supplyDate } = payload;
  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/pickets/supplements/supply?supplyDate=${supplyDate}`,
    getHeaders(false, null)
  );
}

export function findBySupplyDateAndSupplyNumber(payload) {
  const { groupId, farmId, supplyDate, supplyNumber } = payload;
  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/pickets/supplements/supply?supplyDate=${supplyDate}&supplyNumber=${supplyNumber}`,
    getHeaders(false, null)
  );
}

export function save(payload) {
  const { groupId, farmId, body } = payload;
  return apiFarms.put(
    `/bovexo/${groupId}/farms/${farmId}/pickets/supplements/supply`,
    body,
    getHeaders(false, null)
  );
}

export function destroyBySupplyDate(payload) {
  const { groupId, farmId, supplyDate } = payload;
  return apiFarms.delete(
    `/bovexo/${groupId}/farms/${farmId}/pickets/supplements/supply?supplyDate=${supplyDate}`,
    getHeaders(false, null)
  );
}
