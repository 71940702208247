import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

// libs
import Axios from "axios";
import moment from "moment";
import { Row, Col, Menu, Dropdown, notification } from "antd";

// Components
import useKpiContext from "../../../hooks/useKpiContext";
import ArrowDownWhite from "../../../components/utils/icons/arrows/green/down";

// Services
import {
  showKPIsHarvests,
  loadKPIGoalResult,
  showKPIsFarmsForHome,
  reprocessKpi,
} from "../../../services/kpiService";

//Icons
import KpiLotation from "./cards/lotation";
import KpiProduction from "./cards/production";
import KpiUsufruct from "./cards/usufruct";
import KpiGDP from "./cards/gdp";
import KpiCost from "./cards/cost";
import KpiMargin from "./cards/margin";
import KpiCarbonFootprint from "./cards/carbonFootprint";
import KpiDetail from "./detail";
import KpiWeanCalf from "./cards/weanCalf";
import KpiIEP from "./cards/iep";
import KpiReproductiveEfficiency from "./cards/reproductiveEfficiency";
import KpiWeanCalfCost from "./cards/weanCalfCost";
import KpiDiscardedCows from "./cards/discardedCows";
import KpiDisbursement from "./cards/disbursement";
import ButtonStandard from "../../../components/utils/button";
import KpiAssetValue from "./cards/assetValue";
import KpiIncrementalMargin from "./cards/incrementalMargin";

const signal = Axios.CancelToken.source();

const fattenKpiNames = [
  "LOTACAO",
  "PRODUCAO",
  "DESFRUTE",
  "GDP",
  "CUSTO",
  "MARGEM",
  "PEGADA_CARBONICA",
  "DESEMBOLSO_CAB_MES",
  "VALOR_DO_ATIVO",
  "MARGEM_INCREMENTAL",
];
const createKpiNames = [
  "Kg Bez. Desm / Kg Vaca",
  "IEP",
  "VACAS DESCARTADAS",
  "CUSTO BEZERRO DESMAMADO",
  "EFICIENCIA REPRODUTIVA",
];

const KpiPanel = () => {
  const {
    app: {
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
  } = useSelector((state) => state);

  const {
    updateKpiDataList,
    updateKpiHarvest,
    updateKpiSelected,
    kpiLotation,
    kpiProduction,
    kpiUsufruct,
    kpiGDP,
    kpiCost,
    kpiMargin,
    kpiCarbonFootprint,
    kpiDisbursement,
    kpiAssetValue,
    kpiIncrementalMargin,
    kpiWeanCalf,
    kpiIEP,
    kpiReproductiveEfficiency,
    kpiWeanCalfCost,
    kpiDiscardedCows,
  } = useKpiContext();

  const { homeConfigData } = useSelector((state) => state.user);

  const [kpiHarvestList, setKpiHarvestList] = useState([]);
  const [kpiHarvestSelected, setKpiHarvestSelected] = useState(null);
  const [kpiDataList, setKpiDataList] = useState(null);

  const [selectedProductionSystem, setSelectedProductionSystem] =
    useState(null);
  const [selectedProductionSubSystem, setSelectedProductionSubSystem] =
    useState(null);

  // Fetch initial data - update selected harvest
  const fetchHarvests = useCallback(async () => {
    if (groupId != null && farmId != null) {
      try {
        const {
          data: { results: harvests },
        } = await showKPIsHarvests({ groupId, farmId, signal });

        if (Array.isArray(harvests) && harvests.length > 0) {
          let harvest = harvests.find((harvest) => harvest.current);
          updateKpiHarvest(harvest);
          setKpiHarvestSelected(harvest);
          setKpiHarvestList(harvests);
        } else {
          setKpiHarvestSelected(null);
          setKpiHarvestList([]);
          updateKpiHarvest(null);
        }
      } catch (error) {
        setKpiHarvestSelected(null);
        setKpiHarvestList([]);
      }
    }
  }, [groupId, farmId, updateKpiHarvest]);

  // Fetch Kpis data
  const fetchKpis = useCallback(
    (
      goals,
      harvestId,
      selectedProductionSystem,
      selectedProductionSubSystem
    ) => {
      if (
        farmId != null &&
        harvestId != null &&
        selectedProductionSystem != null &&
        goals != null
      ) {
        let selectedGoals = goals.find(
          (goal) =>
            goal.productionSystem === selectedProductionSystem &&
            goal.productionSubSystem === selectedProductionSubSystem
        );

        if (selectedGoals != null) {
          selectedGoals.results.map(async (result) => {
            let kpiGoalId = result.kpiGoalId;
            let kpiId = result.kpiId;

            if (result.name === null) {
              const {
                data: { results: kpiData },
              } = await loadKPIGoalResult({
                groupId,
                farmId,
                harvestId,
                kpiGoalId,
                kpiId,
                signal,
              });

              setKpiDataList((kpiList) =>
                kpiList?.map((k) => {
                  const item = { ...k };
                  if (item.name === kpiData.name) {
                    item.data = kpiData;
                    item.isLoading = false;
                  }
                  return item;
                })
              );
            }
          });
        }
      }
    },
    [groupId, farmId]
  );

  // Load kpi goals
  const fetchGoals = useCallback(
    async (harvestId, system, subSystem) => {
      try {
        const {
          data: { results: kpiResultsResponse },
        } = await showKPIsFarmsForHome({
          groupId,
          farmId,
          harvestId: harvestId,
          signal,
        });

        if (kpiResultsResponse != null) {
          fetchKpis(kpiResultsResponse.goals, harvestId, system, subSystem);
        }
      } catch (error) {}
    },
    [groupId, farmId, fetchKpis]
  );

  // reoad Kpi Goals and Kpi Data
  const reloadKpiData = useCallback(
    (harvest, system, subSystem) => {
      if (harvest != null) {
        fetchGoals(harvest.id, system, subSystem);
      }
    },
    [fetchGoals]
  );

  // Generate Kpi Data List
  const handleGenerateKpiDataList = useCallback((homeConfigData, system) => {
    if (homeConfigData != null && system != null) {
      let kpiList = null;
      if (system === "Fatten") {
        kpiList = fattenKpiNames.map((kpi) => ({
          data: {},
          name: kpi,
          isSelected: false,
          isLoading: true,
          isVisible: homeConfigData.kpiVisibility[kpi],
        }));
      } else {
        // Create kpis
        kpiList = createKpiNames.map((kpi) => ({
          data: {},
          name: kpi,
          isSelected: false,
          isLoading: true,
          isVisible: homeConfigData.kpiVisibility[kpi],
        }));
      }
      setKpiDataList(kpiList);
    }
  }, []);

  // Set all kpis is loading
  const setIsLoadingKpis = useCallback(() => {
    setKpiDataList((kpiList) =>
      kpiList.map((k) => {
        k.isLoading = true;
        return k;
      })
    );
  }, []);

  // Harvest dropdown
  const harvestMenu = useMemo(() => {
    return (
      <Menu>
        {kpiHarvestList.map((harvest) => (
          <Menu.Item
            key={harvest.id}
            onClick={() => {
              // setIsLoadingKpis();
              setKpiHarvestSelected(harvest);
            }}
          >
            Safra{" "}
            {moment(harvest?.startDate, "YYYY-MM-DD").format("DD/MM/YYYY")} a{" "}
            {moment(harvest?.endDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
          </Menu.Item>
        ))}
      </Menu>
    );
  }, [kpiHarvestList]);

  // System dropdown
  const systemMenu = useMemo(() => {
    const isCreateSystemVisible = homeConfigData?.systemVisibility["Cria"];
    const isFattenSystemVisible = homeConfigData?.systemVisibility["Engorda"];

    const defaultSubSystem = homeConfigData?.subSystemVisibility["Extensivo"]
      ? "Extensive"
      : homeConfigData?.subSystemVisibility["Semi-Intensivo"]
      ? "SemiIntensive"
      : homeConfigData?.subSystemVisibility["Intensivo"]
      ? "Intensive"
      : null;

    return (
      <Menu>
        {isFattenSystemVisible != null && isFattenSystemVisible === true ? (
          <Menu.Item
            onClick={() => {
              setSelectedProductionSubSystem(defaultSubSystem);
              setSelectedProductionSystem((old) => {
                if (old !== "Fatten") {
                  handleGenerateKpiDataList(homeConfigData, "Fatten");
                }
                return "Fatten";
              });
              updateKpiSelected(null);
            }}
          >
            <span>Engorda</span>
          </Menu.Item>
        ) : null}
        {isCreateSystemVisible != null && isCreateSystemVisible === true ? (
          <Menu.Item
            onClick={() => {
              setSelectedProductionSubSystem(null);
              setSelectedProductionSystem((old) => {
                if (old !== "Create") {
                  handleGenerateKpiDataList(homeConfigData, "Create");
                }
                return "Create";
              });
              updateKpiSelected(null);
            }}
          >
            <span>Cria</span>
          </Menu.Item>
        ) : null}
      </Menu>
    );
  }, [handleGenerateKpiDataList, updateKpiSelected, homeConfigData]);

  // Subsystem dropdown
  const subSystemMenu = useMemo(() => {
    const subSystemExtensive = homeConfigData?.subSystemVisibility["Extensivo"]
      ? true
      : false;
    const subSystemSemiIntensive = homeConfigData?.subSystemVisibility[
      "Semi-Intensivo"
    ]
      ? "SemiIntensive"
      : null;
    const subSystemIntensive = homeConfigData?.subSystemVisibility["Intensivo"]
      ? "Intensive"
      : null;

    return (
      <Menu>
        {subSystemExtensive ? (
          <Menu.Item
            onClick={() => {
              setSelectedProductionSubSystem("Extensive");
              updateKpiSelected(null);
            }}
          >
            <span>Extensivo</span>
          </Menu.Item>
        ) : null}

        {subSystemSemiIntensive ? (
          <Menu.Item
            onClick={() => {
              setSelectedProductionSubSystem("SemiIntensive");
              updateKpiSelected(null);
            }}
          >
            <span>Semi-Intensivo</span>
          </Menu.Item>
        ) : null}

        {subSystemIntensive ? (
          <Menu.Item
            onClick={() => {
              setSelectedProductionSubSystem("Intensive");
              updateKpiSelected(null);
            }}
          >
            <span>Intensivo</span>
          </Menu.Item>
        ) : null}
      </Menu>
    );
  }, [updateKpiSelected, homeConfigData]);

  const handleReprocessKpi = useCallback(async () => {
    try {
      await reprocessKpi({
        groupId,
        farmId,
        selectedProductionSystem,
        selectedProductionSubSystem,
      });

      notification.info({
        message: "Os Kpis estão sendo reprocessados, aguarde a finalização!",
        description:
          "Você receberá uma notificação (sininho) ao finalizar o processo dos Kpis.",
      });
    } catch (error) {
      console.error(error);
    }
  }, [groupId, farmId, selectedProductionSystem, selectedProductionSubSystem]);

  // Change home config
  useEffect(() => {
    if (homeConfigData != null) {
      const isCreateSystemVisible = homeConfigData?.systemVisibility["Cria"];
      const isFattenSystemVisible = homeConfigData?.systemVisibility["Engorda"];

      const system =
        isCreateSystemVisible && !isFattenSystemVisible ? "Create" : "Fatten";

      const subSystem = homeConfigData?.subSystemVisibility["Extensivo"]
        ? "Extensive"
        : homeConfigData?.subSystemVisibility["Semi-Intensivo"]
        ? "SemiIntensive"
        : homeConfigData?.subSystemVisibility["Intensivo"]
        ? "Intensive"
        : null;

      setSelectedProductionSystem(system);
      setSelectedProductionSubSystem(null);
      setSelectedProductionSubSystem(subSystem);
      handleGenerateKpiDataList(homeConfigData, system);
      fetchHarvests();
    }
  }, [fetchHarvests, handleGenerateKpiDataList, homeConfigData]);

  // On harvest, system or subsystem change - reload kpi data
  useEffect(() => {
    if (kpiHarvestSelected != null && selectedProductionSystem != null) {
      setIsLoadingKpis();
      reloadKpiData(
        kpiHarvestSelected,
        selectedProductionSystem,
        selectedProductionSubSystem
      );
    }
  }, [
    kpiHarvestSelected,
    selectedProductionSystem,
    selectedProductionSubSystem,
    setIsLoadingKpis,
    reloadKpiData,
  ]);

  // on kpiDataList change update context
  useEffect(() => {
    updateKpiDataList(kpiDataList);
  }, [kpiDataList, updateKpiDataList]);

  return (
    <>
      {/* Header */}
      <Row type="flex" align="middle" gutter={16} style={{ marginBottom: 10 }}>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          {selectedProductionSystem !== "Create" && (
            <div className="dropdown-harvest">
              <Dropdown overlay={harvestMenu} value={kpiHarvestSelected}>
                <span className="text-blue" onClick={(e) => e.preventDefault()}>
                  <b>Indicadores (KPIs)</b> - Safra
                  {` ${
                    kpiHarvestSelected?.startDate && kpiHarvestSelected?.endDate
                      ? `${moment(
                          kpiHarvestSelected?.startDate,
                          "YYYY-MM-DD"
                        ).format("DD/MM/YYYY")} a ${moment(
                          kpiHarvestSelected?.endDate,
                          "YYYY-MM-DD"
                        ).format("DD/MM/YYYY")}`
                      : " - "
                  }`}
                  <ArrowDownWhite />
                </span>
              </Dropdown>
            </div>
          )}
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <div className="flex-start">
            <div className="card-system">
              <span className="font-grey">Sistema: </span>
              <Dropdown overlay={systemMenu} value={selectedProductionSystem}>
                <span onClick={(e) => e.preventDefault()}>
                  {selectedProductionSystem != null
                    ? selectedProductionSystem === "Fatten"
                      ? "Engorda"
                      : selectedProductionSystem === "Create"
                      ? "Cria"
                      : null
                    : null}{" "}
                  <ArrowDownWhite />
                </span>
              </Dropdown>
              <div className="vertical-line" />
              <span className="font-grey">Subsistema: </span>
              <Dropdown
                overlay={subSystemMenu}
                value={
                  selectedProductionSystem === "Create"
                    ? null
                    : selectedProductionSubSystem
                }
                disabled={selectedProductionSystem === "Create"}
              >
                <span onClick={(e) => e.preventDefault()}>
                  {selectedProductionSystem === "Create"
                    ? "Nenhum"
                    : selectedProductionSubSystem != null
                    ? selectedProductionSubSystem === "Extensive"
                      ? "Extensivo"
                      : selectedProductionSubSystem === "SemiIntensive"
                      ? "Semi-Intensivo"
                      : selectedProductionSubSystem === "Intensive"
                      ? "Intensivo"
                      : null
                    : null}
                  <ArrowDownWhite />
                </span>
              </Dropdown>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} align="right">
          <ButtonStandard buttonType="secondary" onClick={handleReprocessKpi}>
            Reprocessar KPIs
          </ButtonStandard>
        </Col>
      </Row>
      {/* Kpis */}
      {selectedProductionSystem === "Create" ? (
        <>
          {/* Kpis - For Create System */}
          {/* Row with Kg Bez. Desm / Kg Vaca, IEP and EFICIENCIA REPRODUTIVA kpis */}
          <Row type="flex" justify="start">
            {kpiWeanCalf?.isVisible ? <KpiWeanCalf /> : null}
            {kpiIEP?.isVisible ? <KpiIEP /> : null}
            {kpiReproductiveEfficiency?.isVisible ? (
              <KpiReproductiveEfficiency />
            ) : null}
          </Row>
          {/* Row with CUSTO BEZERRO DESMAMADO and VACAS DESCARTADAS kpis */}
          <Row type="flex" justify="start">
            {kpiWeanCalfCost?.isVisible ? <KpiWeanCalfCost /> : null}
            {kpiDiscardedCows?.isVisible ? <KpiDiscardedCows /> : null}
          </Row>
          <KpiDetail />
        </>
      ) : (
        <>
          {/* Kpis - For Fatten and Recreate System */}
          {/* Row with LOTACAO, PRODUÇÃO and DESFRUTE kpis */}
          <Row type="flex" justify="start">
            {kpiLotation?.isVisible ? <KpiLotation /> : null}
            {selectedProductionSubSystem !== "Intensive" &&
            kpiProduction?.isVisible ? (
              <KpiProduction />
            ) : null}
            {selectedProductionSubSystem !== "Intensive" &&
            kpiUsufruct?.isVisible ? (
              <KpiUsufruct />
            ) : null}
          </Row>
          {/* Row with GDP, CUSTO and MARGEM kpis */}
          <Row type="flex" justify="start">
            {kpiGDP?.isVisible ? <KpiGDP /> : null}
            {kpiCost?.isVisible ? <KpiCost /> : null}
            {kpiMargin?.isVisible ? <KpiMargin /> : null}
          </Row>
          {/* Row with PEGADA_CARBONICA, DESEMBOLSO_CAB_MES e VALOR_DO_ATIVO kpis */}
          <Row type="flex" justify="start">
            {kpiCarbonFootprint?.isVisible ? <KpiCarbonFootprint /> : null}
            {kpiDisbursement?.isVisible ? <KpiDisbursement /> : null}
            {kpiAssetValue?.isVisible ? <KpiAssetValue /> : null}
          </Row>
          {/* Row with MARGEM_INCREMENTAL kpis */}
          <Row type="flex" justify="start">
            {kpiIncrementalMargin?.isVisible ? <KpiIncrementalMargin /> : null}
          </Row>
          <KpiDetail />
        </>
      )}
    </>
  );
};

export default KpiPanel;
