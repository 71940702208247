import React, { createContext, useCallback, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Creators as AnimalActions } from "../../../store/ducks/animal";
import useAnimalListContext from "../../../hooks/useAnimalListContext";
import moment from "moment";

const AnimalReproductionContext = createContext({
  identificationSelected: "handlingNumber",
  femaleTableTypeSelected: "register",
  handleChangeFemaleTableType: (type) => {},
  handleChangeIdentification: (identification) => {},
  fetchData: (
    page = 0,
    sorter = null,
    filters = null,
    size = 10,
    ids = null
  ) => {},
});

export const AnimalReproductionContextProvider = ({ children }) => {
  const {
    app: {
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { alertsEntityIds } = useAnimalListContext();

  const [femaleTableTypeSelected, setFemaleTableTypeSelected] =
    useState("register");

  const [identificationSelected, setIdentificationSelected] =
    useState("handlingNumber");

  const handleChangeIdentification = useCallback((identification) => {
    setIdentificationSelected(identification);
  }, []);

  const handleChangeFemaleTableType = useCallback((type) => {
    setFemaleTableTypeSelected(type);
  }, []);

  const getSearchString = useCallback((filters) => {
    let search = "";

    if (filters && Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([k, v]) => {
        if (v && (v.length > 0 || Object.keys(v)?.length > 0)) {
          const fieldS = k
            ? k === "handlingNumber"
              ? "handling_number"
              : k === "tagId"
              ? "tag_id"
              : k === "farmStatus"
              ? "farm_status"
              : k === "lotName"
              ? "lot_name"
              : k === "picketName"
              ? "picket_name"
              : k === "breedName"
              ? "breed_name"
              : k === "supplierName"
              ? "supplier_name"
              : k === "currentWeightRanking"
              ? "current_weight_ranking"
              : k === "animalReproductionCategory"
              ? "animal_reproduction_category"
              : k === "femaleSituation"
              ? "female_situation"
              : k === "animalReproductionState"
              ? "animal_reproduction_state"
              : k === "lastDailyWeight"
              ? "last_daily_weight"
              : k === "lastDailyWeightGain"
              ? "last_daily_weight_gain"
              : k === "lastDailyWeightDate"
              ? "last_daily_weight_date"
              : k
            : null;
          if (
            fieldS === "last_daily_weight" ||
            fieldS === "last_daily_weight_gain"
          ) {
            let operator = v.type ? v.type : "=";
            operator = operator.replace("<", "%3C").replace(">", "%3E");
            if (operator === "=") {
              const min_value = Math.floor(v.value);
              const max_value = Math.ceil(v.value);
              search =
                search === "" || search === null
                  ? `${fieldS} %3E ${min_value} and ${fieldS} %3C ${max_value}`
                  : `${search};${fieldS} %3E ${min_value} and ${fieldS} %3C ${max_value}`;
            } else if (operator === "%3C=") {
              const max_value = parseFloat(v.value);
              search =
                search === "" || search === null
                  ? `${fieldS} ${operator} ${max_value}`
                  : `${search};${fieldS} ${operator} ${max_value}`;
            } else if (operator === "%3E=") {
              const min_value = parseFloat(v.value);
              search =
                search === "" || search === null
                  ? `${fieldS} ${operator} ${min_value}`
                  : `${search};${fieldS} ${operator} ${min_value}`;
            } else {
              search =
                search === "" || search === null
                  ? `${fieldS} ${operator} ${v.value}`
                  : `${search};${fieldS} ${operator} ${v.value}`;
            }
          } else if (fieldS === "last_daily_weight_date") {
            let operator = v.type ? v.type : "=";
            operator = operator.replace("<", "%3C").replace(">", "%3E");
            const formatedDateValue = `'${moment(v.value).format(
              "YYYY-MM-DD"
            )}'`;
            if (operator === "=") {
              search =
                search === "" || search === null
                  ? `${fieldS} = ${formatedDateValue}`
                  : `${search};${fieldS} = ${formatedDateValue}`;
            } else if (operator === "%3C=") {
              search =
                search === "" || search === null
                  ? `${fieldS} ${operator} ${formatedDateValue}`
                  : `${search};${fieldS} ${operator} ${formatedDateValue}`;
            } else if (operator === "%3E=") {
              search =
                search === "" || search === null
                  ? `${fieldS} ${operator} ${formatedDateValue}`
                  : `${search};${fieldS} ${operator} ${formatedDateValue}`;
            } else {
              search =
                search === "" || search === null
                  ? `${fieldS} ${operator} ${formatedDateValue}`
                  : `${search};${fieldS} ${operator} ${formatedDateValue}`;
            }
          } else if (fieldS === "current_weight_ranking") {
            search =
              search === ""
                ? `${fieldS} = '${v}'`
                : `${search};${fieldS} = '${v}'`;
          } else if (fieldS === "farm_status") {
            if (v.includes("I")) v.push("X");
            search =
              search === ""
                ? `${fieldS} in (${v.map((o) => (o = `'${o}'`)).join(", ")})`
                : `${search};${fieldS} in (${v
                    .map((o) => (o = `'${o}'`))
                    .join(", ")})`;
          } else if (fieldS === "female_situation") {
            if (v.includes("I")) v.push("X");
            search =
              search === ""
                ? `${fieldS} in (${v.map((o) => (o = `'${o}'`)).join(", ")})`
                : `${search};${fieldS} in (${v
                    .map((o) => (o = `'${o}'`))
                    .join(", ")})`;
          } else if (fieldS === "sisbov") {
            search =
              search === ""
                ? `upper(cast(${fieldS} as varchar)) like upper('%25${v}%25')`
                : `${search};upper(cast(${fieldS} as varchar)) like upper('%25${v}%25')`;
          } else {
            search =
              search === ""
                ? `upper(${fieldS}) like upper('%25${v}%25')`
                : `${search};upper(${fieldS}) like upper('%25${v}%25')`;
          }
        }
      });
    }

    return search !== "" ? search : "farm_status='A';";
  }, []);

  const getSortString = useCallback((sorter) => {
    let customSort = sorter;

    if (sorter && Object.entries(sorter).length > 0) {
      const { field } = sorter;

      const fieldS = field
        ? field === "handlingNumber"
          ? "handling_number"
          : field === "tagId"
          ? "tag_id"
          : field === "farmStatus"
          ? "farm_status"
          : field === "lotName"
          ? "lot_name"
          : field === "picketName"
          ? "picket_name"
          : field === "breedName"
          ? "breed_name"
          : field === "supplierName"
          ? "supplier_name"
          : field === "animalReproductionCategory"
          ? "animal_reproduction_category"
          : field === "femaleSituation"
          ? "female_situation"
          : field === "animalReproductionState"
          ? "animal_reproduction_state"
          : field === "lastDailyWeight"
          ? "last_daily_weight"
          : field === "lastDailyWeightGain"
          ? "last_daily_weight_gain"
          : field === "lastDailyWeightDate"
          ? "last_daily_weight_date"
          : field
        : null;

      customSort = {
        ...customSort,
        field: fieldS,
      };
    }

    return customSort;
  }, []);

  const getSearchReproductionEventString = useCallback((filters) => {
    let search = "";

    if (filters && Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([k, v]) => {
        if (v && (v.length > 0 || Object.keys(v)?.length > 0)) {
          const fieldS = k
            ? k === "animalHandlingNumber"
              ? "animal_handling_number"
              : k === "animalTagId"
              ? "animal_tag_id"
              : k === "animalSisbov"
              ? "animal_sisbov"
              : k === "animalName"
              ? "animal_name"
              : k === "currentAge"
              ? "current_age"
              : k === "currentWeight"
              ? "current_weight"
              : k === "currentReproductionScore"
              ? "current_reproduction_score"
              : k === "currentPregnanciesCount"
              ? "current_pregnancies_count"
              : k
            : null;
          if (
            fieldS === "current_age" ||
            fieldS === "current_reproduction_score" ||
            fieldS === "current_pregnancies_count"
          ) {
            let operator = v.type ? v.type : "=";
            operator = operator.replace("<", "%3C").replace(">", "%3E");
            if (operator === "=") {
              search =
                search === "" || search === null
                  ? `${fieldS} = ${v.value}`
                  : `${search};${fieldS} = ${v.value}`;
            } else if (operator === "%3C=") {
              const max_value = parseFloat(v.value) + 0.4999999;
              search =
                search === "" || search === null
                  ? `${fieldS} ${operator} ${max_value}`
                  : `${search};${fieldS} ${operator} ${max_value}`;
            } else if (operator === "%3E=") {
              const min_value = parseFloat(v.value) - 0.5;
              search =
                search === "" || search === null
                  ? `${fieldS} ${operator} ${min_value}`
                  : `${search};${fieldS} ${operator} ${min_value}`;
            } else {
              search =
                search === "" || search === null
                  ? `${fieldS}${operator}'${v.value}'`
                  : `${search};${fieldS}${operator}'${v.value}'`;
            }
          } else if (fieldS === "current_weight") {
            let operator = v.type ? v.type : "=";
            operator = operator.replace("<", "%3C").replace(">", "%3E");
            if (operator === "=") {
              const min_value = Math.floor(v.value);
              const max_value = Math.ceil(v.value);
              search =
                search === "" || search === null
                  ? `${fieldS} %3E ${min_value} and ${fieldS} %3C ${max_value}`
                  : `${search};${fieldS} %3E ${min_value} and ${fieldS} %3C ${max_value}`;
            } else if (operator === "%3C=") {
              const max_value = parseFloat(v.value) + 0.4999999;
              search =
                search === "" || search === null
                  ? `${fieldS} ${operator} ${max_value}`
                  : `${search};${fieldS} ${operator} ${max_value}`;
            } else if (operator === "%3E=") {
              const min_value = parseFloat(v.value) - 0.5;
              search =
                search === "" || search === null
                  ? `${fieldS} ${operator} ${min_value}`
                  : `${search};${fieldS} ${operator} ${min_value}`;
            } else {
              search =
                search === "" || search === null
                  ? `${fieldS}${operator}'${v.value}'`
                  : `${search};${fieldS}${operator}'${v.value}'`;
            }
          } else if (fieldS === "status") {
            if (v.includes("I")) v.push("X");
            search =
              search === ""
                ? `${fieldS} in (${v.map((o) => (o = `'${o}'`)).join(", ")})`
                : `${search};${fieldS} in (${v
                    .map((o) => (o = `'${o}'`))
                    .join(", ")})`;
          } else {
            search =
              search === ""
                ? `upper(${fieldS}) like upper('%25${v}%25')`
                : `${search};upper(${fieldS}) like upper('%25${v}%25')`;
          }
        }
      });
    }

    return search !== "" ? search : "status='Active';";
  }, []);

  const getSortReproductionEventString = useCallback((sorter) => {
    let customSort = sorter;

    if (sorter && Object.entries(sorter).length > 0) {
      const { field } = sorter;

      const fieldS = field
        ? field === "animalHandlingNumber"
          ? "animal_handling_number"
          : field === "animalTagId"
          ? "animal_tag_id"
          : field === "animalSisbov"
          ? "animal_sisbov"
          : field === "animalName"
          ? "animal_name"
          : field === "currentAge"
          ? "current_age"
          : field === "currentWeight"
          ? "current_weight"
          : field === "currentReproductionScore"
          ? "current_reproduction_score"
          : field === "currentPregnanciesCount"
          ? "current_pregnancies_count"
          : field
        : null;

      customSort = {
        ...customSort,
        field: fieldS,
      };
    }

    return customSort;
  }, []);

  const fetchReproductionMaleData = useCallback(
    (page = 0, sorter = null, filters = null, size = 10, ids = null) => {
      let customSort = getSortString(sorter);
      let search =
        `animal_farm_function='reproduction';gender='M';` +
        getSearchString(filters);

      dispatch(
        AnimalActions.indexAnimalMaleTable(
          groupId,
          farmId,
          page,
          customSort,
          search,
          size,
          ids != null ? ids : alertsEntityIds !== null ? alertsEntityIds : null,
          sorter,
          filters
        )
      );
    },
    [getSortString, getSearchString, dispatch, groupId, farmId, alertsEntityIds]
  );

  const fetchReproductionFemaleData = useCallback(
    (page = 0, sorter = null, filters = null, size = 10, ids = null) => {
      let customSort = getSortString(sorter);
      let search =
        `animal_farm_function='reproduction';gender='F';` +
        getSearchString(filters);

      dispatch(
        AnimalActions.indexAnimalFemaleTable(
          groupId,
          farmId,
          page,
          customSort,
          search,
          size,
          ids != null ? ids : alertsEntityIds !== null ? alertsEntityIds : null,
          sorter,
          filters
        )
      );
    },
    [getSortString, getSearchString, dispatch, groupId, farmId, alertsEntityIds]
  );

  const fetchReproductionFemaleReproductionEventData = useCallback(
    (
      year = moment().year(),
      page = 0,
      sorter = null,
      filters = null,
      size = 10,
      ids = null
    ) => {
      let customSort = getSortReproductionEventString(sorter);
      let search = getSearchReproductionEventString(filters);

      dispatch(
        AnimalActions.indexAnimalFemaleReproductionEventsTable(
          groupId,
          farmId,
          year,
          page,
          customSort,
          search,
          size,
          ids != null ? ids : alertsEntityIds !== null ? alertsEntityIds : null,
          sorter,
          filters
        )
      );
    },
    [
      getSortReproductionEventString,
      getSearchReproductionEventString,
      dispatch,
      groupId,
      farmId,
      alertsEntityIds,
    ]
  );

  return (
    <AnimalReproductionContext.Provider
      value={{
        identificationSelected,
        femaleTableTypeSelected,
        handleChangeFemaleTableType,
        handleChangeIdentification,
        fetchReproductionMaleData,
        fetchReproductionFemaleData,
        fetchReproductionFemaleReproductionEventData,
      }}
    >
      {children}
    </AnimalReproductionContext.Provider>
  );
};

export function useAnimalReproductionContext() {
  const value = useContext(AnimalReproductionContext);
  return value;
}
