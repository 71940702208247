import { Formik } from "formik";
import moment from "moment";
import React, { useCallback, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useFinancial } from "../../../hooks/useFinancialReducer";
import useWindowSize from "../../../hooks/useWindowSize";

import {
  Button,
  Col,
  DatePicker,
  Icon,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Table,
  notification,
} from "antd";
import NumberFormat from "react-number-format";
import ButtonStandard from "../../utils/button";
import TagStatus from "../../utils/tagStatus";
import { Container } from "./styles";

// Services
import { useState } from "react";
import {
  settleFinancialTransactions,
  unsettleFinancialTransactions,
} from "../../../services/financialTransactionsService";
import {
  getTwoDecimalDigits,
  numberMask,
} from "../../../services/helpersMethodsService";

// import { Container } from './styles';
const Column = Table.Column;

const INITIAL_FORM_STATE = {
  useExpirationDate: false,
  settleDate: null,
};

const validationSchema = Yup.object().shape({
  useExpirationDate: Yup.boolean().required(),
  settleDate: Yup.date()
    .notRequired()
    .nullable()
    .when(["useExpirationDate"], {
      is: (useExpirationDate) => !useExpirationDate,
      then: Yup.date().required(),
      otherwise: Yup.date().notRequired().nullable(),
    }),
});

function SettleFinancialTransactionModal() {
  const formikRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState(null);
  const [pagination, setPagination] = useState({
    total: null,
    size: 10,
    page: 0,
  });
  const {
    refreshListFinancialTransaction,
    listFinancialTransaction,
    selectedFinancialTransactionElements,
    selectedFinancialTransactionKeys: ids,
    shouldShowModalSettledFinancialTransaction: visible,
    modalSettledFinancialTransactionAction: action,
    handleCloseModalSettled,
    handleSelectFinancialTransaction,
  } = useFinancial();
  const { width } = useWindowSize();
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const rowSelection = {
    selectedRowKeys: ids,
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      if (selected) {
        handleSelectFinancialTransaction([
          ...selectedFinancialTransactionElements,
          record,
        ]);
      } else {
        handleSelectFinancialTransaction(
          selectedFinancialTransactionElements.filter(
            (element) => element.id !== record.id
          )
        );
      }
    },
    onSelectAll: (record, selected, selectedRows, nativeEvent) => {
      // Get just the ids
      // const keys = selectedRows.map((sr) => sr.id);
      // Get all the transactions selected unless the unselected
      const lastSelectedTransactions =
        selectedFinancialTransactionElements.filter(
          (sA) => !selectedRows.includes(sA)
        );

      if (record) {
        handleSelectFinancialTransaction([
          ...selectedFinancialTransactionElements,
          ...selectedRows,
        ]);
      } else {
        handleSelectFinancialTransaction(lastSelectedTransactions);
      }
    },
  };

  const data = useMemo(() => {
    let installments = [];

    listFinancialTransaction.forEach((mainTransaction) => {
      mainTransaction.installments.forEach((installment) => {
        installments.push({
          transactionType: mainTransaction.transactionType,
          documentNumberAlias: mainTransaction.documentNumberAlias,
          entityName: mainTransaction.entityName,
          ...installment,
        });
      });
    });

    if (action === "remove") {
      installments = installments.filter(
        (installment) => installment.isSettled === true
      );
    }
    return installments;
  }, [listFinancialTransaction, action]);

  const onTableChange = (pagination, filters, sorter) => {
    setPagination({
      total: pagination.total,
      page: pagination.current,
      size: pagination.pageSize,
    });
    setFilters(filters);
  };

  const updateTransactionsToSettled = useCallback(
    async (values) => {
      setIsLoading(true);
      try {
        const financialTransactionMainIds = [
          ...new Set(
            selectedFinancialTransactionElements.map(
              (element) => element.financialTransactionMainId
            )
          ),
        ];
        const body = {
          settleDate: values.settleDate,
          useExpirationDate: values.useExpirationDate,
          financialTransactionIds: ids,
          financialTransactionMainIds,
        };
        await settleFinancialTransactions({
          groupId,
          farmId,
          body,
        });
        formikRef.current.resetForm(INITIAL_FORM_STATE);
        notification.success({
          message: "As movimentações financeiras foram atualizadas e baixadas",
        });
        setPagination({
          total: null,
          size: 10,
          page: 0,
        });
        setFilters(null);
        handleSelectFinancialTransaction([]);
        refreshListFinancialTransaction();
        setIsLoading(false);
        handleCloseModalSettled();
      } catch (error) {
        notification.error({
          message:
            "Não foi possível baixar as movimentações financeiras. Contate o suporte.",
        });
        setIsLoading(false);
      }
    },
    [
      selectedFinancialTransactionElements,
      ids,
      groupId,
      farmId,
      handleSelectFinancialTransaction,
      refreshListFinancialTransaction,
      handleCloseModalSettled,
    ]
  );

  const updateTransactionsToUnsettled = useCallback(
    async (values) => {
      setIsLoading(true);
      try {
        const financialTransactionMainIds = [
          ...new Set(
            selectedFinancialTransactionElements.map(
              (element) => element.financialTransactionMainId
            )
          ),
        ];
        const body = {
          financialTransactionIds: ids,
          financialTransactionMainIds,
        };
        await unsettleFinancialTransactions({
          groupId,
          farmId,
          body,
        });
        formikRef.current.resetForm(INITIAL_FORM_STATE);
        notification.success({
          message:
            'As movimentações financeiras foram atualizadas e seu status atualizado para "Em Aberto"',
        });
        setPagination({
          total: null,
          size: 10,
          page: 0,
        });
        setFilters(null);
        handleSelectFinancialTransaction([]);
        refreshListFinancialTransaction();
        setIsLoading(false);
        handleCloseModalSettled();
      } catch (error) {
        notification.error({
          message:
            "Não foi possível atualizar as movimentações financeiras. Contate o suporte.",
        });
        setIsLoading(false);
      }
    },
    [
      selectedFinancialTransactionElements,
      ids,
      groupId,
      farmId,
      handleSelectFinancialTransaction,
      refreshListFinancialTransaction,
      handleCloseModalSettled,
    ]
  );

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  const handleGetDateSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div
          style={{
            padding: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Row style={{ marginBottom: 5 }}>
            <DatePicker.RangePicker
              value={selectedKeys && selectedKeys[0]?.dates}
              format="DD/MM/YYYY"
              onChange={(dates, dateStrings) => setSelectedKeys([{ dates }])}
            />
          </Row>
          <Row>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{
                width: 90,
                marginRight: 8,
                background: "#684e94",
                borderColor: "none",
                border: "none",
              }}
            />
            <Button
              onClick={() => handleReset(clearFilters)}
              icon="delete"
              size="small"
              style={{ width: 90 }}
            />
          </Row>
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="calendar"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return moment(record[dataIndex]).isBetween(
          value?.dates[0],
          value?.dates[1],
          "days",
          "[]"
        );
      },
    }),
    [handleReset, handleSearch]
  );

  const handleGetColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          {dataIndex === "expirationDate" || dataIndex === "billingDate" ? (
            <DatePicker
              allowClear={true}
              value={
                selectedKeys[0] != null
                  ? moment(selectedKeys[0], "YYYY-MM-DD")
                  : undefined
              }
              style={{ width: 188, marginBottom: 8, display: "block" }}
              format={"DD/MM/YYYY"}
              onChange={(date, dateString) => {
                setSelectedKeys(date ? [date] : []);
              }}
            />
          ) : (
            <Input
              value={selectedKeys ? selectedKeys[0] : undefined}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() =>
                handleSearch(selectedKeys, confirm, dataIndex)
              }
              style={{ width: 188, marginBottom: 8, display: "block" }}
            />
          )}
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        if (dataIndex === "expirationDate" || dataIndex === "billingDate") {
          return record[dataIndex]
            ?.toString()
            .includes(moment(value).format("YYYY-MM-DD"));
        } else if (dataIndex === "costActivityType") {
          return translation.costType[record[dataIndex]]
            ?.toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase());
        } else if (dataIndex === "transactionType") {
          let labelValue =
            record[dataIndex] === "expense" ? "Despesa" : "Receita";
          return labelValue
            .toLowerCase()
            .includes(value.toString().toLowerCase());
        } else {
          return record[dataIndex]
            ?.toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase());
        }
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
        }
      },
    }),
    [handleReset, handleSearch, translation.costType]
  );

  const handleGetColumnValueSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <div style={{ display: "block" }}>
            <Select
              value={
                selectedKeys
                  ? selectedKeys[0]?.type != null
                    ? selectedKeys[0]?.type
                    : "="
                  : "="
              }
              style={{
                width: 90,
                textAlign: "center",
                marginBottom: 8,
                marginRight: 8,
              }}
              onChange={(value) => {
                setSelectedKeys(
                  value
                    ? [{ ...selectedKeys[0], type: value }]
                    : [{ ...selectedKeys[0] }]
                );
              }}
            >
              <Select.Option key="<" value="<" style={{ textAlign: "center" }}>
                &lt;
              </Select.Option>
              <Select.Option
                key="<="
                value="<="
                style={{ textAlign: "center" }}
              >
                &le;
              </Select.Option>
              <Select.Option key="=" value="=" style={{ textAlign: "center" }}>
                &#61;
              </Select.Option>
              <Select.Option
                key=">="
                value=">="
                style={{ textAlign: "center" }}
              >
                &ge;
              </Select.Option>
              <Select.Option key=">" value=">" style={{ textAlign: "center" }}>
                &gt;
              </Select.Option>
            </Select>
            <NumberFormat
              customInput={Input}
              value={
                selectedKeys
                  ? selectedKeys[0]?.value != null
                    ? selectedKeys[0]?.value
                    : ""
                  : ""
              }
              decimalScale={2}
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale={true}
              onValueChange={({ floatValue }) =>
                setSelectedKeys(
                  floatValue
                    ? [
                        {
                          type: selectedKeys
                            ? selectedKeys[0]?.type || "="
                            : "=",
                          value: floatValue,
                        },
                      ]
                    : []
                )
              }
              onPressEnter={() => handleSearch(selectedKeys, confirm)}
              style={{ width: 90, marginBottom: 8 }}
              defaultValue={0}
              step={0.01}
            />
          </div>
          <div>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{
                width: 90,
                marginRight: 8,
                background: "#684e94",
                borderColor: "none",
                border: "none",
              }}
            />
            <Button
              onClick={() => handleReset(clearFilters)}
              icon="delete"
              size="small"
              style={{ width: 90 }}
            />
          </div>
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        switch (value.type) {
          case "=":
            return getTwoDecimalDigits(record[dataIndex]) === value.value;
          case "<=":
            return getTwoDecimalDigits(record[dataIndex]) <= value.value;
          case "<":
            return getTwoDecimalDigits(record[dataIndex]) < value.value;
          case ">=":
            return getTwoDecimalDigits(record[dataIndex]) >= value.value;
          case ">":
            return getTwoDecimalDigits(record[dataIndex]) > value.value;
          default:
            return getTwoDecimalDigits(record[dataIndex]) === value.value;
        }
      },
    }),
    [handleReset, handleSearch]
  );

  const handleCancel = useCallback(() => {
    setPagination({
      total: null,
      size: 10,
      page: 0,
    });
    setFilters(null);
    formikRef.current.resetForm(INITIAL_FORM_STATE);
    handleCloseModalSettled();
  }, [handleCloseModalSettled]);

  const handleSubmit = useCallback(
    (values) => {
      if (selectedFinancialTransactionElements.length === 0) {
        notification.warn({
          message: "Você precisa selecionar as movimentações primeiro.",
        });
      } else {
        if (action === "new") {
          const settleDate = values.settleDate;
          if (
            selectedFinancialTransactionElements.filter(
              (financialTransaction) =>
                moment(financialTransaction.billingDate).isAfter(settleDate)
            ).length > 0
          ) {
            const numberOfTransactionsWithBillingDateBeforeSettleDate =
              selectedFinancialTransactionElements.filter(
                (financialTransaction) =>
                  moment(financialTransaction.billingDate).isAfter(settleDate)
              ).length;
            Modal.error({
              centered: true,
              width: 550,
              title: "Erro",
              content: `Data da Baixa não compatível com a data de faturamento de ${numberOfTransactionsWithBillingDateBeforeSettleDate} Mov. Financeira(s).`,
            });
            return;
          } else {
            if (
              selectedFinancialTransactionElements.filter(
                (financialTransaction) =>
                  financialTransaction.isSettled === true
              ).length > 0
            ) {
              Modal.confirm({
                centered: true,
                width: 550,
                title: "Atenção",
                content: `As movimentações que já estavam baixadas, serão atualizadas. Deseja continuar?`,
                okText: "Não",
                onOk: () => {
                  return;
                },
                cancelText: "Sim",
                onCancel: () => {
                  updateTransactionsToSettled(values);
                },
              });
            } else {
              updateTransactionsToSettled(values);
            }
          }
        } else {
          if (
            selectedFinancialTransactionElements.filter(
              (financialTransaction) => financialTransaction.isSettled === false
            ).length > 0
          ) {
            Modal.error({
              centered: true,
              width: 500,
              content: `Por favor, selecione somente movimentações com o status "Baixado".`,
            });

            return;
          } else if (
            selectedFinancialTransactionElements.filter(
              (financialTransaction) =>
                financialTransaction.isSettled === true &&
                financialTransaction.isPaidPartially === true
            ).length > 0
          ) {
            Modal.error({
              centered: true,
              width: 500,
              content: `Por favor, selecione somente movimentações que não foram pagas parcialmente.`,
            });

            return;
          }
          updateTransactionsToUnsettled(values);
        }
      }
    },
    [
      action,
      selectedFinancialTransactionElements,
      updateTransactionsToSettled,
      updateTransactionsToUnsettled,
    ]
  );

  const TableListOfFinancialTransactions = (
    <Table
      rowClassName={(record) =>
        record.transactionType === "expense"
          ? "table-row-red"
          : "table-row-green"
      }
      rowKey="id"
      size="small"
      dataSource={data != null ? data : []}
      onChange={onTableChange}
      pagination={{
        current: pagination.page,
        hideOnSinglePage: pagination.size > 10 ? false : true,
        pageSize: pagination.size,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
      }}
      scroll={{
        x: true,
      }}
      rowSelection={rowSelection}
      style={{ width: "100%", margin: "0" }}
    >
      <Column
        title={translation.financial.transactions.table.columns.isSettled}
        dataIndex="isSettled"
        key="isSettled"
        align="left"
        sorter={(first, second) => {
          return first.isSettled === second.isSettled
            ? 0
            : first.isSettled
            ? -1
            : 1;
        }}
        filteredValue={
          filters?.isSettled != null
            ? filters?.isSettled
            : action === "new"
            ? ["false"]
            : null
        }
        filtered
        filters={[
          {
            text: "Todos",
            value: "all",
          },
          {
            text: "Baixado",
            value: "true",
          },
          {
            text: "Em Aberto",
            value: "false",
          },
        ]}
        filterMultiple={false}
        onFilter={(value, record) => {
          return value === "all"
            ? true
            : value === "true"
            ? record.isSettled === true
            : record.isSettled === false;
        }}
        render={(text, record) =>
          record.isSettled === false ? (
            <TagStatus
              background="#E4F4FF"
              borderColor="#4A85AE"
              color="#4A85AE"
            >
              Em Aberto
            </TagStatus>
          ) : (
            <TagStatus
              background="#E1FFE4"
              borderColor="#106518"
              color="#106518"
            >
              Baixado
            </TagStatus>
          )
        }
      />
      {action === "remove" && (
        <Column
          title={translation.financial.transactions.table.columns.settleDate}
          dataIndex="settleDate"
          key="settleDate"
          align="left"
          sorter={(first, second) => {
            return moment(first.settleDate).isBefore(moment(second.settleDate));
          }}
          filteredValue={filters?.settleDate || null}
          {...handleGetDateSearchProps("settleDate")}
          render={(text) => (text ? moment(text).format("DD/MM/YYYY") : null)}
        />
      )}
      <Column
        title={translation.financial.transactions.table.columns.transactionType}
        dataIndex="transactionType"
        key="transactionType"
        align="left"
        sorter={(first, second) => {
          return first.transactionType.localeCompare(
            second.transactionType,
            "pt-BR",
            {
              numeric: false,
              ignorePunctuation: true,
            }
          );
        }}
        filteredValue={filters?.transactionType || null}
        filtered
        filters={[
          {
            text: "Todos",
            value: "all",
          },
          {
            text: "Despesa",
            value: "expense",
          },
          {
            text: "Receita",
            value: "income",
          },
        ]}
        filterMultiple={false}
        onFilter={(value, record) => {
          return value === "all" ? true : record.transactionType === value;
        }}
        render={(text) =>
          text === "expense" ? <span>D</span> : <span>R</span>
        }
      />

      <Column
        title={translation.financial.transactions.table.columns.documentNumber}
        dataIndex="documentNumberAlias"
        key="documentNumberAlias"
        align="left"
        width={200}
        sorter={(first, second) => {
          if (!first.documentNumberAlias) {
            first.documentNumberAlias = "";
          }
          return first.documentNumberAlias.localeCompare(
            second.documentNumberAlias,
            "pt-BR",
            {
              numeric: false,
              ignorePunctuation: true,
              sensitivity: "base",
            }
          );
        }}
        filteredValue={filters?.documentNumberAlias || null}
        {...handleGetColumnSearchProps("documentNumberAlias")}
      />
      <Column
        title={translation.financial.transactions.table.columns.entityName}
        dataIndex="entityName"
        key="entityName"
        align="left"
        sorter={(first, second) => {
          if (!first.entityName) {
            first.entityName = "";
          }
          return first.entityName.localeCompare(second.entityName, "pt-BR", {
            numeric: false,
            ignorePunctuation: true,
            sensitivity: "base",
          });
        }}
        filteredValue={filters?.entityName || null}
        {...handleGetColumnSearchProps("entityName")}
      />
      <Column
        title={translation.financial.transactions.table.columns.installment}
        dataIndex="installmentNumber"
        key="installmentNumber"
        align="left"
        sorter={(first, second) => {
          return first.installmentNumber - second.installmentNumber;
        }}
        render={(text, record) => (
          <span>{`${record.installmentNumber}/${record.installmentTotal}`}</span>
        )}
      />
      <Column
        title={translation.financial.transactions.table.columns.billingDate}
        dataIndex="billingDate"
        key="billingDate"
        align="left"
        sorter={(first, second) => {
          return moment(first.billingDate).isBefore(moment(second.billingDate));
        }}
        filteredValue={filters?.billingDate || null}
        {...handleGetDateSearchProps("billingDate")}
        render={(text) => <span>{moment(text).format("DD/MM/YYYY")}</span>}
      />

      <Column
        title={translation.financial.transactions.table.columns.expirationDate}
        dataIndex="expirationDate"
        key="expirationDate"
        align="left"
        sorter={(first, second) => {
          return moment(first.expirationDate).isBefore(
            moment(second.expirationDate)
          );
        }}
        filteredValue={filters?.expirationDate || null}
        {...handleGetDateSearchProps("expirationDate")}
        render={(text) => <span>{moment(text).format("DD/MM/YYYY")}</span>}
      />
      <Column
        title={translation.financial.transactions.table.columns.isPaidPartially}
        dataIndex="isPaidPartially"
        key="isPaidPartially"
        align="left"
        sorter={(first, second) => {
          return first.isPaidPartially === second.isPaidPartially
            ? 0
            : first.isPaidPartially
            ? -1
            : 1;
        }}
        filteredValue={filters?.isPaidPartially || null}
        filtered
        filters={[
          {
            text: "Todos",
            value: "all",
          },
          {
            text: "Parc. Pago",
            value: "true",
          },
          {
            text: "Pago",
            value: "false",
          },
        ]}
        filterMultiple={false}
        onFilter={(value, record) => {
          return value === "all"
            ? true
            : value === "true"
            ? record.isPaidPartially === true
            : record.isPaidPartially === false;
        }}
        render={(text, record) => (text === true ? <Icon type="close" /> : "")}
      />

      <Column
        title={translation.financial.transactions.table.columns.value}
        dataIndex="value"
        key="value"
        align="left"
        sorter={(first, second) => {
          return first.value - second.value;
        }}
        filteredValue={filters?.value || null}
        {...handleGetColumnValueSearchProps("value")}
        render={(text, record) => (
          <span>{numberMask(record.value || 0, true)}</span>
        )}
      />
    </Table>
  );

  return (
    <Modal
      visible={visible}
      width={width >= 1440 ? 1350 : 800}
      title={
        action === "new" ? "Dar baixa nas movimentações" : "Remover baixa(s)"
      }
      centered
      closable={true}
      onCancel={handleCancel}
      footer={null}
    >
      <Formik
        ref={formikRef}
        initialValues={INITIAL_FORM_STATE}
        validationSchema={action === "new" ? validationSchema : null}
        onSubmit={handleSubmit}
        render={({
          values,
          errors,
          submitCount,
          setFieldValue,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Spin spinning={isLoading}>
              <Container>
                <Row type="flex" justify="center" align="middle">
                  <Col>
                    {action === "new" ? (
                      <p>
                        Ao clicar em confirmar, as movimentações listadas abaixo
                        com o status{" "}
                        <TagStatus
                          background="#E4F4FF"
                          borderColor="#4A85AE"
                          color="#4A85AE"
                        >
                          Em Aberto
                        </TagStatus>{" "}
                        serão atualizadas para{" "}
                        <TagStatus
                          background="#E1FFE4"
                          borderColor="#106518"
                          color="#106518"
                        >
                          Baixado
                        </TagStatus>{" "}
                        na data informada no campo "Data da Baixa", ou caso
                        habilitado o campo "Utilizar data de vencimento como
                        data de baixa?", será utilizado a data de vencimento
                        como a data da baixa.
                      </p>
                    ) : (
                      <p>
                        Ao clicar em confirmar, as movimentações listadas abaixo
                        com o status{" "}
                        <TagStatus
                          background="#E1FFE4"
                          borderColor="#106518"
                          color="#106518"
                        >
                          Baixado
                        </TagStatus>{" "}
                        serão atualizadas para{" "}
                        <TagStatus
                          background="#E4F4FF"
                          borderColor="#4A85AE"
                          color="#4A85AE"
                        >
                          Em Aberto
                        </TagStatus>
                        .
                      </p>
                    )}
                  </Col>
                </Row>
                {Object.entries(errors).length > 0 && submitCount > 0 && (
                  <Row type="flex" justify="center" align="middle">
                    <label className="error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
                {action === "new" && (
                  <Row
                    type="flex"
                    gutter={16}
                    style={{ marginBottom: 8, marginTop: 8 }}
                  >
                    <Col>
                      <Row>
                        <label>
                          Utilizar data de vencimento como data de baixa?
                        </label>
                      </Row>
                      <Row>
                        <Radio.Group
                          name="useExpirationDate"
                          style={{ color: "#4b4b4b" }}
                          value={values.useExpirationDate}
                          onChange={(e) => {
                            setFieldValue("useExpirationDate", e.target.value);
                            setFieldValue("settleDate", null);
                          }}
                        >
                          <Radio value={true}>{translation.buttons.yes}</Radio>
                          <Radio value={false}>{translation.buttons.no}</Radio>
                        </Radio.Group>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <label
                          className={
                            errors.settleDate && submitCount > 0 ? "error" : ""
                          }
                        >
                          Data da baixa
                          {values.useExpirationDate === false && "*"}
                        </label>
                      </Row>
                      <Row>
                        <DatePicker
                          name="settleDate"
                          value={values.settleDate}
                          format={"DD/MM/YYYY"}
                          disabledDate={(current) => current.isAfter(moment())}
                          disabled={values.useExpirationDate}
                          onChange={(date) => setFieldValue("settleDate", date)}
                        />
                      </Row>
                    </Col>
                  </Row>
                )}
                <Row type="flex">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {TableListOfFinancialTransactions}
                  </Col>
                </Row>
                {/* Footer */}
                <Row
                  type="flex"
                  justify="center"
                  align="middle"
                  gutter={16}
                  style={{ marginTop: 16 }}
                >
                  <Col>
                    <ButtonStandard
                      type="button"
                      buttonType="type4"
                      size="s"
                      onClick={handleCancel}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>
                  </Col>
                  <Col>
                    <ButtonStandard type="submit" buttonType="type1" size="s">
                      {translation.buttons.confirm}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Container>
            </Spin>
          </form>
        )}
      />
    </Modal>
  );
}

export default SettleFinancialTransactionModal;
