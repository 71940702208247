import React, {
  useContext,
  createContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { useSelector } from "react-redux";

import { notification } from "antd";

import {
  activateFarmCurrency,
  favoriteFarmCurrency,
  findAllFarmCurrency,
  inactivateFarmCurrency,
  saveFarmCurrency,
} from "../../../../../services/farmCurrencyService";
import { useCurrencyContext } from "../../../../../hooks/useCurrencyContext";

const FarmConvertionsContext = createContext(null);

export const FarmConvertionsContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [farmCurrencies, setFarmCurrencies] = useState([]);

  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const { refreshCurrencyContextValues } = useCurrencyContext();

  const hideOrShowForm = useCallback(() => {
    setShowForm((prevState) => !prevState);
  }, []);

  const switchLoading = useCallback(() => {
    setIsLoading((prevState) => !prevState);
  }, []);

  const fetchFarmCurrenciesData = useCallback(async () => {
    if (groupId && farmId) {
      setIsLoading(true);
      try {
        const {
          data: { results },
        } = await findAllFarmCurrency({
          groupId,
          farmId,
        });
        setFarmCurrencies(results);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
  }, [groupId, farmId]);

  const createFarmCurrency = useCallback(
    async (data) => {
      setIsLoading(true);
      try {
        await saveFarmCurrency({
          groupId,
          farmId,
          body: data,
          id: null,
        });
        notification.success({
          message: "Moeda cadastrada com sucesso.",
        });
        fetchFarmCurrenciesData();
        refreshCurrencyContextValues();
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    },
    [groupId, farmId, fetchFarmCurrenciesData, refreshCurrencyContextValues]
  );
  const favorite = useCallback(
    async (id, record) => {
      setIsLoading(true);
      try {
        await favoriteFarmCurrency({
          groupId,
          farmId,
          id,
        });
        notification.success({
          message: "Moeda colocada como padrão com sucesso.",
        });
        fetchFarmCurrenciesData();
        refreshCurrencyContextValues();
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    },
    [groupId, farmId, fetchFarmCurrenciesData, refreshCurrencyContextValues]
  );
  const activate = useCallback(
    async (id, record) => {
      setIsLoading(true);
      try {
        await activateFarmCurrency({
          groupId,
          farmId,
          id,
        });
        notification.success({
          message: "Moeda ativada com sucesso.",
        });
        fetchFarmCurrenciesData();
        refreshCurrencyContextValues();
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    },
    [groupId, farmId, fetchFarmCurrenciesData, refreshCurrencyContextValues]
  );
  const inactivate = useCallback(
    async (id, record) => {
      setIsLoading(true);
      try {
        await inactivateFarmCurrency({
          groupId,
          farmId,
          id,
        });
        notification.success({
          message: "Moeda inativada com sucesso.",
        });
        fetchFarmCurrenciesData();
        refreshCurrencyContextValues();
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    },
    [groupId, farmId, fetchFarmCurrenciesData, refreshCurrencyContextValues]
  );

  useEffect(() => {
    fetchFarmCurrenciesData();

    return () => {
      setShowForm(false);
      setFarmCurrencies([]);
    };
  }, [fetchFarmCurrenciesData]);

  const value = {
    isLoading,
    showForm,
    farmCurrencies,
    hideOrShowForm,
    switchLoading,
    fetchFarmCurrenciesData,
    createFarmCurrency,
    activate,
    inactivate,
    favorite,
  };

  return (
    <FarmConvertionsContext.Provider value={value}>
      {children}
    </FarmConvertionsContext.Provider>
  );
};

export function useFarmConvertionsContext() {
  const values = useContext(FarmConvertionsContext);
  return values;
}
