import {
  Col,
  Divider,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import { Formik } from "formik";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import ResultCenterApportionmentModal from "../../../pages/financial/tabs/transactions/form/resultCenterApportionmentModal";
import { findAllFarmOwners } from "../../../services/farmOwnerService";
import { findAllFinancialClassesByGroupIdAndFarmId } from "../../../services/financialClassService";
import { numberMask } from "../../../services/helpersMethodsService";
import {
  confirmTransferAnimalTransactions,
  rejectTransferAnimalTransactions,
} from "../../../services/transferAnimalTransactionService";
import ButtonStandard from "../../utils/button";
import SelectLotProduction from "../../utils/selectLotProduction";
import { Container } from "./styles";

// import { Container } from './styles';

const validateSchema = Yup.object().shape({
  financialNatureId: Yup.string().required(),
  financialClassId: Yup.string().required(),
  lotDestinationId: Yup.string().required(),
});

const validateSchemaOnlyLotDestination = Yup.object().shape({
  lotDestinationId: Yup.string().required(),
});

const FORM_INITIAL_VALUE = {
  farmOwnerId: null,
  lotDestinationId: null,
  financialNatureId: null,
  financialClassId: null,
  resultCenterApportionmentDefault: true,
  customApportionmentValues: null,
};

function ProcessTransferAnimalTransactionModal({
  transferAnimalTransactionData,
  modalVisible,
  closeModal,
}) {
  const formik = useRef();
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const [isLoading, setIsLoading] = useState(false);
  const [filteredListFinancialNature, setFilteredListFinancialNature] =
    useState([]);
  const [isLoadingFinancialNature, setIsLoadingFinancialNature] =
    useState(false);
  const [listFinancialClass, setListFinancialClass] = useState([]);
  const [isLoadingFinancialClass, setIsLoadingFinancialClass] = useState(false);
  const [
    isResultCenterApportionmentModalVisible,
    setIsResultCenterApportionmentModalVisible,
  ] = useState(false);
  const [farmOwners, setFarmOwners] = useState([]);

  const getFinancialClassAndNature = useCallback(async () => {
    setIsLoadingFinancialNature(true);
    setIsLoadingFinancialClass(true);
    try {
      const {
        data: { results: classes },
      } = await findAllFinancialClassesByGroupIdAndFarmId({
        groupId,
        farmId,
      });
      const natures = [
        ...new Map(
          classes
            .map((c) => c.financialNature)
            .map((item) => [item["id"], item])
        ).values(),
      ];
      setFilteredListFinancialNature(
        natures.filter((n) => n.type === "expense")
      );
      setListFinancialClass(classes);
    } catch (error) {
    } finally {
      setIsLoadingFinancialNature(false);
      setIsLoadingFinancialClass(false);
    }
  }, [farmId, groupId]);

  const getFarmOwnersOptions = useCallback(async () => {
    try {
      const {
        data: { results },
      } = await findAllFarmOwners({
        groupId,
        farmId,
      });

      const farmOwnerMainId = results.find((r) => r.mainOwner)?.id;

      //Update state
      setFarmOwners(results);

      //Update default form value
      formik.current.setFieldValue("farmOwnerId", farmOwnerMainId);
    } catch (error) {}
  }, [farmId, groupId]);

  const handleClose = useCallback(() => {
    formik.current.resetForm({});
    closeModal();
  }, [closeModal]);

  const handleReject = useCallback(() => {
    Modal.confirm({
      centered: true,
      title:
        translation.processTransferAnimalTransactionModal.messages
          .rejectModalTitle,
      content:
        translation.processTransferAnimalTransactionModal.messages
          .rejectModalContent,
      okText: "Não",
      onOk: () => {
        return;
      },
      cancelText: "Sim",
      onCancel: async () => {
        try {
          await rejectTransferAnimalTransactions({
            groupId,
            farmId,
            id: transferAnimalTransactionData?.id,
          });
          notification.success({
            message:
              translation.processTransferAnimalTransactionModal.messages
                .successReject,
          });
        } catch (error) {
          console.error(error);
          notification.error({
            message:
              translation.processTransferAnimalTransactionModal.messages
                .errorReject,
          });
        }
        closeModal();
      },
    });
  }, [
    closeModal,
    farmId,
    groupId,
    transferAnimalTransactionData,
    translation.processTransferAnimalTransactionModal.messages.errorReject,
    translation.processTransferAnimalTransactionModal.messages
      .rejectModalContent,
    translation.processTransferAnimalTransactionModal.messages.rejectModalTitle,
    translation.processTransferAnimalTransactionModal.messages.successReject,
  ]);

  const handleSubmit = useCallback(
    async (values, actions) => {
      setIsLoading(true);
      try {
        await confirmTransferAnimalTransactions({
          groupId,
          farmId,
          id: transferAnimalTransactionData?.id,
          body: values,
        });
        notification.success({
          message:
            translation.processTransferAnimalTransactionModal.messages
              .successSubmit,
        });
        closeModal();
      } catch (error) {
        notification.error({
          message:
            translation.processTransferAnimalTransactionModal.messages
              .errorSubmit,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      closeModal,
      farmId,
      groupId,
      transferAnimalTransactionData,
      translation.processTransferAnimalTransactionModal.messages.errorSubmit,
      translation.processTransferAnimalTransactionModal.messages.successSubmit,
    ]
  );

  const handleOpenCloseResultCenterApportionmentModal = () => {
    setIsResultCenterApportionmentModalVisible((old) => !old);
  };

  /* Modal Center Cost methods */
  const handleCancelCustomApportionmentModal = () => {
    handleOpenCloseResultCenterApportionmentModal();
    formik.current.setFieldValue("resultCenterApportionmentDefault", true);
    formik.current.setFieldValue("customApportionmentValues", null);
  };

  const handleSaveCustomApportionmentModal = (data) => {
    handleOpenCloseResultCenterApportionmentModal();
    formik.current.setFieldValue("resultCenterApportionmentDefault", false);
    formik.current.setFieldValue("customApportionmentValues", data);
  };

  useEffect(() => {
    if (modalVisible === true && formik) {
      Promise.all([getFinancialClassAndNature(), getFarmOwnersOptions()]);
    }
  }, [getFarmOwnersOptions, getFinancialClassAndNature, modalVisible, formik]);

  return (
    <Modal
      visible={modalVisible}
      width={700}
      centered
      closable={true}
      maskClosable={false}
      onCancel={handleClose}
      footer={null}
      title={translation.processTransferAnimalTransactionModal.title}
    >
      <Container>
        <Spin spinning={isLoading}>
          {/* Form */}
          <Row type="flex" gutter={8}>
            <Col span={24}>
              <Formik
                ref={formik}
                initialValues={FORM_INITIAL_VALUE}
                enableReinitialize={true}
                onSubmit={handleSubmit}
                validationSchema={
                  transferAnimalTransactionData?.financialCompensation
                    ? validateSchema
                    : validateSchemaOnlyLotDestination
                }
                render={({
                  values,
                  errors,
                  submitCount,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    {/* Body */}
                    <div className="body">
                      {Object.entries(errors).length > 0 && submitCount > 0 && (
                        <Row type="flex" justify="center" align="middle">
                          <label className="error">
                            {translation.error.formError}
                          </label>
                        </Row>
                      )}
                      <Row type="flex">
                        <Col span={24}>
                          <Row>
                            <label>
                              {
                                translation
                                  .processTransferAnimalTransactionModal.form
                                  .origin
                              }
                            </label>
                          </Row>
                          <Row>
                            <Typography.Text strong>
                              {transferAnimalTransactionData?.originGroupName !==
                              transferAnimalTransactionData?.destinationGroupName
                                ? `${transferAnimalTransactionData?.originGroupName} / ${transferAnimalTransactionData?.originFarmName}`
                                : transferAnimalTransactionData?.originFarmName}
                            </Typography.Text>
                          </Row>
                        </Col>
                      </Row>
                      {transferAnimalTransactionData?.financialCompensation ? (
                        <>
                          <Row type="flex" gutter={8}>
                            <Col span={6}>
                              <Row>
                                <label>
                                  {
                                    translation
                                      .processTransferAnimalTransactionModal
                                      .form.amountAnimals
                                  }
                                </label>
                              </Row>
                              <Row>
                                <Typography.Text strong>
                                  {`${transferAnimalTransactionData?.amountAnimals}`}
                                </Typography.Text>
                              </Row>
                            </Col>
                            <Col span={6}>
                              <Row>
                                <label>
                                  {
                                    translation
                                      .processTransferAnimalTransactionModal
                                      .form.sellDate
                                  }
                                </label>
                              </Row>
                              <Row>
                                <Typography.Text strong>
                                  {`${moment(
                                    transferAnimalTransactionData?.sellDate
                                  ).format("DD/MM/YYYY")}`}
                                </Typography.Text>
                              </Row>
                            </Col>
                            <Col span={6}>
                              <Row>
                                <label>
                                  {
                                    translation
                                      .processTransferAnimalTransactionModal
                                      .form.animalValue
                                  }
                                </label>
                              </Row>
                              <Row>
                                <Typography.Text strong>
                                  {`${numberMask(
                                    transferAnimalTransactionData?.priceNegotiated ||
                                      0,
                                    true
                                  )}/cab`}
                                </Typography.Text>
                              </Row>
                            </Col>
                            <Col span={6}>
                              <Row>
                                <label>
                                  {
                                    translation
                                      .processTransferAnimalTransactionModal
                                      .form.totalValue
                                  }
                                </label>
                              </Row>
                              <Row>
                                <Typography.Text strong>
                                  {`${numberMask(
                                    transferAnimalTransactionData?.totalValue ||
                                      0,
                                    true
                                  )}`}
                                </Typography.Text>
                              </Row>
                            </Col>
                          </Row>
                          <Row type="flex" gutter={8}>
                            <Col span={8}>
                              <Row>
                                <label>
                                  {
                                    translation
                                      .processTransferAnimalTransactionModal
                                      .form.totalInstallments
                                  }
                                </label>
                              </Row>
                              <Row>
                                <Typography.Text strong>
                                  {`${transferAnimalTransactionData.installmentTotal}`}
                                </Typography.Text>
                              </Row>
                            </Col>
                            <Col span={8}>
                              <Row>
                                <label>
                                  {
                                    translation
                                      .processTransferAnimalTransactionModal
                                      .form.firstInstallmentDate
                                  }
                                </label>
                              </Row>
                              <Row>
                                <Typography.Text strong>
                                  {`${moment(
                                    transferAnimalTransactionData?.firstInstallmentDate
                                  ).format("DD/MM/YYYY")}`}
                                </Typography.Text>
                              </Row>
                            </Col>
                            <Col span={8}>
                              <Row>
                                <label>
                                  {
                                    translation
                                      .processTransferAnimalTransactionModal
                                      .form.firstInstallmentValue
                                  }
                                </label>
                              </Row>
                              <Row>
                                <Typography.Text strong>
                                  {`${numberMask(
                                    transferAnimalTransactionData?.firstInstallmentValue ||
                                      0,
                                    true
                                  )}`}
                                </Typography.Text>
                              </Row>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <Row type="flex" justify="center" gutter={8}>
                          <Col span={6}>
                            <Row>
                              <label>
                                {
                                  translation
                                    .processTransferAnimalTransactionModal.form
                                    .amountAnimals
                                }
                              </label>
                            </Row>
                            <Row>
                              <Typography.Text strong>
                                {`${transferAnimalTransactionData?.amountAnimals}`}
                              </Typography.Text>
                            </Row>
                          </Col>
                          <Col span={6}>
                            <Row>
                              <label>
                                {
                                  translation
                                    .processTransferAnimalTransactionModal.form
                                    .sellDate
                                }
                              </label>
                            </Row>
                            <Row>
                              <Typography.Text strong>
                                {`${moment(
                                  transferAnimalTransactionData?.sellDate
                                ).format("DD/MM/YYYY")}`}
                              </Typography.Text>
                            </Row>
                          </Col>
                        </Row>
                      )}
                      <Divider dashed>
                        {
                          translation.processTransferAnimalTransactionModal.form
                            .dividerTitle
                        }
                      </Divider>
                      {transferAnimalTransactionData?.financialCompensation ? (
                        <>
                          {/* Lot destination id, financial nature and class */}
                          <Row type="flex" gutter={8}>
                            <Col span={8}>
                              <Row>
                                <label
                                  className={
                                    errors.lotDestinationId && submitCount > 0
                                      ? "error"
                                      : ""
                                  }
                                >
                                  {
                                    translation
                                      .processTransferAnimalTransactionModal
                                      .form.lotDestinationId
                                  }
                                  *
                                </label>
                              </Row>
                              <Row className="rowInput">
                                <SelectLotProduction
                                  id={"lotDestinationId"}
                                  name={"lotDestinationId"}
                                  value={values.lotDestinationId || undefined}
                                  onDeselect={() =>
                                    setFieldValue("lotDestinationId", null)
                                  }
                                  onChange={(value) =>
                                    setFieldValue("lotDestinationId", value)
                                  }
                                />
                              </Row>
                            </Col>
                            <Col span={8}>
                              <Row>
                                <label
                                  className={
                                    errors.financialNatureId && submitCount > 0
                                      ? "error"
                                      : ""
                                  }
                                >
                                  {
                                    translation
                                      .processTransferAnimalTransactionModal
                                      .form.financialNatureId
                                  }
                                  *
                                </label>
                              </Row>
                              <Row>
                                <Select
                                  name="financialNatureId"
                                  value={
                                    isLoadingFinancialNature
                                      ? undefined
                                      : values.financialNatureId || undefined
                                  }
                                  placeholder={
                                    translation.defaultSelectPlaceholder
                                  }
                                  loading={isLoadingFinancialNature}
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  onChange={(value) => {
                                    setFieldValue("financialNatureId", value);
                                    setFieldValue("financialClassId", null);
                                  }}
                                >
                                  {filteredListFinancialNature.map((l) => (
                                    <Select.Option key={l.id} value={l.id}>
                                      {l.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Row>
                            </Col>
                            <Col span={8}>
                              <Row>
                                <label
                                  className={
                                    errors.financialClassId && submitCount > 0
                                      ? "error"
                                      : ""
                                  }
                                >
                                  {
                                    translation
                                      .processTransferAnimalTransactionModal
                                      .form.financialClassId
                                  }
                                  *
                                </label>
                              </Row>
                              <Row>
                                <Select
                                  name="financialClassId"
                                  value={
                                    isLoadingFinancialClass
                                      ? undefined
                                      : values.financialClassId || undefined
                                  }
                                  placeholder={
                                    translation.defaultSelectPlaceholder
                                  }
                                  loading={isLoadingFinancialClass}
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  onChange={(value) => {
                                    setFieldValue("financialClassId", value);
                                  }}
                                >
                                  {values.financialNatureId != null &&
                                    listFinancialClass
                                      .filter(
                                        (l) =>
                                          l.financialNature.id ===
                                          values.financialNatureId
                                      )
                                      .map((l) => (
                                        <Select.Option key={l.id} value={l.id}>
                                          {l.name}
                                        </Select.Option>
                                      ))}
                                </Select>
                              </Row>
                            </Col>
                          </Row>
                          {/* Result Center and Owner Id */}
                          <Row type="flex">
                            <Col span={8}>
                              <Row>
                                <label
                                  className={
                                    errors.resultCenterApportionmentDefault &&
                                    submitCount > 0
                                      ? "error"
                                      : ""
                                  }
                                >
                                  {
                                    translation
                                      .processTransferAnimalTransactionModal
                                      .form.resultCenter
                                  }
                                  *
                                </label>
                              </Row>
                              <Row>
                                <Radio.Group
                                  value={
                                    values.resultCenterApportionmentDefault
                                  }
                                  defaultValue={true}
                                  buttonStyle="solid"
                                  disabled={!values.financialNatureId}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "resultCenterApportionmentDefault",
                                      e.target.value
                                    );
                                  }}
                                >
                                  <Radio.Button value={true}>
                                    {
                                      translation.financial.animalSell.form
                                        .resultCenterApportionmentDefault
                                    }
                                  </Radio.Button>
                                  <Radio.Button
                                    value={false}
                                    onClick={
                                      handleOpenCloseResultCenterApportionmentModal
                                    }
                                  >
                                    {
                                      translation.financial.animalSell.form
                                        .resultCenterApportionmentCustom
                                    }
                                  </Radio.Button>
                                </Radio.Group>
                              </Row>
                            </Col>
                            <Col span={6}>
                              <Row>
                                <label
                                  className={
                                    errors?.farmOwnerId && submitCount > 0
                                      ? "error"
                                      : ""
                                  }
                                >
                                  {
                                    translation
                                      .processTransferAnimalTransactionModal
                                      .form.farmOwnerId
                                  }
                                  *
                                </label>
                              </Row>
                              <Row>
                                <Select
                                  name="farmOwnerId"
                                  value={values.farmOwnerId || undefined}
                                  placeholder={
                                    translation.defaultSelectPlaceholder
                                  }
                                  onChange={(value) =>
                                    setFieldValue("farmOwnerId", value)
                                  }
                                >
                                  {farmOwners != null &&
                                    Object.entries(farmOwners).length > 0 &&
                                    farmOwners.map((fo) => (
                                      <Select.Option key={fo.id} value={fo.id}>
                                        {fo.name}
                                      </Select.Option>
                                    ))}
                                </Select>
                              </Row>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <Row type="flex" gutter={8}>
                          <Col span={8}>
                            <Row>
                              <label
                                className={
                                  errors.lotDestinationId && submitCount > 0
                                    ? "error"
                                    : ""
                                }
                              >
                                {
                                  translation
                                    .processTransferAnimalTransactionModal.form
                                    .lotDestinationId
                                }
                                *
                              </label>
                            </Row>
                            <Row className="rowInput">
                              <SelectLotProduction
                                id={"lotDestinationId"}
                                name={"lotDestinationId"}
                                value={values.lotDestinationId || undefined}
                                onDeselect={() =>
                                  setFieldValue("lotDestinationId", null)
                                }
                                onChange={(value) =>
                                  setFieldValue("lotDestinationId", value)
                                }
                              />
                            </Row>
                          </Col>
                          <Col span={6}>
                            <Row>
                              <label
                                className={
                                  errors?.farmOwnerId && submitCount > 0
                                    ? "error"
                                    : ""
                                }
                              >
                                {
                                  translation
                                    .processTransferAnimalTransactionModal.form
                                    .farmOwnerId
                                }
                                *
                              </label>
                            </Row>
                            <Row>
                              <Select
                                name="farmOwnerId"
                                value={values.farmOwnerId || undefined}
                                placeholder={
                                  translation.defaultSelectPlaceholder
                                }
                                onChange={(value) =>
                                  setFieldValue("farmOwnerId", value)
                                }
                              >
                                {farmOwners != null &&
                                  Object.entries(farmOwners).length > 0 &&
                                  farmOwners.map((fo) => (
                                    <Select.Option key={fo.id} value={fo.id}>
                                      {fo.name}
                                    </Select.Option>
                                  ))}
                              </Select>
                            </Row>
                          </Col>
                        </Row>
                      )}
                    </div>
                    <ResultCenterApportionmentModal
                      isVisible={isResultCenterApportionmentModalVisible}
                      closeModal={handleCancelCustomApportionmentModal}
                      saveModal={handleSaveCustomApportionmentModal}
                      financialNatureId={values.financialNatureId}
                      transactionValue={
                        transferAnimalTransactionData.totalValue
                      }
                    />
                    {/* Footer */}
                    <Row type="flex">
                      <Col span={24}>
                        <Row
                          type="flex"
                          justify="end"
                          align="middle"
                          gutter={16}
                        >
                          <Col>
                            <ButtonStandard
                              type="button"
                              buttonType="type4"
                              size="s"
                              onClick={handleReject}
                            >
                              {translation.buttons.reject}
                            </ButtonStandard>
                          </Col>
                          <Col>
                            <ButtonStandard
                              type="submit"
                              buttonType="type1"
                              size="s"
                            >
                              {translation.buttons.accept}
                            </ButtonStandard>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </form>
                )}
              />
            </Col>
          </Row>
        </Spin>
      </Container>
    </Modal>
  );
}

export default ProcessTransferAnimalTransactionModal;
