import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { Creators as AnimalActions } from "../../../../store/ducks/animal";
import { Creators as SupplierActions } from "../../../../store/ducks/supplier";
import moment from "moment";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Row,
  Col,
  Select,
  Input,
  Radio,
  DatePicker,
  notification,
  Spin,
  Checkbox,
  Icon,
  Modal,
  Button,
} from "antd";
import {
  Container,
  Footer,
  Body,
  AnimalCard,
  CustomDivider,
  GeneticBox,
  PopConfirmCustom,
} from "./styles";

// Components
import ButtonStandard from "../../../utils/button";
import BullAvatarIcon from "./icons/bullAvatar";
import InfoTooltip from "../../../utils/infoTooltip";
import RadioButton from "../../../utils/radioButton";
import DrawerSupplier from "../../supplier";

//Services
import {
  getBreedIndexActive,
  getBreedParametersShow,
} from "../../../../services/breedService";
import {
  getLotIndex,
  getLotIndexDropDown,
} from "../../../../services/lotService";
import {
  saveAnimal,
  verifyIfHaveOtherAnimalsWithSameHandlingNumber,
} from "../../../../services/animalService";
import { findAll } from "../../../../services/semenService";
import { findAllFarmOwners } from "../../../../services/farmOwnerService";
import SelectLotProduction from "../../../utils/selectLotProduction";

const { warning } = Modal;

class DrawerNewAnimal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveOptions: "only_save",
      breeds: [],
      lots: [],
      receiptLots: [],
      suppliers: [],
      semens: [],
      farmOwners: [],
      isLoading: true,
      animalData: {
        id: null,
        supplierId: null,
        handlingNumber: null,
        sisbov: null,
        tagId: null,
        nickname: null,
        breedId: null,
        birthday: null,
        gender: null,
        cap: null,
        birthdayWeight: "",
        firstInseminationWeight: "",
        asymptoticWeight: "",
        weaningWeight: "",
        weaningDate: null,
        acquisitionDate: null,
        purchaseCost: "",
        acquisitionEstimatedWeight: "",
        carcassHarnessing: "",
        motherId: null,
        motherHandlingNumber: null,
        motherSupplierId: null,
        fromInsermination: false,
        fatherSemenId: null,
        fatherId: null,
        fatherHandlingNumber: null,
        fatherSupplierId: null,
        animalFarmFunction: "fatten",
        animalReproductionCategory: null,
        status: "Active",
        farmStatus: "Active",
        isFarmMotherAnimal: false,
        isFarmFatherAnimal: false,
        sisbovIdentificationDate: null,
        sisbovInsertBNDDate: null,
        sisbovSlaughterDate: null,
        farmOwnerId: null,
        lotReceiptStatus: null,
        lotItemEntryId: null,
      },
      isFarmAnimal: false,
      ismixedMilk: false,
      allowReceiptLot: false,
    };

    this.formik = React.createRef();
  }

  openNotificationError = (title, message) => {
    notification["error"]({
      message: title,
      description: message,
    });
  };

  openBirthdayConfirmation = () => {
    warning({
      title: "O animal possui mais de 60 meses.",
      content: "Confirma ?",
      centered: true,
    });
  };

  closeDrawer = (props) => {
    const { animalActions } = this.props;
    this.formik.current.resetForm({
      id: null,
      supplierId: null,
      handlingNumber: null,
      sisbov: null,
      tagId: null,
      nickname: null,
      breedId: null,
      birthday: null,
      gender: null,
      cap: null,
      birthdayWeight: "",
      firstInseminationWeight: "",
      asymptoticWeight: "",
      weaningWeight: "",
      weaningDate: null,
      acquisitionDate: null,
      purchaseCost: "",
      acquisitionEstimatedWeight: "",
      carcassHarnessing: "",
      motherId: null,
      motherHandlingNumber: null,
      motherSupplierId: null,
      fromInsermination: false,
      fatherSemenId: null,
      fatherId: null,
      fatherHandlingNumber: null,
      fatherSupplierId: null,
      animalFarmFunction: "fatten",
      animalReproductionCategory: null,
      status: "Active",
      farmStatus: "Active",
      isFarmMotherAnimal: false,
      isFarmFatherAnimal: false,
      sisbovIdentificationDate: null,
      sisbovInsertBNDDate: null,
      sisbovSlaughterDate: null,
      farmOwnerId: null,
      lotReceiptStatus: null,
      lotItemEntryId: null,
    });
    this.setState({
      animalData: {
        id: null,
        supplierId: null,
        handlingNumber: null,
        sisbov: null,
        tagId: null,
        nickname: null,
        breedId: null,
        birthday: null,
        gender: null,
        cap: null,
        birthdayWeight: "",
        firstInseminationWeight: "",
        asymptoticWeight: "",
        weaningWeight: "",
        weaningDate: null,
        acquisitionDate: null,
        purchaseCost: "",
        acquisitionEstimatedWeight: "",
        carcassHarnessing: "",
        motherId: null,
        motherHandlingNumber: null,
        motherSupplierId: null,
        fromInsermination: false,
        fatherSemenId: null,
        fatherId: null,
        fatherHandlingNumber: null,
        fatherSupplierId: null,
        animalFarmFunction: "fatten",
        animalReproductionCategory: null,
        status: "Active",
        farmStatus: "Active",
        isFarmMotherAnimal: false,
        isFarmFatherAnimal: false,
        sisbovIdentificationDate: null,
        sisbovInsertBNDDate: null,
        sisbovSlaughterDate: null,
        farmOwnerId: null,
        lotReceiptStatus: null,
        lotItemEntryId: null,
      },
      isFarmAnimal: false,
    });
    animalActions.showDrawerNewAnimal();
  };

  fetchData = async (groupSelected, farmSelected) => {
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;

    this.setState({ isLoading: true });
    try {
      this.getSuppliers(groupId, farmId);
      this.getMotherOptions(groupId, farmId);
      this.getFatherOptions(groupId, farmId);

      await Promise.all([
        this.getLots(groupId, farmId),
        this.getBreeds(groupId, farmId),
        this.getSemenOptions(groupId, farmId),
        this.getFarmOwnersOptions(groupId, farmId),
      ]);
    } catch (error) {
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  getBreedParameter = async (id) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
    } = this.props;
    const { isFarmAnimal } = this.state;
    let { ismixedMilk } = this.state;
    this.setState({ isLoading: true });
    try {
      const {
        data: { results: breedParameter },
      } = await getBreedParametersShow({
        groupId,
        farmId,
        id,
      });

      if (breedParameter != null) {
        const { asymptoticWeight, maleBirthdayWeight } = breedParameter;
        this.formik.current.setFieldValue("asymptoticWeight", asymptoticWeight);
        this.formik.current.setFieldValue("birthdayWeight", maleBirthdayWeight);
        if (isFarmAnimal) {
          this.formik.current.setFieldValue(
            "acquisitionEstimatedWeight",
            maleBirthdayWeight
          );
        }
        ismixedMilk = breedParameter.geneticGroup === "Cruzado";
      }
    } catch (error) {
    } finally {
      this.setState({ isLoading: false, ismixedMilk });
    }
  };

  getLots = async (groupId, farmId) => {
    const {
      data: { results: listOfReceiptLots },
    } = await getLotIndex({
      groupId,
      farmId,
      page: null,
      sorter: { field: "createdAt", order: "descend" },
      filters: "is_receipt=true;status='A'",
    });

    this.setState({
      receiptLots: listOfReceiptLots?.content ? listOfReceiptLots.content : [],
    });
  };

  getBreeds = async (groupId, farmId) => {
    const {
      data: { results: breeds },
    } = await getBreedIndexActive({
      groupId,
      farmId,
      withoutBreedCurveParam: true,
    });

    this.setState({ breeds });
  };

  getSuppliers = async (groupId, farmId) => {
    const { supplierActions } = this.props;
    supplierActions.getDropdownSuppliers(groupId, farmId, "CattleFarmer", true);
  };

  getMotherOptions = (groupId, farmId) => {
    const { animalActions } = this.props;
    animalActions.indexAnimalMotherDropdown(groupId, farmId);
  };

  getFatherOptions = (groupId, farmId) => {
    const { animalActions } = this.props;
    animalActions.indexAnimalFatherDropdown(groupId, farmId);
  };

  getFarmOwnersOptions = async (groupId, farmId) => {
    try {
      const {
        data: { results },
      } = await findAllFarmOwners({
        groupId,
        farmId,
      });

      const formValue = this.state.animalData;
      formValue.farmOwnerId = results.find((r) => r.mainOwner)?.id;

      //Update state
      this.setState({
        farmOwners: results,
        animalData: { ...formValue },
      });

      //Update default form value
      this.formik.current.resetForm({ ...formValue });
    } catch (error) {}
  };

  getSemenOptions = async (groupId, farmId) => {
    try {
      const {
        data: { results },
      } = await findAll({
        groupId,
        farmId,
      });
      this.setState({
        semens: results,
      });
    } catch (error) {}
  };

  validateForm = (values) => {
    const {
      app: { translation },
    } = this.props;
    const { isFarmAnimal } = this.state;
    if (values.supplierId === null && isFarmAnimal === false) {
      this.openNotificationError(
        "Fornecedor não informado",
        'Informe um fornecedor ou marque a opção "Animal fornecido pela própria fazenda".'
      );
      return false;
    }
    if (
      values.carcassHarnessing != null &&
      values.carcassHarnessing !== "" &&
      (values.carcassHarnessing < 45 || values.carcassHarnessing > 60)
    ) {
      this.openNotificationError(
        "Aproveitamento de Carcaça inválida",
        'O valor do campo "Aproveitamento de Carcaça" deve estar entre o intervalo de valores (45% e 60%)'
      );
      return false;
    } else if (
      values.sisbov != null &&
      values.sisbov.trim() !== "" &&
      values.sisbov.length !== 15
    ) {
      this.openNotificationError(
        translation.error.sisbovInvalidTitle,
        translation.error.sisbovInvalidMessage
      );
      return false;
    } else if (
      moment(values.acquisitionDate).isSameOrBefore(
        moment(values.birthday).subtract(1, "days")
      )
    ) {
      this.openNotificationError(
        "Datas inválidas",
        "A Data de Aquisição não pode ser antes da Data de Nascimento."
      );
      return false;
    } else if (
      values.weaningDate !== null &&
      moment(values.weaningDate).isSameOrBefore(
        moment(values.birthday).subtract(1, "days")
      )
    ) {
      this.openNotificationError(
        "Datas inválidas",
        "A Data de Desmame não pode ser antes da Data de Nascimento."
      );
      return false;
    } else if (
      values.weaningWeight &&
      values.birthdayWeight > values.weaningWeight
    ) {
      this.openNotificationError(
        "Pesos inválidos",
        "O Peso de Nascimento não pode ser maior do que o Peso de Desmame."
      );
      return false;
    } else {
      return true;
    }
  };

  handleSubmitModalForm = async (values, actions) => {
    if (this.validateForm(values)) {
      this.handleCreateAnimal(values, actions);
    }
  };

  handleCreateAnimal = async (animal, actions) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      animal: {
        pagination: {
          page,
          sorter,
          filters,
          size,
          ids,
          tableFilters,
          tableSorters,
        },
        paginationFemaleTable,
        paginationMaleTable,
      },
      animalActions,
    } = this.props;
    const { saveOptions } = this.state;
    this.setState({ isLoading: true });
    let animalClone = { ...animal };
    try {
      const {
        data: { results: haveOthersAnimalsWithHandlingNumber },
      } = await verifyIfHaveOtherAnimalsWithSameHandlingNumber({
        groupId,
        farmId,
        handlingNumber: animalClone.handlingNumber,
      });

      if (haveOthersAnimalsWithHandlingNumber === true) {
        Modal.warning({
          title: "Alerta",
          centered: true,
          content:
            "Existem animais ativos nesta propriedade que possuem essa mesma Identificação.",
        });
      }

      if (animalClone.lotItemReceiptId !== null) {
        animalClone.lotReceiptStatus = "identified";
      }

      await saveAnimal({
        groupId,
        farmId,
        id: null,
        animal: animalClone,
      });

      if (animal.animalFarmFunction === "reproduction") {
        if (animal.gender === "M") {
          animalActions.indexAnimalMaleTable(
            groupId,
            farmId,
            paginationMaleTable.page,
            paginationMaleTable.sorter,
            paginationMaleTable.filters,
            paginationMaleTable.size,
            paginationMaleTable.ids,
            paginationMaleTable.tableSorters,
            paginationMaleTable.tableFilters
          );
        } else {
          animalActions.indexAnimalFemaleTable(
            groupId,
            farmId,
            paginationFemaleTable.page,
            paginationFemaleTable.sorter,
            paginationFemaleTable.filters,
            paginationFemaleTable.size,
            paginationFemaleTable.ids,
            paginationFemaleTable.tableSorters,
            paginationFemaleTable.tableFilters
          );
        }
      } else {
        animalActions.indexAnimal(
          groupId,
          farmId,
          page,
          sorter,
          filters,
          size,
          ids,
          tableSorters,
          tableFilters
        );
      }

      notification.success({ message: "Animal criado com sucesso" });

      if (saveOptions !== "only_save") {
        this.setState({
          isFarmAnimal: false,
          allowReceiptLot: false,
          animalData: {
            id: null,
            supplierId: null,
            handlingNumber: null,
            sisbov: null,
            tagId: null,
            nickname: null,
            breedId: null,
            birthday: null,
            gender: null,
            cap: null,
            birthdayWeight: "",
            firstInseminationWeight: "",
            asymptoticWeight: "",
            weaningWeight: "",
            weaningDate: null,
            acquisitionDate: null,
            purchaseCost: "",
            acquisitionEstimatedWeight: "",
            carcassHarnessing: "",
            motherId: null,
            motherHandlingNumber: null,
            motherSupplierId: null,
            fromInsermination: false,
            fatherSemenId: null,
            fatherId: null,
            fatherHandlingNumber: null,
            fatherSupplierId: null,
            animalFarmFunction: "fatten",
            animalReproductionCategory: null,
            status: "Active",
            farmStatus: "Active",
            lotReceiptId: null,
            lotItemReceiptId: null,
            isFarmMotherAnimal: false,
            isFarmFatherAnimal: false,
            sisbovIdentificationDate: null,
            sisbovInsertBNDDate: null,
            sisbovSlaughterDate: null,
            farmOwnerId: null,
            lotReceiptStatus: null,
            lotItemEntryId: null,
          },
        });
        this.formik.current.resetForm({
          id: null,
          supplierId: null,
          handlingNumber: null,
          sisbov: null,
          tagId: null,
          nickname: null,
          breedId: null,
          birthday: null,
          gender: null,
          cap: null,
          birthdayWeight: "",
          firstInseminationWeight: "",
          asymptoticWeight: "",
          weaningWeight: "",
          weaningDate: null,
          acquisitionDate: null,
          purchaseCost: "",
          acquisitionEstimatedWeight: "",
          carcassHarnessing: "",
          motherId: null,
          motherHandlingNumber: null,
          motherSupplierId: null,
          fromInsermination: false,
          fatherSemenId: null,
          fatherId: null,
          fatherHandlingNumber: null,
          fatherSupplierId: null,
          animalFarmFunction: "fatten",
          animalReproductionCategory: null,
          status: "Active",
          farmStatus: "Active",
          lotReceiptId: null,
          lotItemReceiptId: null,
          isFarmMotherAnimal: false,
          isFarmFatherAnimal: false,
          sisbovIdentificationDate: null,
          sisbovInsertBNDDate: null,
          sisbovSlaughterDate: null,
          farmOwnerId: null,
          lotReceiptStatus: null,
          lotItemEntryId: null,
        });
      } else {
        this.closeDrawer(actions);
      }
    } catch (error) {
      this.openNotificationError(
        "Erro ao cadastrar",
        "Não foi possível cadastrar o animal. Contate o suporte"
      );
    }
    this.setState({ isLoading: false });
  };

  handleDrawerVisible = async (visible) => {
    if (visible) {
      const {
        app: { groupSelected, farmSelected },
      } = this.props;
      if (
        Object.entries(groupSelected).length > 0 &&
        Object.entries(farmSelected).length > 0
      ) {
        this.fetchData(groupSelected, farmSelected);
        this.formik.current.resetForm({
          id: null,
          supplierId: null,
          handlingNumber: null,
          sisbov: null,
          tagId: null,
          nickname: null,
          breedId: null,
          birthday: null,
          gender: null,
          cap: null,
          birthdayWeight: "",
          firstInseminationWeight: "",
          asymptoticWeight: "",
          weaningWeight: "",
          weaningDate: null,
          acquisitionDate: null,
          purchaseCost: "",
          acquisitionEstimatedWeight: "",
          carcassHarnessing: "",
          motherId: null,
          motherHandlingNumber: null,
          motherSupplierId: null,
          fromInsermination: false,
          fatherSemenId: null,
          fatherId: null,
          fatherHandlingNumber: null,
          fatherSupplierId: null,
          animalFarmFunction: "fatten",
          animalReproductionCategory: null,
          status: "Active",
          farmStatus: "Active",
          sisbovIdentificationDate: null,
          sisbovInsertBNDDate: null,
          sisbovSlaughterDate: null,
          farmOwnerId: null,
          lotReceiptStatus: null,
          lotItemEntryId: null,
        });
      }
    }
  };

  handleCreateSupplier = () => {
    const { supplierActions } = this.props;
    supplierActions.showDrawer("new_animal_supplier", null);
  };

  handleChangeAge = (e, type, props) => {
    const { isFarmAnimal } = this.state;
    let newDate = null;
    switch (type) {
      case "days":
        newDate = moment().subtract(e.target.value, "days");
        break;
      case "months":
        newDate = moment().subtract(e.target.value, "months");
        break;
      case "years":
        newDate = moment().subtract(e.target.value, "years");
        break;
      default:
        break;
    }
    if (moment().diff(newDate, "months") > 60) {
      this.openBirthdayConfirmation();
    }
    props.setValues({
      ...props.values,
      birthday: newDate,
    });
    if (isFarmAnimal) {
      props.setFieldValue("acquisitionDate", newDate);
    }
  };

  render() {
    const {
      app: {
        translation,
        farmSelected: { farmSisbov },
      },
      animal: {
        drawerNewAnimalVisible,
        animalMotherDropdown,
        animalFatherDropdown,
      },
      supplier: {
        listDrowpDown: suppliers,
        isLoadingDropDown: isLoadingDropDownSuppliers,
      },
    } = this.props;

    const {
      animalData,
      saveOptions,
      lots,
      receiptLots,
      breeds,
      isLoading,
      isFarmAnimal,
      allowReceiptLot,
      semens,
      farmOwners,
    } = this.state;

    const { Option } = Select;

    const { Group: RadioGroup } = Radio;

    const { Group: InputGroup } = Input;

    const dateFormat = "DD/MM/YYYY";

    const validationSchema = Yup.object().shape({
      handlingNumber: Yup.string().required(),
      breedId: Yup.string().required(),
      birthday: Yup.date().required().max(moment().add(1, "days").toDate()),
      acquisitionDate: Yup.date()
        .required()
        .max(moment().add(1, "days").toDate()),
      asymptoticWeight: Yup.number().required().moreThan(0),
      birthdayWeight: Yup.number().required().moreThan(0),
      acquisitionEstimatedWeight: Yup.number().required(),
      purchaseCost: Yup.number().required(),
      lotId: Yup.string().required(),
      gender: Yup.string().required(),
      animalFarmFunction: Yup.string().required(),
      animalReproductionCategory: Yup.string()
        .nullable()
        .notRequired()
        .when(["animalFarmFunction"], {
          is: (animalFarmFunction) => animalFarmFunction === "reproduction",
          then: Yup.string().required(),
          otherwise: Yup.string().nullable().notRequired(),
        }),
      farmOwnerId: Yup.string().required(),
      fromInsermination: Yup.boolean().required(),
      fatherSemenId: Yup.string()
        .nullable()
        .notRequired()
        .when(["fromInsermination"], {
          is: (fromInsermination) => fromInsermination === true,
          then: Yup.string().required(),
          otherwise: Yup.string().nullable().notRequired(),
        }),
    });

    const InfoTooltipContentAnimalReproductionCategory = (
      <div>
        <strong>Para Fêmeas:</strong>
        <ul style={{ listStyle: "none", margin: "8px 0" }}>
          <li>
            Nulíparas: Fêmeas de reprodução, desmamadas, que não foram
            trabalhadas (inseminadas ou colocadas à disposição do touro).
          </li>
          <li>
            Novilhas: Fêmeas de reprodução, desmamadas, que foram trabalhadas,
            mas inda não pariram.
          </li>
          <li>Primíparas: Fêmeas de reprodução, que tiverem 1 parto.</li>
          <li>Secundíparas: Fêmeas de reprodução, que tiverem 2 partos.</li>
          <li>
            Multíparas: Fêmeas de reprodução, que tiveram 3 ou mias partos.
          </li>
        </ul>
        <span>
          *São considerados partos, a geração de cria viva ou natimorto. Abortos
          ou Mortes Embrionárias, não evoluem a categoria da matriz.
        </span>
        <br />
        <strong style={{ marginTop: 8 }}>Para Machos:</strong>
        <ul style={{ listStyle: "none", margin: "8px 0" }}>
          <li>Reprodutor: Machos destinados para uso na reprodução.</li>
        </ul>
      </div>
    );

    // Build the animal description to the dropdown
    const getParentDescription = (animal) => {
      let description = animal.handlingNumber;

      if (animal.gender === "M") {
        description += " / Macho";
      } else {
        description += " / Fêmea";
      }

      if (animal.animalFarmFunction === "reproduction") {
        description += " / Reprodução";
      } else {
        description += " / Engorda";
      }

      if (animal?.birthday !== null && animal?.birthday !== undefined) {
        description +=
          " / " + moment(animal.birthday, "YYYY-MM-DD").format("DD/MM/YYYY");
      }

      return description;
    };

    return (
      <Formik
        ref={this.formik}
        enableReinitialize={true}
        initialValues={animalData}
        onSubmit={this.handleSubmitModalForm}
        validationSchema={validationSchema}
        render={(props) => (
          <Container
            title="Adicionar animal"
            width={770}
            onClose={() => this.closeDrawer(props)}
            maskClosable={false}
            visible={drawerNewAnimalVisible}
            afterVisibleChange={this.handleDrawerVisible}
          >
            <Spin spinning={isLoading}>
              <form onSubmit={props.handleSubmit} autoComplete="off">
                <Body>
                  <Row type="flex">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <span style={{ fontSize: "14px", color: "#000" }}>
                        {translation.animal.drawerSubTitleEdit}
                      </span>
                    </Col>
                  </Row>
                  {/* Error message */}
                  {Object.entries(props.errors).length > 0 &&
                    props.submitCount > 0 && (
                      <Row type="flex" justify="center" align="middle">
                        <label className="formLabel error">
                          {translation.error.formError}
                        </label>
                      </Row>
                    )}
                  {/* Animal Supplier */}
                  <Row type="flex" justify="start">
                    <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                      <Row>
                        <label className="formLabel">
                          {translation.lot.receipt.supplier.supplierTitle}
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Select
                          style={{
                            width: "90%",
                          }}
                          value={
                            props.values?.supplierId != null
                              ? suppliers != null &&
                                isLoadingDropDownSuppliers === false &&
                                suppliers.find(
                                  (s) => s.id === props.values?.supplierId
                                ) != null
                                ? props.values?.supplierId
                                : undefined
                              : undefined
                          }
                          loading={isLoadingDropDownSuppliers}
                          showSearch
                          placeholder={
                            translation.lot.receipt.supplier
                              .supplierComboBoxPlaceHolder
                          }
                          name="supplierId"
                          onChange={(value) => {
                            props.setFieldValue("supplierId", value);
                            props.setFieldValue("lotReceiptId", null);
                            props.setFieldValue("lotItemReceiptId", null);
                          }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          disabled={isFarmAnimal}
                        >
                          {suppliers != null &&
                            Object.entries(suppliers).length > 0 &&
                            suppliers.map((supplier) => (
                              <Option key={supplier.id} value={supplier.id}>
                                {supplier.name}
                              </Option>
                            ))}
                        </Select>
                        <Button
                          disabled={isFarmAnimal}
                          shape="circle"
                          icon="plus"
                          style={{ marginLeft: 10 }}
                          onClick={() => this.handleCreateSupplier()}
                        />
                      </Row>
                      <Row className="rowInput">
                        <Checkbox
                          checked={isFarmAnimal}
                          onChange={(e) => {
                            if (e.target.checked)
                              props.setFieldValue("supplierId", null);
                            props.setFieldValue("lotReceiptId", null);
                            props.setFieldValue("lotItemReceiptId", null);
                            this.setState({
                              ...this.state,

                              isFarmAnimal: e.target.checked,
                            });
                          }}
                        >
                          Animal fornecido pela <strong>própria fazenda</strong>
                        </Checkbox>
                      </Row>
                    </Col>
                  </Row>
                  <CustomDivider dashed />
                  {/* Animal Card */}
                  <Row type="flex">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <AnimalCard>
                        <Row type="flex" justify="start">
                          <Col>
                            <BullAvatarIcon />
                          </Col>
                          <Col
                            xs={4}
                            sm={4}
                            md={4}
                            lg={4}
                            xl={4}
                            offset={1}
                            className="animalCardCol"
                          >
                            <Row>
                              <label
                                className={
                                  props.errors.handlingNumber &&
                                  props.submitCount > 0
                                    ? "formLabel error"
                                    : "formLabel"
                                }
                              >
                                {translation.animal.edit.form.handlingNumber}*
                              </label>
                            </Row>
                            <Row className="rowInput">
                              <Input
                                placeholder={
                                  translation.animal.edit.form
                                    .handlingNumberPlaceHolder
                                }
                                value={props.values?.handlingNumber}
                                name="handlingNumber"
                                onChange={(e) => {
                                  props.setValues({
                                    ...props.values,
                                    handlingNumber: e.target.value,
                                  });
                                }}
                              />
                            </Row>
                          </Col>
                          <Col
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            offset={1}
                            className="animalCardCol"
                          >
                            <Row>
                              <label
                                className={
                                  animalData?.deathDate != null
                                    ? "formLabel disabled"
                                    : "formLabel"
                                }
                              >
                                {translation.animal.edit.form.sisbov}
                              </label>
                            </Row>
                            <Row className="rowInput">
                              <NumberFormat
                                customInput={Input}
                                placeholder={
                                  translation.animal.edit.form.sisbovPlaceHolder
                                }
                                maxLength={15}
                                decimalScale={0}
                                allowLeadingZeros={false}
                                isNumericString={true}
                                value={
                                  props.values?.sisbov !== null
                                    ? props.values?.sisbov
                                    : ""
                                }
                                name="sisbov"
                                onValueChange={({ value }) => {
                                  props.setValues({
                                    ...props.values,
                                    sisbov: value,
                                  });
                                }}
                                disabled={animalData?.deathDate != null}
                              />
                            </Row>
                          </Col>
                          <Col
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            offset={1}
                            className="animalCardCol"
                          >
                            <Row>
                              <label className="formLabel">
                                {translation.animal.edit.form.tagId}
                              </label>
                            </Row>
                            <Row className="rowInput">
                              <NumberFormat
                                customInput={Input}
                                placeholder={
                                  translation.animal.edit.form.tagIdPlaceHolder
                                }
                                value={
                                  props.values?.tagId !== null
                                    ? props.values?.tagId
                                    : ""
                                }
                                decimalScale={0}
                                allowLeadingZeros={true}
                                isNumericString={true}
                                name="tagId"
                                onValueChange={({ value }) => {
                                  props.setValues({
                                    ...props.values,
                                    tagId: value,
                                  });
                                }}
                                disabled={animalData?.deathDate != null}
                              />
                            </Row>
                          </Col>
                        </Row>
                      </AnimalCard>
                    </Col>
                  </Row>
                  {/* Animal nickname and breed  */}
                  <Row
                    type="flex"
                    justify="space-between"
                    style={{ marginTop: 24 }}
                  >
                    {/* Nickname */}
                    <Col span={12}>
                      <Row>
                        <label className="formLabel">
                          {translation.animal.edit.form.nickname}
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Input
                          placeholder={
                            translation.animal.edit.form.nicknamePlaceHolder
                          }
                          value={props.values?.nickname}
                          name="nickname"
                          onChange={(e) => {
                            props.setValues({
                              ...props.values,
                              nickname: e.target.value,
                            });
                          }}
                        />
                      </Row>
                    </Col>
                    {/* Breed */}
                    <Col span={11} offset={1}>
                      <Row>
                        <label
                          className={
                            props.errors.breedId && props.submitCount > 0
                              ? "formLabel error"
                              : "formLabel"
                          }
                        >
                          {translation.animal.edit.form.breed}*
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Select
                          style={{ width: "100%" }}
                          name="breedId"
                          placeholder={
                            translation.animal.edit.form.breedPlaceHolder
                          }
                          value={
                            breeds != null && props.values?.breedId != null
                              ? props.values?.breedId
                              : undefined
                          }
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) => {
                            props.setValues({
                              ...props.values,
                              breedId: value,
                              breedName:
                                breeds != null &&
                                breeds.filter((breed) => breed.id === value)[0]
                                  .name,
                            });
                            this.getBreedParameter(value);
                          }}
                        >
                          {breeds != null &&
                            Object.entries(breeds).length > 0 &&
                            breeds.map((breed) => (
                              <Option key={breed.id} value={breed.id}>
                                {breed.name}
                              </Option>
                            ))}
                        </Select>
                      </Row>
                      <Row className="rowInput">
                        {!this.state.ismixedMilk ? (
                          <></>
                        ) : (
                          <Checkbox
                            checked={props.values?.mixedMilk}
                            onChange={(e) => {
                              props.setValues({
                                ...props.values,
                                mixedMilk: e.target.checked,
                              });
                            }}
                          >
                            <strong>Cruzado de leite</strong>
                          </Checkbox>
                        )}
                      </Row>
                    </Col>
                  </Row>
                  {/* Animal birthday date, gender and cap */}
                  <Row
                    type="flex"
                    justify="start"
                    style={{ marginTop: 16 }}
                    gutter={16}
                  >
                    {/* Birthday date */}
                    <Col span={5}>
                      <Row>
                        <label
                          className={
                            props.errors.birthday && props.submitCount > 0
                              ? "formLabel error"
                              : "formLabel"
                          }
                        >
                          {translation.animal.edit.form.birthday}*
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <DatePicker
                          name="birthday"
                          allowClear={false}
                          style={{ width: "100%" }}
                          value={
                            props.values?.birthday != null
                              ? moment(props.values?.birthday, "YYYY-MM-DD")
                              : null
                          }
                          placeholder={translation.defaultDatePickerPlaceholder}
                          format={dateFormat}
                          onChange={(date, dateString) => {
                            if (date <= moment() && date !== null) {
                              props.setValues({
                                ...props.values,
                                birthday: date,
                              });
                              if (isFarmAnimal) {
                                props.setFieldValue("acquisitionDate", date);
                              }
                            }
                            if (moment().diff(date, "months") > 60) {
                              this.openBirthdayConfirmation();
                            }
                          }}
                        />
                      </Row>
                    </Col>
                    <Col span={1}>
                      <Row
                        type="flex"
                        align="middle"
                        justify="center"
                        style={{ height: "100%" }}
                      >
                        <span>-</span>
                      </Row>
                    </Col>
                    <Col span={7}>
                      <Row>
                        <label className="formLabel">
                          Idade (dias/meses/anos)
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Col>
                          <InputGroup compact>
                            <Input
                              type="number"
                              style={{ width: 65, textAlign: "center" }}
                              name="ageDay"
                              min="0"
                              placeholder="Dias"
                              value={
                                props.values?.birthday != null
                                  ? moment().diff(
                                      props.values?.birthday,
                                      "days"
                                    )
                                  : null
                              }
                              onChange={(e) =>
                                this.handleChangeAge(e, "days", props)
                              }
                            />
                            <Input
                              style={{
                                width: 65,
                                textAlign: "center",
                                borderLeft: "1px solid #c4c4c4",
                              }}
                              type="number"
                              name="ageMonth"
                              min="0"
                              placeholder="Meses"
                              value={
                                props.values?.birthday != null
                                  ? moment().diff(
                                      props.values?.birthday,
                                      "months"
                                    )
                                  : null
                              }
                              onChange={(e) =>
                                this.handleChangeAge(e, "months", props)
                              }
                            />
                            <Input
                              style={{
                                width: 65,
                                textAlign: "center",
                                borderLeft: "1px solid #c4c4c4",
                              }}
                              type="number"
                              name="ageYear"
                              min="0"
                              placeholder="Anos"
                              value={
                                props.values?.birthday != null
                                  ? moment().diff(
                                      props.values?.birthday,
                                      "years",
                                      false
                                    )
                                  : null
                              }
                              onChange={(e) =>
                                this.handleChangeAge(e, "years", props)
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </Col>
                    {/* Gender */}
                    <Col
                      span={props.values?.gender === "M" ? 5 : 10}
                      offset={1}
                    >
                      <Row>
                        <label
                          className={
                            props.errors.gender && props.submitCount > 0
                              ? "formLabel error"
                              : "formLabel"
                          }
                        >
                          {translation.animal.edit.form.sex} *
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Select
                          style={{ width: "100%" }}
                          name="gender"
                          value={
                            props.values?.gender != null
                              ? props.values?.gender
                              : undefined
                          }
                          placeholder={translation.defaultSelectPlaceholder}
                          onChange={(value) => {
                            props.setValues({
                              ...props.values,
                              gender: value,
                              lotReceiptId: null,
                              lotItemReceiptId: null,
                              animalReproductionCategory:
                                value === "F"
                                  ? null
                                  : props.values.animalFarmFunction ===
                                    "reproduction"
                                  ? "reprodutor"
                                  : null,
                            });
                          }}
                        >
                          <Option key="F" value="F">
                            {translation.animal.edit.form.female}
                          </Option>
                          <Option key="M" value="M">
                            {translation.animal.edit.form.male}
                          </Option>
                        </Select>
                      </Row>
                    </Col>
                    {/* Cap */}
                    {props.values?.gender === "M" ? (
                      <Col span={5}>
                        <Row>
                          <label className="formLabel">
                            {translation.animal.edit.form.cap}
                          </label>
                        </Row>
                        <Row className="rowInput">
                          <RadioGroup
                            name="cap"
                            value={props.values?.cap}
                            defaultValue={false}
                            onChange={(e) => {
                              props.setValues({
                                ...props.values,
                                cap: e.target.value,
                              });
                            }}
                          >
                            <RadioButton value={true}>
                              {translation.buttons.yes}
                            </RadioButton>
                            <RadioButton value={false}>
                              {translation.buttons.no}
                            </RadioButton>
                          </RadioGroup>
                        </Row>
                      </Col>
                    ) : null}
                  </Row>
                  {/* animalFarmFunction and animalReproductionCategory*/}
                  <Row type="flex" justify="start" style={{ marginTop: 16 }}>
                    <Col span={11}>
                      <Row>
                        <label
                          className={
                            props.errors.animalFarmFunction &&
                            props.submitCount > 0
                              ? "formLabel error"
                              : "formLabel"
                          }
                        >
                          {translation.animal.edit.form.animalFarmFunction}*
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Select
                          name="animalFarmFunction"
                          value={props.values.animalFarmFunction || undefined}
                          placeholder={translation.defaultSelectPlaceholder}
                          onChange={(value) => {
                            props.setFieldValue("animalFarmFunction", value);
                            if (value === "fatten") {
                              props.setFieldValue(
                                "animalReproductionCategory",
                                null
                              );
                            } else if (
                              props.values.gender === "M" &&
                              value === "reproduction"
                            ) {
                              props.setFieldValue(
                                "animalReproductionCategory",
                                "reprodutor"
                              );
                            }
                          }}
                        >
                          <Option value="reproduction">Reprodução</Option>
                          <Option value="fatten">Engorda</Option>
                        </Select>
                      </Row>
                    </Col>
                    {props.values.animalFarmFunction === "reproduction" && (
                      <Col span={12} offset={1}>
                        <Row>
                          <label
                            className={
                              props.errors.animalReproductionCategory &&
                              props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            {
                              translation.animal.edit.form
                                .animalReproductionCategory
                            }
                            *{" "}
                            <InfoTooltip
                              title={
                                InfoTooltipContentAnimalReproductionCategory
                              }
                            />
                          </label>
                        </Row>
                        <Row className="rowInput">
                          <Select
                            name="animalReproductionCategory"
                            value={
                              props.values.animalReproductionCategory ||
                              undefined
                            }
                            placeholder={translation.defaultSelectPlaceholder}
                            onChange={(value) =>
                              props.setFieldValue(
                                "animalReproductionCategory",
                                value
                              )
                            }
                          >
                            {props.values.gender === "F" && (
                              <Option key="nulipara" value="nulipara">
                                Nulípara
                              </Option>
                            )}
                            {props.values.gender === "F" && (
                              <Option key="novilha" value="novilha">
                                Novilha
                              </Option>
                            )}
                            {props.values.gender === "F" && (
                              <Option key="primipara" value="primipara">
                                Primípara
                              </Option>
                            )}
                            {props.values.gender === "F" && (
                              <Option key="secundipara" value="secundipara">
                                Secundípara
                              </Option>
                            )}
                            {props.values.gender === "F" && (
                              <Option key="multipara" value="multipara">
                                Multípara
                              </Option>
                            )}
                            {props.values.gender === "M" && (
                              <Option value="reprodutor">Reprodutor</Option>
                            )}
                          </Select>
                        </Row>
                      </Col>
                    )}
                  </Row>
                  {/* farm owner */}
                  <Row type="flex" justify="start" style={{ marginTop: 16 }}>
                    <Col span={11}>
                      <Row>
                        <label
                          className={
                            props.errors.farmOwnerId && props.submitCount > 0
                              ? "formLabel error"
                              : "formLabel"
                          }
                        >
                          {translation.animal.edit.form.farmOwner}*
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Select
                          name="farmOwnerId"
                          value={props.values.farmOwnerId || undefined}
                          placeholder={translation.defaultSelectPlaceholder}
                          onChange={(value) =>
                            props.setFieldValue("farmOwnerId", value)
                          }
                        >
                          {farmOwners != null &&
                            Object.entries(farmOwners).length > 0 &&
                            farmOwners.map((fo) => (
                              <Option key={fo.id} value={fo.id}>
                                {fo.name}
                              </Option>
                            ))}
                        </Select>
                      </Row>
                    </Col>
                  </Row>
                  <CustomDivider>
                    {translation.animal.edit.weights}
                  </CustomDivider>
                  {/* Animal birthday weight, first weighting, firts weight date */}
                  <Row type="flex" justify="start">
                    {/* Birthday Weight */}
                    <Col span={5}>
                      <Row>
                        <label
                          className={
                            props.errors.birthdayWeight && props.submitCount > 0
                              ? "formLabel error"
                              : "formLabel"
                          }
                        >
                          {translation.animal.edit.form.birthdayWeight}*
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          customInput={Input}
                          value={
                            props.values?.birthdayWeight !== null
                              ? props.values?.birthdayWeight
                              : ""
                          }
                          name="birthdayWeight"
                          addonAfter="Kg"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          placeholder={
                            translation.animal.edit.form.birthdayWeight
                          }
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              birthdayWeight: floatValue
                                ? floatValue > 100
                                  ? 100
                                  : floatValue
                                : null,
                            });
                            if (isFarmAnimal) {
                              props.setFieldValue(
                                "acquisitionEstimatedWeight",
                                floatValue
                                  ? floatValue > 100
                                    ? 100
                                    : floatValue
                                  : null
                              );
                            }
                          }}
                        />
                      </Row>
                    </Col>
                    {/* First Insemination Weight */}
                    {props.values?.gender === "F" && (
                      <Col span={5} offset={1}>
                        <Row>
                          <label className="formLabel">1a. Inseminação</label>
                        </Row>
                        <Row className="rowInput">
                          <NumberFormat
                            customInput={Input}
                            value={
                              props.values?.firstInseminationWeight !== null
                                ? props.values?.firstInseminationWeight
                                : ""
                            }
                            placeholder={
                              translation.animal.edit.form
                                .firstInseminationWeight
                            }
                            name="firstInseminationWeight"
                            addonAfter="Kg"
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            fixedDecimalScale={true}
                            onValueChange={({ floatValue }) => {
                              props.setValues({
                                ...props.values,
                                firstInseminationWeight: floatValue,
                              });
                            }}
                          />
                        </Row>
                      </Col>
                    )}
                    {/* Asymptotic Weight */}
                    <Col span={5} offset={1}>
                      <Row>
                        <label
                          className={
                            props.errors.asymptoticWeight &&
                            props.submitCount > 0
                              ? "formLabel error"
                              : "formLabel"
                          }
                        >
                          {translation.animal.edit.form.asymptoticWeight}*
                          <InfoTooltip
                            title={
                              translation.animal.edit.form
                                .asymptoticWeightInfoTooltip
                            }
                          />
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          customInput={Input}
                          value={
                            props.values?.asymptoticWeight !== null
                              ? props.values?.asymptoticWeight
                              : ""
                          }
                          placeholder={
                            translation.animal.edit.form.asymptoticWeight
                          }
                          name="asymptoticWeight"
                          addonAfter="Kg"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              asymptoticWeight: floatValue,
                            });
                          }}
                        />
                      </Row>
                    </Col>
                    {/* Weaning weight */}
                    <Col span={5} offset={1}>
                      <Row>
                        <label className="formLabel">
                          {translation.animal.edit.form.weaningWeight}
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          customInput={Input}
                          value={
                            props.values?.weaningWeight !== null
                              ? props.values?.weaningWeight
                              : ""
                          }
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          maxLength={6}
                          fixedDecimalScale={true}
                          name="weaningWeight"
                          placeholder={
                            translation.animal.edit.form.weaningWeight
                          }
                          addonAfter="Kg"
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              weaningWeight: floatValue,
                            });
                          }}
                        />
                      </Row>
                    </Col>
                    {/* Weaning date */}
                    <Col span={5} offset={props.values?.gender === "F" ? 0 : 1}>
                      <Row>
                        <label className="formLabel">
                          {translation.animal.edit.form.weaningDate}
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <DatePicker
                          name="weaningDate"
                          allowClear={false}
                          style={{ width: "100%" }}
                          value={
                            props.values?.weaningDate != null
                              ? moment(props.values?.weaningDate, "YYYY-MM-DD")
                              : null
                          }
                          placeholder={translation.defaultDatePickerPlaceholder}
                          format={dateFormat}
                          onChange={(date, dateString) => {
                            if (date <= moment() && date !== null) {
                              props.setValues({
                                ...props.values,
                                weaningDate: date,
                              });
                            }
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  <CustomDivider>
                    {translation.animal.edit.receipt}
                  </CustomDivider>
                  {/* Animal receipt data */}
                  <Row type="flex" justify="start">
                    <Col span={7}>
                      <Row>
                        <label
                          className={
                            props.errors.acquisitionDate &&
                            props.submitCount > 0
                              ? "formLabel error"
                              : "formLabel"
                          }
                        >
                          {translation.animal.edit.form.acquisitionDate}*
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <DatePicker
                          name="acquisitionDate"
                          placeholder={
                            translation.animal.edit.form.acquisitionDate
                          }
                          allowClear={false}
                          style={{ width: "100%" }}
                          value={
                            props.values?.acquisitionDate != null
                              ? moment(
                                  props.values?.acquisitionDate,
                                  "YYYY-MM-DD"
                                )
                              : undefined
                          }
                          format={dateFormat}
                          disabled={isFarmAnimal}
                          onChange={(date, dateString) => {
                            props.setValues({
                              ...props.values,
                              acquisitionDate: date,
                            });
                          }}
                        />
                      </Row>
                    </Col>
                    <Col span={8} offset={1}>
                      <Row>
                        <label
                          className={
                            props.errors.purchaseCost && props.submitCount > 0
                              ? "formLabel error"
                              : "formLabel"
                          }
                        >
                          {translation.animal.edit.form.purchaseCost}*
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          customInput={Input}
                          value={
                            props.values?.purchaseCost !== null
                              ? props.values?.purchaseCost
                              : ""
                          }
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          placeholder={
                            translation.animal.edit.form.purchaseCost
                          }
                          name="purchaseCost"
                          addonBefore="R$ "
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue("purchaseCost", floatValue);
                          }}
                        />
                      </Row>
                    </Col>
                    <Col span={7} offset={1}>
                      <Row>
                        <label
                          className={
                            props.errors.acquisitionEstimatedWeight &&
                            props.submitCount > 0
                              ? "formLabel error"
                              : "formLabel"
                          }
                        >
                          {
                            translation.animal.edit.form
                              .acquisitionEstimatedWeight
                          }
                          *
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          customInput={Input}
                          disabled={isFarmAnimal}
                          value={
                            props.values?.acquisitionEstimatedWeight !== null
                              ? props.values?.acquisitionEstimatedWeight
                              : ""
                          }
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          placeholder={
                            translation.animal.edit.form
                              .acquisitionEstimatedWeight
                          }
                          name="acquisitionEstimatedWeight"
                          addonAfter="Kg"
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue(
                              "acquisitionEstimatedWeight",
                              floatValue
                            );
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  <CustomDivider>{translation.animal.edit.sale}</CustomDivider>
                  {/* Animal sale data */}
                  <Row type="flex" justify="start">
                    <Col span={7}>
                      <Row>
                        <label className="formLabel">
                          {translation.animal.edit.form.carcassHarnessing}
                          <InfoTooltip
                            title={
                              translation.animal.carcassHarnessingInfoTooltip
                            }
                          />
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          customInput={Input}
                          placeholder={
                            translation.animal.edit.form.carcassHarnessing
                          }
                          value={
                            props.values?.carcassHarnessing !== null
                              ? props.values?.carcassHarnessing
                              : ""
                          }
                          name="carcassHarnessing"
                          addonAfter="%"
                          decimalScale={2}
                          fixedDecimalScale={true}
                          decimalSeparator=","
                          thousandSeparator="."
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              carcassHarnessing: floatValue,
                            });
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>

                  {farmSisbov === true ? (
                    <>
                      <CustomDivider>RASTREABIDLIDADE (SISBOV)</CustomDivider>

                      {/* Animal sisbov info */}
                      <Row type="flex" justify="start" gutter={8}>
                        <Col span={7}>
                          <Row>
                            <label
                              className={
                                props.errors.sisbovIdentificationDate &&
                                props.submitCount > 0
                                  ? "formLabel error"
                                  : "formLabel"
                              }
                            >
                              {
                                translation.animal.edit.form
                                  .sisbovIdentificationDate
                              }
                            </label>
                          </Row>
                          <Row className="rowInput">
                            <DatePicker
                              name="sisbovIdentificationDate"
                              placeholder={
                                translation.animal.edit.form
                                  .sisbovIdentificationDate
                              }
                              allowClear={false}
                              style={{ width: "100%" }}
                              value={
                                props.values?.sisbovIdentificationDate != null
                                  ? moment(
                                      props.values?.sisbovIdentificationDate,
                                      "YYYY-MM-DD"
                                    )
                                  : undefined
                              }
                              format={dateFormat}
                              onChange={(date, dateString) => {
                                props.setValues({
                                  ...props.values,
                                  sisbovIdentificationDate: date,
                                });
                              }}
                            />
                          </Row>
                        </Col>
                        <Col span={7}>
                          <Row>
                            <label
                              className={
                                props.errors.sisbovInsertBNDDate &&
                                props.submitCount > 0
                                  ? "formLabel error"
                                  : "formLabel"
                              }
                            >
                              {translation.animal.edit.form.sisbovInsertBNDDate}
                            </label>
                          </Row>
                          <Row className="rowInput">
                            <DatePicker
                              name="sisbovInsertBNDDate"
                              placeholder={
                                translation.animal.edit.form.sisbovInsertBNDDate
                              }
                              allowClear={false}
                              style={{ width: "100%" }}
                              value={
                                props.values?.sisbovInsertBNDDate != null
                                  ? moment(
                                      props.values?.sisbovInsertBNDDate,
                                      "YYYY-MM-DD"
                                    )
                                  : undefined
                              }
                              format={dateFormat}
                              onChange={(date, dateString) => {
                                let sisbovSlaughterDate =
                                  props.values.sisbovSlaughterDate;
                                if (
                                  sisbovSlaughterDate === null ||
                                  sisbovSlaughterDate === undefined
                                ) {
                                  sisbovSlaughterDate = date
                                    .clone()
                                    .add(90, "day");
                                }

                                props.setValues({
                                  ...props.values,
                                  sisbovInsertBNDDate: date,
                                  sisbovSlaughterDate: sisbovSlaughterDate,
                                });
                              }}
                            />
                          </Row>
                        </Col>
                        <Col span={7}>
                          <Row>
                            <label
                              className={
                                props.errors.sisbovSlaughterDate &&
                                props.submitCount > 0
                                  ? "formLabel error"
                                  : "formLabel"
                              }
                            >
                              {translation.animal.edit.form.sisbovSlaughterDate}
                            </label>
                          </Row>
                          <Row className="rowInput">
                            <DatePicker
                              name="sisbovSlaughterDate"
                              placeholder={
                                translation.animal.edit.form.sisbovSlaughterDate
                              }
                              allowClear={false}
                              style={{ width: "100%" }}
                              value={
                                props.values?.sisbovSlaughterDate != null
                                  ? moment(
                                      props.values?.sisbovSlaughterDate,
                                      "YYYY-MM-DD"
                                    )
                                  : undefined
                              }
                              format={dateFormat}
                              onChange={(date, dateString) => {
                                props.setValues({
                                  ...props.values,
                                  sisbovSlaughterDate: date,
                                });
                              }}
                            />
                          </Row>
                        </Col>
                      </Row>
                    </>
                  ) : null}

                  <CustomDivider>GENEALOGIA</CustomDivider>

                  {/* Animal genetic info */}
                  <Row type="flex" justify="start" gutter={8}>
                    {/* Mother info box */}
                    <Col span={12}>
                      <GeneticBox>
                        <Row>
                          <span className="geneticTitle">MÃE</span>
                        </Row>
                        <Row className="rowInput">
                          <Checkbox
                            checked={props.values.isFarmMotherAnimal}
                            onChange={(e) => {
                              props.setFieldValue(
                                "isFarmMotherAnimal",
                                e.target.checked
                              );
                              if (e.target.checked)
                                props.setFieldValue("motherSupplierId", null);
                            }}
                          >
                            Animal fornecido pela{" "}
                            <strong>própria fazenda</strong>
                          </Checkbox>
                        </Row>
                        <Row>
                          <label className="formLabel">Fornecedor</label>
                        </Row>
                        <Row className="rowInput">
                          <Select
                            value={
                              props.values?.motherSupplierId != null
                                ? suppliers != null &&
                                  suppliers.find(
                                    (s) =>
                                      s.id === props.values?.motherSupplierId
                                  ) != null
                                  ? props.values?.motherSupplierId
                                  : undefined
                                : undefined
                            }
                            showSearch
                            placeholder={
                              translation.lot.receipt.supplier
                                .supplierComboBoxPlaceHolder
                            }
                            name="motherSupplierId"
                            onChange={(value) => {
                              props.setFieldValue("motherSupplierId", value);
                            }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={props.values.isFarmMotherAnimal}
                          >
                            {suppliers != null &&
                              Object.entries(suppliers).length > 0 &&
                              suppliers.map((supplier) => (
                                <Option key={supplier.id} value={supplier.id}>
                                  {supplier.name}
                                </Option>
                              ))}
                          </Select>
                        </Row>
                        <Row>
                          <label className="formLabel">Identificação</label>
                        </Row>
                        <Row className="rowInput">
                          {props.values.isFarmMotherAnimal ? (
                            <Select
                              value={
                                props.values?.motherId != null
                                  ? animalMotherDropdown != null &&
                                    animalMotherDropdown.find(
                                      (a) => a.id === props.values?.motherId
                                    ) != null
                                    ? props.values?.motherId
                                    : undefined
                                  : undefined
                              }
                              showSearch
                              placeholder={translation.defaultPlaceholder}
                              name="motherId"
                              onChange={(value) => {
                                props.setFieldValue("motherId", value);
                              }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {animalMotherDropdown != null &&
                                Object.entries(animalMotherDropdown).length >
                                  0 &&
                                animalMotherDropdown.map((a) => (
                                  <Option key={a.id} value={a.id}>
                                    {getParentDescription(a)}
                                  </Option>
                                ))}
                            </Select>
                          ) : (
                            <Input
                              placeholder={translation.defaultPlaceholder}
                              value={props.values?.motherHandlingNumber}
                              name="motherHandlingNumber"
                              onChange={(e) => {
                                props.setFieldValue(
                                  "motherHandlingNumber",
                                  e.target.value
                                );
                              }}
                            />
                          )}
                        </Row>
                      </GeneticBox>
                    </Col>
                    {/* Father info box */}
                    <Col span={12}>
                      <GeneticBox>
                        <Row>
                          <span className="geneticTitle">PAI</span>
                        </Row>
                        <Row className="rowInput">
                          <Checkbox
                            checked={props.values.isFarmFatherAnimal}
                            onChange={(e) => {
                              props.setFieldValue(
                                "isFarmFatherAnimal",
                                e.target.checked
                              );
                              if (e.target.checked)
                                props.setFieldValue("fatherSupplierId", null);
                            }}
                          >
                            Animal fornecido pela{" "}
                            <strong>própria fazenda</strong>
                          </Checkbox>
                        </Row>
                        <Row className="rowInput">
                          <Checkbox
                            checked={props.values.fromInsermination}
                            onChange={(e) => {
                              props.setFieldValue(
                                "fromInsermination",
                                e.target.checked
                              );
                              if (e.target.checked) {
                                props.setFieldValue("fatherSupplierId", null);
                                props.setFieldValue(
                                  "fatherHandlingNumber",
                                  null
                                );
                                props.setFieldValue("fatherId", null);
                              } else {
                                props.setFieldValue("fatherSemenId", null);
                              }
                            }}
                          >
                            Sêmen
                          </Checkbox>
                        </Row>
                        <Row>
                          <label className="formLabel">Fornecedor</label>
                        </Row>
                        <Row className="rowInput">
                          <Select
                            value={
                              props.values?.fatherSupplierId != null
                                ? suppliers != null &&
                                  suppliers.find(
                                    (s) =>
                                      s.id === props.values?.fatherSupplierId
                                  ) != null
                                  ? props.values?.fatherSupplierId
                                  : undefined
                                : undefined
                            }
                            showSearch
                            placeholder={
                              translation.lot.receipt.supplier
                                .supplierComboBoxPlaceHolder
                            }
                            name="fatherSupplierId"
                            onChange={(value) => {
                              props.setFieldValue("fatherSupplierId", value);
                            }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={props.values.isFarmFatherAnimal}
                          >
                            {suppliers != null &&
                              Object.entries(suppliers).length > 0 &&
                              suppliers.map((supplier) => (
                                <Option key={supplier.id} value={supplier.id}>
                                  {supplier.name}
                                </Option>
                              ))}
                          </Select>
                        </Row>
                        <Row>
                          <label
                            className={
                              props.values.fromInsermination === true &&
                              props.errors.fatherSemenId &&
                              props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            {`Identificação${
                              props.values.fromInsermination === true ? "*" : ""
                            }`}
                          </label>
                        </Row>
                        <Row className="rowInput">
                          {props.values.fromInsermination ? (
                            <Select
                              value={
                                semens.length === 0
                                  ? undefined
                                  : props.values.fatherSemenId != null
                                  ? props.values.fatherSemenId
                                  : undefined
                              }
                              showSearch
                              placeholder={translation.defaultPlaceholder}
                              name="fatherSemenId"
                              onChange={(value) => {
                                props.setFieldValue("fatherSemenId", value);
                              }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {semens.map((a) => (
                                <Option key={a.id} value={a.id}>
                                  {a.name}
                                </Option>
                              ))}
                            </Select>
                          ) : props.values.isFarmFatherAnimal ? (
                            <Select
                              value={
                                props.values?.fatherId != null
                                  ? animalFatherDropdown != null &&
                                    animalFatherDropdown.find(
                                      (a) => a.id === props.values?.fatherId
                                    ) != null
                                    ? props.values?.fatherId
                                    : undefined
                                  : undefined
                              }
                              showSearch
                              placeholder={translation.defaultPlaceholder}
                              name="fatherId"
                              onChange={(value) => {
                                props.setFieldValue("fatherId", value);
                              }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {animalFatherDropdown != null &&
                                Object.entries(animalFatherDropdown).length >
                                  0 &&
                                animalFatherDropdown.map((a) => (
                                  <Option key={a.id} value={a.id}>
                                    {getParentDescription(a)}
                                  </Option>
                                ))}
                            </Select>
                          ) : (
                            <Input
                              placeholder={translation.defaultPlaceholder}
                              value={props.values?.fatherHandlingNumber}
                              name="fatherHandlingNumber"
                              onChange={(e) => {
                                props.setFieldValue(
                                  "fatherHandlingNumber",
                                  e.target.value
                                );
                              }}
                            />
                          )}
                        </Row>
                      </GeneticBox>
                    </Col>
                  </Row>
                  <CustomDivider dashed />
                  {/* LotId */}
                  <Row type="flex" justify="start">
                    <Col span={24}>
                      <span className="lotMessage">
                        Selecione um Lote de Produção já existente para este
                        animal:
                      </span>
                    </Col>
                  </Row>
                  <Row type="flex" justify="start">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            props.errors.lotId && props.submitCount > 0
                              ? "formLabel error"
                              : "formLabel"
                          }
                        >
                          Lote de produção*
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <SelectLotProduction
                          id="lotId"
                          name="lotId"
                          value={
                            props.values?.lotId != null
                              ? props.values?.lotId
                              : undefined
                          }
                          onDeselect={() => {
                            props.setFieldValue("lotId", null);
                          }}
                          onChange={(value) => {
                            if (value == null) {
                              props.setFieldValue("lotId", null);
                            } else {
                              props.setFieldValue("lotId", value);
                            }
                          }}
                        />
                      </Row>
                      <Row className="rowInput">
                        <Checkbox
                          disabled={isFarmAnimal}
                          checked={allowReceiptLot}
                          onChange={(e) => {
                            if (!e.target.checked) {
                              props.setFieldValue("lotReceiptId", null);
                              props.setFieldValue("lotItemReceiptId", null);
                            }
                            this.setState({
                              ...this.state,
                              allowReceiptLot: e.target.checked,
                            });
                          }}
                        >
                          Associar o animal a um Lote de Recebimento.
                        </Checkbox>
                        <InfoTooltip title="Selecione os lotes de recebimento já cadastrados em Lotes/ Recebimento, somente poderão ser selecionados os lotes de recebimento pendentes e que sejam do mesmo fornecedor dos animais." />
                      </Row>

                      <Row>
                        <label
                          className={
                            props.errors.lotReceiptId && props.submitCount > 0
                              ? "formLabel error"
                              : "formLabel"
                          }
                        >
                          Lote de Recebimento
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Select
                          disabled={!allowReceiptLot || isFarmAnimal}
                          name="lotItemReceiptId"
                          value={
                            props.values?.lotItemReceiptId != null
                              ? props.values?.lotItemReceiptId
                              : undefined
                          }
                          placeholder={translation.defaultSelectPlaceholder}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          allowClear={true}
                          onChange={(value) => {
                            if (value == null) {
                              props.setFieldValue("lotReceiptId", null);
                              props.setFieldValue("lotItemReceiptId", null);
                            } else {
                              let lotReceipt = receiptLots.find((lot) => {
                                let item = lot.lotItems.find(
                                  (item) => item.id === value
                                );

                                if (item) {
                                  return true;
                                }
                                return false;
                              });
                              props.setFieldValue(
                                "lotReceiptId",
                                lotReceipt.id
                              );
                              props.setFieldValue("lotItemReceiptId", value);
                            }
                          }}
                        >
                          {receiptLots.length > 0 &&
                            receiptLots.map((lot) => {
                              return lot.lotItems
                                .filter((item) => {
                                  return (
                                    item.status !== "Confirmed" &&
                                    item.gender.substring(0, 1) ===
                                      props.values.gender &&
                                    lot.supplierId === props.values.supplierId
                                  );
                                })
                                .map((item) => {
                                  return lot.isReceiptFromBoitelScenario ===
                                    true ? (
                                    <Option key={item.id} value={item.id}>
                                      {`Boitel:
                                      ${item.identification} /
                                      Fornecedor: ${lot.supplierName} /
                                      ${
                                        item.entryDate
                                          ? moment(item.entryDate).format(
                                              dateFormat
                                            ) + " / "
                                          : ""
                                      }
                                      ${item?.gender.substring(0, 1)} /
                                      ${item.amountAnimalsConfirmed}
                                    `}
                                    </Option>
                                  ) : (
                                    <Option key={item.id} value={item.id}>
                                      {`
                                    ${lot.name} /
                                    Fornecedor: ${lot.supplierName} /
                                    ${moment(item.birthday).format(
                                      "MM/YYYY - "
                                    )}
                                    ${moment(new Date())
                                      .diff(
                                        moment(item.birthday),
                                        "months",
                                        true
                                      )
                                      .toFixed()} m /
                                    ${item?.gender.substring(0, 1)} /
                                    ${item.amountAnimalsConfirmed || 0}
                                  `}
                                    </Option>
                                  );
                                });
                            })}
                        </Select>
                      </Row>
                    </Col>
                  </Row>
                </Body>
                {/* Footer */}
                <Footer>
                  <Row type="flex" justify="space-between">
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="selectDiv"
                    >
                      <Select
                        value={saveOptions}
                        name="saveOptions"
                        onChange={(value) =>
                          this.setState({ saveOptions: value })
                        }
                      >
                        <Option key="1" value="only_save">
                          Salvar somente
                        </Option>
                        <Option key="2 " value="save_and_add_animals">
                          Salvar e adicionar novo animal
                        </Option>
                      </Select>
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="buttonsDiv"
                    >
                      <ButtonStandard
                        type="button"
                        buttonType="type7"
                        onClick={() => this.closeDrawer(props)}
                      >
                        {translation.buttons.cancel}
                      </ButtonStandard>
                      <PopConfirmCustom
                        title={
                          <p>
                            Caso o animal seja adicionado em um lote que possua{" "}
                            <br /> cenário(s) de venda, ao confirmar, reprocesse
                            os <br /> cenário(s) para que o animal seja
                            considerado.
                          </p>
                        }
                        cancelText="Cancelar"
                        okText="Confirmar"
                        placement="topRight"
                        icon={
                          <Icon
                            type="exclamation-circle"
                            theme="filled"
                            style={{
                              fontSize: 18,
                              color: "#D44C4C",
                              marginRight: 5,
                            }}
                          />
                        }
                        onConfirm={props.submitForm}
                      >
                        <ButtonStandard type="button" buttonType="type6">
                          {translation.buttons.save}
                        </ButtonStandard>
                      </PopConfirmCustom>
                    </Col>
                  </Row>
                </Footer>
              </form>
            </Spin>
            <DrawerSupplier />
          </Container>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  animal: state.animal,
  supplier: state.supplier,
});

const mapDispatchToProps = (dispatch) => ({
  animalActions: bindActionCreators(AnimalActions, dispatch),
  supplierActions: bindActionCreators(SupplierActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawerNewAnimal);
