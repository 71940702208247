import { Col, Modal, notification, Row, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import ButtonStandard from "../../../components/utils/button";
import PencilIcon from "../../../components/utils/icons/pencil";
import {
  findFarmDayScaleTreatmentParameterData,
  saveFarmDayScaleTreatmentParameterData,
} from "../../../services/farmDayScaleTreatmentParameterService";
import { EditButton } from "../../reproductionParameters/cards/styles";
import { CardCustom, FarmDayScaleTreatmentParameterTable } from "./styles";

// import { Container } from './styles';

const TREAT_DESCRIPTION = {
  treatOne: "1",
  treatTwo: "2",
  treatThree: "3",
  treatFour: "4",
  treatFive: "5",
  treatSix: "6",
  treatSeven: "7",
  treatEight: "8",
};

const DEFAULT_FORM_VALUES = {
  productionSubSystem: "Extensive",
  treatOneInMinutes: 0,
  treatOneInHourAndMinutesFormat: "",
  treatOneBusinessDayPercent: null,
  treatOneNonWorkingDayPercent: null,
  treatTwoInMinutes: 0,
  treatTwoInHourAndMinutesFormat: "",
  treatTwoBusinessDayPercent: null,
  treatTwoNonWorkingDayPercent: null,
  treatThreeInMinutes: 0,
  treatThreeInHourAndMinutesFormat: "",
  treatThreeBusinessDayPercent: null,
  treatThreeNonWorkingDayPercent: null,
  treatFourInMinutes: 0,
  treatFourInHourAndMinutesFormat: "",
  treatFourBusinessDayPercent: null,
  treatFourNonWorkingDayPercent: null,
  treatFiveInMinutes: 0,
  treatFiveInHourAndMinutesFormat: "",
  treatFiveBusinessDayPercent: null,
  treatFiveNonWorkingDayPercent: null,
  treatSixInMinutes: 0,
  treatSixInHourAndMinutesFormat: "",
  treatSixBusinessDayPercent: null,
  treatSixNonWorkingDayPercent: null,
  treatSevenInMinutes: 0,
  treatSevenInHourAndMinutesFormat: "",
  treatSevenBusinessDayPercent: null,
  treatSevenNonWorkingDayPercent: null,
  treatEightInMinutes: 0,
  treatEightInHourAndMinutesFormat: "",
  treatEightBusinessDayPercent: null,
  treatEightNonWorkingDayPercent: null,
};

function FormTable({
  isEditing,
  form = DEFAULT_FORM_VALUES,
  handleChangeValues,
}) {
  return (
    <FarmDayScaleTreatmentParameterTable>
      <thead>
        <tr>
          <th>
            {form.productionSubSystem === "Extensive"
              ? "EXTENSIVO"
              : "INTENSIVO"}
          </th>
          <th colSpan={8}>ESCALA PADRÃO DE TRATOS NO COCHO</th>
        </tr>
        <tr>
          <th>TRATOS/DIA</th>
          <th>1°</th>
          <th>2°</th>
          <th>3°</th>
          <th>4°</th>
          <th>5°</th>
          <th>6°</th>
          <th>7°</th>
          <th>8°</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className={isEditing ? "edit" : ""}>HORÁRIOS</td>
          <td className={isEditing ? "edit" : ""}>
            <input
              type="time"
              name="treatOneInHourAndMinutesFormat"
              id="treatOneInHourAndMinutesFormat"
              value={
                form.treatOneInHourAndMinutesFormat !== null
                  ? form.treatOneInHourAndMinutesFormat
                  : ""
              }
              disabled={!isEditing}
              step={60}
              onChange={(e) =>
                handleChangeValues(
                  form.productionSubSystem,
                  "treatOneInMinutes",
                  e.target.value
                )
              }
            />
          </td>
          <td className={isEditing ? "edit" : ""}>
            <input
              type="time"
              name="treatTwoInHourAndMinutesFormat"
              id="treatTwoInHourAndMinutesFormat"
              value={
                form.treatTwoInHourAndMinutesFormat !== null
                  ? form.treatTwoInHourAndMinutesFormat
                  : ""
              }
              disabled={!isEditing}
              step={60}
              onChange={(e) =>
                handleChangeValues(
                  form.productionSubSystem,
                  "treatTwoInMinutes",
                  e.target.value
                )
              }
            />
          </td>
          <td className={isEditing ? "edit" : ""}>
            <input
              type="time"
              name="treatThreeInHourAndMinutesFormat"
              id="treatThreeInHourAndMinutesFormat"
              value={
                form.treatThreeInHourAndMinutesFormat !== null
                  ? form.treatThreeInHourAndMinutesFormat
                  : ""
              }
              disabled={!isEditing}
              step={60}
              onChange={(e) =>
                handleChangeValues(
                  form.productionSubSystem,
                  "treatThreeInMinutes",
                  e.target.value
                )
              }
            />
          </td>
          <td className={isEditing ? "edit" : ""}>
            <input
              type="time"
              name="treatFourInHourAndMinutesFormat"
              id="treatFourInHourAndMinutesFormat"
              value={
                form.treatFourInHourAndMinutesFormat !== null
                  ? form.treatFourInHourAndMinutesFormat
                  : ""
              }
              disabled={!isEditing}
              step={60}
              onChange={(e) =>
                handleChangeValues(
                  form.productionSubSystem,
                  "treatFourInMinutes",
                  e.target.value
                )
              }
            />
          </td>
          <td className={isEditing ? "edit" : ""}>
            <input
              type="time"
              name="treatFiveInHourAndMinutesFormat"
              id="treatFiveInHourAndMinutesFormat"
              value={
                form.treatFiveInHourAndMinutesFormat !== null
                  ? form.treatFiveInHourAndMinutesFormat
                  : ""
              }
              disabled={!isEditing}
              step={60}
              onChange={(e) =>
                handleChangeValues(
                  form.productionSubSystem,
                  "treatFiveInMinutes",
                  e.target.value
                )
              }
            />
          </td>
          <td className={isEditing ? "edit" : ""}>
            <input
              type="time"
              name="treatSixInHourAndMinutesFormat"
              id="treatSixInHourAndMinutesFormat"
              value={
                form.treatSixInHourAndMinutesFormat !== null
                  ? form.treatSixInHourAndMinutesFormat
                  : ""
              }
              disabled={!isEditing}
              step={60}
              onChange={(e) =>
                handleChangeValues(
                  form.productionSubSystem,
                  "treatSixInMinutes",
                  e.target.value
                )
              }
            />
          </td>
          <td className={isEditing ? "edit" : ""}>
            <input
              type="time"
              name="treatSevenInHourAndMinutesFormat"
              id="treatSevenInHourAndMinutesFormat"
              value={
                form.treatSevenInHourAndMinutesFormat !== null
                  ? form.treatSevenInHourAndMinutesFormat
                  : ""
              }
              disabled={!isEditing}
              step={60}
              onChange={(e) =>
                handleChangeValues(
                  form.productionSubSystem,
                  "treatSevenInMinutes",
                  e.target.value
                )
              }
            />
          </td>
          <td className={isEditing ? "edit" : ""}>
            <input
              type="time"
              name="treatEightInHourAndMinutesFormat"
              id="treatEightInHourAndMinutesFormat"
              value={
                form.treatEightInHourAndMinutesFormat !== null
                  ? form.treatEightInHourAndMinutesFormat
                  : ""
              }
              disabled={!isEditing}
              step={60}
              onChange={(e) =>
                handleChangeValues(
                  form.productionSubSystem,
                  "treatEightInMinutes",
                  e.target.value
                )
              }
            />
          </td>
        </tr>
        <tr>
          <td className={isEditing ? "edit" : ""}>ÚTEIS</td>
          <td className={isEditing ? "edit" : ""}>
            <NumberFormat
              value={form.treatOneBusinessDayPercent || 0}
              suffix="%"
              disabled={!isEditing}
              allowNegative={false}
              decimalScale={0}
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale={true}
              onValueChange={({ floatValue }) =>
                handleChangeValues(
                  form.productionSubSystem,
                  "treatOneBusinessDayPercent",
                  floatValue > 100 ? 100 : floatValue
                )
              }
            />
          </td>
          <td className={isEditing ? "edit" : ""}>
            <NumberFormat
              value={form.treatTwoBusinessDayPercent || 0}
              suffix="%"
              disabled={!isEditing}
              allowNegative={false}
              decimalScale={0}
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale={true}
              onValueChange={({ floatValue }) =>
                handleChangeValues(
                  form.productionSubSystem,
                  "treatTwoBusinessDayPercent",
                  floatValue > 100 ? 100 : floatValue
                )
              }
            />
          </td>
          <td className={isEditing ? "edit" : ""}>
            <NumberFormat
              value={form.treatThreeBusinessDayPercent || 0}
              suffix="%"
              disabled={!isEditing}
              allowNegative={false}
              decimalScale={0}
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale={true}
              onValueChange={({ floatValue }) =>
                handleChangeValues(
                  form.productionSubSystem,
                  "treatThreeBusinessDayPercent",
                  floatValue > 100 ? 100 : floatValue
                )
              }
            />
          </td>
          <td className={isEditing ? "edit" : ""}>
            <NumberFormat
              value={form.treatFourBusinessDayPercent || 0}
              suffix="%"
              disabled={!isEditing}
              allowNegative={false}
              decimalScale={0}
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale={true}
              onValueChange={({ floatValue }) =>
                handleChangeValues(
                  form.productionSubSystem,
                  "treatFourBusinessDayPercent",
                  floatValue > 100 ? 100 : floatValue
                )
              }
            />
          </td>
          <td className={isEditing ? "edit" : ""}>
            <NumberFormat
              value={form.treatFiveBusinessDayPercent || 0}
              suffix="%"
              disabled={!isEditing}
              allowNegative={false}
              decimalScale={0}
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale={true}
              onValueChange={({ floatValue }) =>
                handleChangeValues(
                  form.productionSubSystem,
                  "treatFiveBusinessDayPercent",
                  floatValue > 100 ? 100 : floatValue
                )
              }
            />
          </td>
          <td className={isEditing ? "edit" : ""}>
            <NumberFormat
              value={form.treatSixBusinessDayPercent || 0}
              suffix="%"
              disabled={!isEditing}
              allowNegative={false}
              decimalScale={0}
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale={true}
              onValueChange={({ floatValue }) =>
                handleChangeValues(
                  form.productionSubSystem,
                  "treatSixBusinessDayPercent",
                  floatValue > 100 ? 100 : floatValue
                )
              }
            />
          </td>
          <td className={isEditing ? "edit" : ""}>
            <NumberFormat
              value={form.treatSevenBusinessDayPercent || 0}
              suffix="%"
              disabled={!isEditing}
              allowNegative={false}
              decimalScale={0}
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale={true}
              onValueChange={({ floatValue }) =>
                handleChangeValues(
                  form.productionSubSystem,
                  "treatSevenBusinessDayPercent",
                  floatValue > 100 ? 100 : floatValue
                )
              }
            />
          </td>
          <td className={isEditing ? "edit" : ""}>
            <NumberFormat
              value={form.treatEightBusinessDayPercent || 0}
              suffix="%"
              disabled={!isEditing}
              allowNegative={false}
              decimalScale={0}
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale={true}
              onValueChange={({ floatValue }) =>
                handleChangeValues(
                  form.productionSubSystem,
                  "treatEightBusinessDayPercent",
                  floatValue > 100 ? 100 : floatValue
                )
              }
            />
          </td>
        </tr>
        <tr>
          <td className={isEditing ? "edit" : ""}>NÃO ÚTEIS</td>
          <td className={isEditing ? "edit" : ""}>
            <NumberFormat
              value={form.treatOneNonWorkingDayPercent || 0}
              suffix="%"
              disabled={!isEditing}
              allowNegative={false}
              decimalScale={0}
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale={true}
              onValueChange={({ floatValue }) =>
                handleChangeValues(
                  form.productionSubSystem,
                  "treatOneNonWorkingDayPercent",
                  floatValue > 100 ? 100 : floatValue
                )
              }
            />
          </td>
          <td className={isEditing ? "edit" : ""}>
            <NumberFormat
              value={form.treatTwoNonWorkingDayPercent || 0}
              suffix="%"
              disabled={!isEditing}
              allowNegative={false}
              decimalScale={0}
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale={true}
              onValueChange={({ floatValue }) =>
                handleChangeValues(
                  form.productionSubSystem,
                  "treatTwoNonWorkingDayPercent",
                  floatValue > 100 ? 100 : floatValue
                )
              }
            />
          </td>
          <td className={isEditing ? "edit" : ""}>
            <NumberFormat
              value={form.treatThreeNonWorkingDayPercent || 0}
              suffix="%"
              disabled={!isEditing}
              allowNegative={false}
              decimalScale={0}
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale={true}
              onValueChange={({ floatValue }) =>
                handleChangeValues(
                  form.productionSubSystem,
                  "treatThreeNonWorkingDayPercent",
                  floatValue > 100 ? 100 : floatValue
                )
              }
            />
          </td>
          <td className={isEditing ? "edit" : ""}>
            <NumberFormat
              value={form.treatFourNonWorkingDayPercent || 0}
              suffix="%"
              disabled={!isEditing}
              allowNegative={false}
              decimalScale={0}
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale={true}
              onValueChange={({ floatValue }) =>
                handleChangeValues(
                  form.productionSubSystem,
                  "treatFourNonWorkingDayPercent",
                  floatValue > 100 ? 100 : floatValue
                )
              }
            />
          </td>
          <td className={isEditing ? "edit" : ""}>
            <NumberFormat
              value={form.treatFiveNonWorkingDayPercent || 0}
              suffix="%"
              disabled={!isEditing}
              allowNegative={false}
              decimalScale={0}
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale={true}
              onValueChange={({ floatValue }) =>
                handleChangeValues(
                  form.productionSubSystem,
                  "treatFiveNonWorkingDayPercent",
                  floatValue > 100 ? 100 : floatValue
                )
              }
            />
          </td>
          <td className={isEditing ? "edit" : ""}>
            <NumberFormat
              value={form.treatSixNonWorkingDayPercent || 0}
              suffix="%"
              disabled={!isEditing}
              allowNegative={false}
              decimalScale={0}
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale={true}
              onValueChange={({ floatValue }) =>
                handleChangeValues(
                  form.productionSubSystem,
                  "treatSixNonWorkingDayPercent",
                  floatValue > 100 ? 100 : floatValue
                )
              }
            />
          </td>
          <td className={isEditing ? "edit" : ""}>
            <NumberFormat
              value={form.treatSevenNonWorkingDayPercent || 0}
              suffix="%"
              disabled={!isEditing}
              allowNegative={false}
              decimalScale={0}
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale={true}
              onValueChange={({ floatValue }) =>
                handleChangeValues(
                  form.productionSubSystem,
                  "treatSevenNonWorkingDayPercent",
                  floatValue > 100 ? 100 : floatValue
                )
              }
            />
          </td>
          <td className={isEditing ? "edit" : ""}>
            <NumberFormat
              value={form.treatEightNonWorkingDayPercent || 0}
              suffix="%"
              disabled={!isEditing}
              allowNegative={false}
              decimalScale={0}
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale={true}
              onValueChange={({ floatValue }) =>
                handleChangeValues(
                  form.productionSubSystem,
                  "treatEightNonWorkingDayPercent",
                  floatValue > 100 ? 100 : floatValue
                )
              }
            />
          </td>
        </tr>
      </tbody>
    </FarmDayScaleTreatmentParameterTable>
  );
}

function FarmDayScaleTreatmentParameter() {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [intensiveForm, setIntensiveForm] = useState({
    ...DEFAULT_FORM_VALUES,
    productionSubSystem: "Intensive",
  });
  const [extensiveForm, setExtensiveForm] = useState({
    ...DEFAULT_FORM_VALUES,
    productionSubSystem: "Extensive",
  });
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const {
        data: { results },
      } = await findFarmDayScaleTreatmentParameterData({
        groupId,
        farmId,
      });
      const intensiveFormData = results.filter(
        (result) => result.productionSubSystem === "Intensive"
      )[0];
      const extensiveFormData = results.filter(
        (result) => result.productionSubSystem === "Extensive"
      )[0];
      setIntensiveForm(intensiveFormData);
      setExtensiveForm(extensiveFormData);
    } catch (error) {
      notification.error({
        message: "Erro ao pegar os parâmetros de Escala de Tratos",
      });
    } finally {
      setIsLoading(false);
    }
  }, [farmId, groupId]);

  const validateForm = useCallback((productionSubSystem, data) => {
    const keys = [
      "treatOne",
      "treatTwo",
      "treatThree",
      "treatFour",
      "treatFive",
      "treatSix",
      "treatSeven",
      "treatEight",
    ];
    const keysInMinutes = Object.keys(data).filter((keyName) =>
      keyName.includes("InMinutes")
    );
    const haveEntriesInMinutesWithData = Object.entries(data).some(
      ([key, value]) => keysInMinutes.includes(key) && value > 0
    );
    const entriesInMinutesWithData = Object.entries(data)
      .filter(([key, value]) => keysInMinutes.includes(key) && value > 0)
      .map(([key, value]) => key);
    const percentagesWithoutHour = Object.entries(data).filter(
      ([key, value]) => {
        const partKeyTreatNumber = key.includes("BusinessDayPercent")
          ? key.split("BusinessDayPercent")[0]
          : key.split("NonWorkingDayPercent")[0];
        const hasMinutesInformed = entriesInMinutesWithData.some((key) =>
          key.includes(partKeyTreatNumber)
        );
        return !hasMinutesInformed && value > 0;
      }
    );
    if (percentagesWithoutHour.length > 0) {
      throw new Error(
        `A tabela de ${
          productionSubSystem === "Extensive" ? "EXTENSIVO" : "INTENSIVO"
        } deve conter HORÁRIOS nos tratos informados porcentagem no dias ÚTEIS e NÃO ÚTEIS.`
      );
    }
    if (haveEntriesInMinutesWithData && entriesInMinutesWithData.length > 0) {
      let totalWorkingDays = 0;
      let totalNonWorkingDays = 0;
      entriesInMinutesWithData.forEach((key) => {
        const treatNumber = key.split("InMinutes")[0];
        const hourValue = data[treatNumber + "InHourAndMinutesFormat"];
        const workingDay = data[treatNumber + "BusinessDayPercent"];
        const nonWorkingDay = data[treatNumber + "NonWorkingDayPercent"];

        totalWorkingDays += workingDay;
        totalNonWorkingDays += nonWorkingDay;
        if (
          (workingDay === null || workingDay === 0) &&
          (nonWorkingDay === null || nonWorkingDay === 0)
        ) {
          throw new Error(
            `A tabela de ${
              productionSubSystem === "Extensive" ? "EXTENSIVO" : "INTENSIVO"
            } no trato ${
              TREAT_DESCRIPTION[treatNumber]
            } no horário ${hourValue} deve conter valores nos dias ÚTEIS ou NÃO ÚTEIS.`
          );
        }

        if (workingDay === null || workingDay === 0) {
          throw new Error(
            `Na tabela de ${
              productionSubSystem === "Extensive" ? "EXTENSIVO" : "INTENSIVO"
            } no trato ${
              TREAT_DESCRIPTION[treatNumber]
            } no horário ${hourValue} deve conter valores nos dias ÚTEIS.`
          );
        }
      });
      if (totalWorkingDays !== 100) {
        throw new Error(
          `A tabela de ${
            productionSubSystem === "Extensive" ? "EXTENSIVO" : "INTENSIVO"
          } deve completar 100% nos dias ÚTEIS.`
        );
      }
      if (totalNonWorkingDays !== 100) {
        throw new Error(
          `A tabela de ${
            productionSubSystem === "Extensive" ? "EXTENSIVO" : "INTENSIVO"
          } deve completar 100% nos dias NÃO ÚTEIS.`
        );
      }
      return true;
    } else {
      return false;
    }
  }, []);

  const handleChangeValues = useCallback(
    (productionSubSystem, field, value) => {
      let fieldValue = value;
      if (productionSubSystem === "Extensive") {
        if (field.includes("InMinutes")) {
          const [hourValue, minutesValue] = fieldValue.split(":").map(Number);
          const minutes = hourValue * 60 + minutesValue;
          setExtensiveForm((prevState) => ({
            ...prevState,
            [field]: minutes,
            [field.split("InMinutes")[0] + "InHourAndMinutesFormat"]:
              fieldValue,
          }));
        } else {
          setExtensiveForm((prevState) => ({
            ...prevState,
            [field]: fieldValue,
          }));
        }
      } else {
        if (field.includes("InMinutes")) {
          const [hourValue, minutesValue] = fieldValue.split(":").map(Number);
          const minutes = hourValue * 60 + minutesValue;
          setIntensiveForm((prevState) => ({
            ...prevState,
            [field]: minutes,
            [field.split("InMinutes")[0] + "InHourAndMinutesFormat"]:
              fieldValue,
          }));
        } else {
          setIntensiveForm((prevState) => ({
            ...prevState,
            [field]: fieldValue,
          }));
        }
      }
    },
    []
  );

  async function handleSubmit() {
    setIsLoading(true);
    let isExtensiveValid = false;
    let isIntensiveValid = false;
    // Validate intensive
    try {
      isIntensiveValid = validateForm(
        intensiveForm.productionSubSystem,
        intensiveForm
      );
    } catch (error) {
      Modal.error({
        centered: true,
        width: 600,
        content: <h2>{error.message}</h2>,
      });
      setIsLoading(false);
      return;
    }
    // Validate extensive
    try {
      isExtensiveValid = validateForm(
        extensiveForm.productionSubSystem,
        extensiveForm
      );
    } catch (error) {
      Modal.error({
        centered: true,
        width: 600,
        content: <h2>{error.message}</h2>,
      });
      setIsLoading(false);
      return;
    }

    try {
      if (isExtensiveValid) {
        await saveFarmDayScaleTreatmentParameterData({
          groupId,
          farmId,
          body: extensiveForm,
        });
      }
      if (isIntensiveValid) {
        await saveFarmDayScaleTreatmentParameterData({
          groupId,
          farmId,
          body: intensiveForm,
        });
      }
      notification.success({
        message: "Parâmetros atualizados",
      });
    } catch (error) {
      notification.error({
        message: "Não foi possível atualizar os parâmetros.",
      });
    }
    setIsLoading(false);
    setIsEditing(false);
  }

  function handleCancel() {
    setIsEditing(false);
    fetchData();
  }

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <CardCustom>
      <Spin spinning={isLoading}>
        <div className="body-container">
          <Row type="flex" justify="end" gutter={8}>
            {isEditing ? (
              <>
                <Col>
                  <ButtonStandard
                    type="submit"
                    name="buttonSaveFinancialTransactionsForm"
                    buttonType="principal"
                    onClick={handleSubmit}
                  >
                    {translation.buttons.save}
                  </ButtonStandard>
                </Col>
                <Col>
                  <ButtonStandard
                    name="buttonSaveFinancialTransactionsForm"
                    buttonType="secondary"
                    type="button"
                    onClick={handleCancel}
                  >
                    {translation.buttons.cancel}
                  </ButtonStandard>
                </Col>
              </>
            ) : (
              <Col>
                <EditButton
                  type="button"
                  onClick={() => setIsEditing((old) => !old)}
                  disabled={isEditing}
                >
                  {isEditing
                    ? `${translation.buttons.editing}...`
                    : `${translation.buttons.edit}`}
                  <PencilIcon />
                </EditButton>
              </Col>
            )}
          </Row>
          <Row type="flex">
            <Col className="body-container" span={24}>
              <Row>
                <FormTable
                  isEditing={isEditing}
                  form={intensiveForm}
                  handleChangeValues={handleChangeValues}
                />
              </Row>
              <Row>
                <FormTable
                  isEditing={isEditing}
                  form={extensiveForm}
                  handleChangeValues={handleChangeValues}
                />
              </Row>
            </Col>
          </Row>
        </div>
      </Spin>
    </CardCustom>
  );
}

export default FarmDayScaleTreatmentParameter;
