import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Icon,
  Input,
  Menu,
  Row,
  Select,
  Table,
  notification,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import MenuIcon from "../../../components/utils/table/icons/menu";
import { Container as MenuContainer } from "../../../components/utils/table/menu/styles";
import useTroughFeedingAssessmentContext from "../../../hooks/useTroughFeedingAssessmentContext";
import { destroyTroughFeedingAssementByReadingDateAndPicketId } from "../../../services/troughFeedingAssessmentService";

// import { Container } from './styles';

function TroughFeedingAssessmentTable() {
  const {
    tableProperties,
    dataList,
    isLoadingDataList,
    fetchData,
    handleTableChange,
  } = useTroughFeedingAssessmentContext();
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  const handleGetColumnDateSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Row type="flex" gutter={4}>
            <Col>
              <Select
                value={selectedKeys ? selectedKeys[1] : "equal"}
                onChange={(value) =>
                  setSelectedKeys(
                    value
                      ? selectedKeys
                        ? [selectedKeys[0], value]
                        : [null, value]
                      : selectedKeys
                      ? [selectedKeys[0], "equal"]
                      : [null, "equal"]
                  )
                }
              >
                <Select.Option value="equal">=</Select.Option>
                <Select.Option value="greaterThan">&gt;</Select.Option>
                <Select.Option value="greaterOrEqualThan">&ge;</Select.Option>
                <Select.Option value="lessThan">&lt;</Select.Option>
                <Select.Option value="lessOrEqualThan">&le;</Select.Option>
              </Select>
            </Col>
            <Col>
              <DatePicker
                value={selectedKeys && selectedKeys[0]}
                format={"DD/MM/YYYY"}
                onChange={(date) =>
                  setSelectedKeys(
                    date
                      ? selectedKeys && selectedKeys.length > 1
                        ? [date, selectedKeys[1]]
                        : [date, "equal"]
                      : []
                  )
                }
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
            </Col>
          </Row>

          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
    }),
    [handleReset, handleSearch]
  );

  const handleGetColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return record[dataIndex]
          ?.toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    }),
    [handleReset, handleSearch]
  );

  const handleDelete = useCallback(
    async (readingDate, picketId) => {
      try {
        await destroyTroughFeedingAssementByReadingDateAndPicketId({
          groupId,
          farmId,
          readingDate,
          picketId,
        });
        fetchData(tableProperties.tableSorters, tableProperties.search);
        notification.success({
          message: "A leitura de cocho foi deletada com sucesso!",
        });
      } catch (error) {
        notification.error({
          message: "Erro",
          description: "Erro ao deletar leitura de cocho. Contate o suporte",
        });
      } finally {
      }
    },
    [
      farmId,
      fetchData,
      groupId,
      tableProperties.search,
      tableProperties.tableSorters,
    ]
  );

  const menu = (id, record) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => handleDelete(record.readingDate, record.picketId)}
        >
          {translation.table.menu.delete}
        </Menu.Item>
      </Menu>
    );
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Table
      dataSource={dataList}
      loading={isLoadingDataList}
      pagination={false}
      rowKey={"id"}
      onChange={handleTableChange}
    >
      <Table.Column
        dataIndex="readingDate"
        title={translation.troughFeedingAssessment.table.columns.supplyDate}
        sorter={(first, second) => {
          return first.supplyDate.localeCompare(second.supplyDate, "pt-BR", {
            numeric: false,
            ignorePunctuation: true,
          });
        }}
        {...handleGetColumnDateSearchProps("readingDate")}
        render={(text) => moment(text).format("DD/MM/YYYY")}
      />
      <Table.Column
        dataIndex="lotName"
        title={translation.troughFeedingAssessment.table.columns.lotName}
        sorter
        {...handleGetColumnSearchProps("lotName")}
      />
      <Table.Column
        dataIndex="picketName"
        title={translation.troughFeedingAssessment.table.columns.picketName}
        sorter
        {...handleGetColumnSearchProps("picketName")}
      />
      <Table.Column
        dataIndex="amountAnimals"
        title={translation.troughFeedingAssessment.table.columns.totalAnimals}
      />
      <Table.Column
        dataIndex="dietStrategyName"
        title={
          translation.troughFeedingAssessment.table.columns.dietStrategyName
        }
        sorter
        {...handleGetColumnSearchProps("dietStrategyName")}
      />
      <Table.ColumnGroup
        title="Hoje -3d"
        children={[
          <Table.Column
            dataIndex="realQuantitySuppliedMinus3Day"
            title={
              translation.troughFeedingAssessment.table.columns.quantitySupplied
            }
            render={(text) =>
              text &&
              Number(text).toLocaleString("pt-BR", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                useGrouping: false,
              })
            }
          />,
          <Table.Column
            dataIndex="readingResultMinus3Day"
            title={translation.troughFeedingAssessment.table.columns.readValue}
          />,
        ]}
      />
      <Table.ColumnGroup
        title="Hoje -2d"
        children={[
          <Table.Column
            dataIndex="realQuantitySuppliedMinus2Day"
            title={
              translation.troughFeedingAssessment.table.columns.quantitySupplied
            }
            render={(text) =>
              text &&
              Number(text).toLocaleString("pt-BR", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                useGrouping: false,
              })
            }
          />,
          <Table.Column
            dataIndex="readingResultMinus2Day"
            title={translation.troughFeedingAssessment.table.columns.readValue}
          />,
        ]}
      />
      <Table.ColumnGroup
        title="Hoje -1d"
        children={[
          <Table.Column
            dataIndex="realQuantitySuppliedMinus1Day"
            title={
              translation.troughFeedingAssessment.table.columns.quantitySupplied
            }
            render={(text) =>
              text &&
              Number(text).toLocaleString("pt-BR", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                useGrouping: false,
              })
            }
          />,
          <Table.Column
            dataIndex="readingResultMinus1Day"
            title={translation.troughFeedingAssessment.table.columns.readValue}
          />,
        ]}
      />
      <Table.ColumnGroup
        title="Hoje"
        children={[
          <Table.Column
            dataIndex="realQuantitySupplied"
            title={
              translation.troughFeedingAssessment.table.columns.quantitySupplied
            }
            render={(text) =>
              text &&
              Number(text).toLocaleString("pt-BR", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                useGrouping: false,
              })
            }
          />,
          <Table.Column
            dataIndex="readingResult"
            title={translation.troughFeedingAssessment.table.columns.readValue}
          />,
        ]}
      />
      <Table.Column
        align="left"
        width={50}
        render={(text, record) => (
          <Dropdown
            overlay={menu(record.id, record)}
            trigger={["click"]}
            key={record.id}
          >
            <MenuContainer>
              <MenuIcon />
            </MenuContainer>
          </Dropdown>
        )}
      />
    </Table>
  );
}

export default TroughFeedingAssessmentTable;
