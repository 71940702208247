import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  Button,
  DatePicker,
  Icon,
  Input,
  InputNumber,
  Select,
  Table,
} from "antd";
import { TagStatus } from "../styles";

import {
  numberMask,
  getTwoDecimalDigits,
} from "../../../../../services/helpersMethodsService";
import useFarmAnimalSellContext from "../../../../../hooks/useFarmAnimalSellContext";

const { Column } = Table;

function FarmAnimalsTable() {
  const { translation } = useSelector((state) => state.app);
  const {
    pagination,
    updatePagination,
    selectedAnimalsKeys,
    allAnimals,
    isLoadingAnimals,
    updateSelectedAnimalsKeys,
  } = useFarmAnimalSellContext();

  const rowSelection = {
    selectedRowKeys: selectedAnimalsKeys.map((a) => a.animalId),
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      if (selected) {
        updateSelectedAnimalsKeys([
          ...selectedAnimalsKeys,
          { animalId: record.id, lotOriginalId: record.lotId },
        ]);
      } else {
        updateSelectedAnimalsKeys(
          selectedAnimalsKeys.filter((r) => r.animalId !== record.id)
        );
      }
    },
    onSelectAll: (record, selected, selectedRows, nativeEvent) => {
      // Get just the ids
      const keys = selectedRows.map((sr) => ({
        animalId: sr.id,
        lotOriginalId: sr.lotId,
      }));
      // Get all the selectedAnimalsKeys unless the unselected
      const lastSelectedAnimalsKeys = selectedAnimalsKeys.filter(
        (sAK) => !keys.map((k) => k.animalId).includes(sAK.animalId)
      );

      if (selected.length > 0) {
        updateSelectedAnimalsKeys([...selectedAnimalsKeys, ...keys]);
      } else {
        updateSelectedAnimalsKeys(lastSelectedAnimalsKeys);
      }
    },
  };

  // Methods
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          value={selectedKeys ? selectedKeys[0] : null}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8,
            background: "#684e94",
            borderColor: "none",
            border: "none",
          }}
        />
        <Button
          onClick={() => handleReset(clearFilters)}
          icon="delete"
          size="small"
          style={{ width: 90 }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  const getColumnWeightSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div style={{ display: "block" }}>
          <Select
            value={
              selectedKeys
                ? selectedKeys["type"] != null
                  ? selectedKeys["type"]
                  : "="
                : "="
            }
            style={{
              width: 90,
              textAlign: "center",
              marginBottom: 8,
              marginRight: 8,
            }}
            onChange={(value) => {
              setSelectedKeys(
                value ? { ...selectedKeys, type: value } : { ...selectedKeys }
              );
            }}
          >
            <Select.Option key="<" value="<" style={{ textAlign: "center" }}>
              &lt;
            </Select.Option>
            <Select.Option key="<=" value="<=" style={{ textAlign: "center" }}>
              &le;
            </Select.Option>
            <Select.Option key="=" value="=" style={{ textAlign: "center" }}>
              &#61;
            </Select.Option>
            <Select.Option key=">=" value=">=" style={{ textAlign: "center" }}>
              &ge;
            </Select.Option>
            <Select.Option key=">" value=">" style={{ textAlign: "center" }}>
              &gt;
            </Select.Option>
          </Select>
          {dataIndex === "age" ? (
            <Input
              type="number"
              onChange={(e) =>
                setSelectedKeys(
                  e.target.value
                    ? { ...selectedKeys, value: e.target.value }
                    : { ...selectedKeys }
                )
              }
              value={
                selectedKeys
                  ? selectedKeys["value"] != null
                    ? selectedKeys["value"]
                    : null
                  : null
              }
              onPressEnter={() => handleSearch(selectedKeys, confirm)}
              style={{ width: 90, marginBottom: 8 }}
            />
          ) : (
            <InputNumber
              value={
                selectedKeys
                  ? selectedKeys["value"] != null
                    ? selectedKeys["value"]
                    : null
                  : null
              }
              onChange={(e) =>
                setSelectedKeys(
                  e ? { ...selectedKeys, value: e } : { ...selectedKeys }
                )
              }
              onPressEnter={() => handleSearch(selectedKeys, confirm)}
              style={{ width: 90, marginBottom: 8 }}
              defaultValue={0}
              min={-1000}
              max={1000}
              step={0.01}
              formatter={(value) => {
                if (value !== "") {
                  value = `${value}`.replace(/^-^[^0-9.,]*/g, "");
                  value = value.replace(/\.{2,}/g, ".");
                  value = value.replace(/\.,/g, ",");
                  value = value.replace(/,\./g, ",");
                  value = value.replace(/,{2,}/g, ",");
                  value = value.replace(/\.[0-9]+\./g, ".");
                  value =
                    value.split(".")[1] !== "" &&
                    value.split(".")[1] !== undefined
                      ? value.split(".")[0] +
                        "." +
                        value.split(".")[1].substring(0, 2)
                      : value;
                }
                return value;
              }}
            />
          )}
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  const getDateColumnSearchProps = (dataIndex, columnPlaceHolder) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div style={{ display: "block" }}>
          <Select
            value={
              selectedKeys
                ? selectedKeys["type"] != null
                  ? selectedKeys["type"]
                  : "="
                : "="
            }
            style={{
              width: 70,
              textAlign: "center",
              marginBottom: 8,
              marginRight: 8,
            }}
            onChange={(value) => {
              setSelectedKeys(
                value ? { ...selectedKeys, type: value } : { ...selectedKeys }
              );
            }}
          >
            <Select.Option key="<" value="<" style={{ textAlign: "center" }}>
              &lt;
            </Select.Option>
            <Select.Option key="<=" value="<=" style={{ textAlign: "center" }}>
              &le;
            </Select.Option>
            <Select.Option key="=" value="=" style={{ textAlign: "center" }}>
              &#61;
            </Select.Option>
            <Select.Option key=">=" value=">=" style={{ textAlign: "center" }}>
              &ge;
            </Select.Option>
            <Select.Option key=">" value=">" style={{ textAlign: "center" }}>
              &gt;
            </Select.Option>
          </Select>
          <DatePicker
            value={
              selectedKeys
                ? selectedKeys["date"] != null
                  ? moment(selectedKeys["date"], "YYYY-MM-DD")
                  : null
                : null
            }
            placeholder={columnPlaceHolder}
            format="DD/MM/YYYY"
            onChange={(e) =>
              setSelectedKeys(
                e
                  ? { ...selectedKeys, date: e.format("YYYY-MM-DD") }
                  : { ...selectedKeys }
              )
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 110, marginBottom: 8 }}
          />
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const handleTableAnimalsChange = async (pagination, filters, sorter) => {
    let search = ``;

    if (sorter["field"]) {
      sorter["field"] =
        sorter["field"] === "lastDailyWeight"
          ? "last_daily_weight"
          : sorter["field"] === "lastDailyWeightGain"
          ? "last_daily_weight_gain"
          : sorter["field"] === "lastDailyWeightDate"
          ? "last_daily_weight_date"
          : sorter["field"] === "lotName"
          ? "lot_name"
          : sorter["field"] === "breedName"
          ? "breed_name"
          : sorter["field"] === "tagId"
          ? "tag_id"
          : sorter["field"] === "sisbov"
          ? "sisbov"
          : sorter["field"] === "handlingNumber"
          ? "handling_number"
          : sorter["field"];
    }

    if (Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([k, v]) => {
        if (v && (v.length > 0 || Object.keys(v)?.length > 0)) {
          if (k === "age") {
            let operator = v.type ? v.type : "=";
            operator = operator.replace("<", "%3C").replace(">", "%3E");
            if (operator === "=") {
              search =
                search === "" || search === null
                  ? `age = ${v.value}`
                  : `${search};age = ${v.value}`;
            } else if (operator === "%3C=") {
              const max_value = parseFloat(v.value) + 0.4999999;
              search =
                search === "" || search === null
                  ? `age ${operator} ${max_value}`
                  : `${search};age ${operator} ${max_value}`;
            } else if (operator === "%3E=") {
              const min_value = parseFloat(v.value) - 0.5;
              search =
                search === "" || search === null
                  ? `age ${operator} ${min_value}`
                  : `${search};age ${operator} ${min_value}`;
            } else {
              search =
                search === "" || search === null
                  ? `age${operator}'${v.value}'`
                  : `${search};age${operator}'${v.value}'`;
            }
          } else if (k === "lastDailyWeight") {
            let operator = v.type ? v.type : "=";
            operator = operator.replace("<", "%3C").replace(">", "%3E");
            if (operator === "=") {
              search =
                search === "" || search === null
                  ? `last_daily_weight = ${v.value}`
                  : `${search};last_daily_weight = ${v.value}`;
            } else if (operator === "%3C=") {
              const max_value = parseFloat(v.value) + 0.4999999;
              search =
                search === "" || search === null
                  ? `last_daily_weight ${operator} ${max_value}`
                  : `${search};last_daily_weight ${operator} ${max_value}`;
            } else if (operator === "%3E=") {
              const min_value = parseFloat(v.value) - 0.5;
              search =
                search === "" || search === null
                  ? `last_daily_weight ${operator} ${min_value}`
                  : `${search};last_daily_weight ${operator} ${min_value}`;
            } else {
              search =
                search === "" || search === null
                  ? `last_daily_weight${operator}'${v.value}'`
                  : `${search};last_daily_weight${operator}'${v.value}'`;
            }
          } else if (k === "lastDailyWeightGain") {
            let operator = v.type ? v.type : "=";
            operator = operator.replace("<", "%3C").replace(">", "%3E");

            if (operator === "=") {
              search =
                search === "" || search === null
                  ? `last_daily_weight_gain = ${v.value}`
                  : `${search};last_daily_weight_gain = ${v.value}`;
            } else if (operator === "%3C=") {
              const max_value = parseFloat(v.value) + 0.004999999;
              search =
                search === "" || search === null
                  ? `last_daily_weight_gain ${operator} ${max_value}`
                  : `${search};last_daily_weight_gain ${operator} ${max_value}`;
            } else if (operator === "%3E=") {
              const min_value = parseFloat(v.value) - 0.005;
              search =
                search === "" || search === null
                  ? `last_daily_weight_gain ${operator} ${min_value}`
                  : `${search};last_daily_weight_gain ${operator} ${min_value}`;
            } else {
              search =
                search === "" || search === null
                  ? `last_daily_weight_gain${operator}'${v.value}'`
                  : `${search};last_daily_weight_gain${operator}'${v.value}';`;
            }
          } else if (k === "lastDailyWeightDate") {
            let operator = v.type ? v.type : "=";
            operator = operator.replace("<", "%3C").replace(">", "%3E");
            search =
              search === "" || search === null
                ? `last_daily_weight_date${operator}'${v["date"]}'`
                : `${search};last_daily_weight_date${operator}'${v["date"]}';`;
          } else if (k === "currentWeightRanking") {
            search =
              search === "" || search === null
                ? `current_weight_ranking = '${v}'`
                : `${search};current_weight_ranking = '${v}'`;
          } else if (k === "lotName") {
            search =
              search === "" || search === null
                ? `lower(lot_name) like lower('%25${v}%25')`
                : `${search};lower(lot_name) like lower('%25${v}%25')`;
          } else if (k === "breedName") {
            search =
              search === "" || search === null
                ? `lower(breed_name) like lower('%25${v}%25')`
                : `${search};lower(breed_name) like lower('%25${v}%25')`;
          } else if (k === "handlingNumber") {
            search =
              search === "" || search === null
                ? `lower(handling_number) like lower('%25${v}%25')`
                : `${search};lower(handling_number) like lower('%25${v}%25')`;
          } else if (k === "tagId") {
            search =
              search === "" || search === null
                ? `lower(tag_id) like lower('%25${v}%25')`
                : `${search};lower(tag_id) like lower('%25${v}%25')`;
          } else if (k === "sisbov") {
            search =
              search === "" || search === null
                ? `lower(sisbov::text) like lower('%25${v}%25')`
                : `${search};lower(sisbov::text) like lower('%25${v}%25')`;
          } else {
            search =
              search === "" || search === null
                ? `${k}='${v}'`
                : `${search};${k}='${v}'`;
          }
        }
      });
    }

    updatePagination({
      page: pagination.current,
      size: pagination.pageSize,
      sorter: sorter,
      filters: search,
      tableFilters: filters,
      tableSorters: sorter,
    });
  };

  return (
    <Table
      key="dismemberLotAnimals"
      rowKey="id"
      loading={isLoadingAnimals}
      dataSource={allAnimals != null ? allAnimals.content : []}
      pagination={{
        total: allAnimals != null ? allAnimals.totalElements : 0,
        size: allAnimals != null ? pagination.size : 0,
        current: allAnimals != null ? pagination.page : 0,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
        hideOnSinglePage:
          allAnimals !== null && Object.entries(allAnimals).length !== 0
            ? allAnimals.totalElements > 10
              ? false
              : true
            : true,
      }}
      scroll={{ x: true }}
      size="small"
      rowSelection={rowSelection}
      onChange={handleTableAnimalsChange}
      footer={() => (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>
            <strong>Total de animais filtrados: </strong>
            {` ${allAnimals != null ? allAnimals.totalElements : 0} animais`}
          </span>
          <span>
            <strong>Total de animais selecionados: </strong>
            {` ${
              selectedAnimalsKeys != null ? selectedAnimalsKeys.length : 0
            } animais`}
          </span>
        </div>
      )}
    >
      <Column
        title={translation.animal.columns.handlingNumber}
        dataIndex="handlingNumber"
        sorter
        key="handlingNumber"
        filteredValue={pagination.tableFilters?.handlingNumber || null}
        sortOrder={
          pagination.sorter?.columnKey === "handlingNumber" &&
          pagination.sorter?.order
        }
        {...getColumnSearchProps("handlingNumber")}
      />
      <Column
        title={translation.animal.columns.tagId}
        dataIndex="tagId"
        sorter
        key="tagId"
        filteredValue={pagination.tableFilters?.tagId || null}
        sortOrder={
          pagination.sorter?.columnKey === "tagId" && pagination.sorter?.order
        }
        {...getColumnSearchProps("tagId")}
      />
      <Column
        title={translation.animal.columns.sisbov}
        dataIndex="sisbov"
        sorter
        key="sisbov"
        filteredValue={pagination.tableFilters?.sisbov || null}
        sortOrder={
          pagination.sorter?.columnKey === "sisbov" && pagination.sorter?.order
        }
        {...getColumnSearchProps("sisbov")}
      />
      <Column
        title={translation.animal.columns.lot}
        dataIndex="lotName"
        sorter
        key="lotName"
        filteredValue={pagination.tableFilters?.lotName || null}
        sortOrder={
          pagination.sorter?.columnKey === "lotName" && pagination.sorter?.order
        }
        {...getColumnSearchProps("lotName")}
      />
      <Column
        title={translation.animal.columns.breed}
        dataIndex="breedName"
        key="breedName"
        sorter
        filteredValue={pagination.tableFilters?.breedName || null}
        sortOrder={
          pagination.sorter?.columnKey === "breedName" &&
          pagination.sorter?.order
        }
        {...getColumnSearchProps("breedName")}
      />
      <Column
        title={translation.animal.columns.gender}
        dataIndex="gender"
        key="gender"
        filteredValue={pagination.tableFilters?.gender || null}
        sortOrder={
          pagination.sorter?.columnKey === "gender" && pagination.sorter?.order
        }
        filtered
        filters={[
          {
            text: translation.animal.details.male,
            value: "M",
          },
          {
            text: translation.animal.details.female,
            value: "F",
          },
        ]}
        filterMultiple={false}
        sorter
        sortDirections={["descend", "ascend"]}
        render={(text, record) => (
          <span>
            {record.gender === "M"
              ? translation.animal.details.male
              : translation.animal.details.female}
          </span>
        )}
      />
      <Column
        title="Idade"
        dataIndex="age"
        key="age"
        filteredValue={pagination.tableFilters?.age || null}
        sorter
        sortOrder={
          pagination.sorter?.columnKey === "age" && pagination.sorter?.order
        }
        {...getColumnWeightSearchProps("age")}
        sortDirections={["descend", "ascend"]}
        render={(text, record) =>
          record.age !== null ? <span>{`${record.age} m`}</span> : ""
        }
      />
      <Column
        title={translation.animal.columns.currentWeight}
        dataIndex="lastDailyWeight"
        key="lastDailyWeight"
        sorter
        filteredValue={pagination.tableFilters?.lastDailyWeight || null}
        sortOrder={
          pagination.sorter?.columnKey === "lastDailyWeight" &&
          pagination.sorter?.order
        }
        {...getColumnWeightSearchProps("lastDailyWeight")}
        sortDirections={["descend", "ascend"]}
        render={(text, record) => (
          <span>{`${
            record.lastDailyWeight != null
              ? record.lastDailyWeight.toFixed(2)
              : 0
          } Kg`}</span>
        )}
      />
      <Column
        title={translation.animal.columns.gdp}
        dataIndex="lastDailyWeightGain"
        key="lastDailyWeightGain"
        sorter
        sortDirections={["descend", "ascend"]}
        filteredValue={pagination.tableFilters?.lastDailyWeightGain || null}
        sortOrder={
          pagination.sorter?.columnKey === "lastDailyWeightGain" &&
          pagination.sorter?.order
        }
        {...getColumnWeightSearchProps("lastDailyWeightGain")}
        render={(text, record) => (
          <span>{`${
            record.lastDailyWeightGain != null
              ? numberMask(
                  getTwoDecimalDigits(record.lastDailyWeightGain || 0),
                  false
                )
              : 0
          } Kg`}</span>
        )}
      />
      <Column
        title={translation.animal.columns.lastDailyWeightDate}
        dataIndex="lastDailyWeightDate"
        sorter
        key="lastDailyWeightDate"
        filteredValue={pagination.tableFilters?.lastDailyWeightDate || null}
        sortOrder={
          pagination.sorter?.columnKey === "lastDailyWeightDate" &&
          pagination.sorter?.order
        }
        {...getDateColumnSearchProps("lastDailyWeightDate", "Data")}
        render={(date) =>
          date != null
            ? `${date.split("-")[2]}/${date.split("-")[1]}/${
                date.split("-")[0]
              }`
            : "-"
        }
      />
      <Column
        title={translation.animal.columns.weightRanking}
        dataIndex="currentWeightRanking"
        key="currentWeightRanking"
        align="center"
        width={150}
        filtered
        filters={[
          {
            text: "Cabeceira",
            value: "top",
          },
          {
            text: "Meio",
            value: "medium",
          },
          {
            text: "Fundo",
            value: "worst",
          },
        ]}
        filterMultiple={false}
        filteredValue={pagination.tableFilters?.currentWeightRanking || null}
        sortOrder={
          pagination.sorter?.columnKey === "currentWeightRanking" &&
          pagination.sorter?.order
        }
        render={(text, record) =>
          record.currentWeightRanking !== null ? (
            record.currentWeightRanking === "top" ? (
              <TagStatus
                background="#D8FFF2"
                borderColor="#99C9B9"
                color="#99C9B9"
              >
                {translation.weightRanking.top}
              </TagStatus>
            ) : record.currentWeightRanking === "medium" ? (
              <TagStatus
                background="#E4F7FF"
                borderColor="#99BBC9"
                color="#99BBC9"
              >
                {translation.weightRanking.middle}
              </TagStatus>
            ) : (
              record.currentWeightRanking === "worst" && (
                <TagStatus
                  background="#FFDFDF"
                  borderColor="#E88D8D"
                  color="#E88D8D"
                >
                  {translation.weightRanking.bottom}
                </TagStatus>
              )
            )
          ) : null
        }
      />
    </Table>
  );
}

export default FarmAnimalsTable;
