import { Drawer } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as AppActions } from "../../../store/ducks/app";
import { DrawerArrobaPriceContainer } from "./styles";
import MarketAnalysisDash from "./components/marketAnalysisDash";
import DataAgro from "./components/dataAgro";

// import { Container } from './styles';

function DrawerArrobaPrice() {
  const { translation, isDrawerArrobaPriceVisible } = useSelector(
    (state) => state.app
  );
  const dispatch = useDispatch();
  return (
    <Drawer
      width={700}
      title={translation.arrobaPrice.title}
      visible={isDrawerArrobaPriceVisible}
      onClose={() => dispatch(AppActions.showOrHideDrawerArrobaPrice())}
    >
      <DrawerArrobaPriceContainer>
        <MarketAnalysisDash />
        <DataAgro />
      </DrawerArrobaPriceContainer>
    </Drawer>
  );
}

export default DrawerArrobaPrice;
