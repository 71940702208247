import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-width: 100%;
  height: 100%;

  .title-sub-2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Asap", sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #684e94;
    margin: 13px 0px 17px 15px;
    text-transform: uppercase;

    svg {
      width: 18px;
      height: 18px;
      path {
        fill: #684e94 !important;
      }
    }

    span {
      cursor: pointer;
    }
  }
`;

export const CardContainerAlert = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20vh;
  width: 100%;
  min-height: 560px;
  background: #fcfcfc;
  border-radius: 6px;
  box-shadow: 0px 3px 6px #d8d8d8;
  margin-left: 15px;
  margin-bottom: 12px;

  p {
    display: inline-block;
    margin-bottom: 0;
  }

  img {
    margin: 0px auto;
  }

  .title-1 {
    display: block;
    margin: 8px 15px;
    color: #a5a5a5;
    font-size: 14px;
  }

  .title-2 {
    display: block;
    margin: 8px 18px 0px 18px;
    color: #9074bf;
    font-weight: bold;
    font-size: 14px;
  }

  .sub-title {
    display: block;
    color: #a5a5a5;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
  }

  .text-purple {
    margin-left: 10px;
    padding-bottom: 6px;
    color: #684e94 !important;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
  }

  .text-red {
    /* margin-left: 105px; */
    margin-right: 13px;
    padding-bottom: 6px;
    color: #d44c4c !important;
    text-align: center;
  }

  .flex-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  @media screen and (max-width: 769px) {
    /* start of medium tablet styles */
    margin-left: 0;
  }
`;

export const CardItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  width: 100%;
  padding: 3px 15px;
  border-bottom: 1px solid #f5f5f5;
  color: black;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }

  span {
    margin-left: 5px;
    font-size: 12px;
  }

  svg {
    height: 20px;
    width: 30px;
    path {
      fill: #a5a5a5 !important;
    }
  }

  .failed {
    height: 6px;
    width: 6px;
  }

  .ant-tooltip {
    max-width: 400px !important;
  }

  .new {
    color: #c4c4c4;
    font-style: italic;
    font-size: 13px;
  }
`;

export const NotificationContainerItem = styled.ul`
  border: none;
  background: transparent;
  border: 1px solid #e5e5e5;
  overflow-y: auto;
  font-family: Asap;
  font-size: 14px;
  color: #4b4b4b;
  border-bottom: 1px solid #e5e5e5;
  margin-top: 13px;
  margin-bottom: 16px;
`;

export const NotificationItem = styled.li`
  width: 100%;
  height: 100% !important;
  background: #f5f5f5;
  flex-direction: column !important;
  border-bottom: 1px solid #e5e5e5;
  padding: 12px 5px 12px 10px !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  font-family: Asap;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }

  &.read {
    background: #fff;
    cursor: not-allowed !important;
  }
  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &:last-child {
    border-bottom: none;
  }
  &.footer {
    border: none;
    justify-content: center !important;
    align-items: center !important;
    text-transform: uppercase;
    border-top: 1px solid #e5e5e5;
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #684e94;

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    a {
      color: #684e94;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 14px !important;

      &:hover {
        color: #684e94;
        text-decoration: underline;
      }
    }
  }

  .ant-row-flex {
    width: 100% !important;
  }
  p {
    margin: 0 !important;
  }
  div.icon {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span.time {
    font-family: Asap;
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    color: #707070;
  }

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;

export const NotificationType = styled.span`
  font-family: Asap;
  font-weight: bold;
  font-size: 12px;
  text-align: left;
  color: ${(props) =>
    props.notificationType === "Sale"
      ? "#E86969"
      : props.notificationType === "Purchase"
      ? "#E86969"
      : props.notificationType === "Warning"
      ? "#684E94"
      : props.notificationType === "NewObject"
      ? "#4A85AE"
      : props.notificationType === "Alert"
      ? "#FE8D2A"
      : "#4b4b4b"};
`;

export const ButtonReadAllNotifications = styled.button`
  position: relative;
  font-family: Asap;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #684e94;
  background: transparent;
  border: none;

  span.slider {
    position: absolute;
    display: block;
    left: 0;
    top: 90%;
    margin: 0 auto;
    height: 2px;
    background-color: #684e94;
    width: 0%;
    transition: width 1s ease;
  }
  &:hover {
    cursor: pointer;

    span.slider {
      width: 100%;
    }
  }
  &:disabled {
    display: none;
  }

  svg {
    margin-right: 6px;
  }
`;
