import { Drawer } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as AppActions } from "../../../store/ducks/app";
import ClimaticPanel from "./climaticPanel";

// import { Container } from './styles';

function DrawerWeather() {
  const { translation, isDrawerWeatherVisible } = useSelector(
    (state) => state.app
  );
  const dispatch = useDispatch();
  return (
    <Drawer
      width={700}
      title={translation.weather.title}
      visible={isDrawerWeatherVisible}
      onClose={() => dispatch(AppActions.showOrHideDrawerWeather())}
    >
      <ClimaticPanel />
    </Drawer>
  );
}

export default DrawerWeather;
