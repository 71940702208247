import axios from "axios";
import apiFinancial from "../config/api_financial";
// import axios from "axios";
import getHeaders from "./getHeaders";

// const apiFinancial = axios.create({
//   baseURL: "http://localhost:8086",
// });

export function findAllTransferAnimalTransactions(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/transfer/animal/transactions`,
    getHeaders(false, signal)
  );
}

export function getAnimalsTransferAnimalTransactions(payload) {
  const { groupId, farmId, id, search, page, size, sort, signal } = payload;

  const orderS =
    Object.entries(sort).length > 0 && sort.order
      ? sort.order === "descend"
        ? "DESC"
        : sort.order === "ascend"
        ? "ASC"
        : "DESC"
      : "DESC";
  const requestParams = `page=${page > 0 ? page - 1 : 0}&size=${size ?? 10}${
    sort && Object.entries(sort).length > 0 && sort.order
      ? `&sort=${sort.field}&direction=${orderS}`
      : ""
  }${search && search !== "" ? `&search=${search}` : ""}`;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/transfer/animal/transactions/${id}/animals?${requestParams}`,
    getHeaders(false, signal)
  );
}

export function confirmTransferAnimalTransactions(payload) {
  const { groupId, farmId, id, body, signal } = payload;

  return apiFinancial.post(
    `/bovexo/${groupId}/farms/${farmId}/transfer/animal/transactions/${id}/confirm`,
    body,
    getHeaders(false, signal)
  );
}

export function rejectTransferAnimalTransactions(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiFinancial.post(
    `/bovexo/${groupId}/farms/${farmId}/transfer/animal/transactions/${id}/reject`,
    null,
    getHeaders(false, signal)
  );
}
