import { Col, Divider, Modal, Row, Typography } from "antd";
import moment from "moment";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { numberMask } from "../../../services/helpersMethodsService";
import { Container } from "./styles";
import ViewTransferAnimalTransactionAnimalTable from "./table";

// import { Container } from './styles';

function ViewTransferAnimalTransactionModal({
  transferAnimalTransactionData,
  modalVisible,
  closeModal,
}) {
  const { translation } = useSelector((state) => state.app);

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <Modal
      visible={modalVisible}
      width={700}
      centered
      closable={true}
      maskClosable={false}
      onCancel={handleClose}
      footer={null}
      title={translation.viewTransferAnimalTransactionModal.title}
    >
      <Container>
        <div className="body">
          <Row type="flex">
            <Col span={24}>
              <Row>
                <label>
                  {translation.viewTransferAnimalTransactionModal.form.origin}
                </label>
              </Row>
              <Row>
                <Typography.Text strong>
                  {transferAnimalTransactionData?.originGroupName !==
                  transferAnimalTransactionData?.destinationGroupName
                    ? `${transferAnimalTransactionData?.originGroupName} / ${transferAnimalTransactionData?.originFarmName}`
                    : transferAnimalTransactionData?.originFarmName}
                </Typography.Text>
              </Row>
            </Col>
          </Row>
          {transferAnimalTransactionData?.financialCompensation ? (
            <>
              <Row type="flex" gutter={8}>
                <Col span={6}>
                  <Row>
                    <label>
                      {
                        translation.viewTransferAnimalTransactionModal.form
                          .amountAnimals
                      }
                    </label>
                  </Row>
                  <Row>
                    <Typography.Text strong>
                      {`${transferAnimalTransactionData?.amountAnimals}`}
                    </Typography.Text>
                  </Row>
                </Col>
                <Col span={6}>
                  <Row>
                    <label>
                      {
                        translation.viewTransferAnimalTransactionModal.form
                          .sellDate
                      }
                    </label>
                  </Row>
                  <Row>
                    <Typography.Text strong>
                      {`${moment(
                        transferAnimalTransactionData?.sellDate
                      ).format("DD/MM/YYYY")}`}
                    </Typography.Text>
                  </Row>
                </Col>
                <Col span={6}>
                  <Row>
                    <label>
                      {
                        translation.viewTransferAnimalTransactionModal.form
                          .animalValue
                      }
                    </label>
                  </Row>
                  <Row>
                    <Typography.Text strong>
                      {`${numberMask(
                        transferAnimalTransactionData?.priceNegotiated || 0,
                        true
                      )}/cab`}
                    </Typography.Text>
                  </Row>
                </Col>
                <Col span={6}>
                  <Row>
                    <label>
                      {
                        translation.viewTransferAnimalTransactionModal.form
                          .totalValue
                      }
                    </label>
                  </Row>
                  <Row>
                    <Typography.Text strong>
                      {`${numberMask(
                        transferAnimalTransactionData?.totalValue || 0,
                        true
                      )}`}
                    </Typography.Text>
                  </Row>
                </Col>
              </Row>
              <Row type="flex" gutter={8}>
                <Col span={8}>
                  <Row>
                    <label>
                      {
                        translation.viewTransferAnimalTransactionModal.form
                          .totalInstallments
                      }
                    </label>
                  </Row>
                  <Row>
                    <Typography.Text strong>
                      {`${transferAnimalTransactionData.installmentTotal}`}
                    </Typography.Text>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row>
                    <label>
                      {
                        translation.viewTransferAnimalTransactionModal.form
                          .firstInstallmentDate
                      }
                    </label>
                  </Row>
                  <Row>
                    <Typography.Text strong>
                      {`${moment(
                        transferAnimalTransactionData?.firstInstallmentDate
                      ).format("DD/MM/YYYY")}`}
                    </Typography.Text>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row>
                    <label>
                      {
                        translation.viewTransferAnimalTransactionModal.form
                          .firstInstallmentValue
                      }
                    </label>
                  </Row>
                  <Row>
                    <Typography.Text strong>
                      {`${numberMask(
                        transferAnimalTransactionData?.firstInstallmentValue ||
                          0,
                        true
                      )}`}
                    </Typography.Text>
                  </Row>
                </Col>
              </Row>
            </>
          ) : (
            <Row type="flex" justify="center" gutter={8}>
              <Col span={6}>
                <Row>
                  <label>
                    {
                      translation.viewTransferAnimalTransactionModal.form
                        .amountAnimals
                    }
                  </label>
                </Row>
                <Row>
                  <Typography.Text strong>
                    {`${transferAnimalTransactionData?.amountAnimals}`}
                  </Typography.Text>
                </Row>
              </Col>
              <Col span={6}>
                <Row>
                  <label>
                    {
                      translation.viewTransferAnimalTransactionModal.form
                        .sellDate
                    }
                  </label>
                </Row>
                <Row>
                  <Typography.Text strong>
                    {`${moment(transferAnimalTransactionData?.sellDate).format(
                      "DD/MM/YYYY"
                    )}`}
                  </Typography.Text>
                </Row>
              </Col>
            </Row>
          )}
          <Divider />
          <ViewTransferAnimalTransactionAnimalTable
            transferAnimalTransactionId={transferAnimalTransactionData?.id}
          />
        </div>
      </Container>
    </Modal>
  );
}

export default ViewTransferAnimalTransactionModal;
