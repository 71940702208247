import React from "react";
import { Route, Switch } from "react-router-dom";
import AllowedRouteTo from "../components/utils/allowedRouteTo";

// Pages
import Error404 from "../pages/404";
import Animal from "../pages/animal";
import AnimalDetail from "../pages/animal/details";
import AnimalFarmReproductiveProtocolForm from "../pages/animalFarmReproductiveProtocol/form";
import BoitelPriceDetails from "../pages/boitelPrice/details";
import BoitelPriceEdit from "../pages/boitelPrice/edit";
import DetailsBoitelSaleScenario from "../pages/boitelSaleScenario/details";
import EditBoitelSaleScenario from "../pages/boitelSaleScenario/edit";
import NewBoitelSaleScenario from "../pages/boitelSaleScenario/new";
import BoitelSaleScenarioFarmSellDetails from "../pages/boitelSaleScenarioFarmSell/details";
import NewBoitelSaleScenarioFarmSell from "../pages/boitelSaleScenarioFarmSell/new";
import NewEditBoitelScenario from "../pages/boitelScenario/newEdit";
import Breed from "../pages/breed";
import ClosedSalesReport from "../pages/closedSalesReport";
import Dashboard from "../pages/dashboard";
import Diet from "../pages/diet";
import DietStrategy from "../pages/dietStrategy";
import NewDietStrategy from "../pages/dietStrategy/new";
import Farm from "../pages/farm";
import FarmSellDetails from "../pages/farmSell/details";
import NewFarmSell from "../pages/farmSell/new";
import Financial from "../pages/financial";
import EditFarmProductionSell from "../pages/financial/tabs/farmProductionSell/edit";
import NewFarmProductionSell from "../pages/financial/tabs/farmProductionSell/new";
import Import from "../pages/import";
import ImportAnimalDetails from "../pages/import/animal/details";
import SupplementImportDetails from "../pages/import/supplement/details";
import ImportAnimalWeightDetails from "../pages/import/weight/details";
import Lot from "../pages/lot";
import AddAnimalsInLot from "../pages/lot/addAnimalsInLot";
import CompareLots from "../pages/lot/compareLots";
import LotDetails from "../pages/lot/details";
import DismemberLot from "../pages/lot/dismemberLot";
import Management from "../pages/management";
import MarketAnalysis from "../pages/marketAnalysis";
import NewEditFuturePrice from "../pages/marketAnalysis/futurePrice/newEdit";
import Parameter from "../pages/parameter";
import Pasture from "../pages/pasture";
import Payments from "../pages/payments";
import Picket from "../pages/picket";
import ListPurchaseScenario from "../pages/purchaseScenario";
import PurchaseScenarioDetails from "../pages/purchaseScenario/details";
import NewEditPurchaseScenario from "../pages/purchaseScenario/newEdit";
import ListSaleScenario from "../pages/saleScenario";
import CompareSaleScenario from "../pages/saleScenario/compare";
import SaleScenarioDetails from "../pages/saleScenario/details";
import NewSaleScenario from "../pages/saleScenario/new";
import Supplement from "../pages/supplement";
import Supplier from "../pages/supplier";
import EditSupplierManagement from "../pages/supplierManagement/edit";
import NewSupplierManagement from "../pages/supplierManagement/new";
import updateTrialToContract from "../pages/updateTrialToContract";
import AnimalWeightHistory from "../pages/animalWeightHistory";
import ListBoitelSaleScenario from "../pages/boitelSaleScenario";
import ListBoitelScenario from "../pages/boitelScenario";
import DetailsFarmProductionSell from "../pages/financial/tabs/farmProductionSell/details";
import HandlingReproduction from "../pages/handlingReproduction";
import FinancialTransactionImportDetails from "../pages/import/financialTransaction/details";
import PicketSupplementSupply from "../pages/picketSupplementSupply";
import TroughFeedingAssessment from "../pages/troughFeedingAssessment";
import Vaccinations from "../pages/vaccinations";

// Providers
import { AnimalFarmReproductiveProtocolContextProvider } from "../contexts/animalFarmReproductiveProtocolContext";
import { CurrencyContextProvider } from "../contexts/currencyContext";
import { MenuNavigationContextProvider } from "../contexts/menuNavigationContext";
import { RelocatePicketProvider } from "../contexts/relocatePicketContext";
import { UnitContextProvider } from "../contexts/unitContext";

const RoutesAdmin = () => {
  return (
    <RelocatePicketProvider>
      <UnitContextProvider>
        <MenuNavigationContextProvider>
          <AnimalFarmReproductiveProtocolContextProvider>
            <CurrencyContextProvider>
              <Switch>
                <Route path="/admin" exact component={Dashboard} />
                <AllowedRouteTo path="/admin/lots" exact component={Lot} />
                <AllowedRouteTo
                  path="/admin/lots/compare"
                  exact
                  component={CompareLots}
                />
                <AllowedRouteTo
                  path="/admin/lots/:id"
                  exact
                  component={LotDetails}
                />
                <AllowedRouteTo
                  path="/admin/lots/:id/add/animals"
                  exact
                  component={AddAnimalsInLot}
                />
                <AllowedRouteTo
                  path="/admin/lots/:id/dismember"
                  exact
                  component={DismemberLot}
                />
                <AllowedRouteTo
                  path="/admin/pickets"
                  exact
                  component={Picket}
                />
                <AllowedRouteTo
                  path="/admin/animals"
                  exact
                  component={Animal}
                />
                <AllowedRouteTo path="/admin/breeds" exact component={Breed} />
                <AllowedRouteTo
                  path="/admin/animals/:id"
                  exact
                  component={AnimalDetail}
                />
                <AllowedRouteTo path="/admin/diets" exact component={Diet} />
                <AllowedRouteTo
                  path="/admin/managements/diets/strategies/new"
                  exact
                  component={NewDietStrategy}
                />
                <AllowedRouteTo
                  path="/admin/managements/diets/strategies/edit/:id"
                  exact
                  component={NewDietStrategy}
                />
                <AllowedRouteTo
                  path="/admin/supplements"
                  exact
                  component={Supplement}
                />
                <AllowedRouteTo
                  path="/admin/marketAnalysis"
                  exact
                  component={MarketAnalysis}
                />
                <AllowedRouteTo
                  path="/admin/marketAnalysis/new"
                  exact
                  component={NewEditFuturePrice}
                />
                <AllowedRouteTo
                  path="/admin/marketAnalysis/:id/edit"
                  exact
                  component={NewEditFuturePrice}
                />
                <AllowedRouteTo
                  path="/admin/pastures"
                  exact
                  component={Pasture}
                />
                <AllowedRouteTo path="/admin/farms" component={Farm} />
                <AllowedRouteTo path="/admin/suppliers" component={Supplier} />
                <AllowedRouteTo
                  path="/admin/parameters"
                  component={Parameter}
                />
                <AllowedRouteTo
                  path="/admin/imports"
                  exact
                  component={Import}
                />
                <AllowedRouteTo
                  path="/admin/imports/animals/:id/details"
                  exact
                  component={ImportAnimalDetails}
                />
                <AllowedRouteTo
                  path="/admin/imports/weights/:id/details"
                  exact
                  component={ImportAnimalWeightDetails}
                />
                <AllowedRouteTo
                  path="/admin/imports/supplements/:id/details"
                  exact
                  component={SupplementImportDetails}
                />
                <AllowedRouteTo
                  path="/admin/imports/financial/transactions/:id/details"
                  exact
                  component={FinancialTransactionImportDetails}
                />
                <AllowedRouteTo
                  path="/admin/administrations"
                  component={Management}
                />
                <AllowedRouteTo
                  path="/admin/decisions/scenarios/sales/new"
                  exact
                  component={NewSaleScenario}
                />
                <AllowedRouteTo
                  path="/admin/decisions/scenarios/sales/compare"
                  exact
                  component={CompareSaleScenario}
                />
                <AllowedRouteTo
                  path="/admin/decisions/scenarios/sales/:id"
                  exact
                  component={SaleScenarioDetails}
                />
                <AllowedRouteTo
                  path="/admin/decisions/scenarios/sales/:id/results/:resultId"
                  exact
                  component={SaleScenarioDetails}
                />
                <AllowedRouteTo
                  path="/admin/decisions/scenarios/sales/:saleScenarioId/results/:saleScenarioResultId/farms/sales/new"
                  exact
                  component={NewFarmSell}
                />
                <AllowedRouteTo
                  path="/admin/decisions/scenarios/sales/:saleScenarioId/results/:saleScenarioResultId/farms/sales/:id"
                  exact
                  component={FarmSellDetails}
                />
                <AllowedRouteTo
                  path="/admin/decisions/scenarios/sales/:saleScenarioId/results/:saleScenarioResultId/farms/sales/edit/:id"
                  exact
                  component={NewFarmSell}
                />
                <AllowedRouteTo
                  path="/admin/decisions/scenarios/purchases/new"
                  exact
                  component={NewEditPurchaseScenario}
                />
                <AllowedRouteTo
                  path="/admin/decisions/scenarios/purchases/:id/edit"
                  exact
                  component={NewEditPurchaseScenario}
                />
                <AllowedRouteTo
                  path="/admin/decisions/scenarios/purchases/:id/duplicate"
                  exact
                  component={NewEditPurchaseScenario}
                />
                <AllowedRouteTo
                  path="/admin/decisions/scenarios/purchases/:id"
                  exact
                  component={PurchaseScenarioDetails}
                />
                <AllowedRouteTo
                  path="/admin/decisions/scenarios/boitel/new"
                  exact
                  component={NewEditBoitelScenario}
                />
                <AllowedRouteTo
                  path="/admin/decisions/scenarios/boitel/:id/edit"
                  exact
                  component={NewEditBoitelScenario}
                />
                <AllowedRouteTo path="/admin/payments" component={Payments} />
                <AllowedRouteTo
                  path="/admin/updateToContract"
                  component={updateTrialToContract}
                />
                <AllowedRouteTo
                  path="/admin/boitel/prices/show/:id"
                  component={BoitelPriceDetails}
                />
                <AllowedRouteTo
                  path="/admin/boitel/prices/edit/:id"
                  component={BoitelPriceEdit}
                />
                <AllowedRouteTo
                  path="/admin/decisions/scenarios/sales/boitel/new"
                  exact
                  component={NewBoitelSaleScenario}
                />
                <AllowedRouteTo
                  path="/admin/decisions/scenarios/sales/boitel/:id/edit"
                  exact
                  component={EditBoitelSaleScenario}
                />
                <AllowedRouteTo
                  path="/admin/decisions/scenarios/sales/boitel/:id/results/:resultId"
                  exact
                  component={DetailsBoitelSaleScenario}
                />
                <AllowedRouteTo
                  path="/admin/decisions/scenarios/sales/boitel/:id/results/:resultId/farms/sales/new"
                  exact
                  component={NewBoitelSaleScenarioFarmSell}
                />
                <AllowedRouteTo
                  path="/admin/decisions/scenarios/sales/boitel/:boitelSaleScenarioId/results/:resultId/farms/sales/:id"
                  exact
                  component={BoitelSaleScenarioFarmSellDetails}
                />
                <AllowedRouteTo
                  path="/admin/supplierManagement/new"
                  component={NewSupplierManagement}
                />
                <AllowedRouteTo
                  path="/admin/supplierManagement/edit/:id"
                  component={EditSupplierManagement}
                />
                <AllowedRouteTo
                  path="/admin/financial"
                  exact
                  component={Financial}
                />
                <AllowedRouteTo
                  path="/admin/decisions/scenarios/sales/farms/sales/closed/report"
                  exact
                  component={ClosedSalesReport}
                />
                <AllowedRouteTo
                  path="/admin/productionSell/new"
                  exact
                  component={NewFarmProductionSell}
                />
                <AllowedRouteTo
                  path="/admin/productionSell/edit/:id"
                  exact
                  component={EditFarmProductionSell}
                />
                <AllowedRouteTo
                  path="/admin/productionSell/details/:id"
                  exact
                  component={DetailsFarmProductionSell}
                />
                <AllowedRouteTo
                  path="/admin/managements/reproductive/protocol/new"
                  exact
                  component={AnimalFarmReproductiveProtocolForm}
                />
                {/* New navigation */}
                <AllowedRouteTo
                  path="/admin/managements/reproductive"
                  component={HandlingReproduction}
                />
                <AllowedRouteTo
                  path="/admin/managements/veterinary"
                  component={Vaccinations}
                />
                <AllowedRouteTo
                  path="/admin/managements/weights"
                  component={AnimalWeightHistory}
                />
                <AllowedRouteTo
                  path="/admin/managements/trough/feeding/assessment"
                  component={TroughFeedingAssessment}
                />
                <AllowedRouteTo
                  path="/admin/managements/picket/supplement/supply"
                  component={PicketSupplementSupply}
                />
                <AllowedRouteTo
                  path="/admin/managements/diets/strategies"
                  component={DietStrategy}
                />
                <AllowedRouteTo
                  path="/admin/sale/scenario"
                  component={ListSaleScenario}
                />
                <AllowedRouteTo
                  path="/admin/purchase/scenario"
                  component={ListPurchaseScenario}
                />
                <AllowedRouteTo
                  path="/admin/boitel/sale/scenario"
                  component={ListBoitelSaleScenario}
                />
                <AllowedRouteTo
                  path="/admin/boitel/purchase/scenario"
                  component={ListBoitelScenario}
                />
                <Route component={Error404} />
              </Switch>
            </CurrencyContextProvider>
          </AnimalFarmReproductiveProtocolContextProvider>
        </MenuNavigationContextProvider>
      </UnitContextProvider>
    </RelocatePicketProvider>
  );
};
export default RoutesAdmin;
