import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";

import { Creators as AnimalActions } from "../../../../../store/ducks/animal";

import { Col, Icon, Row, Spin, Table, Tabs } from "antd";
import ButtonStandard from "../../../../../components/utils/button";
import { TabAnimalPaneContent } from "../../styles";

import {
  getTwoDecimalDigits,
  numberMask,
} from "../../../../../services/helpersMethodsService";
import { getParameterItems } from "../../../../../services/generalParameterService";

const { Column } = Table;
const { TabPane } = Tabs;

const CODE_PARAMETER_4002 = 4002;

function TabAnimalEconomicInfo({
  economicValues,
  farmSellValue,
  tabAnimalPane,
  translation,
  loadingEconomicReturn,
}) {
  const [useDietStrategyCost, setUseDietStrategyCost] = useState(true);
  const {
    animal: { animalData, animalDailyCostData, isLoading, isLoadingAnimalCost },
    app: {
      languageSelected,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const {
    params: { id: animalId },
  } = useRouteMatch();

  const limitDate = useMemo(() => {
    let date = moment().format("YYYY-MM-DD");

    if (animalData?.sellDate !== null && animalData?.sellDate !== undefined) {
      date = animalData?.sellDate;
    }

    if (animalData?.finalDate !== null && animalData?.sellDate == null) {
      date = animalData?.finalDate;
    }

    return date;
  }, [animalData]);

  const totalAnimalDietCost = useMemo(() => {
    if (animalDailyCostData !== null) {
      return animalDailyCostData?.reduce(
        (sumValue, currentObj) =>
          sumValue +
          (useDietStrategyCost
            ? currentObj.dietCost
            : currentObj.dietCostBySupplementSupply),
        0
      );
    }
  }, [animalDailyCostData, useDietStrategyCost]);

  const totalAnimalFixedVariableCost = useMemo(() => {
    if (animalDailyCostData !== null) {
      return animalDailyCostData?.reduce(
        (sumValue, currentObj) => sumValue + currentObj.fixedVariableCost,
        0
      );
    }
  }, [animalDailyCostData]);

  const totalAnimalCost = useMemo(() => {
    let total = 0;
    if (animalData !== null) {
      total += animalData?.purchaseCost;
    }

    if (totalAnimalDietCost !== null) {
      total += totalAnimalDietCost;
    }

    if (totalAnimalFixedVariableCost !== null) {
      total += totalAnimalFixedVariableCost;
    }
    return total;
  }, [totalAnimalDietCost, totalAnimalFixedVariableCost, animalData]);

  const totalAnimalPriceAccomplished = useMemo(() => {
    if (
      economicValues !== null &&
      economicValues?.saleScenarioResultAnimal !== null &&
      economicValues?.saleScenarioResultFarmSell !== null
    ) {
      if (
        economicValues.saleScenarioResultFarmSell?.priceNegotiatedUnit ===
        "VALUE_PER_KG"
      ) {
        return getTwoDecimalDigits(
          economicValues.saleScenarioResultFarmSell.priceNegotiated *
            economicValues.saleScenarioResultAnimal.weightSell
        );
      } else {
        return (
          economicValues.saleScenarioResultFarmSell?.priceNegotiated *
          (economicValues.saleScenarioResultAnimal?.weightSell / 15) *
          (economicValues.saleScenarioResultAnimal?.carcassHarnessing / 100)
        );
      }
    } else if (farmSellValue !== null) {
      if (farmSellValue?.priceNegotiatedUnit === "VALUE_PER_KG") {
        return getTwoDecimalDigits(
          farmSellValue.priceNegotiated * farmSellValue.weightSell
        );
      }
      if (farmSellValue?.priceNegotiatedUnit === "VALUE_PER_ANIMAL") {
        return getTwoDecimalDigits(farmSellValue.priceNegotiated);
      } else {
        return (
          farmSellValue?.priceNegotiated *
          (farmSellValue?.weightSell / 15) *
          (farmSellValue?.carcassHarnessing / 100)
        );
      }
    }
    return 0;
  }, [economicValues, farmSellValue]);

  const totalMargin = useMemo(() => {
    if (totalAnimalPriceAccomplished && totalAnimalCost)
      return totalAnimalPriceAccomplished - totalAnimalCost;
    return 0;
  }, [totalAnimalCost, totalAnimalPriceAccomplished]);

  const totalMarginPercent = useMemo(() => {
    if (totalAnimalPriceAccomplished && totalMargin)
      return (totalMargin / totalAnimalPriceAccomplished) * 100;
    return 0;
  }, [totalAnimalPriceAccomplished, totalMargin]);

  useEffect(() => {
    async function getParameter4002() {
      const {
        data: { results: parameter4002 },
      } = await getParameterItems({
        groupId,
        farmId,
        code: CODE_PARAMETER_4002,
      });
      setUseDietStrategyCost(parameter4002.value === "DietStrategy");
    }
    getParameter4002();
  }, [farmId, groupId]);

  return (
    <TabAnimalPaneContent active={tabAnimalPane === 3 ? true : false}>
      {/* Card with economic details */}
      <Row type="flex">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="cardBovexo">
          <Spin spinning={loadingEconomicReturn}>
            <Row type="flex">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <span className="span16">
                  {translation.animal.details.economicReturn}
                </span>
              </Col>
            </Row>
            <Tabs type="card" defaultActiveKey="1">
              <TabPane tab={<span>Previsto</span>} key="1">
                {economicValues != null &&
                economicValues.saleScenario?.name != null &&
                economicValues.saleScenarioResult != null ? (
                  <>
                    <Row type="flex" style={{ marginTop: 13 }}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row>
                          <Link
                            to={`/admin/decisions/scenarios/sales/${economicValues.saleScenario?.id}/results/${economicValues.saleScenarioResult?.id}`}
                          >
                            <span className="span20Link">
                              {economicValues.saleScenario?.name}
                              <span className="span14">{` (${economicValues.saleScenarioResult?.identification})`}</span>
                            </span>
                          </Link>
                        </Row>
                        <Row>
                          <span className="span16Regular">
                            Cenário de Venda
                          </span>
                        </Row>
                      </Col>
                    </Row>
                    <Row type="flex" style={{ marginTop: 13 }}>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Row>
                          {economicValues.saleScenarioResultAnimal !== null && (
                            <span className="span24">
                              {moment(
                                economicValues.saleScenarioResultAnimal.sellDate
                              ).format("DD/MM/YYYY")}
                            </span>
                          )}
                        </Row>
                        <Row>
                          <span className="span16Regular">Data Sugerida</span>
                        </Row>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Row>
                          {economicValues.saleScenarioResultAnimal !== null && (
                            <span className="span24">
                              {numberMask(
                                economicValues.saleScenarioResultAnimal.weight,
                                false
                              )}
                            </span>
                          )}
                        </Row>
                        <Row>
                          <span className="span16Regular">Peso Estimado</span>
                        </Row>
                      </Col>
                    </Row>
                    <Row type="flex" style={{ marginTop: 13 }}>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Row>
                          {economicValues.saleScenarioResultAnimal !== null && (
                            <span className="span24">
                              {numberMask(
                                economicValues.saleScenarioResultAnimal
                                  .weightPrice,
                                true
                              )}
                            </span>
                          )}
                        </Row>
                        <Row>
                          <span className="span16Regular">Valor @/Kg</span>
                        </Row>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Row>
                          {economicValues.saleScenarioResultAnimal !== null && (
                            <span className="span24">
                              {numberMask(
                                economicValues.saleScenarioResultAnimal
                                  .dietCarcassHarnessing,
                                false
                              )}
                            </span>
                          )}
                        </Row>
                        <Row>
                          <span className="span16Regular">
                            % Aprov. Carcaça
                          </span>
                        </Row>
                      </Col>
                    </Row>

                    <Row type="flex" style={{ marginTop: 13 }}>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Row>
                          {economicValues.saleScenarioResultAnimal !== null && (
                            <span className="span24">
                              {numberMask(
                                economicValues.saleScenarioResultAnimal
                                  .animalPrice,
                                true
                              )}
                            </span>
                          )}
                        </Row>
                        <Row>
                          <span className="span16Regular">Fat. Estimado</span>
                        </Row>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Row>
                          {economicValues.saleScenarioResultAnimal !== null && (
                            <span className="span24">
                              {numberMask(
                                economicValues.saleScenarioResultAnimal
                                  .animalPrice -
                                  economicValues.saleScenarioResultAnimal
                                    .margin,
                                true
                              )}
                            </span>
                          )}
                        </Row>
                        <Row>
                          <span className="span16Regular">Custo Acumulado</span>
                        </Row>
                      </Col>
                    </Row>
                    <Row type="flex" style={{ marginTop: 13 }}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row>
                          <span className="span24">
                            {economicValues != null &&
                            economicValues.saleScenarioResultAnimal != null
                              ? `${numberMask(
                                  economicValues?.saleScenarioResultAnimal
                                    ?.margin,
                                  true
                                )} / ${numberMask(
                                  (economicValues?.saleScenarioResultAnimal
                                    ?.margin /
                                    economicValues?.saleScenarioResultAnimal
                                      ?.animalPrice) *
                                    100,
                                  false
                                )} %`
                              : `${numberMask(0, true)}/ 0%`}
                          </span>
                        </Row>
                        <Row>
                          <span className="span16Regular">
                            {translation.animal.details.margin}
                          </span>
                        </Row>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Row type="flex" justify="center" style={{ marginTop: 13 }}>
                    <span
                      className="span16Regular"
                      style={{ textAlign: "left" }}
                    >
                      Este animal não contêm nenhum Cenário de Venda favorito.
                    </span>
                  </Row>
                )}
              </TabPane>
              <TabPane tab={<span>Realizado</span>} key="2">
                {economicValues != null &&
                economicValues.saleScenario?.name !== null &&
                economicValues.saleScenarioResult !== null &&
                economicValues.saleScenarioResultFarmSell !== null ? (
                  <>
                    <Row type="flex" style={{ marginTop: 13 }}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row>
                          <Link
                            to={`/admin/decisions/scenarios/sales/${economicValues.saleScenario?.id}/results/${economicValues.saleScenarioResult?.id}`}
                          >
                            <span className="span20Link">
                              {economicValues.saleScenario?.name}
                              <span className="span14">{` (${economicValues.saleScenarioResult?.identification})`}</span>
                            </span>
                          </Link>
                        </Row>
                        <Row>
                          <span className="span16Regular">
                            Cenário de Venda
                          </span>
                        </Row>
                      </Col>
                    </Row>
                    <Row type="flex" style={{ marginTop: 13 }}>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Row>
                          {economicValues.saleScenarioResultFarmSell !==
                            null && (
                            <span className="span24">
                              {moment(
                                economicValues.saleScenarioResultFarmSell
                                  ?.sellDate
                              ).format("DD/MM/YYYY")}
                            </span>
                          )}
                        </Row>
                        <Row>
                          <span className="span16Regular">Data Saída</span>
                        </Row>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Row>
                          {economicValues.saleScenarioResultAnimal !== null && (
                            <span className="span24">
                              {numberMask(
                                economicValues.saleScenarioResultAnimal
                                  ?.weightSell,
                                false
                              )}
                            </span>
                          )}
                        </Row>
                        <Row>
                          <span className="span16Regular">Peso Venda</span>
                        </Row>
                      </Col>
                    </Row>
                    <Row type="flex" style={{ marginTop: 13 }}>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Row>
                          {economicValues.saleScenarioResultAnimal !== null && (
                            <span className="span24">
                              {numberMask(
                                economicValues.saleScenarioResultFarmSell
                                  ?.priceNegotiated,
                                true
                              )}
                            </span>
                          )}
                        </Row>
                        <Row>
                          <span className="span16Regular">Valor @/Kg</span>
                        </Row>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Row>
                          {economicValues.saleScenarioResultAnimal !== null && (
                            <span className="span24">
                              {numberMask(
                                economicValues.saleScenarioResultAnimal
                                  ?.carcassHarnessing,
                                false
                              )}
                            </span>
                          )}
                        </Row>
                        <Row>
                          <span className="span16Regular">
                            % Aprov. Carcaça
                          </span>
                        </Row>
                      </Col>
                    </Row>

                    <Row type="flex" style={{ marginTop: 13 }}>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Row>
                          <span className="span24">
                            {numberMask(totalAnimalPriceAccomplished, true)}
                          </span>
                        </Row>
                        <Row>
                          <span className="span16Regular">Faturamento</span>
                        </Row>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Row>
                          <span className="span24">
                            {numberMask(totalAnimalCost, true)}
                          </span>
                        </Row>
                        <Row>
                          <span className="span16Regular">Custo Acumulado</span>
                        </Row>
                      </Col>
                    </Row>
                    <Row type="flex" style={{ marginTop: 13 }}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row>
                          <span className="span24">
                            {`${numberMask(
                              totalAnimalPriceAccomplished - totalAnimalCost,
                              true
                            )} /
                                            ${numberMask(
                                              ((totalAnimalPriceAccomplished -
                                                totalAnimalCost) /
                                                totalAnimalPriceAccomplished) *
                                                100,
                                              false
                                            )} %`}
                          </span>
                        </Row>
                        <Row>
                          <span className="span16Regular">
                            {translation.animal.details.margin}
                          </span>
                        </Row>
                      </Col>
                    </Row>
                  </>
                ) : farmSellValue !== null ? (
                  <>
                    <Row type="flex" style={{ marginTop: 13 }}>
                      <Col span={24}>
                        <Row>
                          <Link
                            to={`/admin/productionSell/edit/${farmSellValue.farmSellId}`}
                          >
                            <span className="span20Link">
                              {farmSellValue.documentNumber}
                            </span>
                          </Link>
                        </Row>
                        <Row>
                          <span className="span16Regular">Nº Documento</span>
                        </Row>
                      </Col>
                    </Row>
                    <Row type="flex" style={{ marginTop: 13 }}>
                      <Col span={12}>
                        <Row>
                          <span className="span24">
                            {moment(farmSellValue?.sellDate).format(
                              "DD/MM/YYYY"
                            )}
                          </span>
                        </Row>
                        <Row>
                          <span className="span16Regular">Data Saída</span>
                        </Row>
                      </Col>
                      <Col span={12}>
                        <Row>
                          <span className="span24">
                            {numberMask(farmSellValue?.weightSell, false)}
                          </span>
                        </Row>
                        <Row>
                          <span className="span16Regular">Peso Venda</span>
                        </Row>
                      </Col>
                    </Row>
                    <Row type="flex" style={{ marginTop: 13 }}>
                      <Col span={12}>
                        <Row>
                          <span className="span24">
                            {numberMask(farmSellValue?.priceNegotiated, true)}
                          </span>
                        </Row>
                        <Row>
                          <span className="span16Regular">
                            {farmSellValue?.priceNegotiatedUnit ===
                            "VALUE_PER_KG"
                              ? "Valor /Kg"
                              : farmSellValue?.priceNegotiatedUnit ===
                                "VALUE_PER_ANIMAL"
                              ? "Valor /cab."
                              : "Valor /@"}
                          </span>
                        </Row>
                      </Col>
                      <Col span={12}>
                        <Row>
                          <span className="span24">
                            {numberMask(
                              farmSellValue?.carcassHarnessing,
                              false
                            )}
                          </span>
                        </Row>
                        <Row>
                          <span className="span16Regular">
                            % Aprov. Carcaça
                          </span>
                        </Row>
                      </Col>
                    </Row>

                    <Row type="flex" style={{ marginTop: 13 }}>
                      <Col span={12}>
                        <Row>
                          <span className="span24">
                            {numberMask(totalAnimalPriceAccomplished, true)}
                          </span>
                        </Row>
                        <Row>
                          <span className="span16Regular">Faturamento</span>
                        </Row>
                      </Col>
                      <Col span={12}>
                        <Row>
                          <span className="span24">
                            {numberMask(totalAnimalCost, true)}
                          </span>
                        </Row>
                        <Row>
                          <span className="span16Regular">Custo Acumulado</span>
                        </Row>
                      </Col>
                    </Row>
                    <Row type="flex" style={{ marginTop: 13 }}>
                      <Col span={24}>
                        <Row>
                          <span className="span24">
                            {`${numberMask(totalMargin, true)} /
                                            ${numberMask(
                                              totalMarginPercent,
                                              false
                                            )} %`}
                          </span>
                        </Row>
                        <Row>
                          <span className="span16Regular">
                            {translation.animal.details.margin}
                          </span>
                        </Row>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Row type="flex" justify="center" style={{ marginTop: 13 }}>
                    <span
                      className="span16Regular"
                      style={{ textAlign: "left" }}
                    >
                      Este animal ainda não foi vendido.
                    </span>
                  </Row>
                )}
              </TabPane>
            </Tabs>
          </Spin>
        </Col>
      </Row>
      {/* Card with animal costs */}
      <Row type="flex">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="cardBovexo">
          <Spin spinning={isLoading}>
            <Row type="flex">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <span className="span16">
                  {translation.animal.details.appropriateCosts}
                </span>
              </Col>
            </Row>
            {animalDailyCostData !== null ? (
              <>
                <Row>
                  <Col span={24}>
                    <Row type="flex" justify="space-between">
                      <span className="span14Bold">{`Custo Aquisição: ${numberMask(
                        animalData?.purchaseCost,
                        true
                      )}`}</span>
                      <span className="span14Bold">{`Nutrição: ${numberMask(
                        totalAnimalDietCost,
                        true
                      )}`}</span>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row type="flex" justify="space-between">
                      <span className="span14Bold">{`Adm/Operacional: ${numberMask(
                        totalAnimalFixedVariableCost,
                        true
                      )}`}</span>
                      <span className="span14Bold">{`Total: ${numberMask(
                        totalAnimalCost,
                        true
                      )}`}</span>
                    </Row>
                  </Col>
                </Row>
                <Row
                  justify="end"
                  type="flex"
                  style={{ marginTop: 8, marginBottom: 8 }}
                >
                  <Col>
                    <ButtonStandard
                      buttonType="secondary"
                      onClick={() =>
                        dispatch(
                          AnimalActions.indexAnimalDailyCost(
                            groupId,
                            farmId,
                            animalId,
                            limitDate
                          )
                        )
                      }
                    >
                      <Icon type="reload" /> {translation.buttons.updateTable}
                    </ButtonStandard>
                  </Col>
                </Row>
                <Table
                  loading={isLoadingAnimalCost}
                  dataSource={animalDailyCostData}
                  pagination={{
                    total: animalDailyCostData?.length,
                    defaultCurrent: 1,
                    size: 10,
                  }}
                  size="middle"
                  rowKey="id"
                >
                  <Column
                    // title={translation.animal.details.history.grid.date}
                    title="Data"
                    dataIndex="date"
                    key="date"
                    sorter={(a, b) => a.date > b.date}
                    sortDirections={["descend", "ascend"]}
                    render={(text) =>
                      text !== null
                        ? languageSelected === "en"
                          ? moment(text).format("MM/DD/YYYY")
                          : moment(text).format("DD/MM/YYYY")
                        : ""
                    }
                  />
                  <Column
                    // title={translation.animal.details.history.grid.date}
                    title="Nutrição ED"
                    dataIndex="dietCost"
                    key="dietCost"
                    sorter={(a, b) => a.dietCost > b.dietCost}
                    sortDirections={["descend", "ascend"]}
                    render={(value) =>
                      value !== null
                        ? numberMask(value, false)
                        : numberMask(0, false)
                    }
                    // (value) => `R$ ${numberMask(getTwoDecimalDigits(value, 2).toFixed(2), true)}`}
                  />
                  <Column
                    title="Nutrição F"
                    dataIndex="dietCostBySupplementSupply"
                    key="dietCostBySupplementSupply"
                    sorter={(a, b) =>
                      a.dietCostBySupplementSupply >
                      b.dietCostBySupplementSupply
                    }
                    sortDirections={["descend", "ascend"]}
                    render={(value) =>
                      value !== null
                        ? numberMask(value, false)
                        : numberMask(0, false)
                    }
                  />
                  <Column
                    // title={translation.animal.details.history.grid.date}
                    title="Adm/Operac."
                    dataIndex="fixedVariableCost"
                    key="fixedVariableCost"
                    sorter={(a, b) => a.fixedVariableCost > b.fixedVariableCost}
                    sortDirections={["descend", "ascend"]}
                    render={(value) =>
                      value !== null
                        ? numberMask(value, false)
                        : numberMask(0, false)
                    }
                  />
                  <Column
                    // title={translation.animal.details.history.grid.date}
                    title="Total"
                    dataIndex="totalCost"
                    key="totalCost"
                    sorter={(a, b) =>
                      a.dietCost + a.fixedVariableCost >
                      b.dietCost + b.fixedVariableCost
                    }
                    sortDirections={["descend", "ascend"]}
                    render={(value, record) => {
                      return numberMask(
                        (useDietStrategyCost
                          ? record.dietCost
                          : record.dietCostBySupplementSupply) +
                          record.fixedVariableCost,
                        false
                      );
                    }}
                  />
                </Table>
              </>
            ) : (
              <Row type="flex" justify="center" style={{ marginTop: 13 }}>
                <span className="span16Regular" style={{ textAlign: "left" }}>
                  Este animal não possui custos apropriados.
                </span>
              </Row>
            )}
          </Spin>
        </Col>
      </Row>
    </TabAnimalPaneContent>
  );
}

export default TabAnimalEconomicInfo;
