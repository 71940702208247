import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import Axios from "axios";
import { Row, Col, Spin, Select } from "antd";
import CanvasJSReact from "../../../../../assets/canvasjs.react";
// Components
import { Container, Title } from "./styles";
// Services
import { getTwoDecimalDigits } from "../../../../../services/helpersMethodsService";
import { getMarketAnalysisDropDown } from "../../../../../services/marketAnalysisService";
import { getFuturePriceChart } from "../../../../../services/futurePriceService";

function MarketAnalysisDash() {
  // Variables
  const { CanvasJSChart } = CanvasJSReact;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [data, setData] = useState(null);
  const [listMarketAnalysis, setListMarketAnalysis] = useState([]);
  const [marketAnalysisSelected, setMarketAnalysisSelected] = useState([]);

  // Redux variables
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
    translation,
  } = useSelector((state) => state.app);

  // Effects

  // Get List of MarketAnalysis and add Default in marketAnalysisSelected
  useEffect(() => {
    const signal = Axios.CancelToken.source();
    let mount = true;
    async function fetch() {
      let dData = [];
      setIsLoadingList(true);
      try {
        const {
          data: { results },
        } = await getMarketAnalysisDropDown({
          groupId,
          farmId,
          signal,
        });
        dData = results;
        if (mount) {
          setListMarketAnalysis(dData);
          const defaultMarketAnalysis = dData.find(
            (ma) => ma.baseline === true
          );
          const cartaPercuariaMarketAnalysis = dData.find(
            ({ id }) => id === "6faa8b29-eca3-4ee3-a9b8-ad48fef50c22"
          );
          setMarketAnalysisSelected([
            defaultMarketAnalysis != null
              ? defaultMarketAnalysis.id
              : cartaPercuariaMarketAnalysis.id,
          ]);
          setIsLoadingList(false);
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (groupId != null && farmId != null) {
      fetch();
    }
    return () => {
      mount = false;
      signal.cancel();
    };
  }, [groupId, farmId]);

  // Mount dashboards
  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      if (marketAnalysisSelected.length > 0) {
        const promises = marketAnalysisSelected.map(
          async (marketAnalysisId) => {
            try {
              const {
                data: { results },
              } = await getFuturePriceChart({
                groupId,
                farmId,
                marketAnalysisId,
              });
              if (results.length > 0) {
                const values = results.map((r) => {
                  r.x = moment(r.x).toDate();
                  r.y = Number.parseFloat(getTwoDecimalDigits(r.y));
                  return r;
                });
                const marketAnalysis = listMarketAnalysis.find(
                  ({ id }) => id === marketAnalysisId
                );
                return {
                  type: "line",
                  showInLegend: true,
                  name: marketAnalysis.name,
                  xValueFormatString: "DD/MM/YYYY",
                  yValueFormatString: "R$#######.00",
                  dataPoints: values,
                };
              } else {
                return;
              }
            } catch (error) {
              return;
            }
          }
        );
        const chartData = await Promise.all(promises);
        if (chartData.length > 0) {
          setData(chartData);
        } else {
          setData(null);
        }
      } else {
        setData(null);
      }
      setIsLoading(false);
    }
    if (groupId && farmId) fetch();
  }, [groupId, farmId, marketAnalysisSelected, listMarketAnalysis]);

  // Methods
  const selectChange = (value) => {
    setMarketAnalysisSelected(value);
  };

  return (
    <Container>
      <Row type="flex">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Title>{translation.arrobaPrice.marketAnalysisDash.title}</Title>
        </Col>
      </Row>
      <Row type="flex">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <strong>
            {translation.arrobaPrice.marketAnalysisDash.selectCurve}
          </strong>
        </Col>
      </Row>
      <Row type="flex">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Select
            mode="multiple"
            loading={isLoadingList}
            value={
              marketAnalysisSelected.length > 0
                ? marketAnalysisSelected
                : undefined
            }
            onChange={selectChange}
            placeholder={translation.defaultSelectPlaceholder}
          >
            {listMarketAnalysis.map(({ id, name, baseline }) => (
              <Select.Option key={id} value={id}>
                {baseline ? `${name} (Padrão)` : name}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row type="flex">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Spin spinning={isLoading}>
            {data != null && data.length > 0 ? (
              <CanvasJSChart
                options={{
                  animationEnabled: true,
                  zoomEnabled: true,
                  height: 200,
                  axisX: {
                    valueFormatString: "MM/YY",
                    crosshair: {
                      enabled: true,
                      snapToDataPoint: true,
                    },
                  },
                  toolTip: {
                    fontFamily: "Asap",
                    shared: true,
                  },
                  axisY: {
                    includeZero: true,
                    prefix: "R$ ",
                    valueFormatString: "###0.00",
                    gridDashType: "dot",
                  },
                  data: data,
                }}
              />
            ) : (
              <Row type="flex" justify="center">
                <p>{translation.arrobaPrice.marketAnalysisDash.emptyMessage}</p>
              </Row>
            )}
          </Spin>
        </Col>
      </Row>
    </Container>
  );
}

export default MarketAnalysisDash;
