import React from "react";
import { useHistory } from "react-router-dom";

import { FarmAnimalSellContextProvider } from "../../../../../contexts/farmAnimalSellContext";

// Components
import { Container } from "./styles";
import FormProductionSell from "./form";

const DetailsFarmProductionSellLayout = () => {
  const history = useHistory();

  const cancelAnimals = () => {
    history.push("/admin/financial");
  };

  return (
    <Container>
      <FormProductionSell onCancel={cancelAnimals} />
    </Container>
  );
};

const DetailsFarmProductionSell = () => {
  return (
    <FarmAnimalSellContextProvider>
      <DetailsFarmProductionSellLayout />
    </FarmAnimalSellContextProvider>
  );
};

export default DetailsFarmProductionSell;
