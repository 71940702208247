import { Select } from "antd";
import styled from "styled-components";

export const ContainerSelectLotProduction = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  gap: 0.5rem;
`;

export const CustomSelect = styled(Select)`
  &.ant-select.ant-select-enabled {
    .ant-select-selection {
      border: 1px solid #684e94 !important;
      color: #684e94 !important;
    }
    .ant-select-arrow {
      color: #684e94 !important;
    }
    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
      color: #684e94 !important;
    }
  }
`;
