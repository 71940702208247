import React from "react";
import { MenuNavigationButtonContainer } from "./styles";

// import { Container } from './styles';

function MenuNavigationButton({
  id,
  level,
  color,
  label,
  link,
  active = false,
  onClick,
}) {
  return (
    <MenuNavigationButtonContainer
      id={`menu-navigation-button-${level}-${id}`}
      className={
        active ? "menu-navigation-button active" : "menu-navigation-button"
      }
      onClick={() => onClick(id, level, link)}
      color={color}
    >
      <span>{label}</span>
    </MenuNavigationButtonContainer>
  );
}

export { MenuNavigationButton };
