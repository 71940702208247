import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
/** Redux libs */
import { useSelector, useDispatch } from "react-redux";
import { Creators as PurchaseScenarioActions } from "../../../../../store/ducks/purchaseScenario";
/** Components */
import CanvasJSReact from "../../../../../assets/canvasjs.react";
import {
  Container,
  Body,
  Header,
  ChartContainer,
  ListPeriodsContainer,
  ListPeriodsItem,
  ListPeriodsItemContainer,
  ListPeriodsItemPlus,
  ListPeriodsTitleContainer,
  PopConfirmCustom,
} from "./styles";
import { Row, Col, Icon, Spin, notification, Empty, Tooltip } from "antd";
import ButtonStandard from "../../../../../components/utils/button";
import TextToolIcon from "../../../../../components/utils/icons/textTool";
import RainIcon from "../../../../generalParameter/weatherSeasonsParameter/display/icons/rain";
import SunIcon from "../../../../generalParameter/weatherSeasonsParameter/display/icons/sun";
import TransitionIcon from "../../../../generalParameter/weatherSeasonsParameter/display/icons/transition";
import PlusCirclePurpleIcon from "../../../../../components/utils/icons/plus/circle/purple";
import PeriodModel from "./periodModel";
import RoutingLeavingGuardModal from "../../../../../components/modals/routingLeavingGuardModal";
/** Services */
import {
  getParameterItems,
  getWeatherSeason,
} from "../../../../../services/generalParameterService";
import { getPastureIndexDropDown } from "../../../../../services/pastureService";
import { getSupplementIndexDropDown } from "../../../../../services/supplementService";
import {
  storePurchaseScenario,
  updatePurchaseScenario,
} from "../../../../../services/purchaseScenarioService";
import {
  dryColor,
  transitionColor,
  waterColor,
} from "../../../../../utils/colorsHandler";
import { getCarbonFootprintIndex } from "../../../../../services/carbonFootprintService";
import AlertIcon from "../../../../../components/drawers/alert/icons/alert";

/** Method to calculate diet periods curve (only if useEstablishedCurve = false) */
function calculateDietPeriodsCurve(
  initialWeight = 240,
  initialDate = null,
  periods = null
) {
  return new Promise((resolve, reject) => {
    try {
      let baseWeight = initialWeight;
      const baseDate = initialDate;
      let dataPointsArray = [];
      /** Mount dataPointsArrays */
      if (periods != null) {
        if (periods.length > 0) {
          // Get the reference date
          const dateOfReference = baseDate;
          /** Mount curve */
          periods.forEach((dp, index) => {
            let i = dp.startDay;
            let j = 0;
            do {
              const isFirstValue = moment(dateOfReference)
                .add(i, "days")
                .isSame(moment(baseDate), "days", "[]");
              const x = moment(dateOfReference).add(i, "days").toDate();
              let y = 0;
              if (isFirstValue) {
                y = baseWeight;
              } else {
                if (dp?.allWeightGains?.length > 0) {
                  const weightGainAPI = dp.allWeightGains[j];
                  if (weightGainAPI) {
                    y = baseWeight += weightGainAPI.gdp;
                  } else {
                    y = baseWeight += dp.gdpExpectation;
                  }
                } else {
                  y = baseWeight += dp.gdpExpectation;
                }
              }
              dataPointsArray.push({
                x,
                y,
                toolTipContent: `<span style='"'color: {color};'"'>{name}:</span> {x} | {y}`,
              });
              i++;
              j++;
            } while (i <= dp.endDay);
          });
        }
      }
      resolve(dataPointsArray);
    } catch (error) {
      reject(error);
    }
  });
}

/** Method to create curve of gdp */
function calculateGdpCurve(initialDate = null, dietPeriodsP = null) {
  return new Promise((resolve, reject) => {
    let dietPeriodsCopy = dietPeriodsP;
    let dataPointsArray = []; // Array with x (Date) and y (gdp)

    try {
      if (dietPeriodsCopy.length > 0) {
        // Get the reference date
        const dateOfReference = initialDate;
        // Go through diet periods and populate the dataPointsArray
        dietPeriodsCopy.forEach((dp) => {
          const startDay = dp.startDay;
          const endDay = dp.endDay;
          let j = 0;
          for (let i = startDay; i <= endDay; i++) {
            const x = moment(dateOfReference).add(i, "days").toDate();
            let y = 0;
            if (dp?.allWeightGains?.length > 0) {
              const weightGainAPI = dp.allWeightGains[j];
              if (weightGainAPI) {
                y = weightGainAPI.gdp;
              } else {
                y = dp.gdpExpectation;
              }
              j++;
            } else {
              y = dp.gdpExpectation;
            }
            const value = {
              x,
              y,
              toolTipContent: `<span style='"'color: {color};'"'>{name}:</span> {x} | {y}`,
            };
            dataPointsArray.push(value);
          }
        });
      }
      resolve(dataPointsArray);
    } catch (error) {
      reject(error);
    }
  });
}

/** Method to generate StripLines */
function generateStripLines(seasons, editingStartDate, periods) {
  let startDay = 0;
  let resultStripLines = [];
  try {
    let endDay =
      editingStartDate != null
        ? 547 - moment().diff(editingStartDate, "days")
        : 548;
    if (periods.length > 0) {
      const lastEndDayPeriod = periods[periods.length - 1].endDay;
      if (lastEndDayPeriod > endDay) {
        endDay = lastEndDayPeriod + 30;
      }
    }
    // Set StripLines

    if (seasons != null) {
      let season = null;

      let newStripLine = null;

      do {
        const currentMonth =
          Number(moment(editingStartDate).add(startDay, "days").month()) + 1;
        let nextMonth =
          Number(
            moment(editingStartDate)
              .add(startDay + 1, "days")
              .month()
          ) + 1;

        let loopSeasonCurrentMonth = seasons.find(
          (s) => s.month === currentMonth
        ).season;
        let loopSeasonNextMonth = seasons.find(
          (s) => s.month === nextMonth
        ).season;

        if (season === null) {
          season = loopSeasonCurrentMonth;
          newStripLine = {
            startValue: moment(editingStartDate).add(startDay, "days").toDate(),
            label:
              season === "Water"
                ? "Chuva".toUpperCase()
                : season === "Dry"
                ? "Seca".toUpperCase()
                : season === "Transition"
                ? "Transição".toUpperCase()
                : null,
            color:
              season === "Water"
                ? waterColor
                : season === "Dry"
                ? dryColor
                : season === "Transition"
                ? transitionColor
                : null,
            labelFontColor: "#FFF",
            labelAlign: "center",
            labelAngle: 180,
            labelBackgroundColor: "transparent",
          };
        } else {
          /* Verifico se mudou de estacao */
          if (loopSeasonCurrentMonth === season) {
            /* Se continua a mesma - verifico se no proximo dia ira mudar */
            if (loopSeasonCurrentMonth !== loopSeasonNextMonth) {
              /* Se mudou adiciono o end date e adiciono na lista */
              newStripLine = {
                ...newStripLine,
                endValue: moment(editingStartDate)
                  .add(startDay, "days")
                  .toDate(),
              };
              resultStripLines.push(newStripLine);
              newStripLine = null;
              season = null;
            }
          }
        }

        if (startDay === endDay) {
          newStripLine = {
            ...newStripLine,
            endValue: moment(editingStartDate).add(startDay, "days").toDate(),
          };
          resultStripLines.push(newStripLine);
          newStripLine = null;
          season = null;
        }
        startDay++;
      } while (startDay <= endDay);
    }

    if (periods.length > 0) {
      periods.forEach((dp, i) => {
        resultStripLines.push(
          {
            value: moment(dp.startDate).toDate(),
            color: "#000080",
            thickness: 4,
            showOnTop: true,
            lineDashType: "solid",
          },
          {
            startValue: moment(dp.startDate).toDate(),
            endValue: moment(dp.endDate).toDate(),
            label: `Dieta ${i + 1}`,
            color: "transparent",
            labelFontColor: "#000080",
            labelFontSize: 14,
            labelAlign: "near",
            showOnTop: true,
            labelFontWeight: "bold",
            labelBackgroundColor: "transparent",
          },
          {
            value: moment(dp.endDate).toDate(),
            color: "#000080",
            thickness: 4,
            showOnTop: true,
            lineDashType: "solid",
          }
        );
      });
    }
  } catch (error) {
    console.error(`Error on trying create stripLines. Error ${error}`);
  }
  return resultStripLines;
}

const PurchaseDietStrategyTab = ({ history, props }) => {
  /** Variables */
  const { CanvasJSChart } = CanvasJSReact;
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingChart, setLoadingChart] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [maximumDate, setMaximumDate] = useState(moment().add(548, "days"));
  const [listOfPastures, setListOfPastures] = useState([]);
  const [listOfSupplements, setListOfSupplements] = useState([]);
  const [chartOptions, setChartOptions] = useState({
    animationEnabled: true,
    zoomEnabled: true,
    height: 330,

    axisX: {
      labelFontSize: 12,
      maximum: maximumDate.toDate(),
      valueFormatString: "MM/YY",
      interval: 7,
      intervalType: "month",
      crosshair: {
        enabled: true,
      },
      stripLines: [],
    },

    axisY: {
      labelFontSize: 12,
      includeZero: true,
      valueFormatString: "###0.##",
      suffix: "kg",
      gridDashType: "dot",
    },
    axisY2: {
      labelFontSize: 12,
      includeZero: true,
      valueFormatString: "###0.##",
      suffix: "kg",
      gridDashType: "dot",
    },
    toolTip: {
      shared: true,
    },
    data: [],
  });
  const [formDietStrategy, setFormDietStrategy] = useState({
    id: null,
    dietPeriods: [],
    name: null,
    lot: null,
    useEstablishedCurve: true,
    status: "Active",
  });
  const [seasons, setSeasons] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [dietPeriodIndexSelected, setDietPeriodIndexSelected] = useState(null);
  const [startDateDeadline, setStartDateDeadline] = useState(
    moment().add(1, "days")
  );
  const [endDateDeadline, setEndDateDeadline] = useState(
    moment().add(548, "days")
  );
  const [
    dietStrategyPeriodCurveDatesAndWeights,
    setDietStrategyPeriodCurveDatesAndWeights,
  ] = useState([]);
  const [carbonFootprintParameters, setCarbonFootprintParameters] = useState(
    []
  );
  const [carbonBaseKg, setCarbonBaseKg] = useState(0);
  const [carbonCredit, setCarbonCredit] = useState(0);
  /** Redux variables */
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { activeTab, newPurchaseScenario, dietStrategy } = useSelector(
    (state) => state.purchaseScenario
  );
  const dispatch = useDispatch();

  const {
    match: {
      params: { id: scenarioId },
    },
    location: { pathname },
  } = props;

  /** Effects */
  useEffect(() => {
    setShouldBlockNavigation(true);
  }, []);

  // Get list of carbonCredit and carbonBaseline
  useEffect(() => {
    async function getCarbonFootprintParameters() {
      try {
        const {
          data: { results: parameters },
        } = await getCarbonFootprintIndex({
          groupId,
          farmId,
        });
        setCarbonFootprintParameters(parameters);
      } catch (error) {
        setCarbonFootprintParameters([]);
      }
    }
    async function getCarbonBaseKgAndCredit() {
      try {
        const [
          {
            data: { results: carbonBaseKgParam },
          },
          {
            data: { results: carbonCreditParam },
          },
        ] = await Promise.all([
          getParameterItems({ groupId, farmId, code: 1006 }),
          getParameterItems({ groupId, farmId, code: 1007 }),
        ]);
        setCarbonBaseKg(Number(carbonBaseKgParam?.value));
        setCarbonCredit(Number(carbonCreditParam?.value));
      } catch (error) {
        setCarbonBaseKg(0);
        setCarbonCredit(0);
      }
    }
    getCarbonBaseKgAndCredit();
    getCarbonFootprintParameters();
  }, [groupId, farmId]);

  /** Get List of pastures */
  useEffect(() => {
    async function getListOfPastures(groupId, farmId) {
      if (listOfPastures == null || listOfPastures.length === 0) {
        try {
          const {
            data: { results: pastures },
          } = await getPastureIndexDropDown({ groupId, farmId });
          pastures.map((pasture) => {
            let arraySeasonOptions = [];
            pasture.seasons.forEach((season) => {
              if (season.cost > 0 && season.cp > 0 && season.tdn > 0) {
                if (
                  !arraySeasonOptions.find(
                    (aro) => season.season === aro.season
                  )
                ) {
                  arraySeasonOptions.push({
                    id: pasture.id,
                    season: season.season,
                    optionsFertilized: [
                      season.fertilized === true ? "Yes" : "No",
                    ],
                  });
                } else {
                  arraySeasonOptions = arraySeasonOptions.map((aro) => {
                    if (aro.season === season.season) {
                      aro.optionsFertilized = ["Yes", "No"];
                    }
                    return aro;
                  });
                }
              }
            });
            pasture.arraySeasonOptions = arraySeasonOptions;

            return pasture;
          });
          setListOfPastures(
            pastures.filter(
              (p) => p.seasons.length > 0 && p.arraySeasonOptions.length > 0
            )
          );
        } catch (error) {
          console.error(error);
        }
      }
    }
    getListOfPastures(groupId, farmId);
  }, [groupId, farmId, listOfPastures]);

  /** Get List of supplements */
  useEffect(() => {
    async function getListOfSupplements(groupId, farmId) {
      if (listOfSupplements == null || listOfSupplements.length === 0) {
        try {
          const {
            data: { results: supplements },
          } = await getSupplementIndexDropDown({ groupId, farmId });
          setListOfSupplements(supplements.filter((s) => s.cost != null));
        } catch (error) {
          console.error(error);
        }
      }
    }
    getListOfSupplements(groupId, farmId);
  }, [groupId, farmId, listOfSupplements]);

  /** Set DietStrategy info */
  useEffect(() => {
    setFormDietStrategy(dietStrategy);
  }, [dietStrategy]);

  /** Mount the chart */
  useEffect(() => {
    async function generateChart() {
      setIsEditing(!(scenarioId == null || pathname.includes("duplicate")));

      const { initialSimulationDate } = newPurchaseScenario;

      try {
        setLoadingChart(true);
        const {
          data: { results },
        } = await getWeatherSeason({ farmId });
        const seasonsFromResult =
          results.farmSelectedTypeSeason === "Drier"
            ? results.seasonsDrier
            : results.farmSelectedTypeSeason === "Wetter"
            ? results.seasonsWetter
            : results.seasonsDefault;
        let stripLines = generateStripLines(
          seasonsFromResult,
          initialSimulationDate,
          formDietStrategy.dietPeriods
        );
        /** Build DietStrategy Period Curve and Gdp */
        let dsPeriodResult = [];
        let gdpResult = [];
        if (
          formDietStrategy != null &&
          formDietStrategy.dietPeriods.length > 0
        ) {
          dsPeriodResult = await calculateDietPeriodsCurve(
            240,
            initialSimulationDate || moment().day(1),
            formDietStrategy.dietPeriods
          );
          gdpResult = await calculateGdpCurve(
            initialSimulationDate,
            formDietStrategy.dietPeriods
          );
        }
        const lastEndDate =
          formDietStrategy?.dietPeriods.length > 0
            ? formDietStrategy?.dietPeriods[
                formDietStrategy?.dietPeriods.length - 1
              ].endDate
            : null;
        const newMaximumDate =
          lastEndDate != null
            ? moment(lastEndDate).add(2, "months")
            : moment().add(548, "days");
        setMaximumDate(newMaximumDate);
        setDietStrategyPeriodCurveDatesAndWeights(dsPeriodResult);
        setSeasons(seasonsFromResult);
        setChartOptions({
          animationEnabled: true,
          zoomEnabled: true,
          height: 330,
          axisX: {
            labelFontSize: 12,
            maximum: newMaximumDate.toDate(),
            valueFormatString: "MM/YY",
            interval: 7,
            intervalType: "month",
            crosshair: {
              enabled: true,
              snapToDataPoint: true,
            },
            stripLines: stripLines,
          },
          toolTip: {
            fontFamily: "Asap",
            shared: true,
          },
          axisY: {
            labelFontSize: 12,
            includeZero: true,
            valueFormatString: "###0.##",
            suffix: "kg",
            gridDashType: "dot",
          },
          axisY2: {
            lineColor: "#106518",
            tickColor: "#106518",
            labelFontColor: "#106518",
            labelFontSize: 12,
            includeZero: true,
            valueFormatString: "###0.##",
            suffix: "kg",
            gridDashType: "dot",
          },
          data: [
            {
              type: "line",
              name: "Peso",
              showInLegend: true,
              markerType: "none",

              xValueFormatString: "DD/MM/YY",
              yValueFormatString: "###0.## kg",
              color: "#4A85AE",
              dataPoints: dsPeriodResult,
            },
            {
              type: "line",
              name: "Ganho de peso",
              showInLegend: true,
              markerType: "none",

              xValueFormatString: "DD/MM/YY",
              yValueFormatString: "###0.## kg",
              color: "#106518",
              axisYType: "secondary",
              dataPoints: gdpResult,
            },
          ],
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingChart(false);
      }
    }

    generateChart();
  }, [newPurchaseScenario, farmId, scenarioId, pathname, formDietStrategy]);

  /** Methods */

  function goPreviousTab() {
    dispatch(
      PurchaseScenarioActions.changeTab(activeTab === "tab2" ? "tab1" : "tab1")
    );
  }

  /** Method to save the purchase scenario */
  async function savePurchaseScenario() {
    if (validateRequest()) {
      let isSuccess = false;
      setShouldBlockNavigation(false);
      setIsLoading(true);
      let body = {
        id: newPurchaseScenario.id,
        name: newPurchaseScenario.name,
        marginProfit: newPurchaseScenario.marginProfit,
        breedId: newPurchaseScenario.breedId,
        asymptoticWeight: newPurchaseScenario.asymptoticWeight,
        cap: newPurchaseScenario?.cap,
        gender: newPurchaseScenario?.gender,
        marketAnalysisId: newPurchaseScenario?.marketAnalysisId,
        dietStrategyId: newPurchaseScenario?.dietStrategyId,
        createNewDietStrategy: newPurchaseScenario?.createNewDietStrategy,
        initialSimulationDate: newPurchaseScenario?.initialSimulationDate,
        productionSubSystem: newPurchaseScenario?.productionSubSystem,
        status: newPurchaseScenario.status,
        minWeight: newPurchaseScenario?.minWeight,
        maxWeight: newPurchaseScenario?.maxWeight,
        commission: newPurchaseScenario?.commission,
        commissionUnit:
          newPurchaseScenario?.commissionUnit != null
            ? newPurchaseScenario?.commissionUnit
            : "PERCENTAGE",
        freight: newPurchaseScenario?.freight,
        respectCapacity: newPurchaseScenario?.respectCapacity,
        dietPeriods: formDietStrategy.dietPeriods.map((period) => {
          return {
            id: period.id,
            startDate: period.startDate,
            startDay: period.startDay,
            endDate: period.endDate,
            endDay: period.endDay,
            season: period.season,
            productionSubSystem: period.productionSubSystem,
            carcassHarnessing: period.carcassHarnessing,
            gdpExpectation: period.gdpExpectation,
            pastureId: period.pasture != null ? period.pasture.id : null,
            pastureSeason: period.pasture != null ? period.pastureSeason : null,
            pastureFertilized:
              period.pasture != null ? period.pastureFertilized : null,
            pastureDailyCost:
              period.pasture != null ? period.pastureDailyCost : null,
            supplementId:
              period.supplement != null ? period.supplement.id : null,
            supplementVersion:
              period.supplement != null ? period.supplementVersion : null,
            supplementDailyCost:
              period.supplement != null ? period.supplementDailyCost : null,
            supplementConsumptionByAnimal:
              period.supplement != null
                ? period.supplementConsumptionByAnimal
                : null,
            supplementConsumptionType:
              period.supplement != null
                ? period.supplementConsumptionType
                : null,
            supplementConsumptionMeasureType:
              period.supplement != null
                ? period.supplementConsumptionMeasureType
                : null,
            supplementTroughSpacePerAnimal:
              period.supplement != null
                ? period.supplementTroughSpacePerAnimal
                : null,
            supplementTotalTroughSpace:
              period.supplement != null
                ? period.supplementTotalTroughSpace
                : null,
            allWeightGains: period.allWeightGains,
            conflictedGdp: period.conflictedGdp,
            gdpPossible: period.gdpPossible,
            pastureConsumed: period.pastureConsumed,
            pastureNeeded: period.pastureNeeded,
            supplementConsumed: period.supplementConsumed,
            supplementNeeded: period.supplementNeeded,
            tdnExceededOrMissing: period.tdnExceededOrMissing,
            tdnExceededOrMissingValue: period.tdnExceededOrMissingValue,
            cpExceededOrMissing: period.cpExceededOrMissing,
            cpExceededOrMissingValue: period.cpExceededOrMissingValue,
          };
        }),
      };
      if (body.id === null) {
        try {
          await storePurchaseScenario({
            groupId,
            farmId,
            body,
          });
          notification.success({
            message: "Cenário de compra criado!",
            description: "Você será notificado quando o mesmo for executado!",
          });
          isSuccess = true;
        } catch (error) {
          setIsLoading(false);
          setShouldBlockNavigation(true);
          notification.error({
            message: "Erro!",
            description:
              "Houve um erro no momento de criar o cenário de compra, contate o administrador do sistema",
          });
        }
      } else {
        let id = body.id;
        try {
          await updatePurchaseScenario({
            groupId,
            farmId,
            id,
            body,
          });
          notification.success({
            message: "Cenário de compra atualizado!",
            description: "Você será notificado quando o mesmo for executado!",
          });
          isSuccess = true;
        } catch (error) {
          setIsLoading(false);
          setShouldBlockNavigation(true);
          notification.error({
            message: "Erro!",
            description:
              "Houve um erro no momento de atualizar o cenário de compra, contate o administrador do sistema",
          });
        }
      }
      if (isSuccess) {
        setTimeout(() => {
          setIsLoading(false);
          history.push({
            pathname: "/admin/purchase/scenario",
            state: {
              tab: "2",
            },
          });
        }, 250);
      }
    }
  }

  function validateRequest() {
    if (newPurchaseScenario == null) {
      notification.error({
        message: "Inicie novamente a criação do cenário de compra",
      });
      return false;
    }
    if (
      formDietStrategy == null ||
      formDietStrategy.dietPeriods == null ||
      formDietStrategy.dietPeriods.length === 0
    ) {
      notification.error({
        message:
          "É necessário ter pelo menos um período de dieta cadastrado para esse cenário de compra",
      });
      return false;
    }

    let errorArray = [];
    if (newPurchaseScenario.name == null) errorArray.push("Nome");
    if (
      newPurchaseScenario.marginProfit == null ||
      newPurchaseScenario.marginProfit <= 0
    )
      errorArray.push("Margem de Lucro");
    if (newPurchaseScenario.breedId == null) errorArray.push("Raça");
    if (
      newPurchaseScenario.asymptoticWeight == null ||
      newPurchaseScenario.asymptoticWeight <= 0
    )
      errorArray.push("Peso assintótico");

    // Validate periods/diets
    formDietStrategy.dietPeriods.forEach((period, index) => {
      let countScenario = index + 1;
      if (period.startDate == null)
        errorArray.push(`Período Climático ${countScenario} - Data de início`);
      if (period.endDate == null)
        errorArray.push(`Período Climático ${countScenario} - Data de fim`);
      if (period.productionSubSystem == null)
        errorArray.push(
          `Período Climático ${countScenario} - Sistema de Produção`
        );
      if (period.gdpExpectation == null)
        errorArray.push(
          `Período Climático ${countScenario} - Expectativa de GDP`
        );
      if (period.carcassHarnessing == null)
        errorArray.push(
          `Período Climático ${countScenario} - Aproveitamento de carcaça`
        );
      if (
        period.pasture == null &&
        (period.productionSubSystem === "SemiIntensive" ||
          period.productionSubSystem === "Extensive")
      ) {
        errorArray.push(`Período Climático ${countScenario} - Forrageira`);
      } else if (period.productionSubSystem !== "Intensive") {
        if (period.pastureSeason == null)
          errorArray.push(
            `Período Climático ${countScenario} - Período Climático da Forrageira`
          );
        if (period.pastureFertilized == null)
          errorArray.push(
            `Período Climático ${countScenario} - Forrageira Adubado`
          );
        if (period.pastureDailyCost == null || period.pastureDailyCost <= 0)
          errorArray.push(
            `Período Climático ${countScenario} - Custo diário do Pasto`
          );
      }
      if (
        (period.productionSubSystem === "SemiIntensive" ||
          period.productionSubSystem === "Intensive") &&
        period.supplement == null
      ) {
        errorArray.push(
          `Período Climático ${countScenario} - Suplemento/Concent.`
        );
      } else if (period.supplement != null) {
        if (period.supplementConsumptionType == null)
          errorArray.push(
            `Período Climático ${countScenario} - Tipo de Consumo do Suplemento/Concent.`
          );
        if (period.supplementConsumptionMeasureType == null)
          errorArray.push(
            `Período Climático ${countScenario} - Medida do Consumo diário do Suplemento/Concent.`
          );
        if (
          period.supplementConsumptionByAnimal == null ||
          period.supplementConsumptionByAnimal <= 0
        )
          errorArray.push(
            `Período Climático ${countScenario} - Consumo diário do Suplemento/Concent.`
          );
      }
    });

    if (errorArray.length > 0) {
      notification.error({
        message: "É necessário preencher os parâmetros abaixo corretamente:",
        description: errorArray.map((ea, index) => (
          <p key={index}>{`- ${ea}`}</p>
        )),
      });
      return false;
    }

    return true;
  }

  async function handleRecalculateChart(pPeriods) {
    const { initialSimulationDate } = newPurchaseScenario;
    try {
      setLoadingChart(true);
      let stripLines = generateStripLines(
        seasons,
        initialSimulationDate,
        pPeriods
      );
      /** Build DietStrategy Period Curve and Gdp */
      let dsPeriodResult = [];
      let gdpResult = [];
      if (pPeriods.length > 0) {
        dsPeriodResult = await calculateDietPeriodsCurve(
          240,
          initialSimulationDate || moment().day(1),
          pPeriods
        );
        gdpResult = await calculateGdpCurve(initialSimulationDate, pPeriods);
      }
      setChartOptions({
        animationEnabled: true,
        zoomEnabled: true,
        height: 330,
        axisX: {
          labelFontSize: 12,
          maximum: maximumDate.toDate(),
          valueFormatString: "MM/YY",
          interval: 7,
          intervalType: "month",
          crosshair: {
            enabled: true,
            snapToDataPoint: true,
          },
          stripLines: stripLines,
        },
        toolTip: {
          fontFamily: "Asap",
          shared: true,
        },
        axisY: {
          labelFontSize: 12,
          includeZero: true,
          valueFormatString: "###0.##",
          suffix: "kg",
          gridDashType: "dot",
        },
        axisY2: {
          lineColor: "#106518",
          tickColor: "#106518",
          labelFontColor: "#106518",
          labelFontSize: 12,
          includeZero: true,
          valueFormatString: "###0.##",
          suffix: "kg",
          gridDashType: "dot",
        },
        data: [
          {
            type: "line",
            name: "Peso",
            showInLegend: true,
            markerType: "none",

            xValueFormatString: "DD/MM/YY",
            yValueFormatString: "###0.## kg",
            color: "#4A85AE",
            dataPoints: dsPeriodResult,
          },
          {
            type: "line",
            name: "Ganho de peso",
            showInLegend: true,
            markerType: "none",

            xValueFormatString: "DD/MM/YY",
            yValueFormatString: "###0.## kg",
            color: "#106518",
            axisYType: "secondary",
            dataPoints: gdpResult,
          },
        ],
      });
      const lastEndDate =
        formDietStrategy?.dietPeriods.length > 0
          ? formDietStrategy?.dietPeriods[
              formDietStrategy?.dietPeriods.length - 1
            ].endDate
          : null;
      setMaximumDate(
        lastEndDate != null
          ? moment(lastEndDate).add(2, "months")
          : moment().add(548, "days")
      );
      setDietStrategyPeriodCurveDatesAndWeights(dsPeriodResult);
      setLoadingChart(false);
    } catch (error) {}
  }

  /** Method to get the last date of season */
  function handleGetLastDate(startDate, startDay) {
    let result = null;
    let stopDoWhile = false;
    let endDay = startDay;
    let season = null;
    const seasonMap = seasons.map((s) => s.season);
    let i = 0;
    do {
      const currentMonth = Number(moment(startDate).add(i, "days").month());
      let loopSeason = seasonMap[currentMonth];
      if (season === null || season === loopSeason) {
        season = loopSeason;
        endDay += 1;
        i++;
      } else {
        stopDoWhile = true;
      }
    } while (!stopDoWhile);
    const endDate = moment(startDate).add(i - 1, "days");
    result = {
      endDate,
      endDay: endDay - 1,
    };
    return result;
  }

  /** Method that get the user selected period */
  function handleSelectPeriod(index) {
    const { initialSimulationDate } = newPurchaseScenario;
    const { dietPeriods } = formDietStrategy;
    let startDateDeadline = null;
    let endDateDeadline = null;
    /** Update the startDateDeadline and endDateDeadline */
    if (dietPeriods.length > 0) {
      if (dietPeriods.find((ds, i) => i === index + 1)) {
        endDateDeadline = moment(
          dietPeriods.find((ds, i) => i === index + 1).endDate,
          "YYYY-MM-DD"
        )
          .subtract(2, "days")
          .format("YYYY-MM-DD");
      } else {
        endDateDeadline = moment(initialSimulationDate)
          .add(548, "days")
          .format("YYYY-MM-DD");
      }

      if (dietPeriods.find((ds, i) => i === index - 1)) {
        startDateDeadline = moment(
          dietPeriods.find((ds, i) => i === index - 1).startDate,
          "YYYY-MM-DD"
        )
          .add(2, "days")
          .format("YYYY-MM-DD");
      } else {
        startDateDeadline = moment(initialSimulationDate).format("YYYY-MM-DD");
      }

      if (index > 0) {
        dietPeriods.find((ds, i) => index === i).carcassHarnessing =
          dietPeriods[0].carcassHarnessing;
      }
    }
    dispatch(
      PurchaseScenarioActions.selectDietStrategyPeriod(
        dietPeriods.find((ds, i) => index === i)
      )
    );
    setDietPeriodIndexSelected(index);
    setModalVisible(true);
    setStartDateDeadline(startDateDeadline);
    setEndDateDeadline(endDateDeadline);
  }

  /** Method to create a period */
  function handleCreatePeriod() {
    /* Variables - start */
    const { initialSimulationDate } = newPurchaseScenario;
    const { dietPeriods } = formDietStrategy;

    let startDateDeadline = null;
    let endDateDeadline = null;

    let datesAndGdp = null;

    let startDate = null;
    let endDate = null;
    let startDay = null;
    let endDay = null;

    let index = null;

    /* Variables - end */

    /** Update the startDateDeadline and endDateDeadline */
    endDateDeadline = moment(initialSimulationDate)
      .add(548, "days")
      .format("YYYY-MM-DD");

    if (dietPeriods.length > 1) {
      if (dietPeriods.find((ds, i) => i === dietPeriods.length - 2)) {
        startDateDeadline = moment(
          dietPeriods.find((ds, i) => i === dietPeriods.length - 2).startDate
        )
          .add(2, "days")
          .format("YYYY-MM-DD");
      }
    } else if (dietPeriods.length === 1) {
      startDateDeadline = moment(dietPeriods.find((ds, i) => i === 0).startDate)
        .add(2, "days")
        .format("YYYY-MM-DD");
    } else {
      startDateDeadline = moment(initialSimulationDate).format("YYYY-MM-DD");
    }

    /** Calculate the start date, end date */
    if (dietPeriods.length > 0) {
      index = dietPeriods.length;
      const lastPeriod = dietPeriods.find(
        (p, i) => i === dietPeriods.length - 1
      );
      startDate = moment(lastPeriod.endDate)
        .add(1, "days")
        .format("YYYY-MM-DD");
      startDay = lastPeriod.endDay + 1;
    } else {
      index = 0;
      startDate = moment(initialSimulationDate).format("YYYY-MM-DD");
      startDay = 0;
    }
    const { endDate: rEndDate, endDay: rEndDay } = handleGetLastDate(
      startDate,
      startDay
    );
    endDate = rEndDate;
    endDay = Math.round(rEndDay);

    datesAndGdp = {
      startDate,
      startDay,
      endDate,
      endDay,
      gdpExpectation: 0,
    };

    /** Get the season or seasons for the new period */
    let season = null;
    let i = startDay;
    let stopDoWhile = false;

    do {
      const currentMonth =
        Number(moment(initialSimulationDate).add(i, "days").month()) + 1;
      let nextMonth =
        Number(
          moment(initialSimulationDate)
            .add(i + 1, "days")
            .month()
        ) + 1;

      let loopSeasonCurrentMonth = seasons.find(
        (s) => s.month === currentMonth
      ).season;
      let loopSeasonNextMonth = seasons.find(
        (s) => s.month === nextMonth
      ).season;

      if (season === null) {
        season = loopSeasonCurrentMonth;
      } else {
        /* Verifico se mudou de estacao */
        if (loopSeasonCurrentMonth === season) {
          /* Se continua a mesma - verifico se no proximo dia ira mudar */
          if (loopSeasonCurrentMonth !== loopSeasonNextMonth) {
            stopDoWhile = true;
          }
        }
      }

      i++;
    } while (!stopDoWhile);

    /** Mount the diet period form */
    const dietPeriodSelected = {
      id: null,
      pasture: null,
      season: `${season}`,
      productionSubSystem: null,
      pastureSeason: null,
      pastureFertilized: null,
      pastureDailyCost: null,
      supplement: null,
      supplementConsumptionMeasureType: "PV_ANIMAL",
      supplementConsumptionByAnimal: null,
      supplementConsumptionType: "Pure",
      supplementDailyCost: null,
      ...datesAndGdp,
      carcassHarnessing:
        dietPeriods?.length === 0 ? null : dietPeriods[0].carcassHarnessing,
      conflictedGdp: false,
    };
    /** Update states */
    dispatch(
      PurchaseScenarioActions.selectDietStrategyPeriod(dietPeriodSelected)
    );
    setDietPeriodIndexSelected(index);
    setModalVisible(true);
    setStartDateDeadline(startDateDeadline);
    setEndDateDeadline(endDateDeadline);
  }

  async function handleCancel() {
    await setShouldBlockNavigation(false);
    history.push({
      pathname: "/admin/purchase/scenario",
      state: {
        tab: "2",
      },
    });
  }

  /* Modal methods */
  /** This method will be called in modal actions (next, previous, save, delete, etc) */
  function handleModalActions(data = null, action = "close") {
    /* Variables - start */
    const { dietPeriods } = formDietStrategy;

    const { initialSimulationDate } = newPurchaseScenario;

    const index = dietPeriodIndexSelected;

    let newDietPeriods = null;

    let startDateDeadline = null;
    let endDateDeadline = null;

    /* Variables - end */

    if (data === null) {
      dispatch(PurchaseScenarioActions.selectDietStrategyPeriod(null));
      setDietPeriodIndexSelected(null);
      setModalVisible(false);
      setStartDateDeadline(null);
      setEndDateDeadline(null);
    } else {
      /* If action remove */
      if (action === "remove") {
        /* Update the dietPeriods */
        newDietPeriods =
          dietPeriods.length > 0 && dietPeriods.find((dp, i) => i === index)
            ? dietPeriods.filter((dp, i) => i !== index)
            : dietPeriods;

        /* Deselect in Redux */
        dispatch(PurchaseScenarioActions.selectDietStrategyPeriod(null));
        /* Update the state */
        setDietPeriodIndexSelected(null);
        setModalVisible(false);
        setStartDateDeadline(null);
        setEndDateDeadline(null);
      } else {
        let dietStrategyUpdated = data;

        /* Update the DietPeriods */
        newDietPeriods =
          dietPeriods.length > 0 && dietPeriods.find((dp, i) => i === index)
            ? dietPeriods.map((dp, i) =>
                i === index ? dietStrategyUpdated : dp
              )
            : [...dietPeriods, dietStrategyUpdated];

        /* Update other periods */
        if (newDietPeriods.length > 1) {
          // Update the period on the right
          if (newDietPeriods.find((ds, i) => i === index + 1)) {
            newDietPeriods = handelChangeNextOrPreviusPeriod(
              dietStrategyUpdated.startDay,
              dietStrategyUpdated.startDate,
              dietStrategyUpdated.endDay,
              dietStrategyUpdated.endDate,
              index + 1,
              "next",
              newDietPeriods
            );
          }
          // Update the period on the left
          if (newDietPeriods.find((ds, i) => i === index - 1)) {
            newDietPeriods = handelChangeNextOrPreviusPeriod(
              dietStrategyUpdated.startDay,
              dietStrategyUpdated.startDate,
              dietStrategyUpdated.endDay,
              dietStrategyUpdated.endDate,
              index - 1,
              "previous",
              newDietPeriods
            );
          }
        }
        /* Finalize/Save Action */
        if (action === "finalize") {
          dispatch(PurchaseScenarioActions.selectDietStrategyPeriod(null));
          /* Update the state */
          setDietPeriodIndexSelected(null);
          setModalVisible(false);
          setStartDateDeadline(null);
          setEndDateDeadline(null);
        }

        /* Next Action */
        if (action === "next") {
          dispatch(
            PurchaseScenarioActions.selectDietStrategyPeriod(
              newDietPeriods.find((ds, i) => index + 1 === i)
            )
          );
          /* Update the state */
          setDietPeriodIndexSelected(index + 1);
        }

        /* Previous Action */
        if (action === "previous") {
          dispatch(
            PurchaseScenarioActions.selectDietStrategyPeriod(
              newDietPeriods.find((ds, i) => index - 1 === i)
            )
          );
          /* Update the state */
          setDietPeriodIndexSelected(index - 1);
        }

        /** Update the startDateDeadline and endDateDeadline */
        if (action === "next" || action === "previous") {
          const updatedIndex =
            action === "next" ? index + 1 : action === "previous" && index - 1;

          if (newDietPeriods.length > 0) {
            if (newDietPeriods.find((ds, i) => i === updatedIndex + 1)) {
              endDateDeadline = moment(
                newDietPeriods.find((ds, i) => i === updatedIndex + 1).endDate
              )
                .subtract(2, "days")
                .format("YYYY-MM-DD");
            } else {
              endDateDeadline = moment(initialSimulationDate)
                .add(548, "days")
                .format("YYYY-MM-DD");
            }

            if (newDietPeriods.find((ds, i) => i === updatedIndex - 1)) {
              startDateDeadline = moment(
                newDietPeriods.find((ds, i) => i === updatedIndex - 1).startDate
              )
                .add(2, "days")
                .format("YYYY-MM-DD");
            } else {
              startDateDeadline = moment(initialSimulationDate).format(
                "YYYY-MM-DD"
              );
            }
          }
          setStartDateDeadline(startDateDeadline);
          setEndDateDeadline(endDateDeadline);
        }
      }

      /* Update formDietStrategy periods */
      dispatch(
        PurchaseScenarioActions.updateDietStrategy({
          ...formDietStrategy,
          dietPeriods: newDietPeriods,
        })
      );

      /* Recalculate DietPeriodsCurve */
      handleRecalculateChart(newDietPeriods);
    }
  }

  /** Helper method used in  handleModalActions */
  function handelChangeNextOrPreviusPeriod(
    currentStartDay,
    currentStartDate,
    currentEndDay,
    currentEndDate,
    index,
    type,
    dietPeriods
  ) {
    let newDietPeriods = null;
    let updatedPeriod = dietPeriods.find((dp, i) => i === index);

    let startDate = null;
    let endDate = null;
    let startDay = null;
    let endDay = null;

    /* Manipulate the dates and days */

    if (type === "next") {
      startDate = moment(currentEndDate).add(1, "days").format("YYYY-MM-DD");
      startDay = currentEndDay + 1;
      endDate = updatedPeriod.endDate;
      endDay = updatedPeriod.endDay;
    } else if (type === "previous") {
      startDate = updatedPeriod.startDate;
      startDay = updatedPeriod.startDay;
      endDate = moment(currentStartDate)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      endDay = currentStartDay - 1;
    }

    /* Recalculate season */
    let season = [];
    let i = startDay;
    do {
      const currentMonth = Number(
        moment(newPurchaseScenario.initialSimulationDate).add(i, "days").month()
      );
      let loopSeason = seasons.find((s) => s.month === currentMonth + 1).season;

      if (!season.includes(loopSeason)) {
        season.push(loopSeason);
      }

      i++;
    } while (i < endDay);

    /* Update the period (previous or next) */
    updatedPeriod = {
      ...updatedPeriod,
      startDate,
      endDate,
      startDay,
      endDay,
      season: season.join(","),
    };

    /* Update dietPeriods */
    newDietPeriods = dietPeriods.map((ds, i) =>
      i === index ? updatedPeriod : ds
    );

    /* Update state */
    dispatch(
      PurchaseScenarioActions.updateDietStrategy({
        ...formDietStrategy,
        dietPeriods: newDietPeriods,
      })
    );

    return newDietPeriods;
  }

  return (
    <>
      <RoutingLeavingGuardModal
        when={shouldBlockNavigation}
        navigate={(path) => history.push(path)}
        message="Parece que você está saindo sem salvar o cenário de compra."
      />
      <Container>
        <Spin spinning={isLoading}>
          {/* Message and buttons */}
          <Row className="headerButtonsAndMessagens" align="middle">
            <Col
              xs={{ span: 1, offset: 0 }}
              sm={{ span: 1, offset: 0 }}
              md={{ span: 1, offset: 0 }}
              lg={{ span: 1, offset: 0 }}
              xl={{ span: 1, offset: 0 }}
            >
              <Icon
                type="arrow-left"
                className="iconBack"
                onClick={goPreviousTab}
              />
            </Col>
            <Col
              xs={{ span: activeTab !== "tab1" ? 15 : 16, offset: 0 }}
              sm={{ span: activeTab !== "tab1" ? 15 : 16, offset: 0 }}
              md={{ span: activeTab !== "tab1" ? 15 : 16, offset: 0 }}
              lg={{ span: activeTab !== "tab1" ? 15 : 16, offset: 0 }}
              xl={{ span: activeTab !== "tab1" ? 15 : 16, offset: 0 }}
            >
              <strong>Complete os campos de parâmetros da compra</strong>
            </Col>
            <Col
              xs={{ span: 8, offset: 0 }}
              sm={{ span: 8, offset: 0 }}
              md={{ span: 8, offset: 0 }}
              lg={{ span: 8, offset: 0 }}
              xl={{ span: 8, offset: 0 }}
              align="right"
              className="buttonsCol"
            >
              <ButtonStandard
                type="button"
                buttonType="type4"
                size="s"
                onClick={handleCancel}
              >
                {translation.buttons.cancel}
              </ButtonStandard>
              <PopConfirmCustom
                title={
                  <>
                    {" "}
                    Ao confirmar, reprocesse todos os cenários de venda <br />
                    e compra - Favoritos que utilizam esta Estratégia de <br />
                    dieta como Baseline{" "}
                  </>
                }
                cancelText="Cancelar"
                okText="Confirmar"
                placement="bottomRight"
                icon={
                  <Icon
                    type="exclamation-circle"
                    theme="filled"
                    style={{ fontSize: 18, color: "#D44C4C" }}
                  />
                }
                onConfirm={savePurchaseScenario}
              >
                <ButtonStandard
                  type="button"
                  buttonType="type1"
                  width="121px"
                  height="35px"
                  padding="5px 10px 5px 10px"
                >
                  {isEditing ? "Atualizar" : "Criar"}
                </ButtonStandard>
              </PopConfirmCustom>
            </Col>
          </Row>
          <Body>
            <Row type="flex">
              <Col span={24} className="colName">
                <Header>
                  <Row type="flex">
                    <Col span={8}>
                      <span className="title">{newPurchaseScenario?.name}</span>
                    </Col>
                    <Col span={16} className="backgroundGray">
                      <span>
                        Raça:
                        <strong>{` ${newPurchaseScenario?.breedName}`}</strong>
                      </span>
                      <span>
                        Gênero:
                        <strong>{` ${
                          newPurchaseScenario?.gender === "Male"
                            ? "Macho"
                            : "Fêmea"
                        }`}</strong>
                      </span>
                      {newPurchaseScenario?.gender === "Male" && (
                        <span>
                          Capado:
                          <strong>{` ${
                            newPurchaseScenario?.cap ? "Sim" : "Não"
                          }`}</strong>
                        </span>
                      )}
                      <span>
                        Peso assintótico:
                        <strong>{` ${newPurchaseScenario?.asymptoticWeight} Kg`}</strong>
                      </span>
                      <span>
                        Sistema:
                        <strong>{` ${
                          newPurchaseScenario?.productionSubSystem ===
                          "Extensive"
                            ? translation.productionSubSystem.extensive
                            : newPurchaseScenario?.productionSubSystem ===
                              "SemiIntensive"
                            ? translation.productionSubSystem.semiIntensive
                            : translation.productionSubSystem.intensive
                        }`}</strong>
                      </span>
                    </Col>
                  </Row>
                </Header>
                <Spin spinning={loadingChart}>
                  <ChartContainer>
                    {formDietStrategy?.dietPeriods.length > 0 ? (
                      <CanvasJSChart options={chartOptions} />
                    ) : (
                      <Empty
                        style={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: 5,
                          marginBottom: 5,
                        }}
                        description="Por favor, informe ao menos uma dieta para exibir o gráfico."
                      />
                    )}
                  </ChartContainer>
                </Spin>
                {/* Periods */}
                <ListPeriodsContainer>
                  <ListPeriodsTitleContainer>
                    <span>
                      Períodos
                      <br />
                      climáticos
                    </span>
                  </ListPeriodsTitleContainer>
                  <ListPeriodsItemContainer>
                    {formDietStrategy?.dietPeriods.map((period, index) => (
                      <ListPeriodsItem
                        key={index}
                        onClick={() => handleSelectPeriod(index)}
                        className={
                          dietPeriodIndexSelected === index ? "selected" : ""
                        }
                      >
                        {dietPeriodIndexSelected === index ? (
                          <div className="itemContainer">
                            <div className="iconContainer">
                              <TextToolIcon />
                            </div>
                            <div className="timeContainer">
                              <span className="info">
                                Informando <br />
                                dados...
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="itemContainer">
                            <div className="iconContainer">
                              {period.season
                                .split(",")
                                .map((season) =>
                                  season === "Water" ? (
                                    <RainIcon key="W" />
                                  ) : season === "Dry" ? (
                                    <SunIcon key="D" />
                                  ) : season === "Transition" ? (
                                    <TransitionIcon key="T" />
                                  ) : null
                                )}
                            </div>
                            <div className="timeContainer">
                              <div className="time">
                                <span>
                                  {moment(period.startDate).format("MMM YY")}
                                </span>
                                <span>
                                  {moment(period.endDate).format("MMM YY")}
                                </span>
                              </div>
                              <div className="icons">
                                {period.conflictedGdp && (
                                  <Tooltip title="Dieta com GDP conflitante">
                                    <div>
                                      <AlertIcon />
                                    </div>
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </ListPeriodsItem>
                    ))}
                    <ListPeriodsItemPlus onClick={() => handleCreatePeriod()}>
                      <span>
                        Adicionar
                        <br />
                        <strong>Dieta</strong>
                      </span>
                      <PlusCirclePurpleIcon />
                    </ListPeriodsItemPlus>
                  </ListPeriodsItemContainer>
                </ListPeriodsContainer>
              </Col>
            </Row>
          </Body>
        </Spin>
        {/* Modal */}
        <PeriodModel
          dietStrategyData={formDietStrategy}
          visible={modalVisible}
          index={dietPeriodIndexSelected}
          seasons={seasons}
          onAction={handleModalActions}
          lotCurveInfo={{
            correctionFactor:
              newPurchaseScenario.breedCurveParamSelected?.correctionFactor,
            maturationRate:
              newPurchaseScenario.breedCurveParamSelected?.maturationRate,
            theoreticalCurveType:
              newPurchaseScenario.curveSelected?.theoreticalCurveType,
            asymptoticWeight:
              newPurchaseScenario.curveSelected?.asymptoticWeight,
          }}
          pastures={listOfPastures}
          supplements={listOfSupplements}
          startDateDeadline={startDateDeadline}
          //endDateDeadline={endDateDeadline}
          dietStrategyPeriodCurveDatesAndWeights={
            dietStrategyPeriodCurveDatesAndWeights
          }
          isEditing={isEditing}
          carbonFootprintParameters={carbonFootprintParameters}
          carbonBaseKg={carbonBaseKg}
          carbonCredit={carbonCredit}
        />
      </Container>
    </>
  );
};

export default withRouter(PurchaseDietStrategyTab);
