import axios from "axios";
import { Formik } from "formik";
import { cloneDeep } from "lodash";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { v4 as uuidV4 } from "uuid";
import * as Yup from "yup";
import usePicketSupplementSupplyContext from "../../../hooks/usePicketSupplementSupplyContext";

// Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Icon,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
  notification,
} from "antd";

import NumberFormat from "react-number-format";
import ButtonStandard from "../../utils/button";
import { Container, Footer } from "./styles";

// Services
import { findFarmDayScaleTreatmentParameterData } from "../../../services/farmDayScaleTreatmentParameterService";
import { numberMask } from "../../../services/helpersMethodsService";
import { getLotIndexDropDown } from "../../../services/lotService";
import {
  findAllBySupplyDate,
  findBySupplyDateAndSupplyNumber,
  getAllPicketsBySupplyDate,
  save,
} from "../../../services/picketSupplementSupplyService";
import { getOnlyActives } from "../../../services/retreatService";
import { getSupplementIndexDropDown } from "../../../services/supplementService";

const validationSchema = Yup.object({
  supplyDate: Yup.string().required(),
  responsibleName: Yup.string().required(),
  supplyNumber: Yup.string().required(),
});

const DEFAULT_FORM_STATE_VALUES = {
  id: null,
  supplyDate: moment(),
  supplyNumber: 1,
  responsibleName: null,
  status: "Active",
  editingSupply: false,
  pickets: [],
};
const TODAY = moment();
const TREAT_SCALE_HOUR_KEY_MAP = {
  1: "treatOneInHourAndMinutesFormat",
  2: "treatTwoInHourAndMinutesFormat",
  3: "treatThreeInHourAndMinutesFormat",
  4: "treatFourInHourAndMinutesFormat",
  5: "treatFiveInHourAndMinutesFormat",
  6: "treatSixInHourAndMinutesFormat",
  7: "treatSevenInHourAndMinutesFormat",
  8: "treatEightInHourAndMinutesFormat",
};

const DrawerPicketSupplementSupply = () => {
  const formRef = useRef(null);

  // Redux Variable
  const {
    translation,
    user,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const [saveOptions, setSaveOptions] = useState("save_and_create_new");
  const [form, setForm] = useState(() => ({
    ...DEFAULT_FORM_STATE_VALUES,
    responsibleName: user?.fullName,
  }));
  const [dateSupply, setDateSupply] = useState(moment());
  const [supplyNumber, setSupplyNumber] = useState(1);
  const [lastSupplyNumber, setLastSupplyNumber] = useState(null);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  const [dayScaleTreatParameters, setDayScaleTreatParameters] = useState([]);

  const [isLoadingPicketList, setIsLoadingPicketList] = useState(false);
  const [allPickets, setAllPickets] = useState([]);
  const [picketsFiltered, setPicketsFiltered] = useState([]);
  const [supplementsFiltered, setSupplementsFiltered] = useState([]);
  const [filterPicketsIdSelected, setFilterPicketsIdSelected] = useState([]);

  const [isLoadingRetreatList, setIsLoadingRetreatList] = useState(false);
  const [allRetreats, setAllRetreats] = useState([]);
  const [filterRetreatIdSelected, setFilterRetreatIdSelected] = useState([]);

  const [isLoadingSupplementsList, setIsLoadingSupplementsList] =
    useState(false);
  const [allSupplements, setAllSupplements] = useState([]);

  const [isLoadingLotsList, setIsLoadingLotsList] = useState(false);
  const [allLots, setAllLots] = useState([]);
  const [filterLotIdSelected, setFilterLotIdSelected] = useState([]);

  const [filterSubSystemSelected, setFilterSubSystemSelected] = useState([]);

  const [defaultQuantitySupplied, setDefaultQuantitySupplied] = useState(null);
  const [defaultSupplement, setDefaultSupplement] = useState(null);
  const [defaultHourSupplied, setDefaultHourSupplied] = useState(null);

  const signal = axios.CancelToken.source();

  const allSubSystems = [
    {
      id: "E",
      label: "extensive",
    },
    { id: "SI", label: "semiIntensive" },
    { id: "I", label: "intensive" },
  ];

  const { isDrawerVisible, data, closeDrawer, fetchData } =
    usePicketSupplementSupplyContext();

  const selectPicketsList = useMemo(() => {
    let pickets = allPickets;
    if (allRetreats.length > 0 && filterRetreatIdSelected.length > 0) {
      return allRetreats
        .filter((retreat) => filterRetreatIdSelected.includes(retreat.id))
        .flatMap((retreat) => retreat.pickets)
        .filter(
          (picket) => picket.status === "Pasturage" || picket.status === "P"
        );
    }
    return pickets;
  }, [allPickets, allRetreats, filterRetreatIdSelected]);

  // Method
  const incrementPicketArray = useCallback(
    async (pickets, date, numberSupply = null) => {
      let newPicketsData = pickets;
      if (
        formRef.current &&
        dayScaleTreatParameters &&
        dayScaleTreatParameters.length > 0
      ) {
        setIsLoadingRequest(true);
        try {
          const extensiveDayScaleTreat = dayScaleTreatParameters.filter(
            (scale) => scale.productionSubSystem === "Extensive"
          )[0];
          const intensiveDayScaleTreat = dayScaleTreatParameters.filter(
            (scale) => scale.productionSubSystem === "Intensive"
          )[0];

          const {
            data: { results: suppliesOnDate },
          } = await findAllBySupplyDate({
            groupId,
            farmId,
            supplyDate: moment(date).toDate(),
          });

          let lastSupplyNumber = 0;
          if (suppliesOnDate.length > 0) {
            const lastSupplyOnDate = suppliesOnDate[suppliesOnDate.length - 1];

            lastSupplyNumber = lastSupplyOnDate.supplyNumber;
            setLastSupplyNumber(lastSupplyNumber);
          } else {
            setLastSupplyNumber(null);
          }

          // Get info about the last treat with same number supply in previous day
          const {
            data: { results: lastSupplyOnDateMinusAndNumber },
          } = await findBySupplyDateAndSupplyNumber({
            groupId,
            farmId,
            supplyDate: moment(date).subtract(1, "days").toDate(),
            supplyNumber:
              numberSupply && numberSupply > 0
                ? numberSupply
                : lastSupplyNumber + 1,
          });

          if (lastSupplyOnDateMinusAndNumber) {
            newPicketsData = pickets.map((picket) => {
              const previousPicketSupplyIndex =
                lastSupplyOnDateMinusAndNumber.pickets.findIndex(
                  (supply) => supply.picketId === picket.picketId
                );
              if (previousPicketSupplyIndex >= 0) {
                picket.previousQuantitySupplied =
                  lastSupplyOnDateMinusAndNumber.pickets[
                    previousPicketSupplyIndex
                  ].quantitySupplied;
              }
              return picket;
            });
          } else {
            newPicketsData = pickets.map((picket) => {
              picket.previousQuantitySupplied = null;
              return picket;
            });
          }

          // If user input a different number of supply get info about it
          if (numberSupply && numberSupply > 0) {
            const {
              data: { results: supplyOnDateAndNumber },
            } = await findBySupplyDateAndSupplyNumber({
              groupId,
              farmId,
              supplyDate: moment(date).toDate(),
              supplyNumber: numberSupply,
            });
            if (supplyOnDateAndNumber) {
              newPicketsData = pickets.map((picket) => {
                const currentPicketSupplyIndex =
                  supplyOnDateAndNumber.pickets.findIndex(
                    (supply) => supply.picketId === picket.picketId
                  );
                if (currentPicketSupplyIndex >= 0) {
                  picket.quantitySupplied =
                    supplyOnDateAndNumber.pickets[
                      currentPicketSupplyIndex
                    ].quantitySupplied;
                  picket.supplementId =
                    supplyOnDateAndNumber.pickets[
                      currentPicketSupplyIndex
                    ].supplementId;
                  picket.dryMatterPercentage =
                    supplyOnDateAndNumber.pickets[
                      currentPicketSupplyIndex
                    ].dryMatterPercentage;
                  picket.observation =
                    supplyOnDateAndNumber.pickets[
                      currentPicketSupplyIndex
                    ].observation;
                  // Transform minutes int into text HH:mm
                  if (
                    supplyOnDateAndNumber.pickets[currentPicketSupplyIndex]
                      .supplyHourInMinutes
                  ) {
                    const hour = Math.floor(
                      supplyOnDateAndNumber.pickets[currentPicketSupplyIndex]
                        .supplyHourInMinutes / 60
                    );
                    const minutes =
                      supplyOnDateAndNumber.pickets[currentPicketSupplyIndex]
                        .supplyHourInMinutes % 60;
                    picket.supplyHourInMinutes = `${hour.toLocaleString(
                      "pt-BR",
                      {
                        minimumIntegerDigits: 2,
                        minimumFractionDigits: 0,
                        useGrouping: false,
                      }
                    )}:${minutes.toLocaleString("pt-BR", {
                      minimumIntegerDigits: 2,
                      minimumFractionDigits: 0,
                      useGrouping: false,
                    })}`;
                  }
                }
                return picket;
              });
              formRef.current.setFieldValue("id", supplyOnDateAndNumber.id);
            } else {
              formRef.current.setFieldValue("id", null);
            }
          } else if (numberSupply === 0) {
            newPicketsData = pickets.map((picket) => {
              picket.quantitySupplied = null;
              picket.supplementId = null;
              picket.dryMatterPercentage = null;
              return picket;
            });
            formRef.current.setFieldValue("id", null);
            formRef.current.setFieldValue("editingSupply", false);
          } else {
            formRef.current.setFieldValue("id", null);
            formRef.current.setFieldValue("editingSupply", false);
          }

          // Update supplyNumber
          if (suppliesOnDate.length > 0 && numberSupply === null) {
            formRef.current.setFieldValue("supplyNumber", lastSupplyNumber + 1);
            setSupplyNumber(lastSupplyNumber + 1);
          } else if (numberSupply === null) {
            formRef.current.setFieldValue("supplyNumber", 1);
            setSupplyNumber(1);
          }

          // Complete pickets with previous hour
          newPicketsData = newPicketsData.map((picket) => {
            let prevHour = null;
            if (
              picket.productionSubSystem === "Extensive" ||
              picket.productionSubSystem === "SemiIntensive"
            ) {
              prevHour = extensiveDayScaleTreat
                ? extensiveDayScaleTreat[
                    TREAT_SCALE_HOUR_KEY_MAP[
                      numberSupply
                        ? numberSupply
                        : suppliesOnDate.length > 0
                        ? lastSupplyNumber + 1
                        : 1
                    ]
                  ]
                : null;
            } else {
              prevHour = intensiveDayScaleTreat
                ? intensiveDayScaleTreat[
                    TREAT_SCALE_HOUR_KEY_MAP[
                      numberSupply
                        ? numberSupply
                        : suppliesOnDate.length > 0
                        ? lastSupplyNumber + 1
                        : 1
                    ]
                  ]
                : null;
            }
            if (prevHour) {
              const [hour, minute] = prevHour.split(":");
              const minutesValue = Number(hour) * 60 + Number(minute);
              picket.prevSupplyHourInMinutes = minutesValue;
            }

            return picket;
          });

          // Update pickets
          formRef.current.setFieldValue("pickets", newPicketsData);
        } catch (error) {
          console.error(error);
          formRef.current.setFieldValue("pickets", newPicketsData);
        } finally {
          setIsLoadingRequest(false);
        }
      }
    },
    [dayScaleTreatParameters, groupId, farmId]
  );

  function cleanFields(shouldCleanPickets) {
    setForm(DEFAULT_FORM_STATE_VALUES);
    formRef.current.resetForm(DEFAULT_FORM_STATE_VALUES);
    setDateSupply(moment());
    setPicketsFiltered([]);

    setFilterRetreatIdSelected([]);
    setFilterPicketsIdSelected([]);
    setFilterSubSystemSelected([]);

    setDefaultQuantitySupplied(null);
    setDefaultSupplement(null);
    setDefaultHourSupplied(null);
  }

  const executeAfterChangeSupplyNumber = useCallback(
    (values, setFieldValue) => {
      if (values.supplyNumber <= lastSupplyNumber) {
        Modal.confirm({
          content: (
            <h2>
              Já existe um lançamento de fornecimento para este trato nesta
              data, deseja substituílo?
            </h2>
          ),
          centered: true,
          onOk: () => {
            incrementPicketArray(
              values.pickets,
              values.supplyDate,
              values.supplyNumber
            );
            setFieldValue("editingSupply", true);
          },
          onCancel: () => {
            setFieldValue("supplyNumber", lastSupplyNumber + 1);
            setSupplyNumber(lastSupplyNumber + 1);
            setFieldValue("editingSupply", false);
            incrementPicketArray(values.pickets, values.supplyDate, 0);
          },
        });
      } else {
        incrementPicketArray(values.pickets, values.supplyDate, 0);
        setFieldValue("editingSupply", false);
      }
    },
    [incrementPicketArray, lastSupplyNumber]
  );

  const executeAfterChangeSupplyDate = useCallback(
    async (dateSupply, picketsFiltered) => {
      if (formRef.current) {
        try {
          if (!picketsFiltered || picketsFiltered.length === 0) {
            const {
              data: { results },
            } = await getAllPicketsBySupplyDate({
              groupId,
              farmId,
              supplyDate: dateSupply.format("YYYY-MM-DD"),
            });
            const filterPicketsActiveOrPasturage = results.filter(
              (picket) =>
                (picket.picketStatus === "Pasturage" ||
                  picket.picketStatus === "P") &&
                picket.animalCount > 0
            );
            setAllPickets(filterPicketsActiveOrPasturage);
            setPicketsFiltered(filterPicketsActiveOrPasturage);

            picketsFiltered = filterPicketsActiveOrPasturage;
          }
          const newPickets = picketsFiltered.map((picket) => {
            let supplementIdSuggested = null;
            let dryMatterPercentageSuggested = null;
            let permitedSupplementsIds = [];
            if (picket.dietStrategyId !== null) {
              const dietPeriods = picket.dietStrategyPeriods || [];
              permitedSupplementsIds = dietPeriods.map(
                (period) => period.supplement?.id
              );
              const periodSelectedIndex = dietPeriods.findIndex((period) =>
                moment(dateSupply).isBetween(
                  moment(period.startDate),
                  moment(period.endDate)
                )
              );
              if (periodSelectedIndex >= 0) {
                const period = dietPeriods[periodSelectedIndex];
                supplementIdSuggested = period.supplement?.id;
                dryMatterPercentageSuggested =
                  period.supplement?.dryMatterPercentage;
              }
            }
            setSupplementsFiltered((old) => [
              ...new Set([...old, ...permitedSupplementsIds]),
            ]);
            return {
              id: uuidV4(),
              picketId: picket.picketId,
              productionSubSystem: picket.productionSubSystem,
              picketName: picket.picketName,
              lotId: picket.lotId,
              lotName: picket.lotName,
              animalsCount: picket.animalCount,
              avgWeight: picket.avgWeight,
              avgGdp: picket.avgGdp,
              previousQuantitySupplied: null,
              quantitySupplied: null,
              supplementId: supplementIdSuggested,
              dryMatterPercentage: dryMatterPercentageSuggested,
              permitedSupplementsIds,
              observation: null,
              prevSupplyHourInMinutes: null,
              supplyHourInMinutes: null,
            };
          });
          if (dateSupply != null) {
            incrementPicketArray(newPickets, dateSupply);
          } else {
            if (formRef.current) {
              formRef.current.setFieldValue("pickets", newPickets);
            }
          }
        } catch (error) {
          console.error(
            "ERROR WHEN HANDLE AFTER CHANGE SUPPLY DATE. Error: " + error
          );
        }
      }
    },
    [farmId, groupId, incrementPicketArray]
  );

  function validatePickets(pickets) {
    let errorMessage = "";
    if (pickets.length === 0)
      errorMessage = "Nenhum piquete informado a oferta";
    if (
      pickets.filter((picket) => picket.quantitySupplied === null).length ===
      pickets.length
    )
      errorMessage = "É necessário informar ao menos uma oferta.";

    if (
      pickets.filter(
        (picket) =>
          picket.quantitySupplied != null &&
          (picket.supplementId === null || picket.supplementId === undefined)
      ).length > 0
    )
      errorMessage =
        "É necessário informar um suplemento para todos os piquetes com Qtd. Ofertada preenchida.";

    if (!!errorMessage)
      notification.error({
        message: errorMessage,
      });

    return !!errorMessage;
  }

  async function getDayScaleTreatParameters() {
    try {
      const {
        data: { results },
      } = await findFarmDayScaleTreatmentParameterData({
        groupId,
        farmId,
      });
      setDayScaleTreatParameters(results);
      return results;
    } catch (error) {
      return [];
    }
  }

  async function getAllPickets(dayScaleTreat, supplyDate = TODAY) {
    setIsLoadingPicketList(true);
    try {
      const {
        data: { results },
      } = await getAllPicketsBySupplyDate({
        groupId,
        farmId,
        supplyDate: supplyDate.format("YYYY-MM-DD"),
        signal,
      });
      const filterPicketsActiveOrPasturage = results.filter(
        (picket) =>
          (picket.picketStatus === "Pasturage" ||
            picket.picketStatus === "P") &&
          picket.animalCount > 0
      );
      setAllPickets(filterPicketsActiveOrPasturage);
      setPicketsFiltered(filterPicketsActiveOrPasturage);
      executeAfterChangeSupplyDate(TODAY, filterPicketsActiveOrPasturage);
    } catch (error) {
    } finally {
      setIsLoadingPicketList(false);
    }
  }

  async function getAllRetreats() {
    setIsLoadingRetreatList(true);
    try {
      const {
        data: { results },
      } = await getOnlyActives({ groupId, farmId, signal });
      setAllRetreats(results);
    } catch (error) {
    } finally {
      setIsLoadingRetreatList(false);
    }
  }

  async function getAllSupplements() {
    setIsLoadingSupplementsList(true);
    try {
      const {
        data: { results },
      } = await getSupplementIndexDropDown({ groupId, farmId, signal });
      setAllSupplements(results);
    } catch (error) {
    } finally {
      setIsLoadingSupplementsList(false);
    }
  }

  async function getAllLots() {
    setIsLoadingLotsList(true);
    try {
      const {
        data: { results },
      } = await getLotIndexDropDown({ groupId, farmId, signal });
      const lotsActiveOrPending = results.filter(
        (lot) =>
          lot.status === "Active" ||
          lot.status === "Pending" ||
          lot.status === "A" ||
          lot.status === "P"
      );
      setAllLots(lotsActiveOrPending);
    } catch (error) {
    } finally {
      setIsLoadingLotsList(false);
    }
  }

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  const handleGetColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys ? selectedKeys[0] : undefined}
            placeholder="Digite sua pesquisa"
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return record[dataIndex]
          ?.toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    }),
    [handleReset, handleSearch]
  );

  function handleCloseDrawer() {
    cleanFields();
    signal.cancel();
    closeDrawer();
  }

  function handleSubmitForm(values) {
    if (!validatePickets(values.pickets)) {
      const hasNoPreviousSupplyNumber = values.pickets.some(
        (picket) => picket.prevSupplyHourInMinutes === null
      );
      if (hasNoPreviousSupplyNumber) {
        Modal.confirm({
          title: "Atenção!",
          content: "Esse trato não estava previsto. Deseja continuar?",
          centered: true,
          okText: "Não",
          cancelText: "Sim",
          onCancel: () => {
            handleSave(cloneDeep(values));
          },
        });
      } else {
        handleSave(cloneDeep(values));
      }
    }
  }

  async function handleSave(bodyP) {
    setIsLoadingRequest(true);
    const body = {
      ...bodyP,
      pickets: [
        ...bodyP.pickets
          .filter((picket) => picket.quantitySupplied !== null)
          .map((picket) => {
            if (!!picket.supplyHourInMinutes) {
              const [hour, minutes] = picket.supplyHourInMinutes.split(":");
              const hourInMinutes = Number(hour) * 60 + Number(minutes);
              picket.supplyHourInMinutes = hourInMinutes;
            }
            return picket;
          }),
      ],
    };

    try {
      await save({ groupId, farmId, id: body?.id, body });
      notification.success({
        message: "Fornecimento cadastrado/atualizado com sucesso.",
      });
      fetchData();
      if (saveOptions === "save_and_create_new") {
        const newPickets = picketsFiltered.map((picket) => {
          let supplementIdSuggested = null;
          let dryMatterPercentageSuggested = null;
          let permitedSupplementsIds = [];
          if (picket.dietStrategyId !== null) {
            const dietPeriods = picket.dietStrategyPeriods || [];
            permitedSupplementsIds = dietPeriods.map(
              (period) => period.supplement?.id
            );
            const periodSelectedIndex = dietPeriods.findIndex((period) =>
              moment(dateSupply).isBetween(
                moment(period.startDate),
                moment(period.endDate),
                "days",
                "[]"
              )
            );
            if (periodSelectedIndex >= 0) {
              const period = dietPeriods[periodSelectedIndex];
              supplementIdSuggested = period.supplement?.id;
              dryMatterPercentageSuggested =
                period.supplement?.dryMatterPercentage;
            }
          }
          setSupplementsFiltered((old) => [
            ...new Set([...old, ...permitedSupplementsIds]),
          ]);
          return {
            id: uuidV4(),
            picketId: picket.picketId,
            productionSubSystem: picket.productionSubSystem,
            picketName: picket.picketName,
            lotId: picket.lotId,
            lotName: picket.lotName,
            animalsCount: picket.animalCount,
            avgWeight: picket.avgWeight,
            avgGdp: picket.avgGdp,
            previousQuantitySupplied: null,
            quantitySupplied: null,
            supplementId: supplementIdSuggested,
            dryMatterPercentage: dryMatterPercentageSuggested,
            permitedSupplementsIds,
            observation: null,
            prevSupplyHourInMinutes: null,
            supplyHourInMinutes: null,
          };
        });
        incrementPicketArray(newPickets, dateSupply);
      } else {
        setIsLoadingRequest(false);
        handleCloseDrawer();
      }
    } catch (error) {
      setIsLoadingRequest(false);
      console.error(error);
      notification.error({
        message: "Erro",
        description: "Erro ao cadastrar/editar fornecimento.",
      });
    }
  }

  async function handleDrawerVisible(visible) {
    if (visible) {
      setIsLoadingRequest(true);
      setSaveOptions("save_and_create_new");
      setSupplyNumber(1);
      setLastSupplyNumber(null);
      formRef.current.setFieldValue("responsibleName", user?.fullName);
      const [, , , dayScaleTreat] = await Promise.all([
        getAllRetreats(),
        getAllSupplements(),
        getAllLots(),
        getDayScaleTreatParameters(),
      ]);

      await getAllPickets(dayScaleTreat);
    }
  }

  function handleApplyFilter() {
    let newPickets = allPickets;
    // Filter pickets by pickets's id selected
    if (filterPicketsIdSelected.length > 0) {
      newPickets = newPickets.filter((picket) =>
        filterPicketsIdSelected.includes(picket.picketId)
      );
    }
    // Filter pickets by retreat's id selected
    if (filterRetreatIdSelected.length > 0) {
      if (allRetreats.length > 0 && filterRetreatIdSelected.length > 0) {
        const retreatPicketIds = allRetreats
          .filter((retreat) => filterRetreatIdSelected.includes(retreat.id))
          .flatMap((retreat) => retreat.pickets)
          .map((picket) => picket.picketId);
        newPickets = newPickets.filter((picket) =>
          retreatPicketIds.includes(picket.picketId)
        );
      }
    }

    // Filter pickets by lot's id selected
    if (filterLotIdSelected.length > 0) {
      newPickets = newPickets.filter((picket) =>
        filterLotIdSelected.includes(picket.lotId)
      );
    }
    // Filter pickets by sub systems selected
    if (filterSubSystemSelected.length > 0) {
      newPickets = newPickets.filter((picket) =>
        filterSubSystemSelected.includes(picket.productionSubSystem)
      );
    }
    setPicketsFiltered(newPickets);
    executeAfterChangeSupplyDate(dateSupply, newPickets);
  }

  function handleApplyDefaultFieldValues(setFieldValue, picketsValues) {
    const shouldUpdateWeight = !!defaultQuantitySupplied;
    const shouldUpdateSupplement = !!defaultSupplement;
    const shouldUpdateHourSupplied = !!defaultHourSupplied;

    const newPicketsValues = picketsValues.map((picket) => {
      if (shouldUpdateSupplement && !!picket.supplementId === false) {
        const dryMatterPercentage = allSupplements.find(
          (supplement) => supplement.id === defaultSupplement
        ).dryMatterPercentage;
        picket.supplementId = defaultSupplement;
        picket.dryMatterPercentage = dryMatterPercentage;
      }
      if (shouldUpdateWeight && !!picket.quantitySupplied === false) {
        picket.quantitySupplied = defaultQuantitySupplied;
      }
      if (shouldUpdateHourSupplied && !!picket.supplyHourInMinutes === false) {
        picket.supplyHourInMinutes = defaultHourSupplied;
      }
      picket.prevSuppliedPerAnimal =
        picket.quantitySupplied / picket.animalsCount;
      const dryMatterSupplied = picket.dryMatterPercentage
        ? (picket.quantitySupplied / picket.animalsCount) *
          (picket.dryMatterPercentage / 100)
        : 0;
      picket.prevSuppliedDryMatterByWeight =
        (dryMatterSupplied / picket.avgWeight) * 100;
      return picket;
    });
    setFieldValue("pickets", newPicketsValues);
  }

  const handleChangeValuesTable = useCallback(
    (setFieldValue, field, newValue, picketsValues, id) => {
      const newPicketsValues = picketsValues.map((picket) => {
        if (picket.id === id) {
          picket[field] = newValue;
          if (field === "quantitySupplied") {
            picket.prevSuppliedPerAnimal = newValue / picket.animalsCount;
            const dryMatterSupplied = picket.dryMatterPercentage
              ? (newValue / picket.animalsCount) *
                (picket.dryMatterPercentage / 100)
              : 0;
            picket.prevSuppliedDryMatterByWeight =
              (dryMatterSupplied / picket.avgWeight) * 100;
          } else if (field === "dryMatterPercentage") {
            picket.prevSuppliedPerAnimal =
              picket.quantitySupplied / picket.animalsCount;
            const dryMatterSupplied = newValue
              ? (picket.quantitySupplied / picket.animalsCount) *
                (newValue / 100)
              : 0;
            picket.prevSuppliedDryMatterByWeight =
              (dryMatterSupplied / picket.avgWeight) * 100;
          }
        }
        return picket;
      });
      setFieldValue("pickets", newPicketsValues);
    },
    []
  );

  useEffect(() => {
    if (data !== null) {
      setForm({ ...data });
    }
  }, [data]);

  // Side effects when change dateSupply or picketsFiltered
  useEffect(() => {
    if (dateSupply) {
      executeAfterChangeSupplyDate(dateSupply);
    }
  }, [dateSupply, executeAfterChangeSupplyDate]);

  return (
    <Container
      title={
        data?.id == null
          ? translation.picketSupplementSupply.form.titleCreateNew
          : translation.picketSupplementSupply.form.titleEdit
      }
      width={"90vw"}
      onClose={handleCloseDrawer}
      maskClosable={false}
      visible={isDrawerVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <Spin spinning={isLoadingRequest}>
        <Formik
          ref={formRef}
          enableReinitialize={true}
          initialValues={form}
          onSubmit={handleSubmitForm}
          validationSchema={validationSchema}
          render={({
            values,
            errors,
            submitCount,
            setFieldValue,
            handleSubmit,
          }) => (
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className="drawerForm">
                {Object.entries(errors).length > 0 && submitCount > 0 && (
                  <Row type="flex" justify="center" align="middle">
                    <label className="error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
                <Row type="flex" gutter={8}>
                  <Col span={4}>
                    {/* supplyDate */}
                    <Row type="flex" className="rowLabel">
                      <label
                        className={
                          errors.supplyDate && submitCount > 0 ? "error" : ""
                        }
                      >
                        {translation.picketSupplementSupply.form.supplyDate}*
                      </label>
                    </Row>
                    <Row type="flex">
                      <DatePicker
                        name="supplyDate"
                        value={values.supplyDate}
                        allowClear={false}
                        format={"DD/MM/YYYY"}
                        disabledDate={(current) => current.isAfter(moment())}
                        onChange={(date) => {
                          setFieldValue("supplyDate", date);
                          setDateSupply(date);
                        }}
                      />
                    </Row>
                  </Col>
                  <Col span={4}>
                    {/* supplyNumber */}
                    <Row type="flex" className="rowLabel">
                      <label
                        className={
                          errors.supplyNumber && submitCount > 0 ? "error" : ""
                        }
                      >
                        {translation.picketSupplementSupply.form.supplyNumber}*
                      </label>
                    </Row>
                    <Row type="flex">
                      <InputNumber
                        name="supplyNumber"
                        max={8}
                        min={1}
                        value={values.supplyNumber || 1}
                        placeholder={translation.defaultPlaceholder}
                        autoCapitalize="off"
                        step={1}
                        onChange={(value) => {
                          setFieldValue("supplyNumber", value);
                          setSupplyNumber(value);
                        }}
                        onBlur={() => {
                          executeAfterChangeSupplyNumber(values, setFieldValue);
                        }}
                      />
                    </Row>
                  </Col>
                  <Col span={8}>
                    {/* responsibleName */}
                    <Row type="flex" className="rowLabel">
                      <label
                        className={
                          errors.responsibleName && submitCount > 0
                            ? "error"
                            : ""
                        }
                      >
                        {
                          translation.picketSupplementSupply.form
                            .responsibleName
                        }
                        *
                      </label>
                    </Row>
                    <Row type="flex">
                      <Input
                        name="responsibleName"
                        maxLength={30}
                        max={30}
                        value={values.responsibleName || undefined}
                        placeholder={translation.defaultPlaceholder}
                        autoCapitalize="off"
                        onChange={(e) => {
                          setFieldValue("responsibleName", e.target.value);
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                <Divider />
                {/* Filters */}
                <Row type="flex" justify="center" align="middle">
                  <Col>
                    <h2
                      style={{
                        color: "#9074bf",
                        marginBottom: 8,
                      }}
                    >
                      Filtros <Icon type="filter" />
                    </h2>
                  </Col>
                </Row>
                <Row type="flex" gutter={8} align="middle">
                  <Col span={6}>
                    {/* retreats */}
                    <Row type="flex" className="rowLabel">
                      <label>
                        {translation.picketSupplementSupply.form.retreats}
                      </label>
                    </Row>
                    <Row type="flex">
                      <Select
                        name="filterRetreatIdSelected"
                        value={filterRetreatIdSelected || undefined}
                        loading={isLoadingRetreatList}
                        allowClear
                        mode="multiple"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder={translation.defaultSelectPlaceholder}
                        onChange={(value) => {
                          setFilterRetreatIdSelected(value);
                          setFilterPicketsIdSelected([]);
                        }}
                      >
                        {allRetreats.map((retreat) => (
                          <Select.Option key={retreat.id} value={retreat.id}>
                            {retreat.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Row>
                  </Col>
                  <Col span={6}>
                    {/* pickets */}
                    <Row type="flex" className="rowLabel">
                      <label>
                        {translation.picketSupplementSupply.form.pickets}
                      </label>
                    </Row>
                    <Row type="flex">
                      <Select
                        name="filterPicketsIdSelected"
                        value={filterPicketsIdSelected || undefined}
                        loading={isLoadingPicketList}
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        mode="multiple"
                        placeholder={translation.defaultSelectPlaceholder}
                        onChange={(value) => setFilterPicketsIdSelected(value)}
                      >
                        {selectPicketsList.map((picket) => (
                          <Select.Option
                            key={picket.picketId}
                            value={picket.picketId}
                          >
                            {picket.picketName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Row>
                  </Col>
                  <Col span={3}>
                    {/* pickets */}
                    <Row type="flex" className="rowLabel">
                      <label>
                        {translation.picketSupplementSupply.form.lots}
                      </label>
                    </Row>
                    <Row type="flex">
                      <Select
                        name="filterLotIdSelected"
                        value={filterLotIdSelected || undefined}
                        loading={isLoadingLotsList}
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        mode="multiple"
                        placeholder={translation.defaultSelectPlaceholder}
                        onChange={(value) => setFilterLotIdSelected(value)}
                      >
                        {allLots.map((lot) => (
                          <Select.Option key={lot.id} value={lot.id}>
                            {lot.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Row>
                  </Col>
                  <Col span={3}>
                    {/* subSystems */}
                    <Row type="flex" className="rowLabel">
                      <label>
                        {translation.picketSupplementSupply.form.subSystems}
                      </label>
                    </Row>
                    <Row type="flex">
                      <Select
                        name="filterSubSystemSelected"
                        value={filterSubSystemSelected}
                        loading={isLoadingPicketList}
                        allowClear
                        mode="multiple"
                        placeholder={translation.defaultSelectPlaceholder}
                        onChange={(value) => setFilterSubSystemSelected(value)}
                      >
                        {allSubSystems.map((subSystem) => (
                          <Select.Option
                            key={subSystem.id}
                            value={subSystem.id}
                          >
                            {translation.productionSubSystem[subSystem.label]}
                          </Select.Option>
                        ))}
                      </Select>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row type="flex" justify="center">
                      <Tooltip title="Ao clicar em Aplicar, os dados da seção 'Fornecimento' serão sobreescritos pelos os piquetes filtrados.">
                        <ButtonStandard
                          tabIndex="-1"
                          type={"button"}
                          buttonType={"secundary"}
                          onClick={handleApplyFilter}
                        >
                          <Icon type="thunderbolt" /> Aplicar
                        </ButtonStandard>
                      </Tooltip>
                    </Row>
                  </Col>
                </Row>
                <Divider />
                {/* Speeders */}
                <Row type="flex" justify="center" align="middle">
                  <Col>
                    <h2
                      style={{
                        color: "#9074bf",
                        marginBottom: 8,
                      }}
                    >
                      Aceleradores <Icon type="snippets" />
                    </h2>
                  </Col>
                </Row>
                <Row type="flex" gutter={8} align="middle">
                  <Col span={6}>
                    {/* retreats */}
                    <Row type="flex" className="rowLabel">
                      <label>
                        {
                          translation.picketSupplementSupply.form
                            .quantitySupplied
                        }
                      </label>
                    </Row>
                    <Row type="flex">
                      <NumberFormat
                        customInput={Input}
                        value={defaultQuantitySupplied || ""}
                        name="defaultQuantitySupplied"
                        decimalScale={2}
                        placeholder={translation.defaultPlaceholder}
                        addonAfter="Kg"
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        onValueChange={({ floatValue }) =>
                          setDefaultQuantitySupplied(
                            floatValue
                              ? floatValue > 10000
                                ? 10000
                                : floatValue
                              : null
                          )
                        }
                      />
                    </Row>
                  </Col>
                  <Col span={6}>
                    {/* supplements */}
                    <Row type="flex" className="rowLabel">
                      <label>
                        {translation.picketSupplementSupply.form.supplement}
                      </label>
                    </Row>
                    <Row type="flex">
                      <Select
                        name="defaultSupplement"
                        value={defaultSupplement || undefined}
                        loading={isLoadingSupplementsList}
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder={translation.defaultSelectPlaceholder}
                        onChange={(value) => setDefaultSupplement(value)}
                      >
                        {allSupplements
                          .filter((supplement) =>
                            supplementsFiltered.length === 0
                              ? true
                              : supplementsFiltered.includes(supplement.id)
                          )
                          .map((supplement) => (
                            <Select.Option
                              key={supplement.id}
                              value={supplement.id}
                            >
                              {supplement.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Row>
                  </Col>
                  <Col span={6}>
                    {/* defaultHourSupplied */}
                    <Row type="flex" className="rowLabel">
                      <label>
                        {translation.picketSupplementSupply.form.hourSupplied}
                      </label>
                    </Row>
                    <Row type="flex">
                      <Input
                        type="time"
                        step={60}
                        value={
                          defaultHourSupplied != null
                            ? defaultHourSupplied
                            : undefined
                        }
                        onChange={(e) => {
                          setDefaultHourSupplied(e.target.value);
                        }}
                      />
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row type="flex" justify="center">
                      <Tooltip title="Ao clicar em Aplicar, os campos da seção 'Fornecimento' que estiverem vazios serão sobreescritos pelos os valores escolhidos nos campos ao lado.">
                        <ButtonStandard
                          tabIndex="-1"
                          type={"button"}
                          buttonType={"secundary"}
                          onClick={() =>
                            handleApplyDefaultFieldValues(
                              setFieldValue,
                              values.pickets
                            )
                          }
                        >
                          <Icon type="thunderbolt" /> Aplicar
                        </ButtonStandard>
                      </Tooltip>
                    </Row>
                  </Col>
                </Row>
                <Divider />
                <Row type="flex" justify="center" align="middle">
                  <Col>
                    <h2
                      style={{
                        color: "#9074bf",
                        marginBottom: 8,
                      }}
                    >
                      Fornecimento <Icon type="form" />
                    </h2>
                  </Col>
                </Row>
                {/* Table */}
                <Row type="flex">
                  <Col span={24}>
                    <Table
                      dataSource={values.pickets || []}
                      pagination={false}
                      rowKey={"id"}
                      scroll={{
                        x: true,
                      }}
                    >
                      <Table.Column
                        dataIndex="picketName"
                        title="Piquete/Baia"
                        sorter={(first, second) => {
                          return first.picketName.localeCompare(
                            second.picketName,
                            "pt-BR",
                            {
                              numeric: false,
                              ignorePunctuation: true,
                            }
                          );
                        }}
                        filtered
                        {...handleGetColumnSearchProps("picketName")}
                      />
                      <Table.Column
                        dataIndex="lotName"
                        title="Lote"
                        sorter={(first, second) => {
                          return first.lotName.localeCompare(
                            second.lotName,
                            "pt-BR",
                            {
                              numeric: false,
                              ignorePunctuation: true,
                            }
                          );
                        }}
                        filtered
                        {...handleGetColumnSearchProps("lotName")}
                      />
                      <Table.Column
                        dataIndex="animalsCount"
                        title="Qtd. Animais"
                        width={85}
                      />
                      <Table.Column
                        dataIndex="avgWeight"
                        title="Peso Estim."
                        width={80}
                        render={(text) => numberMask(text || 0, false)}
                      />
                      <Table.Column
                        dataIndex="avgGdp"
                        title="GDP Estim."
                        width={80}
                        render={(text) => numberMask(text || 0, false)}
                      />
                      <Table.ColumnGroup
                        title={`TRATO ${values.supplyNumber}`}
                        align="center"
                        children={[
                          <Table.Column
                            dataIndex="supplementId"
                            width={230}
                            title="Suplemento/Ração"
                            render={(text, record) => (
                              <Select
                                value={text || undefined}
                                loading={isLoadingSupplementsList}
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder={
                                  translation.defaultSelectPlaceholder
                                }
                                onChange={(value) => {
                                  if (value == null) {
                                    handleChangeValuesTable(
                                      setFieldValue,
                                      "supplementId",
                                      null,
                                      values.pickets,
                                      record.id
                                    );

                                    handleChangeValuesTable(
                                      setFieldValue,
                                      "dryMatterPercentage",
                                      null,
                                      values.pickets,
                                      record.id
                                    );
                                  } else {
                                    const supplementSelected =
                                      allSupplements.find(
                                        (supplement) => supplement.id === value
                                      );
                                    handleChangeValuesTable(
                                      setFieldValue,
                                      "supplementId",
                                      value,
                                      values.pickets,
                                      record.id
                                    );

                                    handleChangeValuesTable(
                                      setFieldValue,
                                      "dryMatterPercentage",
                                      supplementSelected.dryMatterPercentage,
                                      values.pickets,
                                      record.id
                                    );
                                  }
                                }}
                              >
                                {allSupplements.map((supplement) => (
                                  <Select.Option
                                    key={supplement.id}
                                    value={supplement.id}
                                  >
                                    {supplement.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            )}
                          />,
                          <Table.Column
                            dataIndex="dryMatterPercentage"
                            title="MS: Matéria Seca"
                            width={130}
                            render={(text) =>
                              `${numberMask(text || 0, false)}%MS`
                            }
                          />,
                          <Table.Column
                            dataIndex="quantitySupplied"
                            title="Qtd. Ofertada"
                            align="center"
                            width={300}
                            render={(text, record) => (
                              <Row type="flex" gutter={8} justify="center">
                                <Col>
                                  <Row type="flex" justify="center">
                                    <strong>Atual</strong>
                                  </Row>
                                  <Row>
                                    <NumberFormat
                                      style={{ width: 130 }}
                                      customInput={Input}
                                      value={text || ""}
                                      decimalScale={2}
                                      placeholder={
                                        translation.defaultPlaceholder
                                      }
                                      addonAfter="Kg"
                                      decimalSeparator=","
                                      thousandSeparator="."
                                      fixedDecimalScale={true}
                                      onValueChange={({ floatValue }) =>
                                        handleChangeValuesTable(
                                          setFieldValue,
                                          "quantitySupplied",
                                          floatValue
                                            ? floatValue > 10000
                                              ? 10000
                                              : floatValue
                                            : null,
                                          values.pickets,
                                          record.id
                                        )
                                      }
                                    />
                                  </Row>
                                </Col>
                                <Col>
                                  <Row type="flex" justify="center">
                                    <strong>Anterior</strong>
                                  </Row>
                                  <Row>
                                    <NumberFormat
                                      style={{ width: 130 }}
                                      value={
                                        record.previousQuantitySupplied || ""
                                      }
                                      customInput={Input}
                                      disabled
                                      decimalScale={2}
                                      placeholder={
                                        translation.defaultPlaceholder
                                      }
                                      addonAfter="Kg"
                                      decimalSeparator=","
                                      thousandSeparator="."
                                      fixedDecimalScale={true}
                                    />
                                  </Row>
                                </Col>
                              </Row>
                            )}
                          />,
                          <Table.Column
                            dataIndex="prevSuppliedPerAnimal"
                            title="Prev. cab/dia"
                            width={130}
                            render={(text) =>
                              `${numberMask(text || 0, false)}kg`
                            }
                          />,
                          <Table.Column
                            dataIndex="prevSuppliedDryMatterByWeight"
                            title="Prev. MS %PV"
                            width={130}
                            render={(text) =>
                              `${numberMask(text || 0, false)}%`
                            }
                          />,
                          <Table.Column
                            dataIndex="prevSupplyHourInMinutes"
                            title="Hora Prev."
                            width={80}
                            disabled
                            render={(text) => {
                              if (!text) return "";
                              const hour = Math.floor(text / 60);
                              const minutes = text % 60;
                              return `${hour.toLocaleString("pt-BR", {
                                minimumIntegerDigits: 2,
                                minimumFractionDigits: 0,
                                useGrouping: false,
                              })}:${minutes.toLocaleString("pt-BR", {
                                minimumIntegerDigits: 2,
                                minimumFractionDigits: 0,
                                useGrouping: false,
                              })}`;
                            }}
                          />,
                          <Table.Column
                            dataIndex="supplyHourInMinutes"
                            title="Hora Real"
                            width={80}
                            render={(text, record) => {
                              return (
                                <Input
                                  type="time"
                                  step={60}
                                  value={text || undefined}
                                  format="HH:mm"
                                  onChange={(e) => {
                                    handleChangeValuesTable(
                                      setFieldValue,
                                      "supplyHourInMinutes",
                                      e.target.value,
                                      values.pickets,
                                      record.id
                                    );
                                  }}
                                />
                              );
                            }}
                          />,
                          <Table.Column
                            dataIndex="observation"
                            title="Observação"
                            width={300}
                            render={(text, record) => (
                              <Input
                                value={text}
                                placeholder={translation.defaultPlaceholder}
                                onChange={(e) =>
                                  handleChangeValuesTable(
                                    setFieldValue,
                                    "observation",
                                    e.target.value,
                                    values.pickets,
                                    record.id
                                  )
                                }
                              />
                            )}
                          />,
                        ]}
                      />
                    </Table>
                  </Col>
                </Row>
              </div>
              <Footer>
                <Row type="flex">
                  <Col span={12}>
                    <Select
                      value={saveOptions}
                      name="saveOptions"
                      onChange={(value) => setSaveOptions(value)}
                    >
                      <Select.Option key="1" value="only_save">
                        {
                          translation.picketSupplementSupply.form.saveOptions
                            .only_save
                        }
                      </Select.Option>
                      <Select.Option key="2" value="save_and_create_new">
                        {
                          translation.picketSupplementSupply.form.saveOptions
                            .save_and_create_new
                        }
                      </Select.Option>
                    </Select>
                  </Col>
                  <Col span={12} className="buttonsDiv">
                    <ButtonStandard
                      tabIndex="-1"
                      type="button"
                      buttonType="type7"
                      onClick={handleCloseDrawer}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>

                    <ButtonStandard
                      tabIndex="-1"
                      type="submit"
                      buttonType="type6"
                    >
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Footer>
            </form>
          )}
        />
      </Spin>
    </Container>
  );
};

export default DrawerPicketSupplementSupply;
