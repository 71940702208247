import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Creators as AnimalActions } from "../../../../../store/ducks/animal";

import {
  Col,
  Collapse,
  Empty,
  Icon,
  Input,
  Row,
  Spin,
  Table,
  Tabs,
  notification,
} from "antd";
import NumberFormat from "react-number-format";
import PencilIcon from "../../../../../components/utils/icons/pencil";
import TrashIcon from "../../../../../components/utils/icons/trash";
import InfoTooltip from "../../../../../components/utils/infoTooltip";
import ArrowActiveIcon from "../../../../lot/listReceipt/icons/arrow/active";
import ArrowInactiveIcon from "../../../../lot/listReceipt/icons/arrow/inactive";
import {
  AnimalWeightActionsConfirmContainer,
  AnimalWeightActionsContainer,
  CancelEditButton,
  ConfirmEditButton,
  DeleteButton,
  EditButton,
  TabAnimalPaneContent,
} from "../../styles";

import ButtonStandard from "../../../../../components/utils/button";
import {
  deleteAnimalWeightHistory,
  updateAnimalWeightHistory,
} from "../../../../../services/animalService";
import {
  getTwoDecimalDigits,
  numberMask,
} from "../../../../../services/helpersMethodsService";

const { Panel } = Collapse;
const { Column } = Table;

function TabAnimalWeightsInfo({
  tabAnimalPane,
  translation,
  customPanelStyle,
}) {
  const [loadingWeightAction, setLoadingWeightAction] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [animalToUpdateWeight, setAnimalToUpdateWeight] = useState({});

  const {
    animal: {
      animalWeightHistoryData,
      animalsDailyWeightPageable,
      animalsDailyWeightGainPageable,
      isLoadingAnimalWeightHistory,
      isLoadingAnimalDailyWeight,
      isLoadingAnimalDailyWeightGain,
    },
    app: {
      languageSelected,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const {
    params: { id: animalId },
  } = useRouteMatch();

  async function handleTableAnimalWeightHistoryChange(
    pagination,
    filters,
    sorter
  ) {
    let search = "";
    if (filters && Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([field, value]) => {
        if (value && value.length > 0) {
          const fieldS = field
            ? field === "handlingNumber"
              ? "handling_number"
              : field === "originationLotName"
              ? "origination_lot_name"
              : field === "originEvent"
              ? "event"
              : field === "originationPicketName"
              ? "origination_picket_name"
              : field === "date"
              ? "weight_date"
              : field
            : null;
          if (fieldS === "weight") {
            const operatorS =
              value[1] === "equal"
                ? "="
                : value[1] === "greaterThan"
                ? ">"
                : value[1] === "greaterOrEqualThan"
                ? ">="
                : value[1] === "lessThan"
                ? "<"
                : value[1] === "lessOrEqualThan"
                ? "<="
                : "=";
            search =
              search === ""
                ? `${fieldS} ${operatorS} '${value[0]}'`
                : `${search};${fieldS} ${operatorS} '${value[0]}'`;
          } else if (fieldS === "weight_date") {
            const operatorS =
              value[1] === "equal"
                ? "="
                : value[1] === "greaterThan"
                ? ">"
                : value[1] === "greaterOrEqualThan"
                ? ">="
                : value[1] === "lessThan"
                ? "<"
                : value[1] === "lessOrEqualThan"
                ? "<="
                : "=";

            search =
              search === ""
                ? `cast(${fieldS} as date) ${operatorS} cast('${moment(
                    value[0]
                  ).format("YYYY-MM-DD")}' as date)`
                : `${search};cast(${fieldS} as date) ${operatorS} cast('${moment(
                    value[0]
                  ).format("YYYY-MM-DD")}' as date)`;
          } else if (fieldS === "origination") {
            search =
              search === ""
                ? `${fieldS} in (${value
                    .map((o) => (o = `'${o}'`))
                    .join(", ")})`
                : `${search};${fieldS} in (${value
                    .map((o) => (o = `'${o}'`))
                    .join(", ")})`;
          } else {
            search =
              search === ""
                ? `upper(${fieldS}) like upper('%25${value}%25')`
                : `${search};upper(${fieldS}) like upper('%25${value}%25')`;
          }
        }
      });
    }
    dispatch(
      AnimalActions.indexAnimalWeightHistory(
        groupId,
        farmId,
        animalId,
        pagination.current,
        sorter,
        search
      )
    );
  }

  async function handleTableDailyWeightChange(pagination, filters, sorter) {
    dispatch(
      AnimalActions.indexAnimalDailyWeightPageable(
        groupId,
        farmId,
        animalId,
        true,
        pagination.current,
        sorter
      )
    );
  }

  async function handleTableDailyWeightGainChange(pagination, filters, sorter) {
    dispatch(
      AnimalActions.indexAnimalDailyWeightGainPageable(
        groupId,
        farmId,
        animalId,
        true,
        pagination.current,
        sorter
      )
    );
  }

  async function openEdit(record) {
    setLoadingWeightAction(true);
    setTimeout(() => {
      setLoadingWeightAction(false);
      setEditing(true);
    }, 2000);
  }

  async function updateAnimalWeight(newWeight) {
    setAnimalToUpdateWeight((prevState) => ({
      ...prevState,
      weight: newWeight,
    }));
  }

  async function updateAnimalStatusProcessingWeight(isProcessing) {
    const animalToUpdate = {
      ...animalToUpdateWeight,
      processingWeight: isProcessing,
    };
    setAnimalToUpdateWeight(animalToUpdate);
  }

  async function confirmEdit() {
    setLoadingWeightAction(true);
    const { id, weight } = animalToUpdateWeight;

    try {
      await updateAnimalWeightHistory({
        groupId,
        farmId,
        animalId,
        id,
        weight,
      });

      notification.success({
        message: `O peso do animal foi alterado com sucesso. Iniciado o recálculo de progressão de peso.`,
      });

      updateAnimalStatusProcessingWeight(true);
      setLoadingWeightAction(false);

      setTimeout(() => {
        dispatch(
          AnimalActions.indexAnimalDetails(groupId, farmId, animalId, true)
        );
        dispatch(
          AnimalActions.getAnimalDailyWeightDashboard(groupId, farmId, animalId)
        );
        dispatch(
          AnimalActions.indexAnimalDailyWeightPageable(
            groupId,
            farmId,
            animalId,
            true
          )
        );
        dispatch(
          AnimalActions.getAnimalDailyWeightGainDashboard(
            groupId,
            farmId,
            animalId
          )
        );
        dispatch(
          AnimalActions.indexAnimalDailyWeightGainPageable(
            groupId,
            farmId,
            animalId,
            true
          )
        );
        dispatch(
          AnimalActions.indexAnimalWeightHistory(
            groupId,
            farmId,
            animalId,
            true
          )
        );
      }, 10000);
    } catch (error) {
      setLoadingWeightAction(false);
      notification.error({
        message: `O peso do animal não pode ser alterado. Contate o suporte.`,
      });
    }

    setLoadingWeightAction(false);
    setEditing(false);
  }

  async function confirmDelete(id) {
    setLoadingWeightAction(true);

    try {
      await deleteAnimalWeightHistory({
        groupId,
        farmId,
        animalId,
        id,
      });

      notification.success({
        message: `O peso do animal foi deletado com sucesso. Iniciado o recálculo de progressão de peso.`,
      });

      setTimeout(() => {
        dispatch(
          AnimalActions.indexAnimalDetails(groupId, farmId, animalId, true)
        );
        dispatch(
          AnimalActions.getAnimalDailyWeightDashboard(groupId, farmId, animalId)
        );
        dispatch(
          AnimalActions.indexAnimalDailyWeightPageable(
            groupId,
            farmId,
            animalId,
            true
          )
        );
        dispatch(
          AnimalActions.getAnimalDailyWeightGainDashboard(
            groupId,
            farmId,
            animalId
          )
        );
        dispatch(
          AnimalActions.indexAnimalDailyWeightGainPageable(
            groupId,
            farmId,
            animalId,
            true
          )
        );
        dispatch(
          AnimalActions.indexAnimalWeightHistory(
            groupId,
            farmId,
            animalId,
            true
          )
        );
        setLoadingWeightAction(false);
        setEditing(false);
      }, 10000);
    } catch (error) {
      setLoadingWeightAction(false);
      notification.error({
        message: `O peso do animal não pode ser deletado. Contate o suporte.`,
      });
    }
  }

  async function cancelEdit(originalWeight) {
    setLoadingWeightAction(true);

    const animalToUpdate = { ...animalToUpdateWeight, weight: originalWeight };

    setAnimalToUpdateWeight(animalToUpdate);

    setLoadingWeightAction(false);
    setEditing(false);
  }

  /** update animal editable weight */
  useEffect(() => {
    if (
      Object.entries(animalWeightHistoryData).length > 0 &&
      animalWeightHistoryData !== null &&
      animalWeightHistoryData !== undefined
    ) {
      const animalToUpdate = animalWeightHistoryData.content.find(
        (weight) => weight.editable
      );
      setAnimalToUpdateWeight(animalToUpdate);
    }

    const obj = JSON.parse(localStorage.getItem("@Bovexo:user"));
    const isAdmin =
      obj?.roles.indexOf("Admin") > -1 ||
      obj?.roles.indexOf("Admin Group") > -1 ||
      obj?.roles.indexOf("Admin Farmer") > -1;
    setIsAdmin(isAdmin);
  }, [animalWeightHistoryData]);

  useEffect(() => {
    if (tabAnimalPane === 2) {
      dispatch(
        AnimalActions.indexAnimalWeightHistory(
          groupId,
          farmId,
          animalId,
          0,
          {},
          ""
        )
      );
      dispatch(
        AnimalActions.indexAnimalDailyWeightPageable(
          groupId,
          farmId,
          animalId,
          true
        )
      );
      dispatch(
        AnimalActions.indexAnimalDailyWeightGainPageable(
          groupId,
          farmId,
          animalId,
          true
        )
      );
    }
  }, [animalId, dispatch, farmId, groupId, tabAnimalPane]);

  return (
    <TabAnimalPaneContent active={tabAnimalPane === 2 ? true : false}>
      {/* Card with animal weight history */}
      <Row type="flex">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginTop: 10 }}>
          <Collapse
            defaultActiveKey="CollapseColumn01"
            bordered={false}
            expandIcon={({ isActive }) =>
              isActive ? <ArrowActiveIcon /> : <ArrowInactiveIcon />
            }
          >
            <Panel
              header={
                <span className="detailTitle svg-tooltip">
                  {translation.animal.details.history.title}
                  <InfoTooltip
                    title={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "0 5px",
                        }}
                      >
                        <p>
                          Nesta opção, será apresentadas as pesagens registradas
                          para o animal e o respectivo ganho de peso
                        </p>
                        <ol style={{ marginLeft: 16 }}>
                          <li>
                            Pesagens: São exibidos todos os pesos registrados
                            para animal, mesmo que ocorra mais de uma pesagem no
                            mesmo dia, como por exemplo, pesagens de origem de
                            balanças de pesagens dinâmicas.
                          </li>
                          <li>
                            Pesos Diários: São os pesos que efetivamente serão
                            considerados para o animal, seja para cálculo de GDP
                            ou GMD. Se utilizada balanças de pesagens dinâmicas,
                            que podem registrar mais de um peso no mesmo dia
                            para o mesmo animal, será considerada a regra
                            definida em configuração de Pesos, disponível em
                            [Parâmetros/Gerais].
                          </li>
                          <li>
                            Ganhos de peso (GMD): refere-se ao GMD, ou seja, o
                            ganho diário entre a pesagem da data e sua anterior.
                          </li>
                        </ol>
                      </div>
                    }
                  />
                </span>
              }
              key="CollapseColumn01"
              style={customPanelStyle}
              // extra={Extra(item.id)}
            >
              <Row type="flex" style={{ width: "100% !important" }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Tabs type="card" defaultActiveKey="1">
                    {/* Tab Weights History */}
                    <Tabs.TabPane
                      tab={
                        <span>{translation.animal.details.tab.weighing}</span>
                      }
                      key="1"
                    >
                      <Row justify="end" type="flex" style={{ marginTop: 8 }}>
                        <Col>
                          <ButtonStandard
                            buttonType="secondary"
                            onClick={() =>
                              dispatch(
                                AnimalActions.indexAnimalWeightHistory(
                                  groupId,
                                  farmId,
                                  animalId,
                                  0,
                                  {},
                                  ""
                                )
                              )
                            }
                          >
                            <Icon type="reload" />{" "}
                            {translation.buttons.updateTable}
                          </ButtonStandard>
                        </Col>
                      </Row>
                      <Row type="flex">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          {animalWeightHistoryData == null ||
                          animalWeightHistoryData.totalElements === 0 ? (
                            <Empty
                              description={
                                translation.animal.details.history.grid
                                  .emptyMessage
                              }
                              style={{ marginTop: "30px" }}
                            />
                          ) : (
                            <Table
                              loading={isLoadingAnimalWeightHistory}
                              dataSource={animalWeightHistoryData.content}
                              pagination={{
                                total: animalWeightHistoryData.totalElements,
                                size: animalWeightHistoryData.size,
                                current: animalWeightHistoryData.number + 1,
                              }}
                              scroll={{
                                x: true,
                              }}
                              size="middle"
                              rowKey="id"
                              onChange={handleTableAnimalWeightHistoryChange}
                            >
                              <Column
                                title={
                                  translation.animal.details.history.grid.date
                                }
                                dataIndex="date"
                                key="date"
                                sorter
                                sortDirections={["descend", "ascend"]}
                                render={(text) =>
                                  text !== null
                                    ? languageSelected === "en"
                                      ? moment(text).format("MM/DD/YYYY")
                                      : moment(text).format("DD/MM/YYYY")
                                    : ""
                                }
                              />
                              <Column
                                title={
                                  translation.animal.details.history.grid.hour
                                }
                                dataIndex="hour"
                                key="hour"
                              />
                              <Column
                                title={
                                  translation.animal.details.history.grid.inOut
                                }
                                dataIndex="type"
                                key="type"
                                render={(text) =>
                                  text === "In"
                                    ? translation.animal.details.history.grid.in
                                    : text === "Out"
                                    ? translation.animal.details.history.grid
                                        .out
                                    : translation.animal.details.history.grid
                                        .manual
                                }
                              />
                              <Column
                                title={
                                  translation.animal.details.history.grid.weight
                                }
                                dataIndex="weight"
                                key="weight"
                                render={(text, record) => (
                                  <>
                                    {isAdmin &&
                                      isEditing &&
                                      record.editable &&
                                      !record.processingWeight &&
                                      !animalToUpdateWeight?.processingWeight && (
                                        <Spin
                                          spinning={
                                            isAdmin &&
                                            record.editable &&
                                            !record.processingWeight &&
                                            !animalToUpdateWeight?.processingWeight &&
                                            loadingWeightAction
                                          }
                                        >
                                          <NumberFormat
                                            onValueChange={({ floatValue }) => {
                                              updateAnimalWeight(floatValue);
                                            }}
                                            customInput={Input}
                                            value={animalToUpdateWeight?.weight}
                                            placeholder={
                                              translation.lot.production.drawer
                                                .form.informPlaceHolder
                                            }
                                            addonAfter="Kg"
                                            decimalScale={2}
                                            decimalSeparator=","
                                            thousandSeparator="."
                                            fixedDecimalScale={true}
                                          />
                                        </Spin>
                                      )}
                                    {((isAdmin &&
                                      !isEditing &&
                                      record.editable) ||
                                      (record.editable &&
                                        !record.processingWeight &&
                                        !animalToUpdateWeight?.processingWeight) ||
                                      record.id !==
                                        animalToUpdateWeight?.id) && (
                                      <span>
                                        {record.id === animalToUpdateWeight?.id
                                          ? numberMask(
                                              getTwoDecimalDigits(
                                                animalToUpdateWeight?.weight ||
                                                  0
                                              ),
                                              false
                                            )
                                          : numberMask(
                                              getTwoDecimalDigits(
                                                record.weight || 0
                                              ),
                                              false
                                            )}
                                      </span>
                                    )}
                                  </>
                                )}
                              />
                              <Column
                                title={
                                  translation.animal.details.history.grid
                                    .eventTitle
                                }
                                dataIndex="originEvent"
                                key="originEvent"
                                render={(text, record) =>
                                  text && (
                                    <span>
                                      {
                                        translation.animal.details.history.grid
                                          .originEvent[text]
                                      }
                                    </span>
                                  )
                                }
                              />
                              <Column
                                title={
                                  translation.animalWeightHistory.table.columns
                                    .origination
                                }
                                dataIndex="origination"
                                sorter
                                key="origination"
                                align="left"
                                render={(text) => (
                                  <span>
                                    {text === "Manual"
                                      ? "Manual"
                                      : text === "BovManager"
                                      ? "BovManager"
                                      : text === "Balance"
                                      ? "Balança"
                                      : text === "Import"
                                      ? "Importação"
                                      : text === "ODD"
                                      ? "ODD"
                                      : "-"}
                                  </span>
                                )}
                              />
                              <Column
                                render={(text, record) => (
                                  <Spin
                                    spinning={
                                      isAdmin &&
                                      (record.editable || record.deletable) &&
                                      loadingWeightAction
                                    }
                                  >
                                    {(record.processingWeight ||
                                      animalToUpdateWeight?.processingWeight) &&
                                      record.editable && (
                                        <AnimalWeightActionsContainer>
                                          <InfoTooltip
                                            title={
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  padding: "0 5px",
                                                }}
                                              >
                                                <p>
                                                  Os dados de peso estão sendo
                                                  recalculados devido a uma
                                                  alteração na última pesagem de
                                                  um animal, por favor aguarde
                                                  para editar o peso novamente.
                                                </p>
                                              </div>
                                            }
                                          />
                                        </AnimalWeightActionsContainer>
                                      )}
                                    {isAdmin &&
                                      !isEditing &&
                                      record.editable &&
                                      !record.processingWeight &&
                                      !animalToUpdateWeight?.processingWeight && (
                                        <AnimalWeightActionsContainer>
                                          <EditButton
                                            type="button"
                                            onClick={() => openEdit(record)}
                                          >
                                            <PencilIcon />
                                          </EditButton>
                                          <span>|</span>
                                          <DeleteButton
                                            disabled={!record.deletable}
                                            type="button"
                                            onClick={() =>
                                              confirmDelete(record.id)
                                            }
                                          >
                                            <TrashIcon />
                                          </DeleteButton>
                                        </AnimalWeightActionsContainer>
                                      )}
                                    {isAdmin &&
                                      isEditing &&
                                      record.editable &&
                                      !record.processingWeight &&
                                      !animalToUpdateWeight?.processingWeight && (
                                        <AnimalWeightActionsConfirmContainer>
                                          <ConfirmEditButton
                                            type="button"
                                            onClick={confirmEdit}
                                          >
                                            <Icon
                                              type="check-circle"
                                              theme="filled"
                                              className="iconCheck"
                                            />
                                          </ConfirmEditButton>
                                          <span>|</span>
                                          <CancelEditButton
                                            type="button"
                                            onClick={() =>
                                              cancelEdit(record.weight)
                                            }
                                          >
                                            <Icon
                                              type="close-circle"
                                              theme="filled"
                                              className="iconClose"
                                            />
                                          </CancelEditButton>
                                        </AnimalWeightActionsConfirmContainer>
                                      )}
                                  </Spin>
                                )}
                              />
                            </Table>
                          )}
                        </Col>
                      </Row>
                    </Tabs.TabPane>
                    {/* Tab Daily Weight */}
                    <Tabs.TabPane
                      tab={
                        <span>
                          {translation.animal.details.tab.dailyWeights}
                        </span>
                      }
                      key="2"
                    >
                      <Row justify="end" type="flex" style={{ marginTop: 8 }}>
                        <Col>
                          <ButtonStandard
                            buttonType="secondary"
                            onClick={() =>
                              dispatch(
                                AnimalActions.indexAnimalDailyWeightPageable(
                                  groupId,
                                  farmId,
                                  animalId,
                                  true
                                )
                              )
                            }
                          >
                            <Icon type="reload" />{" "}
                            {translation.buttons.updateTable}
                          </ButtonStandard>
                        </Col>
                      </Row>
                      <Row type="flex">
                        <Col span={24}>
                          {animalsDailyWeightPageable == null ||
                          animalsDailyWeightPageable.totalElements === 0 ? (
                            <Empty
                              description={
                                translation.animal.details.history.grid
                                  .emptyMessage
                              }
                              style={{ marginTop: "30px" }}
                            />
                          ) : (
                            <Table
                              loading={isLoadingAnimalDailyWeight}
                              dataSource={animalsDailyWeightPageable.content}
                              pagination={{
                                total: animalsDailyWeightPageable.totalElements,
                                size: animalsDailyWeightPageable.size,
                                current: animalsDailyWeightPageable.number + 1,
                              }}
                              rowKey="id"
                              size="middle"
                              onChange={handleTableDailyWeightChange}
                            >
                              <Column
                                title={
                                  translation.animal.details.history.grid.date
                                }
                                dataIndex="weighingDate"
                                key="weighingDate"
                                sorter
                                sortDirections={["descend", "ascend"]}
                                render={(text) =>
                                  text !== null
                                    ? languageSelected === "en"
                                      ? moment(text).format("MM/DD/YYYY")
                                      : moment(text).format("DD/MM/YYYY")
                                    : ""
                                }
                              />
                              <Column
                                title={
                                  translation.animal.details.history.grid.weight
                                }
                                dataIndex="weight"
                                key="weight"
                                render={(text, record) => (
                                  <span>
                                    {record.weight != null
                                      ? numberMask(
                                          getTwoDecimalDigits(record.weight),
                                          false
                                        )
                                      : 0}
                                  </span>
                                )}
                              />
                            </Table>
                          )}
                        </Col>
                      </Row>
                    </Tabs.TabPane>
                    {/* Tab Weight Gain */}
                    <Tabs.TabPane
                      tab={
                        <span>
                          {translation.animal.details.tab.weightGains}
                        </span>
                      }
                      key="3"
                    >
                      <Row justify="end" type="flex" style={{ marginTop: 8 }}>
                        <Col>
                          <ButtonStandard
                            buttonType="secondary"
                            onClick={() =>
                              dispatch(
                                AnimalActions.indexAnimalDailyWeightGainPageable(
                                  groupId,
                                  farmId,
                                  animalId,
                                  true
                                )
                              )
                            }
                          >
                            <Icon type="reload" />{" "}
                            {translation.buttons.updateTable}
                          </ButtonStandard>
                        </Col>
                      </Row>
                      <Row type="flex">
                        <Col span={24}>
                          {animalsDailyWeightGainPageable == null ||
                          animalsDailyWeightGainPageable.totalElements === 0 ? (
                            <Empty
                              description={
                                translation.animal.details.history.grid
                                  .emptyMessage
                              }
                              style={{ marginTop: "30px" }}
                            />
                          ) : (
                            <Table
                              loading={isLoadingAnimalDailyWeightGain}
                              dataSource={
                                animalsDailyWeightGainPageable.content
                              }
                              pagination={{
                                total:
                                  animalsDailyWeightGainPageable.totalElements,
                                size: animalsDailyWeightGainPageable.size,
                                current:
                                  animalsDailyWeightGainPageable.number + 1,
                              }}
                              rowKey="id"
                              size="middle"
                              onChange={handleTableDailyWeightGainChange}
                            >
                              <Column
                                title={
                                  translation.animal.details.history.grid.date
                                }
                                dataIndex="dateDailyWeightGain"
                                key="dateDailyWeightGain"
                                sorter
                                sortDirections={["descend", "ascend"]}
                                render={(text) =>
                                  text !== null
                                    ? languageSelected === "en"
                                      ? moment(text).format("MM/DD/YYYY")
                                      : moment(text).format("DD/MM/YYYY")
                                    : ""
                                }
                              />
                              <Column
                                title={
                                  translation.animal.details.history.grid.weight
                                }
                                dataIndex="dailyWeightGain"
                                key="dailyWeightGain"
                                render={(text, record) => (
                                  <span>
                                    {record.dailyWeightGain != null
                                      ? numberMask(
                                          getTwoDecimalDigits(
                                            record.dailyWeightGain
                                          ),
                                          false
                                        )
                                      : 0}
                                  </span>
                                )}
                              />
                            </Table>
                          )}
                        </Col>
                      </Row>
                    </Tabs.TabPane>
                  </Tabs>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </TabAnimalPaneContent>
  );
}

export default TabAnimalWeightsInfo;
