import { Card } from "antd";
import styled from "styled-components";

export const CardCustom = styled(Card)`
  margin-top: 13px !important;
  border-radius: 7px !important;
  background: #fff !important;
  box-shadow: 0px 0px 10px #d8d8d8 !important;
  padding: 0px !important;

  .ant-card-body {
    padding-top: 14px;
  }
  .rowHeader {
    margin-bottom: 17px;
  }

  .body-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  font-family: "Asap", sans-serif;
`;

export const FarmDayScaleTreatmentParameterTable = styled.table`
  width: 100%;
  table-layout: fixed;
  background: #f4f4f4;

  thead {
    th {
      background: #684e94;
      padding: 0.5rem;
      font-size: 0.875rem;
      font-weight: bold;
      color: #fafafa;
      text-align: center;
    }
  }

  tbody {
    tr {
      margin: 4px;
      td {
        border: 10px solid white;
        border-radius: 5px;
        padding: 0.5rem;
        box-sizing: border-box;

        &:first-child {
          width: 100px;
          background: #684e94;
          font-size: 0.875rem;
          font-weight: bold;
          color: #fafafa;
          text-align: center;
        }
        &:not(:first-child) {
          text-align: center;
          &.edit {
            -webkit-box-shadow: inset 0px 0px 0px 2px #4b4b4b;
            -moz-box-shadow: inset 0px 0px 0px 2px #4b4b4b;
            box-shadow: inset 0px 0px 0px 2px #4b4b4b;
          }
        }

        input[type="time"] {
          all: unset;
          width: 100%;
          height: 100%;
          background: transparent;
          border: none;
          font-size: 1rem;
        }

        input[type="text"] {
          all: unset;
          width: 100%;
          height: 100%;
          background: transparent;
          border: none;
          font-size: 1rem;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    tbody {
      tr {
        td {
          border: 4px solid white;
          padding: 0.25rem;
          &:first-child {
            font-size: 0.75rem;
          }

          input[type="time"] {
            font-size: 0.75rem;
          }
          input[type="text"] {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
`;
