import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  Button,
  Col,
  DatePicker,
  Icon,
  Input,
  InputNumber,
  Row,
  Select,
  Table,
} from "antd";

// Service
import { numberMask } from "../../../../services/helpersMethodsService";
import { getAnimalsTransferAnimalTransactions } from "../../../../services/transferAnimalTransactionService";
import { transformCamelCaseToSnakeCase } from "../../../../utils/stringUtils";

const ViewTransferAnimalTransactionAnimalTable = ({
  transferAnimalTransactionId,
}) => {
  const [isLoadingDataList, setIsLoadingDataList] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [tableProperties, setTableProperties] = useState({
    size: 5,
    page: 0,
    tableFilters: {},
    tableSorters: {},
    search: null,
  });

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { Column } = Table;

  const fetchData = useCallback(
    async (page = 0, size = 5, sort = {}, search = null) => {
      if (groupId && farmId && transferAnimalTransactionId) {
        setIsLoadingDataList(true);
        try {
          const {
            data: { results },
          } = await getAnimalsTransferAnimalTransactions({
            groupId,
            farmId,
            id: transferAnimalTransactionId,
            page,
            size,
            sort,
            search,
          });
          setDataList(results);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoadingDataList(false);
        }
      }
    },
    [farmId, groupId, transferAnimalTransactionId]
  );

  const getSortString = useCallback((sorter) => {
    let customSort = sorter;

    if (sorter && Object.entries(sorter).length > 0) {
      const { field } = sorter;

      const fieldS = field ? transformCamelCaseToSnakeCase(field) : null;

      customSort = {
        ...customSort,
        field: fieldS,
      };
    }

    return customSort;
  }, []);

  const getSearchString = useCallback((filters) => {
    let search = "";

    if (filters && Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([field, value]) => {
        if (value && value.length > 0) {
          const fieldS = field ? transformCamelCaseToSnakeCase(field) : null;
          if (fieldS === "weight") {
            const operatorS =
              value[1] === "equal"
                ? "="
                : value[1] === "greaterThan"
                ? ">"
                : value[1] === "greaterOrEqualThan"
                ? ">="
                : value[1] === "lessThan"
                ? "<"
                : value[1] === "lessOrEqualThan"
                ? "<="
                : "=";
            search =
              search === ""
                ? `${fieldS} ${operatorS} '${value[0]}'`
                : `${search};${fieldS} ${operatorS} '${value[0]}'`;
          } else if (fieldS === "weight_date") {
            const operatorS =
              value[1] === "equal"
                ? "="
                : value[1] === "greaterThan"
                ? ">"
                : value[1] === "greaterOrEqualThan"
                ? ">="
                : value[1] === "lessThan"
                ? "<"
                : value[1] === "lessOrEqualThan"
                ? "<="
                : "=";

            search =
              search === ""
                ? `cast(${fieldS} as date) ${operatorS} cast('${moment(
                    value[0]
                  ).format("YYYY-MM-DD")}' as date)`
                : `${search};cast(${fieldS} as date) ${operatorS} cast('${moment(
                    value[0]
                  ).format("YYYY-MM-DD")}' as date)`;
          } else if (fieldS === "origination") {
            search =
              search === ""
                ? `${fieldS} in (${value
                    .map((o) => (o = `'${o}'`))
                    .join(", ")})`
                : `${search};${fieldS} in (${value
                    .map((o) => (o = `'${o}'`))
                    .join(", ")})`;
          } else {
            search =
              search === ""
                ? `upper(${fieldS}) like upper('%25${value}%25')`
                : `${search};upper(${fieldS}) like upper('%25${value}%25')`;
          }
        }
      });
    }

    return search !== "" ? search : "";
  }, []);

  const handleTableChange = useCallback(
    async (pagination = null, filters = null, sorter = null) => {
      if (pagination && filters && sorter) {
        let customSort = getSortString(sorter);
        let search = getSearchString(filters);

        setTableProperties({
          tableFilters: filters,
          tableSorters: sorter,
          size: pagination.pageSize ?? 5,
          page: pagination.current ?? 0,
          search,
        });

        await fetchData(
          pagination.current,
          pagination.pageSize,
          customSort,
          search
        );
      } else {
        setTableProperties({
          size: 5,
          page: 0,
          tableFilters: {},
          tableSorters: {},
          search: null,
        });
        await fetchData(0, 5, {}, "");
      }
    },
    [getSortString, getSearchString, fetchData]
  );

  const handleSearch = useCallback((selectedKeys, confirm) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  const getColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys && selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
    }),
    [handleReset, handleSearch]
  );

  const getColumnDateSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Row type="flex" gutter={4}>
            <Col>
              <Select
                value={selectedKeys ? selectedKeys[1] : "equal"}
                onChange={(value) =>
                  setSelectedKeys(
                    value
                      ? selectedKeys
                        ? [selectedKeys[0], value]
                        : [null, value]
                      : []
                  )
                }
              >
                <Select.Option value="equal">=</Select.Option>
                <Select.Option value="greaterThan">&gt;</Select.Option>
                <Select.Option value="greaterOrEqualThan">&ge;</Select.Option>
                <Select.Option value="lessThan">&lt;</Select.Option>
                <Select.Option value="lessOrEqualThan">&le;</Select.Option>
              </Select>
            </Col>
            <Col>
              <DatePicker
                value={selectedKeys && selectedKeys[0]}
                format={"DD/MM/YYYY"}
                onChange={(date) =>
                  setSelectedKeys(
                    date
                      ? selectedKeys && selectedKeys.length > 1
                        ? [date, selectedKeys[1]]
                        : [date, "equal"]
                      : []
                  )
                }
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
            </Col>
          </Row>

          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
    }),
    [handleReset, handleSearch]
  );

  const getColumnNumberSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Row type="flex" gutter={4}>
            <Col>
              <Select
                value={selectedKeys ? selectedKeys[1] : "equal"}
                onChange={(value) =>
                  setSelectedKeys(
                    value
                      ? selectedKeys
                        ? [selectedKeys[0], value]
                        : [null, value]
                      : []
                  )
                }
              >
                <Select.Option value="equal">=</Select.Option>
                <Select.Option value="greaterThan">&gt;</Select.Option>
                <Select.Option value="greaterOrEqualThan">&ge;</Select.Option>
                <Select.Option value="lessThan">&lt;</Select.Option>
                <Select.Option value="lessOrEqualThan">&le;</Select.Option>
              </Select>
            </Col>
            <Col>
              <InputNumber
                value={selectedKeys && selectedKeys[0]}
                min={0}
                precision={2}
                step={0.01}
                onChange={(value) =>
                  setSelectedKeys(
                    value
                      ? selectedKeys && selectedKeys.length > 1
                        ? [value, selectedKeys[1]]
                        : [value, "equal"]
                      : []
                  )
                }
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
            </Col>
          </Row>

          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
    }),
    [handleReset, handleSearch]
  );

  useEffect(() => {
    handleTableChange();
    return () => {
      setTableProperties({
        size: 5,
        page: 0,
        tableFilters: {},
        tableSorters: {},
        search: null,
      });
      setDataList([]);
    };
  }, [handleTableChange]);

  return (
    <Table
      rowKey="id"
      loading={isLoadingDataList}
      dataSource={dataList != null ? dataList.content : []}
      pagination={{
        total: dataList != null ? dataList.totalElements : 0,
        size: dataList != null ? tableProperties.size : 0,
        current: dataList != null ? tableProperties.page : 0,
        defaultPageSize: 5,
        hideOnSinglePage:
          dataList !== null && Object.entries(dataList).length !== 0
            ? dataList.totalElements > 5
              ? false
              : true
            : true,
        showSizeChanger: true,
        pageSizeOptions: ["5", "20", "30", "100", "500", "1000", "2000"],
      }}
      scroll={{
        x: 1200,
      }}
      onChange={handleTableChange}
    >
      <Column
        title={
          translation.viewTransferAnimalTransactionModal.table.columns
            .handlingNumber
        }
        dataIndex="handlingNumber"
        sorter
        key="handlingNumber"
        filteredValue={tableProperties.tableFilters?.handlingNumber || null}
        align="left"
        width={200}
        sortOrder={
          tableProperties.tableSorters?.columnKey === "handlingNumber" &&
          tableProperties.tableSorters.order
        }
        {...getColumnSearchProps("handlingNumber")}
      />
      <Column
        title={
          translation.viewTransferAnimalTransactionModal.table.columns
            .dateTransferCreation
        }
        dataIndex="dateTransferCreation"
        sorter
        key="dateTransferCreation"
        filteredValue={
          tableProperties.tableFilters?.dateTransferCreation || null
        }
        align="left"
        width={200}
        sortOrder={
          tableProperties.tableSorters?.columnKey === "dateTransferCreation" &&
          tableProperties.tableSorters.order
        }
        {...getColumnDateSearchProps("dateTransferCreation")}
        render={(text) =>
          text && <span>{`${moment(text).format("DD/MM/YYYY")}`}</span>
        }
      />
      <Column
        title={
          translation.viewTransferAnimalTransactionModal.table.columns
            .dateTransferAccept
        }
        dataIndex="dateTransferAccept"
        sorter
        key="dateTransferAccept"
        filteredValue={tableProperties.tableFilters?.dateTransferAccept || null}
        align="left"
        width={200}
        sortOrder={
          tableProperties.tableSorters?.columnKey === "dateTransferAccept" &&
          tableProperties.tableSorters.order
        }
        {...getColumnDateSearchProps("dateTransferAccept")}
        render={(text) =>
          text && <span>{`${moment(text).format("DD/MM/YYYY")}`}</span>
        }
      />
      <Column
        title={
          translation.viewTransferAnimalTransactionModal.table.columns
            .weightTransfer
        }
        dataIndex="weightTransfer"
        sorter
        key="weightTransfer"
        filteredValue={tableProperties.tableFilters?.weightTransfer || null}
        align="left"
        width={200}
        sortOrder={
          tableProperties.tableSorters?.columnKey === "weightTransfer" &&
          tableProperties.tableSorters.order
        }
        {...getColumnNumberSearchProps("weightTransfer")}
        render={(text) => <span>{`${numberMask(text, false)} kg`}</span>}
      />
      <Column
        title={
          translation.viewTransferAnimalTransactionModal.table.columns
            .priceTransfer
        }
        dataIndex="priceTransfer"
        sorter
        key="priceTransfer"
        filteredValue={tableProperties.tableFilters?.priceTransfer || null}
        align="left"
        width={200}
        sortOrder={
          tableProperties.tableSorters?.columnKey === "priceTransfer" &&
          tableProperties.tableSorters.order
        }
        {...getColumnNumberSearchProps("priceTransfer")}
        render={(text) => <span>{`${numberMask(text, true)}`}</span>}
      />
      <Column
        title={
          translation.viewTransferAnimalTransactionModal.table.columns
            .lotOriginName
        }
        dataIndex="lotOriginName"
        sorter
        key="lotOriginName"
        filteredValue={tableProperties.tableFilters?.lotOriginName || null}
        align="left"
        width={200}
        sortOrder={
          tableProperties.tableSorters?.columnKey === "lotOriginName" &&
          tableProperties.tableSorters.order
        }
        {...getColumnSearchProps("lotOriginName")}
      />
      <Column
        title={
          translation.viewTransferAnimalTransactionModal.table.columns
            .lotDestinationName
        }
        dataIndex="lotDestinationName"
        sorter
        key="lotDestinationName"
        filteredValue={tableProperties.tableFilters?.lotDestinationName || null}
        align="left"
        width={200}
        sortOrder={
          tableProperties.tableSorters?.columnKey === "lotDestinationName" &&
          tableProperties.tableSorters.order
        }
        {...getColumnSearchProps("lotDestinationName")}
      />
      <Column
        title={
          translation.viewTransferAnimalTransactionModal.table.columns
            .responsibleCreateTransfer
        }
        dataIndex="responsibleCreateTransfer"
        sorter
        key="responsibleCreateTransfer"
        filteredValue={
          tableProperties.tableFilters?.responsibleCreateTransfer || null
        }
        align="left"
        width={200}
        sortOrder={
          tableProperties.tableSorters?.columnKey ===
            "responsibleCreateTransfer" && tableProperties.tableSorters.order
        }
        {...getColumnSearchProps("responsibleCreateTransfer")}
      />
      <Column
        title={
          translation.viewTransferAnimalTransactionModal.table.columns
            .responsibleAcceptTransfer
        }
        dataIndex="responsibleAcceptTransfer"
        sorter
        key="responsibleAcceptTransfer"
        filteredValue={
          tableProperties.tableFilters?.responsibleAcceptTransfer || null
        }
        align="left"
        width={200}
        sortOrder={
          tableProperties.tableSorters?.columnKey ===
            "responsibleAcceptTransfer" && tableProperties.tableSorters.order
        }
        {...getColumnSearchProps("responsibleAcceptTransfer")}
      />
    </Table>
  );
};
export default ViewTransferAnimalTransactionAnimalTable;
