import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  border-radius: 5px;

  strong {
    margin-top: 10px;
  }

  .ant-select {
    width: 100%;
    margin: 10px 0px;
  }
`;

export const Title = styled.span`
  font-family: Asap;
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  color: #4b4b4b;

  svg {
    margin-left: 5px;
  }
`;
