import React, { useState, useEffect } from "react";
import axios from "axios";
import CanvasJSReact from "../../../../../assets/canvasjs.react";
import moment from "moment";
import { useHistory } from "react-router-dom";
/** Redux libs */
import { useSelector, useDispatch } from "react-redux";
import { Creators as SaleScenarioActions } from "../../../../../store/ducks/saleScenario";
/** Components */
import { Container, RadioButtonCustom, ListSaleCommitments } from "./styles";
import {
  Row,
  Col,
  Input,
  Radio,
  Icon,
  Select,
  DatePicker,
  Spin,
  notification,
  Switch,
} from "antd";
import ButtonStandard from "../../../../../components/utils/button";
import PencilIcon from "../../../../../components/utils/icons/pencil";
import NumberFormat from "react-number-format";
import ModalCommitment from "./modalCommitment";
import Loading from "../../../../../components/utils/loading";
import InfoTooltip from "../../../../../components/utils/infoTooltip";

/* Services */
import { getMarketAnalysisDropDown } from "../../../../../services/marketAnalysisService";
import { getFuturePriceChart } from "../../../../../services/futurePriceService";
import { getParameterItems } from "../../../../../services/generalParameterService";
import { storeSaleScenario } from "../../../../../services/saleScenarioService";
import { indexAllDropdownFarmQuota } from "../../../../../services/quotaService";

const TabParameters = () => {
  /** Variables */
  const [isLoading, setIsLoading] = useState(false);
  const [loadingListCommitments, setLoadingListCommitments] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  // If in the future add new fields in SaleScenarioParam.class, update saleScenario(duck) newSaleScenario.saleScenarioParam
  const [form, setForm] = useState({
    id: null,
    retroactive: false,
    retroactiveDate: new Date(),
    animalsInTruck: null,
    animalsInTruckUnit: "cab",
    strongAnimalsInTruckRestriction: true,
    useCustomCarcassHarnessing: false,
    carcassHarnessing: null,
    useDefaultMaximumWeight: false,
    marketAnalysisId: null,
    allowSeparateSell: false,
    capacitySupportRestriction: "Strong",
    goalType: "MaxProfit",
    minimumAge: null,
    maximumAge: null,
    strongAgeRestriction: true,
    minimumWeight: null,
    maximumWeight: 720,
    strongWeightRestriction: true,
    useCarbonCredit: false,
    carbonCredit: null,
    useQuotaCriteria: true,
    quotaId: null,
  });

  const [errors, setErrors] = useState([]);
  const [listMarketAnalysis, setListMarketAnalysis] = useState([]);
  const [quotas, setQuotas] = useState([]);
  const [loadingListMarketAnalysis, setLoadingListMarketAnalysis] =
    useState(false);
  const [loadingChart, setLoadingChart] = useState(false);
  const [loadingQuota, setLoadingQuota] = useState(false);
  const [dataChart, setDataChart] = useState([]);
  const dateFormat = "DD/MM/YYYY";
  const { Group: InputGroup } = Input;
  const { Group: RadioGroup } = Radio;
  const { CanvasJSChart } = CanvasJSReact;
  const selectAnimalsInTruckUnit = (
    <Select
      value={form?.animalsInTruckUnit != null ? form?.animalsInTruckUnit : "UA"}
      onChange={(value) => {
        setForm({
          ...form,
          animalsInTruckUnit: value,
        });
      }}
    >
      <Select.Option value="UA">UA</Select.Option>
      <Select.Option value="cab">cabeças</Select.Option>
    </Select>
  );

  const history = useHistory();

  /* Chart options */
  const options = {
    animationEnabled: true,
    zoomEnabled: true,
    height: 320,
    backgroundColor: "#fcfcfc",
    axisX: {
      labelFontSize: 12,
      valueFormatString: "MM/YY",
      interval: 1,
      intervalType: "month",
      viewportMinimum: moment().toDate(),
      viewportMaximum: moment().add(1, "years").toDate(),
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
      },
    },
    toolTip: {
      fontFamily: "Asap",
      shared: true,
    },
    axisY: {
      labelFontSize: 12,
      includeZero: true,
      valueFormatString: "###0.##",
      suffix: " R$/@",
      gridDashType: "dot",
    },
    data: dataChart,
  };

  /** Redux variables */
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const {
    newSaleScenario,
    newSaleScenario: { saleScenarioParam, saleCommitments },
    maxDeadLineGoal,
    minDeadLineGoal,
  } = useSelector((state) => state.saleScenario);

  const dispatch = useDispatch();

  /** Effect */
  useEffect(() => {
    setForm(saleScenarioParam);
  }, [saleScenarioParam]);

  useEffect(() => {
    setLoadingListCommitments(true);
    setTimeout(() => setLoadingListCommitments(false), 500);
  }, [saleCommitments]);

  useEffect(() => {
    async function fetchMarketAnalysis() {
      setLoadingListMarketAnalysis(true);
      try {
        const {
          data: { results },
        } = await getMarketAnalysisDropDown({ groupId, farmId });
        setListMarketAnalysis(results);
        setLoadingListMarketAnalysis(false);
      } catch (error) {
        setListMarketAnalysis([]);
        setLoadingListMarketAnalysis(false);
      }
    }

    async function fetchQuotas() {
      setLoadingQuota(true);
      try {
        const {
          data: { results },
        } = await indexAllDropdownFarmQuota({ groupId, farmId });
        setQuotas(results);
        setLoadingQuota(false);
      } catch (error) {
        setLoadingQuota([]);
        setLoadingQuota(false);
      }
    }

    fetchMarketAnalysis();
    fetchQuotas();
  }, [groupId, farmId]);

  // Set carbon footprint credit
  useEffect(() => {
    const signal = axios.CancelToken.source();
    async function fetchData() {
      try {
        let {
          data: { results },
        } = await getParameterItems({ groupId, farmId, code: 1007, signal });
        setForm((old) => ({
          ...old,
          carbonCredit: results?.value,
        }));
      } catch (error) {}
    }
    fetchData();
    return () => {
      signal.cancel();
    };
  }, [groupId, farmId]);

  /** Methods */
  async function createOrUpdateSaleScenario() {
    const body = mountRequestBody();
    setIsLoading(true);
    if (body.id === null) {
      try {
        await storeSaleScenario({
          groupId,
          farmId,
          body,
        });
        dispatch(
          SaleScenarioActions.enableDisableBlockNavigationNewScenario(false)
        );
        notification.success({
          message: "Cenário de venda criado!",
          description: "Você será notificado quando o mesmo for executado!",
        });
        setIsLoading(false);
        history.push(`/admin/sale/scenario`);
      } catch (error) {
        setIsLoading(false);
        notification.error({
          message: "Erro!",
          description:
            "Houve um erro no momento de criar o cenário de venda, contate o administrador do sistema",
        });
      }
    }
  }

  function editCommitment(item) {
    dispatch(SaleScenarioActions.openModalSaleCommitment(item));
  }

  function mountRequestBody() {
    const { saleCommitments, ...newSaleScenarioWithoutFields } =
      newSaleScenario;
    const { name, ...saleScenarioParam } = form;
    const updateSaleScenarioParam = {
      ...saleScenarioParam,
      saleCommitments,
    };
    const bodyRequest = {
      ...newSaleScenarioWithoutFields,
      name,
      saleScenarioParam: updateSaleScenarioParam,
    };
    return bodyRequest;
  }

  function removeCommitment(item) {
    dispatch(
      SaleScenarioActions.addUpdateOrRemoveCommitments(item, item.key, "remove")
    );
  }

  async function renderChart(marketAnalysisId) {
    setLoadingChart(true);
    let chartData = null;
    try {
      const {
        data: { results },
      } = await getFuturePriceChart({ groupId, farmId, marketAnalysisId });
      if (results.length > 0) {
        chartData = results.map((r) => {
          r.x = moment(r.x).toDate();
          r.y = Number.parseFloat(Number(r.y).toFixed(2));
          return r;
        });
      } else {
        chartData = null;
      }
    } catch (error) {
      chartData = null;
    }
    if (chartData !== null) {
      let nameMarketAnalysis =
        listMarketAnalysis.find((ma) => ma.id === marketAnalysisId)?.name ||
        "Valores";
      setDataChart([
        {
          type: "line",
          showInLegend: true,
          name: nameMarketAnalysis,
          color: "#4A85AE",
          xValueFormatString: "DD/MM/YYYY",
          yValueFormatString: "R$#######.00",
          dataPoints: chartData,
        },
      ]);
    } else {
      setDataChart([]);
    }

    setLoadingChart(false);
  }

  function validateForm() {
    let errorArray = [];
    if (form == null) {
      errorArray.push("animalsInTruck");
      errorArray.push("minimumAge");
      errorArray.push("maximumAge");
      errorArray.push("minimumWeight");
      errorArray.push("maximumWeight");
      errorArray.push("allowSeparateSell");
      errorArray.push("marketAnalysisId");
      errorArray.push("capacitySupportRestriction");
      errorArray.push("deadlineGoal");
      errorArray.push("useQuotaCriteria");
    }
    if (form?.name == null) errorArray.push("name");
    if (form?.retroactiveDate == null && form?.retroactive)
      errorArray.push("retroactiveDate");
    if (form?.animalsInTruck == null) errorArray.push("animalsInTruck");

    if (form?.useQuotaCriteria === false) {
      if (form?.minimumAge == null || form?.minimumAge === 0)
        errorArray.push("minimumAge");
      if (form?.maximumAge == null || form?.maximumAge === 0)
        errorArray.push("maximumAge");
      if (
        form?.maximumAge !== null &&
        form?.maximumAge > 0 &&
        form?.maximumAge < form?.minimumAge
      ) {
        errorArray.push("maximumAge");
        notification.error({
          message:
            'O Campo "Idade máxima para venda" deve ser maior que a idade mínima.',
        });
      }
      if (form?.minimumWeight == null || form?.minimumWeight === 0)
        errorArray.push("minimumWeight");
      if (form?.maximumWeight == null || form?.maximumWeight === 0)
        errorArray.push("maximumWeight");
      if (
        form?.maximumWeight !== null &&
        form?.maximumWeight > 0 &&
        form?.maximumWeight < form?.minimumWeight
      ) {
        errorArray.push("maximumWeight");
        notification.error({
          message:
            'O Campo "Peso máximo para venda" deve ser maior que o peso mínimo.',
        });
      }
    } else if (
      form.useQuotaCriteria === true &&
      (form?.quotaId === null || form?.quotaId === undefined)
    ) {
      errorArray.push("useQuotaCriteria");
    }

    if (form?.allowSeparateSell == null) errorArray.push("allowSeparateSell");
    if (form?.marketAnalysisId == null) errorArray.push("marketAnalysisId");
    if (form?.capacitySupportRestriction == null)
      errorArray.push("capacitySupportRestriction");
    if (
      form?.useCustomCarcassHarnessing === true &&
      (form?.carcassHarnessing === null || form?.carcassHarnessing <= 0)
    ) {
      errorArray.push("carcassHarnessing");
    }
    if (
      form?.useCustomCarcassHarnessing === true &&
      form?.carcassHarnessing !== null &&
      (form?.carcassHarnessing < 45 || form?.carcassHarnessing > 60)
    ) {
      errorArray.push("carcassHarnessing");
      notification.error({
        message: "Aproveitamento de Carcaça inválida",
        description:
          'O valor do campo "Aproveitamento de Carcaça" deve estar entre o intervalo de valores (45% e 60%)',
      });
    }
    setErrors(errorArray);
    return errorArray.length > 0 ? false : true;
  }

  function handleCancel() {
    setIsEditing(!isEditing);
    setForm(saleScenarioParam);
    dispatch(
      SaleScenarioActions.enableDisableBlockNavigationNewScenario(false)
    );
    history.goBack();
  }

  function handleSubmitForm() {
    let result = validateForm();
    if (result) {
      dispatch(SaleScenarioActions.updateSaleScenarioParam(form));
      createOrUpdateSaleScenario();
    }
  }

  const StrongInput = (field, helper) => (
    <div className="inputStrong">
      <span>Forte?</span> <InfoTooltip title={helper} />{" "}
      <Switch
        checked={form?.[field] === true ? true : false}
        onChange={(checked, event) =>
          setForm({
            ...form,
            [field]: checked,
          })
        }
      />
    </div>
  );

  return (
    <Container>
      {isLoading && <Loading />}
      {/* Header */}
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          align="right"
          className="buttonsCol"
        >
          <ButtonStandard
            type="button"
            buttonType="type4"
            size="s"
            onClick={handleCancel}
          >
            {translation.buttons.cancel}
          </ButtonStandard>

          <ButtonStandard
            type="button"
            buttonType="type1"
            width="121px"
            height="35px"
            padding="5px 10px 5px 10px"
            onClick={handleSubmitForm}
          >
            Criar
          </ButtonStandard>
        </Col>
      </Row>
      {/* Body */}
      <Row className="customCol">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          {errors.length > 0 && (
            <Row type="flex" justify="center">
              <span className="errorMessage">
                {translation.error.formError}
              </span>
            </Row>
          )}
          <Row type="flex" gutter={16} justify="start">
            {/* Column 1 */}
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              {/* Input name */}
              <Row type="flex" gutter={8} justify="start">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Row>
                    <label className={errors.includes("name") ? "error" : ""}>
                      Nome do cenário *
                    </label>
                  </Row>
                  <Row>
                    <Input
                      name="name"
                      autoComplete="chrome-off"
                      value={form !== null ? form.name : null}
                      placeholder={translation.defaultPlaceholder}
                      maxLength={40}
                      onChange={(e) => {
                        setForm({ ...form, name: e.target.value });
                        setErrors(errors.filter((e) => e !== "name"));
                      }}
                    />
                  </Row>
                </Col>
              </Row>
              {/* Input retroactive and  retroactiveDate*/}
              <Row type="flex" gutter={8} justify="start">
                {/* Input retroactive */}
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Row>
                    <label
                      className={errors.includes("retroactive") ? "error" : ""}
                    >
                      Este cenário é retroativo? *
                    </label>
                  </Row>
                  <Row>
                    <RadioGroup
                      name="retroactive"
                      value={form != null ? form.retroactive : false}
                      defaultValue={false}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          retroactive: e.target.value,
                        });
                      }}
                    >
                      <RadioButtonCustom value={true}>
                        {translation.buttons.yes}
                      </RadioButtonCustom>
                      <RadioButtonCustom value={false}>
                        {translation.buttons.no}
                      </RadioButtonCustom>
                    </RadioGroup>
                  </Row>
                </Col>
                {/* Input retroactiveDate */}
                {form != null && form.retroactive && (
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Row>
                      <label
                        className={
                          errors.includes("retroactiveDate") ? "error" : ""
                        }
                      >
                        Digite a data de inicio do processamento *
                      </label>
                    </Row>
                    <Row>
                      <DatePicker
                        name="retroactiveDate"
                        value={
                          form != null
                            ? form.retroactiveDate != null
                              ? moment(form.retroactiveDate, "YYYY-MM-DD")
                              : null
                            : null
                        }
                        disabledDate={(current) =>
                          current && current > moment()
                        }
                        placeholder={translation.defaultDatePickerPlaceholder}
                        allowClear={false}
                        format={dateFormat}
                        onChange={(date, dateString) => {
                          if (
                            date != null &&
                            date.isBefore(moment(), "days") &&
                            date.isSameOrBefore(
                              moment(maxDeadLineGoal),
                              "days"
                            ) &&
                            date.isSameOrAfter(moment(minDeadLineGoal), "days")
                          ) {
                            setForm({
                              ...form,
                              retroactiveDate: date,
                            });
                            setErrors(
                              errors.filter((e) => e !== "retroactiveDate")
                            );
                          }
                        }}
                      />
                    </Row>
                  </Col>
                )}
              </Row>
              {/* Input quota */}
              <Row type="flex" gutter={8} justify="start">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Row>
                    <label
                      className={
                        errors.includes("useQuotaCriteria") ? "error" : ""
                      }
                    >
                      Critérios de venda *
                    </label>
                  </Row>
                  <Row>
                    <RadioGroup
                      name="useQuotaCriteria"
                      value={form.useQuotaCriteria}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          useQuotaCriteria: e.target.value,
                          quotaId: null,
                        });
                        setErrors(
                          errors.filter((e) => e !== "useQuotaCriteria")
                        );
                      }}
                    >
                      <Radio value={true}>Pré-Fixadas (Cotas)</Radio>
                      <Radio value={false}>Livre</Radio>
                    </RadioGroup>
                  </Row>
                  {form.useQuotaCriteria === true ? (
                    <Row>
                      <Select
                        name="quotaId"
                        style={{ width: "100%" }}
                        value={
                          form != null && form.quotaId != null
                            ? form.quotaId
                            : undefined
                        }
                        loading={loadingQuota}
                        placeholder={translation.defaultSelectPlaceholder}
                        onChange={(value) => {
                          setForm({
                            ...form,
                            quotaId: value,
                          });
                          setErrors(
                            errors.filter((e) => e !== "useQuotaCriteria")
                          );
                        }}
                      >
                        {quotas.map((q) => (
                          <Select.Option key={q.id} value={q.id}>
                            {q.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Row>
                  ) : null}
                </Col>
              </Row>
              {form.useQuotaCriteria === false ? (
                <>
                  {/* Input minimumAge and maximumAge */}
                  <Row type="flex" gutter={8} justify="start">
                    <label>Idade para Venda</label>
                    {StrongInput(
                      "strongAgeRestriction",
                      "Ao selecionar esta opção, o intervalo entre a idade mínima e máxima para venda, se tornará uma restrição forte, desta forma, o sistema buscará a melhor oportunidade de venda, respeitando esta premissa. Caso, esta opção, esteja desmarcada, o sistema  irá considerar o intervalo, porém, caso exista uma possibilidade melhor, mesmo que fora deste intervalo de idade, o sistema retornará com esta melhor opção."
                    )}
                  </Row>
                  <Row type="flex" gutter={8} justify="start">
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Row>
                        <label
                          className={
                            errors.includes("minimumAge") ? "error" : ""
                          }
                        >
                          Idade mínima para venda*
                        </label>
                      </Row>
                      <Row type="flex" align="middle">
                        <InputGroup compact>
                          <Input
                            type="number"
                            style={{ width: 80, textAlign: "center" }}
                            value={form != null ? form.minimumAge : null}
                            name="ageDay"
                            min="0"
                            placeholder="Dias"
                            onChange={(e) => {
                              setForm({
                                ...form,
                                minimumAge:
                                  e.target.value > 0
                                    ? e.target.value > 36135
                                      ? 36135
                                      : e.target.value
                                    : 0,
                              });
                              setErrors(
                                errors.filter((e) => e !== "minimumAge")
                              );
                            }}
                          />
                          <Input
                            style={{
                              width: 80,
                              textAlign: "center",
                              borderLeft: "1px solid #c4c4c4",
                            }}
                            type="number"
                            name="ageMonth"
                            min="0"
                            placeholder="Meses"
                            value={
                              form != null && form.minimumAge != null
                                ? Number(form.minimumAge / 30.417).toFixed(0)
                                : null
                            }
                            onChange={(e) => {
                              setForm({
                                ...form,
                                minimumAge:
                                  e.target.value > 0
                                    ? e.target.value > 1188
                                      ? Math.floor(1188 * 30.417)
                                      : Math.floor(e.target.value * 30.417)
                                    : 0,
                              });
                              setErrors(
                                errors.filter((e) => e !== "minimumAge")
                              );
                            }}
                          />
                          <Input
                            style={{
                              width: 80,
                              textAlign: "center",
                              borderLeft: "1px solid #c4c4c4",
                            }}
                            type="number"
                            name="ageYear"
                            min="0"
                            placeholder="Anos"
                            value={
                              form != null && form.minimumAge != null
                                ? form.minimumAge >= 365
                                  ? Math.floor(form.minimumAge / 365)
                                  : 0
                                : null
                            }
                            onChange={(e) => {
                              setForm({
                                ...form,
                                minimumAge:
                                  e.target.value > 0
                                    ? e.target.value > 99
                                      ? 99 * 365
                                      : e.target.value * 365
                                    : 0,
                              });
                              setErrors(
                                errors.filter((e) => e !== "minimumAge")
                              );
                            }}
                          />
                        </InputGroup>
                      </Row>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Row>
                        <label
                          className={
                            errors.includes("maximumAge") ? "error" : ""
                          }
                        >
                          Idade máxima para venda*
                        </label>
                      </Row>
                      <Row type="flex" align="middle">
                        <InputGroup compact>
                          <Input
                            type="number"
                            style={{ width: 80, textAlign: "center" }}
                            value={form != null ? form.maximumAge : null}
                            name="ageDay"
                            min="0"
                            maxLength={4}
                            placeholder="Dias"
                            onChange={(e) => {
                              setForm({
                                ...form,
                                maximumAge:
                                  e.target.value > 0
                                    ? e.target.value > 36135
                                      ? 36135
                                      : e.target.value
                                    : 0,
                              });
                              setErrors(
                                errors.filter((e) => e !== "maximumAge")
                              );
                            }}
                          />
                          <Input
                            style={{
                              width: 80,
                              textAlign: "center",
                              borderLeft: "1px solid #c4c4c4",
                            }}
                            type="number"
                            name="ageMonth"
                            min="0"
                            maxLength={3}
                            placeholder="Meses"
                            value={
                              form != null && form.maximumAge != null
                                ? Number(form.maximumAge / 30.417).toFixed(0)
                                : null
                            }
                            onChange={(e) => {
                              setForm({
                                ...form,
                                maximumAge:
                                  e.target.value > 0
                                    ? e.target.value > 1188
                                      ? Math.floor(1188 * 30.417)
                                      : Math.floor(e.target.value * 30.417)
                                    : 0,
                              });
                              setErrors(
                                errors.filter((e) => e !== "maximumAge")
                              );
                            }}
                          />
                          <Input
                            style={{
                              width: 80,
                              textAlign: "center",
                              borderLeft: "1px solid #c4c4c4",
                            }}
                            type="number"
                            name="ageYear"
                            min="0"
                            maxLength={2}
                            placeholder="Anos"
                            value={
                              form != null && form.maximumAge != null
                                ? form.maximumAge >= 365
                                  ? Math.floor(form.maximumAge / 365)
                                  : 0
                                : null
                            }
                            onChange={(e) => {
                              setForm({
                                ...form,
                                maximumAge:
                                  e.target.value > 0
                                    ? e.target.value > 99
                                      ? 99 * 365
                                      : e.target.value * 365
                                    : 0,
                              });
                              setErrors(
                                errors.filter((e) => e !== "maximumAge")
                              );
                            }}
                          />
                        </InputGroup>
                      </Row>
                    </Col>
                  </Row>
                  {/* Input Minimum Weight and Maximum Weight*/}
                  <Row type="flex" gutter={8} justify="start">
                    <label>Peso para Venda</label>
                    {StrongInput(
                      "strongWeightRestriction",
                      "Ao selecionar esta opção, o intervalo entre o peso mínimo e máximo para venda, se tornará uma restrição forte, desta forma, o sistema buscará a melhor oportunidade de venda, respeitando esta premissa. Caso, esta opção, esteja desmarcada, o sistema  irá considerar o intervalo, porém, caso exista uma possibilidade melhor, mesmo que fora deste intervalo de peso, o sistema retornará com esta melhor opção."
                    )}
                  </Row>
                  <Row type="flex" gutter={8} justify="start">
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Row>
                        <label
                          className={
                            errors.includes("minimumWeight") ? "error" : ""
                          }
                        >
                          Peso mínimo para venda*
                        </label>
                      </Row>
                      <Row>
                        <NumberFormat
                          customInput={Input}
                          style={{ width: 300 }}
                          value={form !== null ? form.minimumWeight : null}
                          placeholder={translation.defaultPlaceholder}
                          name="minimumWeight"
                          addonAfter="Kg"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            setForm({
                              ...form,
                              minimumWeight:
                                floatValue >= 0
                                  ? floatValue > 9999
                                    ? 9999
                                    : floatValue
                                  : null,
                            });
                            setErrors(
                              errors.filter((e) => e !== "minimumWeight")
                            );
                          }}
                        />
                      </Row>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Row>
                        <label
                          className={
                            errors.includes("maximumWeight") ? "error" : ""
                          }
                        >
                          Peso máximo para venda*
                        </label>
                      </Row>
                      <Row>
                        <NumberFormat
                          customInput={Input}
                          style={{ width: 300 }}
                          value={form !== null ? form.maximumWeight : null}
                          placeholder={translation.defaultPlaceholder}
                          name="maximumWeight"
                          addonAfter="Kg"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            setForm({
                              ...form,
                              maximumWeight:
                                floatValue >= 0
                                  ? floatValue > 9999
                                    ? 9999
                                    : floatValue
                                  : null,
                            });
                            setErrors(
                              errors.filter((e) => e !== "maximumWeight")
                            );
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                </>
              ) : null}
              {/* Input useCustomCarcassHarnessing */}
              <Row type="flex" gutter={8} justify="start">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <label
                      className={
                        errors.includes("useCustomCarcassHarnessing")
                          ? "formLabel error"
                          : "formLabel"
                      }
                    >
                      Informar novo % de Aprov. de Carcaça?{" "}
                      <InfoTooltip title="Por padrão, será utilizado o % de Aproveitamento de Carcaça da Estratégia de Dieta associada ao Lote. Nesta opção, poderá informar um novo % a ser considerado neste Cenário de Venda." />
                    </label>
                  </Row>
                  <Row>
                    <RadioGroup
                      name="useCustomCarcassHarnessing"
                      value={form.useCustomCarcassHarnessing}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          useCustomCarcassHarnessing: e.target.value,
                        });
                        if (e.target.value === false) {
                          setForm({
                            ...form,
                            useCustomCarcassHarnessing: e.target.value,
                            carcassHarnessing: null,
                          });
                        }
                      }}
                    >
                      <Radio value={true}>{translation.buttons.yes}</Radio>
                      <Radio value={false}>{translation.buttons.no}</Radio>
                    </RadioGroup>
                  </Row>
                </Col>
                {form.useCustomCarcassHarnessing === true && (
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Row>
                      <label
                        className={
                          errors.includes("carcassHarnessing")
                            ? "formLabel error"
                            : "formLabel"
                        }
                      >
                        Aprov. de Carcaça
                      </label>
                    </Row>
                    <Row>
                      <NumberFormat
                        customInput={Input}
                        value={
                          form?.carcassHarnessing !== null
                            ? form?.carcassHarnessing
                            : null
                        }
                        placeholder={translation.defaultPlaceholder}
                        name="carcassHarnessing"
                        allowNegative={false}
                        addonAfter="%"
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        onValueChange={({ floatValue }) => {
                          setForm({
                            ...form,
                            carcassHarnessing: floatValue
                              ? floatValue <= 100
                                ? floatValue
                                : 100
                              : null,
                          });
                          setErrors((old) =>
                            old.filter((e) => e !== "carcassHarnessing")
                          );
                        }}
                      />
                    </Row>
                  </Col>
                )}
              </Row>
            </Col>
            {/* Column 2 */}
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              {/* Input animalsInTruck */}
              <Row type="flex" gutter={8} justify="start">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row type="flex" gutter={8}>
                    <label
                      className={
                        errors.includes("animalsInTruck") ? "error" : ""
                      }
                    >
                      Animais no caminhão *
                    </label>
                    {StrongInput(
                      "strongAnimalsInTruckRestriction",
                      "Ao marcar está opção, o sistema buscará a melhor oportunidade de venda, mesmo que o lote seja descascado, mas respeitando a quantidade de animais por caminhão."
                    )}
                  </Row>
                  <Row>
                    <NumberFormat
                      customInput={Input}
                      value={form !== null ? form?.animalsInTruck : null}
                      style={{ width: 300 }}
                      name="animalsInTruck"
                      placeholder={translation.defaultPlaceholder}
                      addonAfter={selectAnimalsInTruckUnit}
                      decimalScale={form?.animalsInTruckUnit === "cab" ? 0 : 2}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale={true}
                      onValueChange={({ floatValue }) => {
                        setForm({
                          ...form,
                          animalsInTruck:
                            floatValue >= 0
                              ? floatValue > 99
                                ? 99
                                : floatValue
                              : null,
                        });
                        setErrors(errors.filter((e) => e !== "animalsInTruck"));
                      }}
                    />
                  </Row>
                </Col>
                {/* Input allowSeparateSell */}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <label
                      className={
                        errors.includes("allowSeparateSell") ? "error" : ""
                      }
                    >
                      Lotes podem sofrer descasca? *
                    </label>
                  </Row>
                  <Row>
                    <RadioGroup
                      name="allowSeparateSell"
                      value={form != null ? form.allowSeparateSell : false}
                      defaultValue={false}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          allowSeparateSell: e.target.value,
                        });
                      }}
                    >
                      <RadioButtonCustom value={true}>
                        {translation.buttons.yes}
                      </RadioButtonCustom>
                      <RadioButtonCustom value={false}>
                        {translation.buttons.no}
                      </RadioButtonCustom>
                    </RadioGroup>
                  </Row>
                </Col>
              </Row>
              {/* Input useCarbonCredit */}
              <Row type="flex" gutter={8} justify="start">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <label
                      className={
                        errors.includes("useCarbonCredit") ? "error" : ""
                      }
                    >
                      Simular potencial de créditos carbono ? *
                    </label>
                  </Row>
                  <Row>
                    <RadioGroup
                      name="useCarbonCredit"
                      value={form != null ? form.useCarbonCredit : false}
                      defaultValue={false}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          useCarbonCredit: e.target.value,
                        });
                      }}
                    >
                      <RadioButtonCustom value={true}>
                        {translation.buttons.yes}
                      </RadioButtonCustom>
                      <RadioButtonCustom value={false}>
                        {translation.buttons.no}
                      </RadioButtonCustom>
                    </RadioGroup>
                  </Row>
                </Col>
                {form?.useCarbonCredit && (
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    {/* Input useCarbonCredit */}
                    <Row>
                      <label>Valor da crédito de carbono</label>
                    </Row>
                    <Row>
                      <NumberFormat
                        customInput={Input}
                        style={{ width: 300 }}
                        value={form !== null ? Number(form.carbonCredit) : null}
                        placeholder={translation.defaultPlaceholder}
                        name="carbonCredit"
                        addonAfter={
                          <span>
                            kg CO<sub>2</sub>E
                          </span>
                        }
                        addonBefore="R$"
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        onValueChange={({ floatValue }) => {
                          setForm({
                            ...form,
                            carbonCredit:
                              floatValue >= 0
                                ? floatValue > 999.99
                                  ? 999.99
                                  : floatValue
                                : null,
                          });
                        }}
                      />
                    </Row>
                  </Col>
                )}
              </Row>
              {/* Input capacitySupportRestriction (Strong = No | Weak = Yes) */}
              <Row type="flex" gutter={8} justify="start">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Row>
                    <label>
                      Você deseja arrendar mais terra caso falte capacidade de
                      suporte? *
                    </label>
                  </Row>
                  <Row>
                    <RadioGroup
                      name="capacitySupportRestriction"
                      value={form.capacitySupportRestriction}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          capacitySupportRestriction: e.target.value,
                        });
                        setErrors(
                          errors.filter(
                            (e) => e !== "capacitySupportRestriction"
                          )
                        );
                      }}
                    >
                      <Radio value={"Weak"}>{translation.buttons.yes}</Radio>
                      <Radio value={"Strong"}>{translation.buttons.no}</Radio>
                    </RadioGroup>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* Commitments and MarketAnalysis */}
          <Row
            type="flex"
            className="withBackground"
            gutter={16}
            justify="start"
          >
            {/* Commitments */}
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Row type="flex" gutter={2}>
                <label>
                  Cenário de venda possui algum compromisso?{" "}
                  <InfoTooltip title="Os compromissos de venda, seja em relação à necessidade de venda de animais ou uma receita específica em determinada data, sempre será considerada como uma Restrição 'Forte', sobrepondo as demais restrições, de forma que seja exibido ao usuário, se com os Lotes e Animais selecionados, o compromisso será respeitado." />
                </label>
              </Row>
              <Row>
                {saleCommitments.length > 0 ? (
                  <ListSaleCommitments
                    itemLayout="horizontal"
                    dataSource={saleCommitments}
                    loading={loadingListCommitments}
                    renderItem={(item, index) => (
                      <ListSaleCommitments.Item key={item.key}>
                        <Row type="flex" justify="space-between">
                          <div>
                            <span>
                              <strong>{index + 1}.</strong>
                              {` ${
                                item.saleCommitmentType === "AmountAnimals"
                                  ? `${item.commitmentValue} animal(is)`
                                  : `R$ ${item.commitmentValue.toFixed(2)}`
                              } até ${moment(item.commitmentDate).format(
                                "DD/MM/YYYY"
                              )}`}
                            </span>
                          </div>
                          <div className="buttonGroup">
                            <Icon
                              component={PencilIcon}
                              onClick={() => editCommitment(item)}
                            />
                            <Icon
                              type="delete"
                              className="delete"
                              onClick={() => removeCommitment(item)}
                            />
                          </div>
                        </Row>
                      </ListSaleCommitments.Item>
                    )}
                  />
                ) : (
                  <p className="emptySaleCommitments">
                    Nenhum compromisso informado
                  </p>
                )}
              </Row>
              <Row>
                <button
                  className="addSaleCommitment"
                  onClick={() =>
                    dispatch(SaleScenarioActions.openModalSaleCommitment())
                  }
                >
                  <Icon type="plus" />
                  Adicionar
                </button>
              </Row>
            </Col>
            {/* MarketAnalysisId */}
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Row>
                <label
                  className={errors.includes("marketAnalysisId") ? "error" : ""}
                >
                  Análise de mercado *
                </label>
              </Row>
              <Row>
                <Select
                  name="marketAnalysisId"
                  style={{ width: "100%" }}
                  value={
                    form != null && form.marketAnalysisId != null
                      ? form.marketAnalysisId
                      : undefined
                  }
                  loading={loadingListMarketAnalysis}
                  placeholder={translation.defaultSelectPlaceholder}
                  onChange={(value) => {
                    setForm({
                      ...form,
                      marketAnalysisId: value,
                    });
                    setErrors(errors.filter((e) => e !== "marketAnalysisId"));
                    renderChart(value);
                  }}
                >
                  {listMarketAnalysis
                    .filter((ma) => ma.status === "Active")
                    .map((ma) => (
                      <Select.Option key={ma.id} value={ma.id}>
                        {ma.name}
                      </Select.Option>
                    ))}
                </Select>
              </Row>
              {/* Chart */}
              {(dataChart.length > 0 || loadingChart === true) && (
                <Row>
                  <Spin spinning={loadingChart}>
                    <CanvasJSChart options={options} />
                  </Spin>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {/* Modal */}
      <ModalCommitment />
    </Container>
  );
};

export default TabParameters;
