import apiFinancial from "../config/api_financial";
import getHeaders from "./getHeaders";

export function getMarketAnalysisDropDown(payload) {
  const { groupId, farmId, signal } = payload;
  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/marketAnalysis?withoutPagination=true`,
    getHeaders(false, signal)
  );
}

export function getMarketAnalysisIndex(payload) {
  const {
    groupId,
    farmId,
    page,
    sorter,
    filters,
    size,
    withoutPagination,
    signal,
  } = payload;

  const { field, order } = sorter;

  const orderS = order === "descend" ? "DESC" : "ASC";

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/marketAnalysis?
    ${page ? `&page=${page - 1}` : ""}
    ${size ? `&size=${size}` : ""}
    ${
      Object.entries(sorter).length > 0
        ? `&sort=${field}&direction=${orderS}`
        : ""
    }
    ${filters !== "" ? `&search=${filters}` : ""}
    ${withoutPagination ? `&withoutPagination=true` : ""}`,
    getHeaders(false, signal)
  );
}

export function getMarketAnalysisShow(payload) {
  const { groupId, farmId, id, signal } = payload;
  if (farmId != null) {
    return apiFinancial.get(
      `/bovexo/${groupId}/farms/${farmId}/marketAnalysis/${id}`,
      getHeaders(false, signal)
    );
  } else {
    return apiFinancial.get(
      `/bovexo/${groupId}/marketAnalysis/${id}`,
      getHeaders(false, signal)
    );
  }
}

export function getMarketAnalysisBaseline(payload) {
  const { groupId, farmId, signal } = payload;
  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/marketAnalysis/baseline`,
    getHeaders(false, signal)
  );
}

export function setMarketAnalysisBaseline(payload) {
  const { groupId, farmId, id, signal } = payload;
  return apiFinancial.patch(
    `/bovexo/${groupId}/farms/${farmId}/marketAnalysis/${id}/baseline`,
    null,
    getHeaders(false, signal)
  );
}

export function saveMarketAnalysis(payload) {
  const { groupId, farmId, id, body, signal } = payload;
  if (body?.marketAnalysis?.farmId != null) {
    if (id != null) {
      return apiFinancial.patch(
        `/bovexo/${groupId}/farms/${farmId}/marketAnalysis/${id}`,
        body,
        getHeaders(false, signal)
      );
    } else {
      return apiFinancial.post(
        `/bovexo/${groupId}/farms/${farmId}/marketAnalysis`,
        body,
        getHeaders(false, signal)
      );
    }
  } else {
    if (id != null) {
      return apiFinancial.patch(
        `/bovexo/${groupId}/marketAnalysis/${id}`,
        body,
        getHeaders(false, signal)
      );
    } else {
      return apiFinancial.post(
        `/bovexo/${groupId}/marketAnalysis`,
        body,
        getHeaders(false, signal)
      );
    }
  }
}

export function deleteMarketAnalysisServ(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiFinancial.delete(
    `/bovexo/${groupId}/farms/${farmId}/marketAnalysis/${id}`,
    getHeaders(false, signal)
  );
}

export function reactivateMarketAnalysisServ(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiFinancial.patch(
    `/bovexo/${groupId}/farms/${farmId}/marketAnalysis/${id}/reactivate`,
    {},
    getHeaders(false, signal)
  );
}
