import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Async } from "react-async";
import { useRouteMatch, withRouter } from "react-router-dom";

/** Redux libs */
import { useDispatch, useSelector } from "react-redux";
import { Creators as ClientActions } from "../../../store/ducks/client";
import { Creators as SaleScenarioActions } from "../../../store/ducks/saleScenario";

/** Components */
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Icon,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
} from "antd";
import Column from "antd/lib/table/Column";
import NumberFormat from "react-number-format";
import DrawerClient from "../../../components/drawers/client";
import ConfirmActionModal from "../../../components/modals/confirmActionModal";
import ButtonStandard from "../../../components/utils/button";
import PencilIcon from "../../../components/utils/icons/pencil";
import SalePercentageIcon from "../../../components/utils/icons/salePercentage";
import StopIcon from "../../../components/utils/icons/stop";
import TrashIcon from "../../../components/utils/icons/trash";
import InfoTooltip from "../../../components/utils/infoTooltip";
import { Container } from "./styles";

/** Services */
import {
  getLastDailyWeightReactAsync,
  verifyIfAnimalsHaveMovementEventAfterDate,
} from "../../../services/animalService";
import { findAllFinancialClassesByGroupIdAndFarmId } from "../../../services/financialClassService";
import {
  cnpjMask,
  cpfMask,
  getTwoDecimalDigits,
  numberMask,
} from "../../../services/helpersMethodsService";
import { getLotStatistics } from "../../../services/lotService";
import {
  showSaleScenarioResultFarmSell,
  showSaleScenarioResultFarmSellAnimals,
  storeSaleScenarioResultFarmSell,
  updateSaleScenarioResultFarmSell,
} from "../../../services/saleScenarioResultFarmSellService";
import { showSaleScenarioResultSellsAnimals } from "../../../services/saleScenarioService";

const NewFarmSell = ({ history }) => {
  const [form, setForm] = useState({
    saleScenarioResultId: null,
    clientName: null,
    sellDate: null,
    priceNegotiated: null,
    weightPerAnimal: null,
    carcassHarnessing: null,
    amountAnimalsSold: null,
    totalBilling: null,
    totalMarginBilling: null,
    estimatedBilling: null,
    priceNegotiatedUnit: "VALUE_PER_ARROBA",
    financialNature: null,
    financialClassId: null,
  });
  const [loadingInfoFarmSell, setLoadingInfoFarmSell] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = useState([]);
  const [formatDate] = useState("DD/MM/YYYY");
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoadingAnimals, setIsLoadingAnimals] = useState(false);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [animalsData, setAnimalsData] = useState(null);

  const [customCarcassHarnessing, setCustomCarcassHarnessing] = useState(false);
  const [customSellWeight, setCustomSellWeight] = useState(false);
  const [animalsCustomData, setAnimalsCustomData] = useState([]);

  const [animalsLastEvents, setAnimalsLastEvents] = useState([]);

  const [filteredListFinancialNature, setFilteredListFinancialNature] =
    useState([]);
  const [isLoadingFinancialNature, setIsLoadingFinancialNature] =
    useState(false);
  const [listFinancialClass, setListFinancialClass] = useState([]);
  const [isLoadingFinancialClass, setIsLoadingFinancialClass] = useState(false);

  /** Props variable */
  const {
    params: { saleScenarioId, saleScenarioResultId, id },
  } = useRouteMatch();

  /** Redux variables */
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const {
    saleScenarioResultAnimalsSelected,
    saleScenarioResultAnimalsSelectedIds,
  } = useSelector((state) => state.saleScenario);

  const { listDropdown: clientData } = useSelector((state) => state.client);

  const dispatch = useDispatch();

  /** Effect */
  // Get farm sell to edit
  useEffect(() => {
    async function fetch() {
      if (id != null) {
        setLoadingInfoFarmSell(true);
        try {
          let {
            data: { results },
          } = await showSaleScenarioResultFarmSell({
            groupId,
            farmId,
            saleScenarioId,
            saleScenarioResultId,
            id,
          });

          results.priceNegotiatedUnit =
            results?.priceNegotiatedUnit != null
              ? results.priceNegotiatedUnit
              : "VALUE_PER_ARROBA";

          setForm(results);
          setIsEditing(true);
          setLoadingInfoFarmSell(false);
        } catch (error) {
          console.error(error);
          setLoadingInfoFarmSell(false);
        }
      } else {
        setIsEditing(false);
      }
    }

    fetch();
  }, [groupId, farmId, saleScenarioId, saleScenarioResultId, id]);

  // Get animals and financial's classe and nature
  useEffect(() => {
    async function getFinancialClassAndNature() {
      setIsLoadingFinancialNature(true);
      setIsLoadingFinancialClass(true);
      try {
        const {
          data: { results: classes },
        } = await findAllFinancialClassesByGroupIdAndFarmId({
          groupId,
          farmId,
        });
        const natures = [
          ...new Map(
            classes
              .map((c) => c.financialNature)
              .map((item) => [item["id"], item])
          ).values(),
        ];
        setFilteredListFinancialNature(
          natures.filter((n) => n.type === "income")
        );
        setListFinancialClass(classes);
      } catch (error) {
      } finally {
        setIsLoadingFinancialNature(false);
        setIsLoadingFinancialClass(false);
      }
    }
    async function fetchAnimals(
      page = 0,
      sorter = {},
      filters = "",
      size = 10
    ) {
      const ids = saleScenarioResultAnimalsSelectedIds
        .map((s) => `'${s}'`)
        .join(",");
      const search =
        filters.trim() === ""
          ? `r.saleScenarioResultFarmSellId is null; ssr.id = '${saleScenarioResultId}'; r.animalId in (${ids})`
          : filters +
            `;r.saleScenarioResultFarmSellId is null; ssr.id = '${saleScenarioResultId}'; r.animalId in (${ids})`;
      setIsLoadingAnimals(true);

      if (id != null) {
        try {
          let {
            data: { results },
          } = await showSaleScenarioResultFarmSellAnimals({
            groupId,
            farmId,
            saleScenarioId,
            saleScenarioFarmSellId: id,
          });
          setIsLoadingAnimals(false);
          setAnimalsData(results);
          setAnimalsCustomData(
            results.map((a) => ({
              id: a.animalId,
              carcassHarnessing: a.carcassHarnessing,
              weightSell: a.weightSell,
            }))
          );
        } catch (error) {
          setIsLoadingAnimals(false);
          console.error(error);
        }
      } else {
        try {
          const {
            data: { results },
          } = await showSaleScenarioResultSellsAnimals({
            groupId,
            farmId,
            page,
            sorter,
            filters: search,
            size,
            saleScenarioId,
          });

          setIsLoadingAnimals(false);
          setAnimalsData(results);
          dispatch(ClientActions.getDropdownClients(groupId, farmId, true));
          setAnimalsCustomData(
            saleScenarioResultAnimalsSelectedIds.map((id) => ({
              id: id,
              carcassHarnessing: null,
              weightSell: null,
            }))
          );
        } catch (error) {
          setIsLoadingAnimals(false);
          console.error(error);
        }
      }
    }
    Promise.all([fetchAnimals(), getFinancialClassAndNature()]);
  }, [
    groupId,
    farmId,
    saleScenarioId,
    saleScenarioResultId,
    saleScenarioResultAnimalsSelectedIds,
    id,
    dispatch,
  ]);

  /** Callback */
  // Build content modal animals last event table
  const animalLastEventModalContent = useCallback(
    (animalsLastEvents) => (
      <Row className="row-form">
        <Table
          rowKey="animalId"
          dataSource={Array.isArray(animalsLastEvents) ? animalsLastEvents : []}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 10,
            pageSizeOptions: ["10", "20", "30", "100"],
          }}
          scroll={{ x: true }}
          size="small"
        >
          <Column
            title="Identificação"
            dataIndex="handlingNumber"
            key="handlingNumber"
            align="left"
          />
          <Column
            title="Evento"
            dataIndex="type"
            key="type"
            align="left"
            render={(type) => (
              <span>{translation.animalMovementEvents[type]}</span>
            )}
          />
          <Column
            title="Data"
            dataIndex="date"
            key="date"
            align="left"
            render={(value) => <span>{moment(value).format(formatDate)}</span>}
          />
        </Table>
      </Row>
    ),
    [translation, formatDate]
  );

  /** Methods */
  function showOrHideModal() {
    if (validateForm()) {
      if (isEditing) {
        saveFarmSell();
      } else {
        setModalVisible(!modalVisible);
      }
    }
  }
  async function saveFarmSell() {
    const animalsClone = [...animalsCustomData];
    if (!customCarcassHarnessing) {
      animalsClone.map((a) => (a.carcassHarnessing = form.carcassHarnessing));
    }

    if (!customSellWeight) {
      animalsClone.map((a) => (a.weightSell = form.weightPerAnimal));
    }

    setAnimalsCustomData([...animalsClone]);

    setForm({
      ...form,
      saleScenarioId,
      saleScenarioResultId,
      estimatedBilling,
      amountAnimalsSold: amountAnimals,
      totalBilling,
      totalMarginBilling,
    });
    const body = {
      ...form,
      saleScenarioId,
      saleScenarioResultId,
      estimatedBilling,
      amountAnimalsSold: amountAnimals,
      totalBilling,
      totalMarginBilling,
      animals: animalsClone,
      // isEditing && id != null
      //   ? animalsData.map((a) => `${a.animalId}`)
      //   : saleScenarioResultAnimalsSelectedIds,
    };
    setIsLoadingRequest(true);
    setLoadingInfoFarmSell(true);
    let isLastAnimalsForLot = [];
    let shouldRedirect = false;
    try {
      if (isEditing && id != null) {
        body.sellDate = moment(body.sellDate);
        await updateSaleScenarioResultFarmSell({
          groupId,
          farmId,
          saleScenarioId,
          saleScenarioResultId,
          id,
          body,
        });
        notification.success({
          message: "Venda atualizada com sucesso.",
        });
      } else {
        const ids = saleScenarioResultAnimalsSelected.map((a) => a.lotId);
        const lotsIds = [...new Set(ids)];
        await Promise.all(
          lotsIds.map(async (idLot) => {
            const {
              data: { results: amountAnimals },
            } = await getLotStatistics({
              groupId: groupId,
              farmId: farmId,
              id: idLot,
              statistics: "AmountActiveAnimals",
            });
            const amountAnimalsForLot =
              saleScenarioResultAnimalsSelected.filter(
                (a) => a.lotId === idLot
              ).length;
            const lotName = saleScenarioResultAnimalsSelected.find(
              (a) => a.lotId === idLot
            ).lotName;
            if (amountAnimals === amountAnimalsForLot) {
              isLastAnimalsForLot.push(lotName);
            }
            return idLot;
          })
        );
        if (isLastAnimalsForLot.length > 0) {
          Modal.warning({
            centered: true,
            content: (
              <div>
                <p style={{ margin: 0 }}>
                  <strong>Atenção! </strong>
                  {`Ao confirmar a venda destes animais, estará finalizando o(s) Lote(s): `}
                  <br />
                  {isLastAnimalsForLot.join(", ")}
                </p>
                <p
                  style={{ fontSize: 10, textAlign: "justify", marginTop: 10 }}
                >
                  Obs.: Em caso de excluir essa venda posteriormente, este(s)
                  Lote(s) ficarão pendentes, e precisarão ser alocados novamente
                  em Piquetes.
                </p>
              </div>
            ),
          });
        }
        await storeSaleScenarioResultFarmSell({
          groupId,
          farmId,
          saleScenarioId,
          saleScenarioResultId,
          body,
        });
        notification.success({
          message: "Venda cadastrada com sucesso.",
        });
      }
      shouldRedirect = true;
    } catch (error) {
      notification.error({
        message: "Houve um erro ao cadastrar venda.",
      });
    } finally {
      setIsLoadingRequest(false);
      setLoadingInfoFarmSell(false);
      if (shouldRedirect) {
        history.push(
          `/admin/decisions/scenarios/sales/${saleScenarioId}/results/${saleScenarioResultId}`
        );
      }
    }
  }
  function removeError(field) {
    return errors.filter((e) => e !== field);
  }
  function validateForm() {
    let errorArray = [];

    if (customCarcassHarnessing) {
      animalsCustomData.forEach((a) => {
        let animal = saleScenarioResultAnimalsSelected.find(
          (sras) => sras.animalId === a.id
        );

        if (
          a.carcassHarnessing === null ||
          a.carcassHarnessing < 45 ||
          a.carcassHarnessing > 60
        ) {
          errorArray.push("carcassHarnessing");
          notification.error({
            message: `${animal.identification} - O valor do campo "Ap. Carcaça" deve estar entre o intervalo de valores (45% e 60%)`,
          });
        }
      });
    }
    if (customSellWeight) {
      animalsCustomData.forEach((a) => {
        let animal = saleScenarioResultAnimalsSelected.find(
          (sras) => sras.animalId === a.id
        );

        if (a.weightSell === null || a.weightSell === 0) {
          errorArray.push("weightPerAnimal");
          notification.error({
            message: `${animal.identification} - Peso de venda não informado`,
          });
        }
      });
    }
    if (form?.clientName == null) errorArray.push("clientName");
    if (form?.carcassHarnessing === null) errorArray.push("carcassHarnessing");
    if (
      customCarcassHarnessing === false &&
      form?.carcassHarnessing !== null &&
      (form?.carcassHarnessing < 45 || form?.carcassHarnessing > 60)
    ) {
      errorArray.push("carcassHarnessing");
      notification.error({
        message:
          'O valor do campo "Ap. Carcaça" deve estar entre o intervalo de valores (45% e 60%)',
      });
    }
    if (form?.sellDate == null) errorArray.push("sellDate");
    if (form?.priceNegotiated == null) errorArray.push("priceNegotiated");
    if (form?.financialNatureId == null) errorArray.push("financialNatureId");
    if (form?.financialClassId == null) errorArray.push("financialClassId");
    if (customSellWeight === false && form?.weightPerAnimal == null)
      errorArray.push("weightPerAnimal");
    if (Array.isArray(animalsLastEvents) && animalsLastEvents.length > 0) {
      errorArray.push("sellDate");
      notification.error({
        message: "Erro",
        description:
          "Não é permitido venda de animais com eventos posteriores à data de saída!",
      });
    }
    setErrors(errorArray);

    return errorArray.length > 0 ? false : true;
  }
  function removeAnimalFromList(id, animalId) {
    dispatch(SaleScenarioActions.removeSaleScenarioResultAnimals(id, animalId));
  }
  const calculateAvgCarcassHarnessing = useCallback(() => {
    if (animalsCustomData && animalsCustomData.length > 0) {
      const sumCarcassHarnessing = animalsCustomData.reduce(
        (sumValue, currentObj) => sumValue + currentObj.carcassHarnessing,
        0
      );
      return +(sumCarcassHarnessing / animalsCustomData.length).toFixed(2);
    }

    return 0;
  }, [animalsCustomData]);

  const calculateAvgWeightSell = useCallback(() => {
    if (animalsCustomData && animalsCustomData.length > 0) {
      const sumWeightSell = animalsCustomData.reduce(
        (sumValue, currentObj) => sumValue + currentObj.weightSell,
        0
      );
      return +(sumWeightSell / animalsCustomData.length).toFixed(2);
    }
  }, [animalsCustomData]);

  function clearCustomWeight() {
    const animalsClone = [...animalsCustomData];
    animalsClone.forEach((a) => (a.weightSell = null));
    setAnimalsCustomData([...animalsClone]);
  }

  function clearCustomCarcassHarnessing() {
    const animalsClone = [...animalsCustomData];
    animalsClone.forEach((a) => (a.carcassHarnessing = null));
    setAnimalsCustomData([...animalsClone]);
  }
  /** Table methds */
  async function fetchAnimals(page = 0, sorter = {}, filters = "", size = 10) {
    const ids = saleScenarioResultAnimalsSelectedIds
      .map((s) => `'${s}'`)
      .join(",");
    const search =
      filters.trim() === ""
        ? `r.saleScenarioResultFarmSellId is null; ssr.id = '${saleScenarioResultId}'; r.animalId in (${ids})`
        : filters +
          `;r.saleScenarioResultFarmSellId is null; ssr.id = '${saleScenarioResultId}'; r.animalId in (${ids})`;
    setIsLoadingAnimals(true);
    try {
      const {
        data: { results },
      } = await showSaleScenarioResultSellsAnimals({
        groupId,
        farmId,
        page,
        sorter,
        filters: search,
        size,
        saleScenarioId,
      });
      setIsLoadingAnimals(false);
      setAnimalsData(results);
    } catch (error) {
      setIsLoadingAnimals(false);
      console.error(error);
    }
  }
  function getColumnSearchAnimalsProps(dataIndex) {
    return isEditing
      ? {
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{ padding: 8 }}>
              <Input
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() =>
                  handleSearchAnimals(selectedKeys, confirm, dataIndex)
                }
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
              <Button
                type="primary"
                onClick={() =>
                  handleSearchAnimals(selectedKeys, confirm, dataIndex)
                }
                icon="search"
                size="small"
                style={{
                  width: 90,
                  marginRight: 8,
                  background: "#684e94",
                  borderColor: "none",
                  border: "none",
                }}
              />
              <Button
                onClick={() => handleAnimalsReset(clearFilters)}
                icon="delete"
                size="small"
                style={{ width: 90 }}
              />
            </div>
          ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? "#684e94" : undefined }}
            />
          ),
          onFilter: (value, record) => {
            return record[dataIndex].toString().includes(value);
          },
        }
      : {
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{ padding: 8 }}>
              {dataIndex === "sellDate" ? (
                <DatePicker
                  value={selectedKeys[0]}
                  format={formatDate}
                  onChange={(date, dateString) =>
                    setSelectedKeys(date ? [date] : [])
                  }
                  onPressEnter={() =>
                    handleSearchAnimals(selectedKeys, confirm)
                  }
                  style={{ width: 188, marginBottom: 8, display: "block" }}
                />
              ) : (
                <Input
                  value={selectedKeys[0]}
                  onChange={(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  onPressEnter={() =>
                    handleSearchAnimals(selectedKeys, confirm)
                  }
                  style={{ width: 188, marginBottom: 8, display: "block" }}
                />
              )}
              <Button
                type="primary"
                onClick={() => handleSearchAnimals(selectedKeys, confirm)}
                icon="search"
                size="small"
                style={{
                  width: 90,
                  marginRight: 8,
                  background: "#684e94",
                  borderColor: "none",
                  border: "none",
                }}
              />
              <Button
                onClick={() => handleAnimalsReset(clearFilters)}
                icon="delete"
                size="small"
                style={{ width: 90 }}
              />
            </div>
          ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? "#684e94" : undefined }}
            />
          ),
        };
  }
  function handleTableAnimalsChange(pagination, filters, sorter) {
    let search = "";

    if (Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([k, v]) => {
        if (v.length > 0)
          if (k === "sellDate") {
            search =
              search === ""
                ? `r.${k} = '${moment(v[0]).format("YYYY-MM-DD").toString()}'`
                : `${search};r.${k} = '${moment(v[0])
                    .format("YYYY-MM-DD")
                    .toString()}'`;
          } else if (k === "identification") {
            search =
              search === ""
                ? `upper(r.${k}) like upper('%25${v}%25')`
                : `${search};upper(r.${k}) like upper('%25${v}%25')`;
          } else {
            search =
              search === "" ? `r.${k} = ${v}` : `${search};r.${k} = ${v}`;
          }
      });
    }
    fetchAnimals(pagination.current, sorter, search, pagination.pageSize);
  }
  function handleSearchAnimals(selectedKeys, confirm) {
    confirm();
  }
  function handleAnimalsReset(clearFilters) {
    clearFilters();
  }
  function selectPriceNegotiatedUnits(props) {
    return (
      <Select
        //disabled={blockEdit}
        value={
          form?.priceNegotiatedUnit != null
            ? form.priceNegotiatedUnit
            : "VALUE_PER_ARROBA"
        }
        style={{ width: 95 }}
        onChange={(value) => setForm({ ...form, priceNegotiatedUnit: value })}
      >
        <Select.Option value="VALUE_PER_ARROBA">R$/@</Select.Option>
        <Select.Option value="VALUE_PER_KG">R$/Kg</Select.Option>
        <Select.Option value="VALUE_PER_ANIMAL">R$/cab</Select.Option>
      </Select>
    );
  }
  function handleCarcassHarnessingChange(value, animalId) {
    const animalsClone = [...animalsCustomData];

    let index = animalsClone.findIndex((a) => a.id === animalId);
    if (index !== null) {
      animalsClone[index].carcassHarnessing = value;
      setAnimalsCustomData([...animalsClone]);
    }
  }
  function handleWeightSellChange(value, animalId) {
    const animalsClone = [...animalsCustomData];

    let index = animalsClone.findIndex((a) => a.id === animalId);
    if (index !== null) {
      animalsClone[index].weightSell = value;
      setAnimalsCustomData([...animalsClone]);
    }
  }
  async function handleSellDateChange(sellDate) {
    if (!isEditing) {
      try {
        const {
          data: { results: events },
        } = await verifyIfAnimalsHaveMovementEventAfterDate({
          groupId,
          farmId,
          animalsIds: saleScenarioResultAnimalsSelectedIds,
          date: sellDate,
          signal: null,
        });
        setAnimalsLastEvents(events);

        if (Array.isArray(events) && events.length > 0) {
          Modal.error({
            icon: null,
            centered: true,
            title: "Animais com eventos posteriores à data de venda informada!",
            content: animalLastEventModalContent(events),
            okText: "Ok",
          });
        }

        // const {
        //   data: { results },
        // } = await getWeightHistoryByAnimalsIdsAndDate({
        //   groupId: groupId,
        //   farmId: farmId,
        //   animalsIds: saleScenarioResultAnimalsSelectedIds,
        //   weightDate: sellDate,
        // });

        // if (results) {
        //   const animalsClone = [...animalsCustomData];

        //   results.forEach((animalWeight) => {
        //     animalsClone.map(
        //       (ac) =>
        //         (ac.weightSell =
        //           ac.id === animalWeight.animalId && ac.weightSell === 0
        //             ? animalWeight.weight
        //             : 0)
        //     );
        //   });

        //   setAnimalsCustomData([...animalsClone]);
        // }
      } catch (error) {
        console.error(error);
      }
    }
  }
  function handleCreateClient() {
    dispatch(ClientActions.showDrawerClientVisible("new", null, true));
  }
  /** Memo */
  // Get sum of all animals price
  const estimatedBilling = useMemo(() => {
    if (isEditing && id != null) {
      return animalsData?.length === 0
        ? 0
        : getTwoDecimalDigits(
            animalsData?.reduce((a, b) => {
              return a + b.animalPrice;
            }, 0)
          );
    } else {
      return getTwoDecimalDigits(
        saleScenarioResultAnimalsSelected?.reduce((a, b) => {
          return a + b.animalPrice;
        }, 0)
      );
    }
  }, [animalsData, id, isEditing, saleScenarioResultAnimalsSelected]);

  // Calculate the animals cost, get the price - margin
  const totalAnimalCost = useMemo(() => {
    if (isEditing && id != null) {
      return animalsData?.length === 0
        ? 0
        : getTwoDecimalDigits(
            animalsData?.reduce((a, b) => {
              return a + (b.animalPrice - b.margin);
            }, 0)
          );
    } else {
      return getTwoDecimalDigits(
        saleScenarioResultAnimalsSelected?.reduce((a, b) => {
          return a + (b.animalPrice - b.margin);
        }, 0)
      );
    }
  }, [animalsData, id, isEditing, saleScenarioResultAnimalsSelected]);

  // Get amount animals to be sold
  const amountAnimals = useMemo(() => {
    return id != null
      ? form.amountAnimalsSold || 0
      : saleScenarioResultAnimalsSelected.length;
  }, [form.amountAnimalsSold, id, saleScenarioResultAnimalsSelected.length]);

  // Calculate the actual billing of the selling = Faturamento real
  const totalBilling = useMemo(() => {
    const {
      priceNegotiated,
      weightPerAnimal,
      carcassHarnessing,
      priceNegotiatedUnit,
    } = form;
    if (priceNegotiatedUnit === "VALUE_PER_KG") {
      return getTwoDecimalDigits(
        (priceNegotiated || 0) * (weightPerAnimal || 0) * amountAnimals
      );
    } else if (priceNegotiatedUnit === "VALUE_PER_ANIMAL") {
      return getTwoDecimalDigits((priceNegotiated || 0) * amountAnimals);
    } else {
      return getTwoDecimalDigits(
        (priceNegotiated || 0) *
          ((weightPerAnimal || 0) / 15) *
          ((carcassHarnessing || 0) / 100) *
          amountAnimals
      );
    }
  }, [form, amountAnimals]);

  // Calculate the actual profit of the selling = Lucro real
  const totalMarginBilling = useMemo(() => {
    return Number(totalBilling - totalAnimalCost);
  }, [totalBilling, totalAnimalCost]);

  const avgCarcassHarnessing = useMemo(() => {
    return calculateAvgCarcassHarnessing();
  }, [calculateAvgCarcassHarnessing]);

  const avgSellWeight = useMemo(() => {
    return calculateAvgWeightSell();
  }, [calculateAvgWeightSell]);

  return (
    <Container>
      <Row type="flex" align="middle" className="header">
        <Col
          xs={{ span: 1, offset: 0 }}
          sm={{ span: 1, offset: 0 }}
          md={{ span: 1, offset: 0 }}
          lg={{ span: 1, offset: 0 }}
          xl={{ span: 1, offset: 0 }}
        >
          <Icon
            type="arrow-left"
            className="iconBack"
            onClick={() => history.goBack()}
          />
        </Col>
        <Col
          xs={{ span: 23, offset: 0 }}
          sm={{ span: 23, offset: 0 }}
          md={{ span: 23, offset: 0 }}
          lg={{ span: 23, offset: 0 }}
          xl={{ span: 23, offset: 0 }}
        >
          <span className="message">
            Defina cliente, data de saída, faturamento final e os animais que
            pertencem a essa venda
          </span>
        </Col>
      </Row>
      {/* Form */}
      <Spin spinning={loadingInfoFarmSell}>
        <form autoComplete="off">
          <Row type="flex">
            <Col span={16}>
              <div className="inputContainer">
                <div className="inputGroup clientName">
                  <label
                    className={errors.includes("clientName") ? "error" : ""}
                  >
                    Cliente*
                  </label>
                  {isEditing ? (
                    <span>
                      <strong>{form.clientName}</strong>
                    </span>
                  ) : (
                    <div className="inputWithButton">
                      <Select
                        style={{ width: "100%" }}
                        value={form.clientName ?? undefined}
                        placeholder={translation.defaultSelectPlaceholder}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onSelect={(value) => {
                          const clientSelected = clientData.find(
                            (c) => c.name === value
                          );
                          setForm({ ...form, clientName: clientSelected.name });
                          setErrors(removeError("clientName"));
                        }}
                      >
                        {clientData &&
                          clientData
                            .filter(
                              (client) =>
                                client.status === "A" ||
                                client.status === "Active"
                            )
                            .map((client) => (
                              <Select.Option
                                key={client.id}
                                value={client.name}
                              >
                                {`${client.name} - ${
                                  client.documentType === "CPF"
                                    ? `CPF : ${cpfMask(client.document)}`
                                    : `CNPJ : ${cnpjMask(client.document)}`
                                }`}
                              </Select.Option>
                            ))}
                      </Select>
                      <Tooltip title={`Novo Cliente`}>
                        <Button
                          shape="circle"
                          icon="plus"
                          onClick={handleCreateClient}
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
                <div className="inputGroup">
                  <label className={errors.includes("sellDate") ? "error" : ""}>
                    Data de saída*
                  </label>
                  <DatePicker
                    name="sellDate"
                    value={
                      form?.sellDate != null
                        ? moment(form?.sellDate, "YYYY-MM-DD")
                        : null
                    }
                    format={formatDate}
                    placeholder={translation.defaultPlaceholder}
                    disabled={isEditing}
                    disabledDate={(date) =>
                      moment(date).isAfter(moment()) ? true : false
                    }
                    onChange={(date, dateString) => {
                      if (date <= moment() && date !== null) {
                        setForm({ ...form, sellDate: date });
                        setErrors(removeError("sellDate"));
                        handleSellDateChange(date.format("yyyy-MM-DD"));
                      }
                    }}
                  />
                </div>
              </div>
              <div className="inputContainer">
                <div className="inputGroup">
                  <label
                    className={
                      errors.includes("priceNegotiated") ? "error" : ""
                    }
                  >
                    Valor negociado*
                  </label>
                  <NumberFormat
                    name="priceNegotiated"
                    value={
                      form?.priceNegotiated !== null
                        ? form?.priceNegotiated
                        : ""
                    }
                    customInput={Input}
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale={true}
                    addonBefore={selectPriceNegotiatedUnits(form)}
                    onValueChange={({ floatValue }) => {
                      setForm({ ...form, priceNegotiated: floatValue });
                      setErrors(removeError("priceNegotiated"));
                    }}
                    placeholder={translation.defaultPlaceholder}
                  />
                </div>
                <div className="inputGroup">
                  <label
                    className={
                      errors.includes("weightPerAnimal") ? "error" : ""
                    }
                  >
                    Peso médio/animal*
                  </label>

                  <div className="inputGroupWithIcon">
                    <NumberFormat
                      disabled={customSellWeight}
                      name="weightPerAnimal"
                      customInput={Input}
                      value={
                        customSellWeight
                          ? avgSellWeight
                          : form?.weightPerAnimal !== null
                          ? form?.weightPerAnimal
                          : ""
                      }
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale={true}
                      addonAfter="Kg"
                      placeholder={translation.defaultPlaceholder}
                      onValueChange={({ floatValue }) => {
                        setForm({ ...form, weightPerAnimal: floatValue });
                        setErrors(removeError("weightPerAnimal"));
                      }}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setForm({ ...form, weightPerAnimal: "" });
                        if (customSellWeight) {
                          clearCustomWeight();
                        }
                        setCustomSellWeight(!customSellWeight);
                      }}
                    >
                      {customSellWeight ? (
                        <StopIcon isRed={true} />
                      ) : (
                        <PencilIcon />
                      )}
                    </button>
                  </div>
                </div>
                <div className="inputGroup">
                  <label
                    className={
                      errors.includes("carcassHarnessing") ? "error" : ""
                    }
                  >
                    Ap. carcaça*
                  </label>
                  <div className="inputGroupWithIcon">
                    <NumberFormat
                      disabled={customCarcassHarnessing}
                      name="carcassHarnessing"
                      customInput={Input}
                      value={
                        customCarcassHarnessing
                          ? avgCarcassHarnessing
                          : form?.carcassHarnessing !== null
                          ? form?.carcassHarnessing
                          : ""
                      }
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale={true}
                      addonAfter="%"
                      placeholder={translation.defaultPlaceholder}
                      onValueChange={({ floatValue }) => {
                        setForm({ ...form, carcassHarnessing: floatValue });
                        setErrors(removeError("carcassHarnessing"));
                      }}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setForm({ ...form, carcassHarnessing: null });
                        if (customCarcassHarnessing) {
                          clearCustomCarcassHarnessing();
                        }
                        setCustomCarcassHarnessing(!customCarcassHarnessing);
                      }}
                    >
                      {customCarcassHarnessing ? (
                        <StopIcon isRed={true} />
                      ) : (
                        <PencilIcon />
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <Row type="flex">
                {/* Financial Nature */}
                <Col className="inputGroup" span={8}>
                  <Row>
                    <label
                      className={
                        errors.includes("financialNatureId") ? "error" : ""
                      }
                    >
                      {translation.financial.animalSell.form.financialNatureId}*
                    </label>
                  </Row>
                  <Row>
                    <Select
                      name="financialNatureId"
                      value={
                        isLoadingFinancialNature
                          ? undefined
                          : form.financialNatureId || undefined
                      }
                      placeholder={translation.defaultSelectPlaceholder}
                      loading={isLoadingFinancialNature}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        setForm((prevState) => ({
                          ...prevState,
                          financialNatureId: value,
                        }));
                        setForm((prevState) => ({
                          ...prevState,
                          financialClassId: null,
                        }));
                      }}
                    >
                      {filteredListFinancialNature.map((l) => (
                        <Select.Option key={l.id} value={l.id}>
                          {l.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
                {/* Financial Class */}
                <Col className="inputGroup" span={8}>
                  <Row>
                    <label
                      className={
                        errors.includes("financialClassId") ? "error" : ""
                      }
                    >
                      {translation.financial.transactions.form.financialClassId}
                      *
                    </label>
                  </Row>
                  <Row>
                    <Select
                      name="financialClassId"
                      value={
                        isLoadingFinancialClass
                          ? undefined
                          : form.financialClassId || undefined
                      }
                      placeholder={translation.defaultSelectPlaceholder}
                      loading={isLoadingFinancialClass}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        setForm((prevState) => ({
                          ...prevState,
                          financialClassId: value,
                        }));
                      }}
                    >
                      {form.financialNatureId != null &&
                        listFinancialClass
                          .filter(
                            (l) =>
                              l.financialNature.id === form.financialNatureId
                          )
                          .map((l) => (
                            <Select.Option key={l.id} value={l.id}>
                              {l.name}
                            </Select.Option>
                          ))}
                    </Select>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={8} className="colWithBorderLeft">
              <div className="infoContainer">
                <div className="infoGroup">
                  <span>Animais</span>
                  <span>
                    <strong>{amountAnimals || 0}</strong>
                  </span>
                </div>
                <div className="infoGroup">
                  <span>
                    Fat. total
                    <InfoTooltip
                      placement="top"
                      trigger="click"
                      title={
                        <>
                          <p>
                            Este valor, será calculado, de acordo com o tipo de
                            Valor Negociado, sendo:
                          </p>
                          <p>
                            <strong>Valor/@ Negociado:</strong> Corresponde ao
                            peso médio dos animais, transformado em @, ou seja,
                            dividido por 15. A quantidade de @ é multiplicada
                            pelo valor da @ negociado com o comprador,
                            multiplicado pelo aproveitamento de carcaça. Desta
                            forma, se tem o valor médio por animal e
                            multiplicado pela quantidade de animais, chegando
                            assim, no valor de faturamento total.
                          </p>
                          <p>
                            <strong>Valor/Kg Negociado:</strong> Corresponde ao
                            peso médio dos animais, multiplicando pela
                            quantidade de animais e posteriormente multiplicado
                            pelo valor do Kg informado, chegando assim, no valor
                            de faturamento total.
                          </p>
                        </>
                      }
                    />
                  </span>
                  <span>
                    <strong className="colored">{`${numberMask(
                      totalBilling || Number(0),
                      true
                    )}`}</strong>
                  </span>
                </div>
                <div className="infoGroup">
                  <span>Fat. estimado</span>
                  <span>
                    <strong>{`${numberMask(
                      estimatedBilling || Number(0),
                      true
                    )}`}</strong>
                  </span>
                </div>
              </div>
              <div className="infoContainer">
                <div className="infoGroup">
                  <ButtonStandard
                    type="button"
                    width="141px"
                    padding="5px 10px"
                    background="#684E94"
                    disabled={amountAnimals === 0}
                    onClick={showOrHideModal}
                  >
                    {isEditing ? "Atualizar venda" : "Salvar venda"}
                  </ButtonStandard>
                </div>
              </div>
            </Col>
          </Row>
        </form>
        <Divider dashed />
        {/* Animals Table */}
        <Row type="flex">
          <Col span={24}>
            <Table
              rowKey="id"
              loading={isLoadingAnimals}
              dataSource={
                id != null && isEditing
                  ? animalsData != null
                    ? animalsData
                    : []
                  : animalsData != null
                  ? animalsData.content
                  : []
              }
              pagination={
                id != null && isEditing
                  ? {
                      showSizeChanger: true,
                      defaultPageSize: 10,
                      pageSizeOptions: [
                        "10",
                        "20",
                        "30",
                        "100",
                        "500",
                        "1000",
                        "2000",
                      ],
                    }
                  : {
                      total:
                        animalsData != null ? animalsData.totalElements : 0,
                      size: animalsData != null ? animalsData.size : 0,
                      current: animalsData != null ? animalsData.number + 1 : 0,
                      showSizeChanger: true,
                      pageSizeOptions: [
                        "10",
                        "20",
                        "30",
                        "100",
                        "500",
                        "1000",
                        "2000",
                      ],
                    }
              }
              scroll={{
                x: true,
              }}
              onChange={isEditing ? null : handleTableAnimalsChange}
            >
              <Column
                title="Identificação / Lote"
                dataIndex="identification"
                key="identification"
                align="left"
                sorter
                {...getColumnSearchAnimalsProps("identification")}
                render={(text, record) => <span>{text}</span>}
              />

              <Column
                title="Peso estimado"
                dataIndex="weight"
                key="weight"
                align="left"
                sorter
                {...getColumnSearchAnimalsProps("weight")}
                render={(text, record) => (
                  <span>{`${numberMask(record.weight || 0)} Kg`}</span>
                )}
              />

              <Column
                title="Peso venda"
                dataIndex="weightSell"
                key="weightSell"
                align="left"
                sorter
                {...getColumnSearchAnimalsProps("weightSell")}
                render={(text, record) =>
                  customSellWeight ? (
                    <NumberFormat
                      name="weightSell"
                      customInput={Input}
                      value={
                        animalsCustomData.find((a) => a.id === record.animalId)
                          ?.weightSell
                      }
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale={true}
                      addonAfter="Kg"
                      placeholder={translation.defaultPlaceholder}
                      onValueChange={({ floatValue }) =>
                        handleWeightSellChange(floatValue, record.animalId)
                      }
                    />
                  ) : (
                    <span>{`${numberMask(record.weightSell || 0)} Kg`}</span>
                  )
                }
              />

              <Column
                title="Último peso"
                dataIndex="lastWeight"
                key="lastWeight"
                align="left"
                render={(text, record) => (
                  <Async
                    promiseFn={getLastDailyWeightReactAsync}
                    payload={{
                      groupId,
                      farmId,
                      animalId: record.animalId,
                      signal: null,
                    }}
                  >
                    <Async.Pending>
                      <Icon type="loading" />
                    </Async.Pending>
                    <Async.Fulfilled>
                      {(resp) => (
                        <span>
                          {resp.data?.results != null
                            ? `${numberMask(
                                resp.data?.results?.weight || 0
                              )} Kg`
                            : `0 Kg`}
                        </span>
                      )}
                    </Async.Fulfilled>
                  </Async>
                )}
              />
              <Column
                title="% Aprov. carcaça"
                dataIndex="carcassHarnessing"
                key="carcassHarnessing"
                align="left"
                sorter
                {...getColumnSearchAnimalsProps("carcassHarnessing")}
                render={(text, record) =>
                  customCarcassHarnessing ? (
                    <NumberFormat
                      name="carcassHarnessing"
                      customInput={Input}
                      value={
                        animalsCustomData.find((a) => a.id === record.animalId)
                          ?.carcassHarnessing
                      }
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale={true}
                      addonAfter="%"
                      placeholder={translation.defaultPlaceholder}
                      onValueChange={({ floatValue }) =>
                        handleCarcassHarnessingChange(
                          floatValue,
                          record.animalId
                        )
                      }
                    />
                  ) : (
                    <span>{`${numberMask(
                      record.carcassHarnessing || 0
                    )}`}</span>
                  )
                }
              />

              <Column
                title="Fat. estimado"
                dataIndex="animalPrice"
                key="animalPrice"
                align="left"
                sorter
                {...getColumnSearchAnimalsProps("animalPrice")}
                render={(text, record) => (
                  <span>{`${numberMask(record.animalPrice || 0, true)}`}</span>
                )}
              />
              <Column
                title="Lucro estimado"
                dataIndex="margin"
                sorter
                key="margin"
                align="left"
                {...getColumnSearchAnimalsProps("margin")}
                render={(text, record) => (
                  <span>{`${numberMask(record.margin || 0, true)}`}</span>
                )}
              />
              <Column
                title="Data sugerida"
                dataIndex="sellDate"
                key="sellDate"
                align="left"
                render={(text, record) => (
                  <span>{moment(record.sellDate).format("DD/MM/YYYY")}</span>
                )}
              />
              {!isEditing && (
                <Column
                  align="left"
                  render={(text, record) => (
                    <button
                      className="buttonTrash"
                      onClick={() =>
                        removeAnimalFromList(record.id, record.animalId)
                      }
                    >
                      <TrashIcon />
                    </button>
                  )}
                />
              )}
            </Table>
          </Col>
        </Row>
      </Spin>
      <DrawerClient />
      <ConfirmActionModal
        show={modalVisible}
        loading={isLoadingRequest}
        cancelAction={showOrHideModal}
        confirmAction={saveFarmSell}
        title="Registrar venda"
        message="Ao confirmar, poderão haver alguns cenários de venda com os mesmos animais a serem vendidos. Estes cenários serão atualizados (reprocessados), desconsiderando os animais dessa venda."
        icon={<SalePercentageIcon />}
      />
    </Container>
  );
};

export default withRouter(NewFarmSell);
