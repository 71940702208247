import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as AppActions } from "../../../store/ducks/app";

import { NavLink, withRouter } from "react-router-dom";

import { Modal } from "antd";
import {
  BlockContainer,
  Container,
  Menu,
  MenuFooter,
  MenuFooterItem,
  MenuItem,
} from "./styles";

/* Icons */
import useModuleContext from "../../../hooks/useModuleContext";
import AnimalIcon from "./icons/animal";
import FinancialIcon from "./icons/financial";
import ImportsIcon from "./icons/imports";
import LotIcon from "./icons/lot";
import ParametersIcon from "./icons/parameters";
import PicketIcon from "./icons/picket";
import SettingsIcon from "./icons/settings";

const SideBar = () => {
  /** Redux */
  const {
    translation,
    groupSelected,
    farmSelected,
    collapse_menu_admin: collapse,
  } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const { isAllowedFunctions } = useModuleContext();

  /** Methods */
  const handleOpenModalNotification = () => {
    let content = "";
    if (groupSelected == null || Object.entries(groupSelected).length === 0) {
      content = "É necessário selecionar um grupo e uma fazenda !!";
    } else if (
      farmSelected == null ||
      Object.entries(farmSelected).length === 0
    ) {
      content = "É necessário selecionar uma fazenda !!";
    }
    Modal.warning({
      title: "Atenção",
      content,
      centered: true,
    });
  };

  const handleRemoveActiveTopMenu = useCallback(() => {
    dispatch(AppActions.inactiveTopMenuNavigation(4));
  }, [dispatch]);

  return (
    <Container collapse={collapse}>
      {groupSelected == null ||
        farmSelected == null ||
        ((Object.entries(groupSelected).length === 0 ||
          Object.entries(farmSelected).length === 0) && (
          <BlockContainer
            collapse={collapse}
            onClick={handleOpenModalNotification}
          />
        ))}
      <Menu collapse={collapse}>
        <MenuItem
          id="menuPicket"
          className={
            collapse
              ? !isAllowedFunctions(["MENU_PICKET"])
                ? "minimize disabled"
                : "minimize"
              : !isAllowedFunctions(["MENU_PICKET"])
              ? "disabled"
              : ""
          }
          onClick={handleRemoveActiveTopMenu}
        >
          <NavLink to="/admin/pickets">
            <div>
              <PicketIcon />
            </div>
            <span>{translation.sidebar.picket}</span>
          </NavLink>
          <div className="customTooltip">
            <span>{translation.sidebar.picket}</span>
          </div>
        </MenuItem>
        <MenuItem
          id="menuLot"
          className={
            collapse
              ? !isAllowedFunctions(["MENU_LOT"])
                ? "minimize disabled"
                : "minimize"
              : !isAllowedFunctions(["MENU_LOT"])
              ? "disabled"
              : ""
          }
          onClick={handleRemoveActiveTopMenu}
        >
          <NavLink to="/admin/lots">
            <div>
              <LotIcon />
            </div>
            <span>{translation.sidebar.lot}</span>
          </NavLink>
          <div className="customTooltip">
            <span>{translation.sidebar.lot}</span>
          </div>
        </MenuItem>
        <MenuItem
          id="menuAnimal"
          className={
            collapse
              ? !isAllowedFunctions(["MENU_ANIMALS"])
                ? "minimize disabled"
                : "minimize"
              : !isAllowedFunctions(["MENU_ANIMALS"])
              ? "disabled"
              : ""
          }
          onClick={handleRemoveActiveTopMenu}
        >
          <NavLink to="/admin/animals">
            <div>
              <AnimalIcon />
            </div>
            <span>{translation.sidebar.animal}</span>
          </NavLink>
          <div className="customTooltip">
            <span>{translation.sidebar.animal}</span>
          </div>
        </MenuItem>

        <MenuItem
          id="menuFinancial"
          className={
            collapse
              ? !isAllowedFunctions(["MENU_FINANCIAL"])
                ? "minimize disabled"
                : "minimize"
              : !isAllowedFunctions(["MENU_FINANCIAL"])
              ? "disabled"
              : ""
          }
          onClick={handleRemoveActiveTopMenu}
        >
          <NavLink to="/admin/financial">
            <div>
              <FinancialIcon />
              {/* <div className="badge">Beta</div> */}
            </div>
            <span>{translation.sidebar.financial}</span>
          </NavLink>
          <div className="customTooltip">
            <span>{translation.sidebar.financial}</span>
          </div>
        </MenuItem>
      </Menu>
      <MenuFooter collapse={collapse}>
        <MenuFooterItem
          id="menuImports"
          className={
            collapse
              ? !isAllowedFunctions(["MENU_IMPORT"])
                ? "minimize disabled"
                : "minimize"
              : !isAllowedFunctions(["MENU_IMPORT"])
              ? "disabled"
              : ""
          }
          onClick={handleRemoveActiveTopMenu}
        >
          <NavLink to="/admin/imports">
            <div>
              <ImportsIcon />
            </div>
            <span>{translation.sidebar.imports}</span>
          </NavLink>
          <div className="customTooltip">
            <span>{translation.sidebar.imports}</span>
          </div>
        </MenuFooterItem>
        <MenuFooterItem
          id="menuParameters"
          className={
            collapse
              ? !isAllowedFunctions(["MENU_PARAMETERS"])
                ? "minimize disabled"
                : "minimize"
              : !isAllowedFunctions(["MENU_PARAMETERS"])
              ? "disabled"
              : ""
          }
          onClick={handleRemoveActiveTopMenu}
        >
          <NavLink to="/admin/parameters">
            <div>
              <ParametersIcon />
            </div>
            <span>{translation.sidebar.parameters}</span>
          </NavLink>
          <div className="customTooltip">
            <span>{translation.sidebar.parameters}</span>
          </div>
        </MenuFooterItem>
        <MenuFooterItem
          id="menuAdministration"
          className={
            collapse
              ? !isAllowedFunctions(["MENU_ADMINISTRATION"])
                ? "minimize disabled"
                : "minimize"
              : !isAllowedFunctions(["MENU_ADMINISTRATION"])
              ? "disabled"
              : ""
          }
          onClick={handleRemoveActiveTopMenu}
        >
          <NavLink to="/admin/administrations">
            <div>
              <SettingsIcon />
            </div>
            <span>{translation.sidebar.administration}</span>
          </NavLink>
          <div className="customTooltip">
            <span>{translation.sidebar.administration}</span>
          </div>
        </MenuFooterItem>
      </MenuFooter>
    </Container>
  );
};

export default withRouter(SideBar);
