import apiFarms from "../config/api_farms";
// import axios from "axios";
import getHeaders from "./getHeaders";

// const apiFarms = axios.create({
//   baseURL: "http://localhost:8082",
// });

export function getPicketListForForm(payload) {
  const { groupId, farmId, body } = payload;
  return apiFarms.post(
    `/bovexo/${groupId}/farms/${farmId}/pickets/trough/feeding/assessments/list/pickets`,
    body,
    getHeaders(false, null)
  );
}

export function findAllTroughFeedingAssement(payload) {
  const { groupId, farmId, search, sort } = payload;

  const orderS =
    Object.entries(sort).length > 0 && sort.order
      ? sort.order === "descend"
        ? "DESC"
        : sort.order === "ascend"
        ? "ASC"
        : "DESC"
      : "DESC";

  const requestParams = `${
    sort && Object.entries(sort).length > 0 && sort.order
      ? `&sort=${sort.field}&direction=${orderS}`
      : ""
  }${search && search !== "" ? `&search=${search}` : ""}`;

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/pickets/trough/feeding/assessments?${requestParams}`,
    getHeaders(false, null)
  );
}

export function saveTroughFeedingAssement(payload) {
  const { groupId, farmId, body } = payload;
  return apiFarms.put(
    `/bovexo/${groupId}/farms/${farmId}/pickets/trough/feeding/assessments`,
    body,
    getHeaders(false, null)
  );
}

export function destroyTroughFeedingAssementByReadingDateAndPicketId(payload) {
  const { groupId, farmId, readingDate, picketId } = payload;
  return apiFarms.delete(
    `/bovexo/${groupId}/farms/${farmId}/pickets/trough/feeding/assessments?readingDate=${readingDate}&picketId=${picketId}`,
    getHeaders(false, null)
  );
}
