import React, { createContext, useState } from "react";
import { useSelector } from "react-redux";

const menuNavigationContextState = {
  activeLevelOneMenu: null,
  activeLevelTwoMenu: null,
  activeLevelThreeMenu: null,
  setActiveLevelOneMenu: (value) => {},
  setActiveLevelTwoMenu: (value) => {},
  setActiveLevelThreeMenu: (value) => {},
};

const MenuNavigationContext = createContext(menuNavigationContextState);

const MenuNavigationContextProvider = ({ children }) => {
  const [activeLevelOneMenu, setActiveLevelOneMenu] = useState(null);
  const [activeLevelTwoMenu, setActiveLevelTwoMenu] = useState(null);
  const [activeLevelThreeMenu, setActiveLevelThreeMenu] = useState(null);

  const {
    farmSelected: farm,
    farmSelected: { id: farmId },
    groupSelected: { id: groupId },
  } = useSelector((state) => state.app);

  return (
    <MenuNavigationContext.Provider
      value={{
        activeLevelOneMenu,
        activeLevelTwoMenu,
        activeLevelThreeMenu,
        setActiveLevelOneMenu,
        setActiveLevelTwoMenu,
        setActiveLevelThreeMenu,
      }}
    >
      {children}
    </MenuNavigationContext.Provider>
  );
};

export { MenuNavigationContext, MenuNavigationContextProvider };
