import React, { createContext, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import DrawerTroughFeedingAssessment from "../../components/drawers/troughFeedingAssessment";

// Services
import { findAllTroughFeedingAssement } from "../../services/troughFeedingAssessmentService";
import { transformCamelCaseToSnakeCase } from "../../utils/stringUtils";
import moment from "moment";

export const TroughFeedingAssessmentContext = createContext({
  dataList: [],
  isLoadingDataList: false,
  data: null,
  isDrawerVisible: false,
  tableProperties: {
    size: 10,
    page: 0,
    tableFilters: {},
    tableSorters: {},
    search: null,
  },
  openDrawer: (data = null) => {},
  closeDrawer: () => {},
  switchIsLoading: () => {},
  fetchData: (sort = {}, search = null) => {},
  handleTableChange: (pagination, filters, sorter) => {},
});

export const TroughFeedingAssessmentContextProvider = ({ children }) => {
  const [dataList, setDataList] = useState([]);
  const [tableProperties, setTableProperties] = useState({
    size: 10,
    page: 0,
    tableFilters: {},
    tableSorters: {},
    search: null,
  });
  const [data, setData] = useState(null);
  const [isLoadingDataList, setIsLoadingDataList] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const openDrawer = useCallback((data = null) => {
    if (data) setData(data);
    setIsDrawerVisible(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setData(null);
    setIsDrawerVisible(false);
  }, []);

  const switchIsLoading = () => {
    setIsLoadingDataList((old) => !old);
  };

  const getSortString = useCallback((sorter) => {
    let customSort = sorter;

    if (sorter && Object.entries(sorter).length > 0) {
      const { field } = sorter;

      const fieldS = field ? transformCamelCaseToSnakeCase(field) : null;

      customSort = {
        ...customSort,
        field: fieldS,
      };
    }

    return customSort;
  }, []);

  const getSearchString = useCallback((filters) => {
    let search = "";
    if (filters && Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([field, value]) => {
        if (value && value.length > 0) {
          const fieldS = field ? transformCamelCaseToSnakeCase(field) : null;
          if (fieldS === "reading_date") {
            const operatorS =
              value[1] === "equal"
                ? "="
                : value[1] === "greaterThan"
                ? ">"
                : value[1] === "greaterOrEqualThan"
                ? ">="
                : value[1] === "lessThan"
                ? "<"
                : value[1] === "lessOrEqualThan"
                ? "<="
                : "=";

            search =
              search === ""
                ? `cast(${fieldS} as date) ${operatorS} cast('${moment(
                    value[0]
                  ).format("YYYY-MM-DD")}' as date)`
                : `${search};cast(${fieldS} as date) ${operatorS} cast('${moment(
                    value[0]
                  ).format("YYYY-MM-DD")}' as date)`;
          } else {
            search =
              search === ""
                ? `upper(${fieldS}) like upper('%25${value}%25')`
                : `${search};upper(${fieldS}) like upper('%25${value}%25')`;
          }
        }
      });
    }

    return search !== "" ? search : "";
  }, []);

  const fetchData = useCallback(
    async (sort = {}, search = null) => {
      setIsLoadingDataList(true);
      try {
        let customSort = getSortString(sort);
        const {
          data: { results },
        } = await findAllTroughFeedingAssement({
          groupId,
          farmId,
          sort: customSort,
          search,
        });
        setDataList(results);
      } catch (error) {
      } finally {
        setIsLoadingDataList(false);
      }
    },
    [farmId, getSortString, groupId]
  );

  const handleTableChange = useCallback(
    async (pagination = null, filters = null, sorter = null) => {
      if (pagination && filters && sorter) {
        let customSort = getSortString(sorter);
        let search = getSearchString(filters);

        setTableProperties({
          tableFilters: filters,
          tableSorters: sorter,
          size: pagination.pageSize,
          page: pagination.current,
          search,
        });

        await fetchData(customSort, search);
      } else {
        setTableProperties({
          size: 10,
          page: 0,
          tableFilters: {},
          tableSorters: {},
          search: null,
        });
        await fetchData();
      }
    },
    [getSortString, getSearchString, fetchData]
  );

  return (
    <TroughFeedingAssessmentContext.Provider
      value={{
        dataList,
        fetchData,
        data,
        isLoadingDataList,
        isDrawerVisible,
        openDrawer,
        closeDrawer,
        switchIsLoading,
        tableProperties,
        handleTableChange,
      }}
    >
      {children}
      <DrawerTroughFeedingAssessment />
    </TroughFeedingAssessmentContext.Provider>
  );
};
