import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Creators as BoitelScenarioActions } from "../../../store/ducks/boitelScenario";
import { Body, Container, Title } from "./styles";
import { Col, notification, Row } from "antd";
import DrawerSupplier from "../../../components/drawers/supplier";
import BreadCrumbs from "../../../components/utils/breadCrumbs";
import ButtonStandard from "../../../components/utils/button";
import Loading from "../../../components/utils/loading";
import NewEditBoitelScenarioForm from "./form";
import NewEditBoitelScenarioResults from "./results";
import NewEditBoitelScenarioResultConsolidated from "./resultConsolidated";
import { BoitelScenarioNewEditContextProvider } from "../../../contexts/boitelScenarioNewEditContext";
// Services
import { downloadBoitelScenarioReport } from "../../../services/boitelScenarioService";

const NewEditBoitelScenario = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const { id: boitelScenarioId } = useParams();
  // Redux variables
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { boitelScenarioData, isLoadingGetBoitelScenarioDataRequest } =
    useSelector((state) => state.boitelScenario);
  const dispatch = useDispatch();

  // Memo
  const isAbleToGenerateReport = useMemo(() => {
    if (boitelScenarioData.consolidatedResult === null) {
      return false;
    }
    if (
      boitelScenarioData.simulations.filter((s) => s.result === null).length > 0
    ) {
      return false;
    }
    return true;
  }, [boitelScenarioData]);

  // Effects
  useEffect(() => {
    if (boitelScenarioId != null) {
      dispatch(
        BoitelScenarioActions.getBoitelScenarioData(
          groupId,
          farmId,
          boitelScenarioId
        )
      );
    }
  }, [boitelScenarioId, dispatch, farmId, groupId]);

  // Methods
  async function handleGenerateReport() {
    setIsLoading(true);
    try {
      const { data } = await downloadBoitelScenarioReport({
        groupId,
        farmId,
        id: boitelScenarioData?.id,
      });
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      let link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "cenario_boitel.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      notification.error({
        message: "Não foi possível gerar o PDF. Contate o suporte.",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleContractScenario() {
    dispatch(
      BoitelScenarioActions.contractBoitelScenario(
        groupId,
        farmId,
        boitelScenarioData?.id
      )
    );
  }

  return (
    <BoitelScenarioNewEditContextProvider>
      <Container>
        {(isLoadingGetBoitelScenarioDataRequest || isLoading) && <Loading />}
        <Row type="flex" justify="space-between" align="middle">
          <Col span={12}>
            <Title>
              {boitelScenarioData?.id === null
                ? "Novo cenário"
                : `Editando cenário - ${boitelScenarioData?.name}`}
            </Title>
          </Col>
          <Col span={12} align="right">
            <BreadCrumbs>
              <span
                onClick={() =>
                  history.push("/admin/boitel/purchase/scenario", { tab: "3" })
                }
                style={{ cursor: "pointer" }}
              >
                <span className="pageTreeInative">Decisões</span>
              </span>
              <span className="pageTreeActive">Boitel - Diária</span>
            </BreadCrumbs>
          </Col>
        </Row>
        <Body>
          {boitelScenarioData.status !== "Hired" && (
            <Row type="flex">
              <NewEditBoitelScenarioForm />
            </Row>
          )}
          {boitelScenarioData?.id !== null && (
            <Row type="flex">
              <NewEditBoitelScenarioResults />
            </Row>
          )}
          {boitelScenarioData?.id !== null && (
            <Row type="flex">
              <NewEditBoitelScenarioResultConsolidated />
            </Row>
          )}
          <br />
          {boitelScenarioData?.id !== null && (
            <Row type="flex" justify="space-between">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row type="flex" justify="end" gutter={24}>
                  {boitelScenarioData?.status !== "Hired" && (
                    <Col>
                      <ButtonStandard
                        type="button"
                        buttonType="secondary"
                        onClick={handleContractScenario}
                        disabled={!isAbleToGenerateReport}
                      >
                        Contratar
                      </ButtonStandard>
                    </Col>
                  )}
                  <Col>
                    <ButtonStandard
                      type="button"
                      buttonType="secondary"
                      onClick={handleGenerateReport}
                      disabled={!isAbleToGenerateReport}
                    >
                      Gerar PDF
                    </ButtonStandard>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Body>
        <DrawerSupplier />
      </Container>
    </BoitelScenarioNewEditContextProvider>
  );
};

export default NewEditBoitelScenario;
