import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as BreedActions } from "../../../store/ducks/breed";
import { Creators as LotActions } from "../../../store/ducks/lot";

import moment from "moment";
import "moment/locale/en-nz";
import "moment/locale/es";
import "moment/locale/pt-br";

import { Col, DatePicker, Divider, Drawer, Input, Row, Select } from "antd";
import { Formik } from "formik";
import NumberFormat from "react-number-format";
import * as Yup from "yup";
import ButtonStandard from "../../utils/button";
import InfoTooltip from "../../utils/infoTooltip";
import SelectLotProduction from "../../utils/selectLotProduction";
import { Container, Footer } from "./styles";

const validationSchema = Yup.object().shape({
  breedId: Yup.string().required(),
  gender: Yup.string().required(),
  birthday: Yup.date().required().max(new Date()),
  estimatedWeight: Yup.number().positive().required(),
  amount: Yup.number().positive().required(),
  purchaseCost: Yup.number().positive().required(),
  prefixIdentification: Yup.string().when(["shouldCreateLotEntry"], {
    is: (shouldCreateLotEntry) => shouldCreateLotEntry === true,
    then: Yup.string().required(),
    otherwise: Yup.string().nullable(),
  }),
  lotDestinationId: Yup.string().when(["shouldCreateLotEntry"], {
    is: (shouldCreateLotEntry) => shouldCreateLotEntry === true,
    then: Yup.string().required(),
    otherwise: Yup.string().nullable(),
  }),
});

const { Option } = Select;

const { Group: InputGroup } = Input;

const dateFormat = "DD/MM/YYYY";

const NewLotItem = () => {
  const formikRef = useRef();

  const {
    app: {
      translation,
      groupSelected,
      farmSelected,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
    lot: { drawerLotItemReceiptVisible, lotItem },
    breed: { data: breeds },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const fetchData = (
    groupSelected,
    farmSelected,
    page = 0,
    sorter = {},
    filters = ""
  ) => {
    dispatch(BreedActions.indexBreedActive(groupId, farmId));
  };

  const closeDrawer = () => {
    formikRef.current.resetForm({
      id: null,
      breedId: null,
      breedName: null,
      gender: null,
      birthday: null,
      estimatedWeight: "",
      amount: "",
      purchaseCost: "",
      shouldCreateLotEntry: false,
      prefixIdentification: null,
      lotDestinationId: null,
      numbersArray: [],
      numbers: "",
      numbersInserted: 0,
    });
    dispatch(LotActions.hideLotItemReceipt());
  };

  const handleSubmitModalForm = async (values, actions) => {
    if (values.id === null) {
      values.id = Math.random();
      handleAddNewLotItem(values);
    } else {
      handleUpdateLotItem(values);
    }
    actions.resetForm({
      id: null,
      breedId: null,
      breedName: null,
      gender: null,
      birthday: null,
      estimatedWeight: "",
      amount: "",
      purchaseCost: "",
      shouldCreateLotEntry: false,
      prefixIdentification: null,
      lotDestinationId: null,
      numbersArray: [],
      numbers: "",
      numbersInserted: 0,
    });
  };

  const handleAddNewLotItem = (lotItem) => {
    dispatch(LotActions.addNewLotReceiptItem(lotItem, false));
  };

  const handleUpdateLotItem = (lotItem) => {
    dispatch(LotActions.updateLotItem(lotItem));
  };

  const handleDrawerVisible = (visible) => {
    if (visible) {
      if (
        Object.entries(groupSelected).length > 0 &&
        Object.entries(farmSelected).length > 0
      ) {
        fetchData(groupSelected, farmSelected);
      }
    }
  };

  return (
    <Drawer
      title={translation.lot.receipt.finalize.newLotItem.title}
      width={550}
      onClose={closeDrawer}
      maskClosable={false}
      visible={drawerLotItemReceiptVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <Container>
        <Formik
          ref={formikRef}
          enableReinitialize={true}
          initialValues={lotItem}
          onSubmit={handleSubmitModalForm}
          validationSchema={validationSchema}
          render={(props) => (
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <div id="drawerForm">
                <Row type="flex">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <span style={{ fontSize: "14px", color: "#000" }}>
                      {translation.lot.receipt.finalize.newLotItem.description}:
                    </span>
                  </Col>
                </Row>
                {Object.entries(props.errors).length > 0 &&
                  props.submitCount > 0 && (
                    <Row type="flex" justify="center" align="middle">
                      <label className="error">
                        {translation.error.formError}
                      </label>
                    </Row>
                  )}
                <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <label
                        className={
                          props.errors.breedId && props.submitCount > 0
                            ? "error"
                            : ""
                        }
                      >
                        {translation.lot.receipt.finalize.newLotItem.breed}*
                      </label>
                    </Row>
                    <Row>
                      <Select
                        style={{ width: "100%" }}
                        name="breedId"
                        value={
                          props.values.breedId != null
                            ? props.values.breedId
                            : undefined
                        }
                        placeholder={
                          translation.lot.receipt.finalize.newLotItem.breed
                        }
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          props.setValues({
                            ...props.values,
                            breedId: value,
                            breedName: breeds.filter(
                              (breed) => breed.id === value
                            )[0].name,
                          });
                        }}
                      >
                        {Object.entries(breeds).length > 0 &&
                          breeds.map((breed) => (
                            <Option key={breed.id} value={breed.id}>
                              {breed.name}
                            </Option>
                          ))}
                      </Select>
                    </Row>
                  </Col>
                </Row>
                <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <label
                        className={
                          props.errors.gender && props.submitCount > 0
                            ? "error"
                            : ""
                        }
                      >
                        {translation.lot.receipt.finalize.newLotItem.gender}*
                      </label>
                    </Row>
                    <Row>
                      <Select
                        style={{ width: "100%" }}
                        name="gender"
                        value={
                          props.values.gender == null
                            ? undefined
                            : props.values.gender
                        }
                        placeholder={translation.defaultSelectPlaceholder}
                        onChange={(value) => {
                          props.setValues({ ...props.values, gender: value });
                        }}
                      >
                        <Option key="M" value="M">
                          {translation.animal.details.male}
                        </Option>
                        <Option key="F" value="F">
                          {translation.animal.details.female}
                        </Option>
                      </Select>
                    </Row>
                  </Col>
                </Row>
                <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Row>
                      <label
                        className={
                          props.errors.birthday && props.submitCount > 0
                            ? "error"
                            : ""
                        }
                      >
                        {translation.lot.receipt.finalize.newLotItem.birthday}*
                      </label>
                    </Row>
                    <Row>
                      <DatePicker
                        style={{ width: "100%" }}
                        allowClear={false}
                        placeholder={
                          translation.lot.receipt.finalize.newLotItem
                            .birthdayPlaceHolder
                        }
                        value={
                          props.values.birthday != null
                            ? moment(props.values.birthday, "YYYY-MM-DD")
                            : null
                        }
                        format={dateFormat}
                        name="birthday"
                        onChange={(dateMoment, dateString) => {
                          if (dateMoment <= moment() && dateMoment !== null) {
                            props.setValues({
                              ...props.values,
                              birthday: dateMoment,
                            });
                          }
                        }}
                      />
                    </Row>
                  </Col>
                  <Col xs={0} sm={0} md={2} lg={2} xl={2}>
                    <Row type="flex" align="middle" justify="center">
                      <span>-</span>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                    <Row>
                      <label>Idade (dias/meses/anos)</label>
                    </Row>
                    <Row>
                      <Col>
                        <InputGroup compact>
                          <Input
                            type="number"
                            style={{ width: 80, textAlign: "center" }}
                            name="ageDay"
                            min="0"
                            placeholder="Dias"
                            value={
                              props.values.birthday != null
                                ? moment().diff(props.values.birthday, "days")
                                : null
                            }
                            onChange={(e) => {
                              props.setValues({
                                ...props.values,
                                birthday: moment().subtract(
                                  e.target.value > 5478 ? 5478 : e.target.value,
                                  "days"
                                ),
                              });
                            }}
                          />
                          <Input
                            style={{
                              width: 80,
                              textAlign: "center",
                              borderLeft: "1px solid #c4c4c4",
                            }}
                            type="number"
                            name="ageMonth"
                            min="0"
                            placeholder="Meses"
                            value={
                              props.values.birthday != null
                                ? moment().diff(props.values.birthday, "months")
                                : null
                            }
                            onChange={(e) => {
                              props.setValues({
                                ...props.values,
                                birthday: moment().subtract(
                                  e.target.value > 180 ? 180 : e.target.value,
                                  "months"
                                ),
                              });
                            }}
                          />
                          <Input
                            style={{
                              width: 80,
                              textAlign: "center",
                              borderLeft: "1px solid #c4c4c4",
                            }}
                            type="number"
                            name="ageYear"
                            min="0"
                            placeholder="Anos"
                            value={
                              props.values.birthday != null
                                ? moment().diff(
                                    props.values.birthday,
                                    "years",
                                    false
                                  )
                                : null
                            }
                            onChange={(e) => {
                              props.setValues({
                                ...props.values,
                                birthday: moment().subtract(
                                  e.target.value > 15 ? 15 : e.target.value,
                                  "years"
                                ),
                              });
                            }}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <label
                        className={
                          props.errors.estimatedWeight && props.submitCount > 0
                            ? "error"
                            : ""
                        }
                      >
                        {
                          translation.lot.receipt.finalize.newLotItem
                            .estimatedWeight
                        }
                        *
                      </label>
                    </Row>
                    <Row>
                      <NumberFormat
                        customInput={Input}
                        placeholder={
                          translation.lot.receipt.finalize.newLotItem
                            .estimatedWeightPlaceHolder
                        }
                        value={
                          props.values.estimatedWeight
                            ? props.values.estimatedWeight
                            : ""
                        }
                        addonAfter="Kg"
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        name="estimatedWeight"
                        onValueChange={({ floatValue }) => {
                          props.setValues({
                            ...props.values,
                            estimatedWeight: floatValue
                              ? floatValue < 1000
                                ? floatValue
                                : 1000
                              : null,
                          });
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <label
                        className={
                          props.errors.amount && props.submitCount > 0
                            ? "error"
                            : ""
                        }
                      >
                        {translation.lot.receipt.finalize.newLotItem.amount}*
                      </label>
                    </Row>
                    <Row>
                      <NumberFormat
                        customInput={Input}
                        placeholder={
                          translation.lot.receipt.finalize.newLotItem
                            .amoutPlaceHolder
                        }
                        value={props.values.amount ? props.values.amount : ""}
                        name="amount"
                        min={0}
                        decimalScale={0}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        onValueChange={({ floatValue }) => {
                          props.setValues({
                            ...props.values,
                            amount: floatValue
                              ? floatValue > 1000
                                ? 1000
                                : floatValue
                              : null,
                          });
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <label
                        className={
                          props.errors.purchaseCost && props.submitCount > 0
                            ? "error"
                            : ""
                        }
                      >
                        {
                          translation.lot.receipt.finalize.newLotItem
                            .purchaseCost
                        }
                        *
                      </label>
                    </Row>
                    <Row>
                      <NumberFormat
                        customInput={Input}
                        placeholder={
                          translation.lot.receipt.finalize.newLotItem
                            .purchaseCostPlaceHolder
                        }
                        value={
                          props.values.purchaseCost
                            ? props.values.purchaseCost
                            : ""
                        }
                        name="purchaseCost"
                        min={0}
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        addonBefore="R$"
                        style={{ width: "100%" }}
                        onValueChange={({ floatValue }) => {
                          props.setValues({
                            ...props.values,
                            purchaseCost: floatValue
                              ? floatValue > 99999
                                ? 99999
                                : floatValue
                              : null,
                          });
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                {props.values.shouldCreateLotEntry && (
                  <>
                    <Divider dashed>
                      INFORMAÇÕES PARA CADASTRO DE ANIMAIS
                    </Divider>
                    <Row type="flex" gutter={8}>
                      <Col span={12}>
                        <Row>
                          <label
                            className={
                              props.submitCount > 0 &&
                              props.errors.prefixIdentification != null
                                ? "error"
                                : ""
                            }
                            htmlFor="prefixIdentification"
                          >
                            {translation.lotItemEntry.form.prefixIdentification}
                            *{" "}
                            <InfoTooltip
                              title={
                                'Adicione o Prefixo a ser utilizado na identificação provisória dos animais. A partir deste prefixo, serão gerados os animais com a identificação  "Prefixo" + "Odem" (001, 002, 003).'
                              }
                            />
                          </label>
                        </Row>
                        <Row>
                          <Input
                            value={props.values.prefixIdentification || ""}
                            placeholder={translation.defaultPlaceholder}
                            maxLength={10}
                            onChange={(e) =>
                              props.setFieldValue(
                                "prefixIdentification",
                                e.target.value
                              )
                            }
                          />
                        </Row>
                      </Col>
                      <Col span={12}>
                        <Row>
                          <label
                            className={
                              props.submitCount > 0 &&
                              props.errors.lotDestinationId != null
                                ? "error"
                                : ""
                            }
                            htmlFor="lotDestinationId"
                          >
                            {translation.lotItemEntry.form.lotDestinationId}*
                          </label>
                        </Row>
                        <Row>
                          <SelectLotProduction
                            id={"lotDestinationId"}
                            name="lotDestinationId"
                            value={props.values.lotDestinationId || undefined}
                            onDeselect={() =>
                              props.setFieldValue("lotDestinationId", null)
                            }
                            onChange={(value) => {
                              props.setFieldValue("lotDestinationId", value);
                            }}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
              {/* Footer */}
              <Footer>
                <Row type="flex" justify="space-between">
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="selectDiv"
                  />
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="buttonsDiv"
                  >
                    <ButtonStandard
                      type="button"
                      buttonType="type7"
                      onClick={closeDrawer}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>

                    <ButtonStandard type="submit" buttonType="type6">
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Footer>
            </form>
          )}
        />
      </Container>
    </Drawer>
  );
};
export default NewLotItem;
