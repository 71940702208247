import {
  Button,
  Checkbox,
  Col,
  Icon,
  notification,
  Row,
  Select,
  Spin,
  Upload,
} from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import template from "../../../../assets/templates/BovExo_template_animais.xlsx";
import templateByLot from "../../../../assets/templates/BovExo_template_animais_lotes.xlsx";
import templateIdentify from "../../../../assets/templates/BovExo_template_identificar_animais.xlsx";
import { importAnimals } from "../../../../services/importService";
import { getLotIndex } from "../../../../services/lotService";
import { Creators as AppActions } from "../../../../store/ducks/app";
import { Creators as SupplierActions } from "../../../../store/ducks/supplier";
import DrawerSupplier from "../../../drawers/supplier";
import ButtonStandard from "../../../utils/button";
import InfoTooltip from "../../../utils/infoTooltip";
import {
  ButtonWithoutBackground,
  Container,
  CustomModal,
  CustomSelect,
  DownloadButton,
  Header,
  Title,
} from "./styles";

//Libs
import moment from "moment";
import SelectLotProduction from "../../../utils/selectLotProduction";

const AnimalUploadModal = () => {
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lotSelected, setLotSelected] = useState(null);
  const [importType, setImportType] = useState(
    "ImportAnimalsForDifferentsLotsAndSuppliers"
  );
  const [supplierSelected, setSupplierSelected] = useState(null);
  const [isFarmAnimal, setIsFarmAnimal] = useState(false);
  const [receiptLots, setReceiptLots] = useState([]);
  const [lotReceiptSelected, setLotReceiptSelected] = useState(null);
  const [lotItemReceiptSelected, setLotItemReceiptSelected] = useState(null);
  const [allowReceiptLot, setAllowReceiptLot] = useState(false);

  const props = {
    accept: ".xlsm, .xlsx",
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    showUploadList: false,
    fileList,
  };
  const { Dragger } = Upload;
  const { Option } = Select;
  const dateFormat = "DD/MM/YYYY";
  // Redux Variables
  const {
    translation,
    modalAnimalUploadVisible,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const {
    listDrowpDown: suppliers,
    isLoadingDropDown: isLoadingDropDownSuppliers,
  } = useSelector((state) => state.supplier);
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchData(groupId, farmId) {
      try {
        dispatch(
          SupplierActions.getDropdownSuppliers(
            groupId,
            farmId,
            "CattleFarmer",
            true
          )
        );

        const {
          data: { results: listOfReceiptLots },
        } = await getLotIndex({
          groupId,
          farmId,
          page: null,
          sorter: { field: "createdAt", order: "descend" },
          filters: "is_receipt=true;status='A'",
        });

        if (listOfReceiptLots?.content) {
          setReceiptLots(listOfReceiptLots?.content);
        }
      } catch (error) {
      } finally {
      }
    }
    if (modalAnimalUploadVisible) fetchData(groupId, farmId);
    return () => {};
  }, [groupId, farmId, modalAnimalUploadVisible, dispatch]);
  // Methods
  const handleCreateSupplier = () => {
    dispatch(SupplierActions.showDrawer("new_animal_supplier", null));
  };
  const handleCancel = () => {
    setIsLoading(false);
    setFileList([]);
    setLotSelected(null);
    setSupplierSelected(null);
    setIsFarmAnimal(false);
    setImportType("ImportAnimalsForDifferentsLotsAndSuppliers");
    setReceiptLots([]);
    setLotReceiptSelected(null);
    setLotItemReceiptSelected(null);
    setAllowReceiptLot(false);
    dispatch(AppActions.hideModalAnimalUpload());
  };
  const handleUpload = async () => {
    try {
      setIsLoading(true);
      const {
        data: { results },
      } = await importAnimals({
        groupId,
        farmId,
        lotId: lotSelected,
        supplierId: supplierSelected,
        lotReceiptId: lotReceiptSelected?.id,
        lotItemReceiptId: lotItemReceiptSelected?.id,
        file: fileList[0],
        importType,
      });
      if (results.success === true) {
        notification.success({
          message: "Animais em processamento",
          description:
            "O seu arquivo esta correto, aguarde. Você será notificado quando todas os animais forem processados.",
        });
        handleCancel();
      } else {
        notification.error({
          message: "Erro ao importar",
          description: `Teve algun(s) erro(s). ${
            results.importResult.filter((ir) => ir.success === false).length > 0
              ? `Verifique as linhas ${results.importResult
                  .filter((ir) => ir.success === false)
                  .map((ir) => ir.rowNumber)
                  .join(", ")}`
              : ``
          }`,
          duration: 0,
        });
        handleCancel();
      }
    } catch (error) {
      notification.error({
        message: "Erro ao importar",
        description: `Nenhum animal foi incluído.`,
        duration: 0,
      });
      handleCancel();
    }
  };

  return (
    <CustomModal
      width={535}
      visible={modalAnimalUploadVisible}
      centered
      maskClosable={false}
      closable={false}
      footer={null}
    >
      <Container>
        <Spin spinning={isLoading}>
          <Header>
            <Title>{translation.importAnimals.title}</Title>
          </Header>
          {/* Select upload type */}
          <Row type="flex">
            <Col span={24}>
              <Row>
                <label>
                  Selecione o tipo de importação:
                  <InfoTooltip title={translation.importAnimals.helpMessage} />
                </label>
              </Row>
              <Row>
                <CustomSelect
                  name="importType"
                  style={{ width: "100%" }}
                  value={importType}
                  onChange={(value) => setImportType(value)}
                >
                  <Option value="ImportAnimalsForDifferentsLotsAndSuppliers">
                    Importar animais por lote e fornecedor
                  </Option>
                  <Option value="ImportAnimalsForOneLotAndSupplier">
                    Importar animais para um único lote e fornecedor
                  </Option>
                  <Option value="ImportIdentifyAnimals">
                    Identificar animais
                  </Option>
                </CustomSelect>
              </Row>
            </Col>
          </Row>
          {/* Message */}
          <Row type="flex" justify="start">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    importType === "ImportAnimalsForOneLotAndSupplier"
                      ? translation.importAnimals.message
                      : importType ===
                        "ImportAnimalsForDifferentsLotsAndSuppliers"
                      ? translation.importAnimals.messageForDifferentsLots
                      : translation.importAnimals.messageForIdentifyAnimals,
                }}
              />
            </Col>
          </Row>

          {importType === "ImportAnimalsForOneLotAndSupplier" && (
            <Row type="flex">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                {/* Lot Select */}
                <Row type="flex" justify="start">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} align="left">
                    <SelectLotProduction
                      value={lotSelected || undefined}
                      customSelect={true}
                      placeholder="Selecione um lote de produção"
                      id="lotId"
                      name="lotId"
                      onDeselect={() => setLotSelected(null)}
                      onChange={(value) => {
                        if (value == null) {
                          setLotSelected(null);
                        } else {
                          setLotSelected(value);
                        }
                      }}
                    />
                  </Col>
                </Row>
                {/* Supplier Select */}
                <Row type="flex" justify="start" gutter={4} align="middle">
                  <Col xs={20} sm={20} md={20} lg={20} xl={20} align="left">
                    <CustomSelect
                      name="supplierId"
                      style={{ width: "100%" }}
                      value={
                        supplierSelected != null ? supplierSelected : undefined
                      }
                      placeholder="Selecione um fornecedor"
                      loading={isLoadingDropDownSuppliers}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear={true}
                      disabled={isFarmAnimal}
                      onChange={(value) => {
                        if (value == null) {
                          setSupplierSelected(null);
                        } else {
                          setSupplierSelected(value);
                        }
                        setLotReceiptSelected(null);
                        setLotItemReceiptSelected(null);
                      }}
                    >
                      {suppliers.length > 0 &&
                        suppliers.map((sup) => (
                          <Option key={sup.id} value={sup.id}>
                            {sup.name}
                          </Option>
                        ))}
                    </CustomSelect>
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <Button
                      shape="circle"
                      icon="plus"
                      onClick={() => handleCreateSupplier()}
                    />
                  </Col>
                </Row>
                {/* Checkbox */}
                <Row type="flex" justify="start">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} align="left">
                    <Checkbox
                      checked={isFarmAnimal}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSupplierSelected(null);
                          setLotReceiptSelected(null);
                          setLotItemReceiptSelected(null);
                        }
                        setIsFarmAnimal(e.target.checked);
                      }}
                    >
                      Animal(is) fornecido(s) pela{" "}
                      <strong>própria fazenda</strong>
                    </Checkbox>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {/* New lot production button */}
          {importType !== "ImportAnimalsForOneLotAndSupplier" ? (
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="downloadButtonRow"
            >
              <Col>
                <ButtonStandard
                  buttonType="type8"
                  onClick={handleCreateSupplier}
                >
                  Novo fornecedor
                </ButtonStandard>
              </Col>
              <Col>
                <DownloadButton
                  href={
                    importType === "ImportAnimalsForOneLotAndSupplier"
                      ? template
                      : importType ===
                        "ImportAnimalsForDifferentsLotsAndSuppliers"
                      ? templateByLot
                      : templateIdentify
                  }
                  download={
                    importType === "ImportAnimalsForOneLotAndSupplier"
                      ? "BovExo_template_animais.xlsx"
                      : importType ===
                        "ImportAnimalsForDifferentsLotsAndSuppliers"
                      ? "BovExo_template_animais_lotes.xlsx"
                      : "BovExo_template_identificar_animais.xlsx"
                  }
                >
                  <Icon type="download" />
                  {translation.buttons.download}
                </DownloadButton>
              </Col>
            </Row>
          ) : (
            <Row type="flex" justify="start" className="downloadButtonRow">
              <Col>
                <DownloadButton
                  href={
                    importType === "ImportAnimalsForOneLotAndSupplier"
                      ? template
                      : templateByLot
                  }
                  download={
                    importType === "ImportAnimalsForOneLotAndSupplier"
                      ? "BovExo_template_animais.xlsx"
                      : "BovExo_template_animais_lotes.xlsx"
                  }
                >
                  <Icon type="download" />
                  {translation.buttons.download}
                </DownloadButton>
              </Col>
            </Row>
          )}
          {/* Upload area */}
          <Row type="flex" className="uploadRow">
            <Row type="flex">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Dragger {...props}>
                  {fileList.length > 0 ? (
                    <Fragment>
                      <p className="ant-upload-drag-icon">
                        <Icon type="file-excel" />
                      </p>
                      <p className="ant-upload-text">{fileList[0].name}</p>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                      </p>
                      <p className="ant-upload-text">
                        {translation.importAnimals.draggerMessage}
                      </p>
                    </Fragment>
                  )}
                </Dragger>
              </Col>
            </Row>
            {importType !== "ImportIdentifyAnimals" && (
              <>
                <Row className="rowInput" style={{ marginTop: "10px" }}>
                  <Checkbox
                    checked={allowReceiptLot}
                    onChange={(e) => {
                      setAllowReceiptLot(e.target.checked);
                      if (!e.target.checked) {
                        setLotReceiptSelected(null);
                        setLotItemReceiptSelected(null);
                      }
                    }}
                  >
                    Associar o animal a um Lote de Recebimento.
                  </Checkbox>
                  <InfoTooltip title="Selecione os lotes de recebimento já cadastrados em Lotes/ Recebimento, somente poderão ser selecionados os lotes de recebimento pendentes e que sejam do mesmo fornecedor dos animais a serem importados." />
                </Row>
                <Row type="flex">
                  <CustomSelect
                    style={{ width: "100%" }}
                    disabled={
                      !allowReceiptLot ||
                      (importType === "ImportAnimalsForOneLotAndSupplier" &&
                        !supplierSelected)
                    }
                    value={
                      lotItemReceiptSelected != null
                        ? lotItemReceiptSelected?.id
                        : undefined
                    }
                    name="lotReceiptId"
                    placeholder={"Selecione um lote de recebimento"}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear={true}
                    onChange={(value) => {
                      if (!value) {
                        setLotReceiptSelected(null);
                        setLotItemReceiptSelected(null);
                      } else {
                        let lotReceipt = receiptLots.find((lot) => {
                          let item = lot.lotItems.find(
                            (item) => item.id === value
                          );
                          if (item) {
                            setLotItemReceiptSelected(item);
                            return true;
                          }
                          return false;
                        });
                        setLotReceiptSelected(lotReceipt);
                      }
                    }}
                  >
                    {receiptLots.map((lot) => {
                      return lot.lotItems
                        .filter((item) => {
                          return (
                            item.status !== "Confirmed" &&
                            (lot.supplierId === supplierSelected ||
                              importType !==
                                "ImportAnimalsForOneLotAndSupplier")
                          );
                        })
                        .map((item) => {
                          return (
                            <Option key={item.id} value={item.id}>
                              {
                                //${item.identification} /
                                `${lot.supplierName} /
                          ${
                            item.entryDate
                              ? moment(item.entryDate).format(dateFormat) +
                                " / "
                              : ""
                          }
                          ${item?.gender.substring(0, 1)} /
                          ${item.amountAnimalsConfirmed} /
                          ${item.identification}
                            `
                              }
                            </Option>
                          );
                        });
                    })}
                  </CustomSelect>
                </Row>
              </>
            )}
            <Row
              type="flex"
              className="footer"
              justify="space-between"
              align="middle"
            >
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <ButtonStandard
                  type="button"
                  buttonType="type4"
                  size="s"
                  onClick={handleCancel}
                >
                  {translation.buttons.cancel}
                </ButtonStandard>
              </Col>
              <Col xs={16} sm={16} md={16} lg={16} xl={16} align="right">
                <ButtonWithoutBackground
                  type="submit"
                  onClick={handleUpload}
                  disabled={
                    fileList.length === 0 ||
                    (importType === "ImportAnimalsForOneLotAndSupplier" &&
                      lotSelected == null)
                      ? true
                      : false
                  }
                >
                  {translation.buttons.processAnimals}
                </ButtonWithoutBackground>
              </Col>
            </Row>
          </Row>
        </Spin>
      </Container>
      <DrawerSupplier />
    </CustomModal>
  );
};

export default AnimalUploadModal;
