import React, { useEffect, useCallback, useRef, useState } from "react";
import moment from "moment-timezone";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Creators as BoitelScenarioActions } from "../../../../store/ducks/boitelScenario";
import { Creators as SupplierActions } from "../../../../store/ducks/supplier";
import { Formik } from "formik";
import { Body, CollapseContainer, Container, Footer } from "./styles";
import {
  Col,
  Input,
  Row,
  DatePicker,
  Radio,
  Select,
  Icon,
  Spin,
  Modal,
  Button,
  Switch,
  notification,
} from "antd";
import NumberFormat from "react-number-format";
import InfoTooltip from "../../../../components/utils/infoTooltip";
import ButtonStandard from "../../../../components/utils/button";
// Contexts
import useBoitelScnearioNewEditContext from "../../../../hooks/useBoitelScnearioNewEditContext";
//  Services
import { numberMask } from "../../../../services/helpersMethodsService";
import { getBoitelPricesHistoryDropDown } from "../../../../services/boitelPriceService";
import { indexAllDropdownFarmQuota } from "../../../../services/quotaService";

const { Group: RadioGroup } = Radio;
const { Group: InputGroup } = Input;

const ID_BREED_COMPOSTO = "f98e1b92-220d-46b3-baca-dda0a50b2a5f";

const NewEditBoitelScenarioForm = () => {
  const [quotas, setQuotas] = useState([]);
  const [loadingQuota, setLoadingQuota] = useState(false);
  const [form, setForm] = useState({
    id: null,
    name: null,
    supplierId: null,
    supplierContact: null,
    amountAnimals: null,
    boitelPriceId: null,
    boitelPriceValidityId: null,
    boitelPriceValidityWeightRangeId: null,
    breedId: null,
    negotiatedAgeInMonths: null,
    useCustomCarcassHarnessing: false,
    carcassHarnessing: null,
    dietStrategyId: null,
    entryDate: null,
    expectedMargin: null,
    gender: "Male",
    limitTimeSimulation: null,
    marketAnalysisId: null,
    maximumWeightSell: 720,
    minimumWeightSell: null,
    mixedMilk: false,
    priceDifferenceOxCow: null,
    priceSimulation: null,
    resultProcessedWithSuccess: true,
    useQuotaCriteria: true,
    quotaId: null,
    minimumAgeSell: null,
    maximumAgeSell: null,
    strongAgeRestriction: null,
    minimumWeight: null,
    maximumWeight: 720,
    strongWeightRestriction: null,
  });
  const [boitelPrice, setBoitelPrice] = useState(null);
  const [allWeightRanges, setAllWeightRanges] = useState([]);
  const [dietStrategies, setDietStrategies] = useState([]);
  const [haveChangeLimitTimeSimulation, setHaveChangeLimitTimeSimulation] =
    useState(false);
  const [haveChangePriceSimulation, setHaveChangePriceSimulation] =
    useState(false);
  const dateFormat = "DD/MM/YYYY";
  const dateTimeFormat = "DD/MM/YYYY HH:mm:ss";
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    supplierId: Yup.string().required(),
    supplierContact: Yup.string().required(),
    amountAnimals: Yup.number().required().moreThan(0),
    boitelPriceId: Yup.string().required(),
    boitelPriceValidityId: Yup.string().required(),
    boitelPriceValidityWeightRangeId: Yup.string().required(),
    breedId: Yup.string().required(),
    negotiatedAgeInMonths: Yup.number().required(),
    useCustomCarcassHarnessing: Yup.boolean().required(),
    carcassHarnessing: Yup.number()
      .notRequired()
      .nullable()
      .when(["useCustomCarcassHarnessing"], {
        is: (useCustomCarcassHarnessing) => useCustomCarcassHarnessing === true,
        then: Yup.number().required(),
        otherwise: Yup.number().notRequired().nullable(),
      }),
    dietStrategyId: Yup.string().required(),
    entryDate: Yup.date().required(),
    expectedMargin: Yup.number().required().moreThan(0),
    gender: Yup.string().required(),
    limitTimeSimulation: Yup.number().required(),
    priceSimulation: Yup.number().required(),
    marketAnalysisId: Yup.string().required(),
    mixedMilk: Yup.boolean().required(),
    priceDifferenceOxCow: Yup.number().required(),
    maximumWeightSell: Yup.number()
      .notRequired()
      .nullable()
      .when(["useQuotaCriteria"], {
        is: (useQuotaCriteria) => useQuotaCriteria === false,
        then: Yup.number().required().moreThan(0),
        otherwise: Yup.number().notRequired().nullable(),
      }),
    minimumWeightSell: Yup.number()
      .notRequired()
      .nullable()
      .when(["useQuotaCriteria"], {
        is: (useQuotaCriteria) => useQuotaCriteria === false,
        then: Yup.number().required().moreThan(0),
        otherwise: Yup.number().notRequired().nullable(),
      }),
    maximumAgeSell: Yup.number()
      .notRequired()
      .nullable()
      .when(["useQuotaCriteria"], {
        is: (useQuotaCriteria) => useQuotaCriteria === false,
        then: Yup.number().required().moreThan(0),
        otherwise: Yup.number().notRequired().nullable(),
      }),
    minimumAgeSell: Yup.number()
      .notRequired()
      .nullable()
      .when(["useQuotaCriteria"], {
        is: (useQuotaCriteria) => useQuotaCriteria === false,
        then: Yup.number().required().moreThan(0),
        otherwise: Yup.number().notRequired().nullable(),
      }),
  });
  const formikRef = useRef(null);
  // Props
  const { allDietStrategies, breeds, marketAnalysis } =
    useBoitelScnearioNewEditContext();
  const history = useHistory();
  // Redux variables
  const { isLoadingSaveRequest, clearFields, boitelScenarioData } = useSelector(
    (state) => state.boitelScenario
  );
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const {
    listDrowpDown: suppliers,
    isLoadingDropDown: isLoadingDropDownSuppliers,
  } = useSelector((state) => state.supplier);
  const dispatch = useDispatch();

  const renderStrongInput = useCallback(
    (field, helper, props) => (
      <div className="inputStrong">
        <span>Forte?</span> <InfoTooltip title={helper} />{" "}
        <Switch
          checked={props.values?.[field] === true ? true : false}
          onChange={(checked, event) => props.setFieldValue(field, checked)}
        />
      </div>
    ),
    []
  );

  // CALLBACK
  const renderValueOverTax = useCallback(
    (props) => {
      if (
        props.values?.boitelPriceValidityId !== null &&
        props.values?.boitelPriceValidityWeightRangeId !== null
      ) {
        const boitelPriceValidity = boitelPrice.find(
          (b) => b.idBoitelPriceValidity === props.values?.boitelPriceValidityId
        );
        const weightRangeSelected = allWeightRanges.find(
          (wr) => wr.id === props.values?.boitelPriceValidityWeightRangeId
        );
        if (
          boitelPriceValidity.useDailyPurchase === true &&
          boitelPriceValidity.dailySurchase != null
        ) {
          if (props.values.mixedMilk === true) {
            return (
              weightRangeSelected?.dailyValueMixedMilk +
              weightRangeSelected?.dailyValueMixedMilk *
                (boitelPriceValidity.dailySurchase / 100)
            );
          } else {
            return (
              weightRangeSelected?.dailyValueCut +
              weightRangeSelected?.dailyValueCut *
                (boitelPriceValidity.dailySurchase / 100)
            );
          }
        } else {
          if (props.values.mixedMilk === true) {
            return weightRangeSelected?.dailyValueAfterMixedMilk;
          } else {
            return weightRangeSelected?.dailyValueAfterCut;
          }
        }
      } else {
        return "";
      }
    },
    [allWeightRanges, boitelPrice]
  );

  const filterDietStrategies = useCallback(
    (breedId, gender) => {
      const result =
        allDietStrategies.length > 0
          ? allDietStrategies.filter(
              (ds) => ds.breedId === breedId && ds.gender === gender
            )
          : [];

      setDietStrategies(result);
    },
    [allDietStrategies]
  );

  const validateDietStrategy = useCallback(
    (dietStrategyId, entryDate, limitTimeSimulation) => {
      const ds = allDietStrategies.find((ds) => ds.id === dietStrategyId);
      const startDs = moment(ds.dietPeriods[0].startDate);
      const endDs = moment(ds.dietPeriods[ds.dietPeriods.length - 1].endDate);
      const entryDateM = moment(entryDate);
      const entryDatePlusLimitTime = moment(entryDate).add(
        limitTimeSimulation,
        "days"
      );

      let haveError = false;
      let message = null;

      if (entryDateM.isBefore(startDs)) {
        haveError = true;
        message =
          "A data de entrada se inicia antes da estratégia de dieta selecionada.";
      } else if (entryDateM.isAfter(endDs)) {
        haveError = true;
        message =
          "A data de entrada se inicia depois do término da estratégia de dieta selecionada.";
      } else if (entryDatePlusLimitTime.isAfter(endDs)) {
        haveError = true;
        message =
          "A estratégia de dieta selecionada não comporta o tempo de permanência informado.";
      }

      if (haveError) {
        Modal.error({
          title: "Erro",
          content: message,
          centered: true,
          okText: "Ok",
        });
      }

      return !haveError;
    },
    [allDietStrategies]
  );

  const validateBoitelPriceValidity = useCallback(
    (boitelPriceValidityId, entryDate, limitTimeSimulation) => {
      const bp = boitelPrice.find(
        (x) => x.idBoitelPriceValidity === boitelPriceValidityId
      );
      const startBs = moment(bp.initialValidity);
      const endBs = moment(bp.finalValidity);
      const entryDateM = moment(entryDate);

      let haveError = false;
      let message = null;

      if (entryDateM.isBefore(startBs)) {
        haveError = true;
        message =
          'A data de entrada se inicia antes da vigência da "Tabela de Diárias" selecionada.';
      } else if (entryDateM.isAfter(endBs)) {
        haveError = true;
        message =
          'A data de entrada se inicia depois do término da vigência da "Tabela de Diárias" selecionada.';
      }
      if (haveError) {
        Modal.warning({
          title: "Alerta",
          content: message,
          centered: true,
          okText: "Ok",
        });
      }

      return true;
    },
    [boitelPrice]
  );

  const handleCreateSupplier = useCallback(() => {
    dispatch(SupplierActions.showDrawer("new_animal_supplier", null));
  }, [dispatch]);

  const handleChangeBoitelPriceValidity = useCallback(
    (value, props) => {
      const validity = boitelPrice.find(
        (b) => b.idBoitelPriceValidity === value
      );
      props.setFieldValue("boitelPriceId", validity?.idBoitelPrice);
      props.setFieldValue("boitelPriceValidityId", value);
      props.setFieldValue("boitelPriceValidityWeightRangeId", null);
      if (!haveChangeLimitTimeSimulation) {
        props.setFieldValue("limitTimeSimulation", null);
      }
      setAllWeightRanges(validity.weightValues);
    },
    [boitelPrice, haveChangeLimitTimeSimulation]
  );

  const handleClearFields = useCallback(() => {
    formikRef.current.resetForm({
      amountAnimals: null,
      boitelPriceId: null,
      boitelPriceValidityId: null,
      boitelPriceValidityWeightRangeId: null,
      breedId: null,
      negotiatedAgeInMonths: null,
      useCustomCarcassHarnessing: false,
      carcassHarnessing: null,
      dietStrategyId: null,
      entryDate: null,
      expectedMargin: null,
      gender: "Male",
      limitTimeSimulation: null,
      marketAnalysisId: null,
      maximumWeightSell: 720,
      minimumWeightSell: null,
      mixedMilk: false,
      priceDifferenceOxCow: null,
      priceSimulation: null,
      resultProcessedWithSuccess: true,
      useQuotaCriteria: true,
      quotaId: null,
      minimumAgeSell: null,
      maximumAgeSell: null,
      strongAgeRestriction: null,
      strongWeightRestriction: null,
    });
    setForm((old) => ({
      ...old,
      amountAnimals: null,
      boitelPriceId: null,
      boitelPriceValidityId: null,
      boitelPriceValidityWeightRangeId: null,
      breedId: null,
      negotiatedAgeInMonths: null,
      useCustomCarcassHarnessing: false,
      carcassHarnessing: null,
      dietStrategyId: null,
      entryDate: null,
      expectedMargin: null,
      gender: "Male",
      limitTimeSimulation: null,
      marketAnalysisId: null,
      maximumWeightSell: 720,
      minimumWeightSell: null,
      mixedMilk: false,
      priceDifferenceOxCow: null,
      priceSimulation: null,
      resultProcessedWithSuccess: true,
      useQuotaCriteria: true,
      quotaId: null,
      minimumAgeSell: null,
      maximumAgeSell: null,
      strongAgeRestriction: null,
      strongWeightRestriction: null,
    }));
    history.push("/admin/boitel/purchase/scenario", { tab: "3" });
  }, [history]);

  // Methods
  function handleSubmit(values, actions) {
    try {
      const { id, name, supplierId, supplierContact, ...simulationData } =
        values;
      const body = {
        id,
        name,
        supplierId,
        supplierContact,
        simulation: simulationData,
      };
      if (
        validateDietStrategy(
          simulationData.dietStrategyId,
          simulationData.entryDate,
          simulationData.limitTimeSimulation
        ) &&
        validateBoitelPriceValidity(
          simulationData.boitelPriceValidityId,
          simulationData.entryDate,
          simulationData.limitTimeSimulation
        )
      ) {
        let isNegatiadedAgeBiggerThanMaximumAgeSell = false;
        if (simulationData.useQuotaCriteria === true) {
          const selectedQuota = quotas.find(
            (quota) => quota.id === simulationData.quotaId
          );
          if (selectedQuota) {
            if (
              selectedQuota.maxAge / 30.417 <
              simulationData.negotiatedAgeInMonths
            ) {
              isNegatiadedAgeBiggerThanMaximumAgeSell = true;
            }
          }
        } else if (
          simulationData.negotiatedAgeInMonths >
          simulationData.maximumAgeSell / 30.417
        ) {
          isNegatiadedAgeBiggerThanMaximumAgeSell = true;
        }

        if (isNegatiadedAgeBiggerThanMaximumAgeSell === false) {
          dispatch(
            BoitelScenarioActions.storeUpdateBoitelScenario(
              groupId,
              farmId,
              id,
              body
            )
          );
        } else {
          notification.error({
            message:
              "Idade negociada é maior que a idade máxima de venda permitida.",
          });
        }
      }
    } catch (error) {}
  }

  // Effects

  // Clear fields
  useEffect(() => {
    if (boitelScenarioData?.id !== null && clearFields === true) {
      const lastSimulation =
        boitelScenarioData.simulations[
          boitelScenarioData.simulations.length - 1
        ];
      formikRef.current.resetForm({
        id: boitelScenarioData?.id,
        name: boitelScenarioData?.name,
        supplierId: boitelScenarioData?.supplierId,
        supplierContact: boitelScenarioData?.supplierContact,
        amountAnimals: "",
        boitelPriceId: null,
        boitelPriceValidityId: null,
        boitelPriceValidityWeightRangeId: null,
        breedId: null,
        negotiatedAgeInMonths: null,
        useCustomCarcassHarnessing: lastSimulation?.useCustomCarcassHarnessing,
        carcassHarnessing: lastSimulation?.carcassHarnessing,
        dietStrategyId: null,
        entryDate: null,
        expectedMargin: lastSimulation?.expectedMargin,
        gender: "Male",
        limitTimeSimulation: null,
        marketAnalysisId: null,
        maximumWeightSell: lastSimulation?.maximumWeightSell || 720,
        minimumWeightSell: lastSimulation?.minimumWeightSell,
        mixedMilk: false,
        priceDifferenceOxCow: lastSimulation?.priceDifferenceOxCow,
        priceSimulation: null,
        resultProcessedWithSuccess: true,
        useQuotaCriteria: true,
        quotaId: null,
        minimumAgeSell: null,
        maximumAgeSell: null,
        strongAgeRestriction: null,
        strongWeightRestriction: null,
      });
      setForm({
        id: boitelScenarioData?.id,
        name: boitelScenarioData?.name,
        amountAnimals: null,
        boitelPriceId: null,
        boitelPriceValidityId: null,
        boitelPriceValidityWeightRangeId: null,
        breedId: null,
        negotiatedAgeInMonths: null,
        useCustomCarcassHarnessing: false,
        carcassHarnessing: null,
        dietStrategyId: null,
        entryDate: null,
        expectedMargin: null,
        gender: "Male",
        limitTimeSimulation: null,
        marketAnalysisId: null,
        maximumWeightSell: 720,
        minimumWeightSell: null,
        mixedMilk: false,
        priceDifferenceOxCow: null,
        priceSimulation: null,
        resultProcessedWithSuccess: true,
        useQuotaCriteria: true,
        quotaId: null,
        minimumAgeSell: null,
        maximumAgeSell: null,
        strongAgeRestriction: null,
        strongWeightRestriction: null,
      });
      dispatch(BoitelScenarioActions.clearFields());
    } else if (boitelScenarioData?.id === null && clearFields === true) {
      formikRef.current.resetForm({
        id: null,
        name: null,
        amountAnimals: null,
        boitelPriceId: null,
        boitelPriceValidityId: null,
        boitelPriceValidityWeightRangeId: null,
        breedId: null,
        negotiatedAgeInMonths: null,
        useCustomCarcassHarnessing: false,
        carcassHarnessing: null,
        dietStrategyId: null,
        entryDate: null,
        expectedMargin: null,
        gender: "Male",
        limitTimeSimulation: null,
        marketAnalysisId: null,
        maximumWeightSell: 720,
        minimumWeightSell: null,
        mixedMilk: false,
        priceDifferenceOxCow: null,
        priceSimulation: null,
        resultProcessedWithSuccess: true,
        useQuotaCriteria: true,
        quotaId: null,
        minimumAgeSell: null,
        maximumAgeSell: null,
        strongAgeRestriction: null,
        strongWeightRestriction: null,
      });
      setForm({
        id: null,
        name: null,
        amountAnimals: null,
        boitelPriceId: null,
        boitelPriceValidityId: null,
        boitelPriceValidityWeightRangeId: null,
        breedId: null,
        negotiatedAgeInMonths: null,
        useCustomCarcassHarnessing: false,
        carcassHarnessing: null,
        dietStrategyId: null,
        entryDate: null,
        expectedMargin: null,
        gender: "Male",
        limitTimeSimulation: null,
        marketAnalysisId: null,
        maximumWeightSell: 720,
        minimumWeightSell: null,
        mixedMilk: false,
        priceDifferenceOxCow: null,
        priceSimulation: null,
        resultProcessedWithSuccess: true,
        useQuotaCriteria: true,
        quotaId: null,
        minimumAgeSell: null,
        maximumAgeSell: null,
        strongAgeRestriction: null,
        strongWeightRestriction: null,
      });
      dispatch(BoitelScenarioActions.clearFields());
    }
  }, [clearFields, boitelScenarioData, dispatch]);

  // Set form from redux
  useEffect(() => {
    if (boitelScenarioData?.id !== null) {
      const { id, name, supplierId, supplierContact } = boitelScenarioData;
      const lastSimulation =
        boitelScenarioData.simulations[
          boitelScenarioData.simulations.length - 1
        ];
      setForm({
        id,
        name,
        supplierId,
        supplierContact,
        amountAnimals: null,
        boitelPriceId: lastSimulation?.boitelPriceId,
        boitelPriceValidityId: lastSimulation?.boitelPriceValidityId,
        boitelPriceValidityWeightRangeId: null,
        breedId: null,
        negotiatedAgeInMonths: null,
        carcassHarnessing: lastSimulation?.carcassHarnessing,
        useCustomCarcassHarnessing: lastSimulation?.useCustomCarcassHarnessing,
        dietStrategyId: null,
        entryDate: null,
        expectedMargin: lastSimulation?.expectedMargin,
        gender: "Male",
        limitTimeSimulation: null,
        marketAnalysisId: null,
        maximumWeightSell: lastSimulation?.maximumWeightSell || 720,
        minimumWeightSell: lastSimulation?.minimumWeightSell,
        mixedMilk: false,
        priceDifferenceOxCow: lastSimulation?.priceDifferenceOxCow,
        priceSimulation: null,
        resultProcessedWithSuccess: true,
        useQuotaCriteria: lastSimulation?.useQuotaCriteria,
        quotaId: lastSimulation?.quotaId,
        minimumAgeSell: lastSimulation?.minimumAgeSell,
        maximumAgeSell: lastSimulation?.maximumAgeSell,
        strongAgeRestriction: lastSimulation?.strongAgeRestriction,
        strongWeightRestriction: lastSimulation?.strongWeightRestriction,
      });
    }
  }, [boitelScenarioData]);

  // Get Suppliers CattleFarmer
  useEffect(() => {
    async function getSuppliers() {
      dispatch(
        SupplierActions.getDropdownSuppliers(
          groupId,
          farmId,
          "CattleFarmer",
          true
        )
      );
    }
    async function fetchQuotas() {
      setLoadingQuota(true);
      try {
        const {
          data: { results },
        } = await indexAllDropdownFarmQuota({ groupId, farmId });
        setQuotas(results);
        setLoadingQuota(false);
      } catch (error) {
        setLoadingQuota([]);
        setLoadingQuota(false);
      }
    }
    getSuppliers();
    fetchQuotas();
  }, [groupId, farmId, dispatch]);

  // Get BoitelPrices
  useEffect(() => {
    async function fetchBoitelPrices() {
      try {
        const {
          data: { results },
        } = await getBoitelPricesHistoryDropDown({ groupId, farmId });
        setBoitelPrice(results);
        const weightRanges = results.flatMap((v) => v.weightValues);
        setAllWeightRanges(weightRanges);
      } catch (error) {
        setBoitelPrice(null);
      }
    }
    fetchBoitelPrices();
  }, [groupId, farmId]);

  return (
    <Container>
      <Formik
        ref={formikRef}
        enableReinitialize={true}
        initialValues={form}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        render={(props) => (
          <form onSubmit={props.handleSubmit} autoComplete="off">
            <Spin spinning={isLoadingSaveRequest}>
              <Body>
                {/* Error message */}
                {Object.entries(props.errors).length > 0 &&
                  props.submitCount > 0 && (
                    <Row type="flex" justify="center" align="middle">
                      <label className="formLabel error">
                        {translation.error.formError}
                      </label>
                    </Row>
                  )}

                {/* Name, Supplier and SupplierContact */}
                <Row type="flex" justify="start" gutter={8}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Row>
                      <label
                        className={
                          props.errors.name && props.submitCount > 0
                            ? "formLabel error"
                            : "formLabel"
                        }
                      >
                        Nome do cenário*
                      </label>
                    </Row>
                    <Row>
                      <Input
                        placeholder={translation.defaultPlaceholder}
                        value={props.values?.name}
                        name="name"
                        maxLength={40}
                        onChange={(e) => {
                          props.setFieldValue("name", e.target.value);
                        }}
                      />
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Row>
                      <label
                        className={
                          props.errors.supplierId && props.submitCount > 0
                            ? "formLabel error"
                            : "formLabel"
                        }
                      >
                        Cliente*
                      </label>
                    </Row>
                    <Row>
                      <Select
                        style={{
                          width: "80%",
                        }}
                        value={
                          props.values?.supplierId != null
                            ? suppliers != null &&
                              isLoadingDropDownSuppliers === false &&
                              suppliers.find(
                                (s) => s.id === props.values?.supplierId
                              ) != null
                              ? props.values?.supplierId
                              : undefined
                            : undefined
                        }
                        loading={isLoadingDropDownSuppliers}
                        showSearch
                        placeholder={
                          translation.lot.receipt.supplier
                            .supplierComboBoxPlaceHolder
                        }
                        name="supplierId"
                        onChange={(value) => {
                          props.setFieldValue("supplierId", value);
                        }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {suppliers != null &&
                          Object.entries(suppliers).length > 0 &&
                          suppliers.map((supplier) => (
                            <Select.Option
                              key={supplier.id}
                              value={supplier.id}
                            >
                              {supplier.name}
                            </Select.Option>
                          ))}
                      </Select>
                      <Button
                        tabIndex="-1"
                        shape="circle"
                        icon="plus"
                        style={{ marginLeft: 10 }}
                        onClick={handleCreateSupplier}
                      />
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Row>
                      <label
                        className={
                          props.errors.supplierContact && props.submitCount > 0
                            ? "formLabel error"
                            : "formLabel"
                        }
                      >
                        Nome Resp. Cliente*
                      </label>
                    </Row>
                    <Row>
                      <Input
                        placeholder={translation.defaultPlaceholder}
                        value={props.values?.supplierContact}
                        maxLength={40}
                        name="supplierContact"
                        onChange={(e) => {
                          props.setFieldValue(
                            "supplierContact",
                            e.target.value
                          );
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                {/* Parameters Collapse */}
                <CollapseContainer
                  defaultActiveKey={["1"]}
                  expandIconPosition="right"
                  expandIcon={({ isActive }) =>
                    isActive ? <Icon type="minus" /> : <Icon type="plus" />
                  }
                >
                  <CollapseContainer.Panel
                    tabIndex="-1"
                    header="Parâmetros"
                    key="1"
                  >
                    <Row type="flex" justify="start" gutter={8}>
                      {/* InputDate */}
                      <Col xs={24} sm={24} md={8} lg={4} xl={4}>
                        <Row>
                          <label
                            className={
                              props.errors.entryDate && props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            Data entrada*
                          </label>
                        </Row>
                        <Row>
                          <DatePicker
                            name="entryDate"
                            style={{ width: "100%" }}
                            allowClear={false}
                            value={
                              props.values.entryDate != null
                                ? moment(props.values.entryDate, "YYYY-MM-DD")
                                : null
                            }
                            placeholder={
                              translation.defaultDatePickerPlaceholder
                            }
                            format={dateFormat}
                            onChange={(date, dateString) => {
                              props.setFieldValue("entryDate", date);
                            }}
                          />
                        </Row>
                      </Col>
                      {/* marketAnalysisId */}
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Row>
                          <label
                            className={
                              props.errors.marketAnalysisId &&
                              props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            Curva de Preços Futuro da @*
                          </label>
                        </Row>
                        <Row>
                          <Select
                            name="marketAnalysisId"
                            value={
                              props.values.marketAnalysisId
                                ? props.values.marketAnalysisId
                                : undefined
                            }
                            placeholder={translation.defaultSelectPlaceholder}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) => {
                              props.setFieldValue("marketAnalysisId", value);
                            }}
                          >
                            {marketAnalysis.map((m) => (
                              <Select.Option key={m.id} value={m.id}>
                                {m.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Row>
                      </Col>
                      {/* Dif. Boi/Vaca */}
                      <Col xs={24} sm={24} md={8} lg={4} xl={4}>
                        <Row>
                          <label
                            className={
                              props.errors.priceDifferenceOxCow &&
                              props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            Dif. Boi/Vaca*{" "}
                            <InfoTooltip title="Diferença média, em percentual, entre os preços praticados entre Boi Gordo e Vaca Gorda para abate." />
                          </label>
                        </Row>
                        <Row>
                          <NumberFormat
                            customInput={Input}
                            value={
                              props.values?.priceDifferenceOxCow !== null
                                ? props.values?.priceDifferenceOxCow
                                : ""
                            }
                            placeholder={translation.defaultPlaceholder}
                            name="priceDifferenceOxCow"
                            addonAfter="%"
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            fixedDecimalScale={true}
                            onValueChange={({ floatValue }) => {
                              props.setFieldValue(
                                "priceDifferenceOxCow",
                                floatValue
                                  ? floatValue > 100
                                    ? 100
                                    : floatValue
                                  : null
                              );
                            }}
                          />
                        </Row>
                      </Col>
                      {/* Input useQuotaCriteria and quotaId */}
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Row>
                          <label
                            className={
                              props.errors.useQuotaCriteria &&
                              props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            Critérios de venda *
                          </label>
                        </Row>
                        <Row>
                          <RadioGroup
                            name="useQuotaCriteria"
                            value={props.values.useQuotaCriteria}
                            onChange={(e) => {
                              props.setFieldValue(
                                "useQuotaCriteria",
                                e.target.value
                              );
                            }}
                          >
                            <Radio value={true}>Pré-Fixadas (Cotas)</Radio>
                            <Radio value={false}>Livre</Radio>
                          </RadioGroup>
                        </Row>
                        {props.values.useQuotaCriteria === true ? (
                          <Row>
                            <Select
                              name="quotaId"
                              style={{ width: "100%" }}
                              value={props.values.quotaId || undefined}
                              loading={loadingQuota}
                              placeholder={translation.defaultSelectPlaceholder}
                              onChange={(value) => {
                                props.setFieldValue("quotaId", value);
                              }}
                            >
                              {quotas.map((q) => (
                                <Select.Option key={q.id} value={q.id}>
                                  {q.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Row>
                        ) : null}
                      </Col>
                    </Row>
                    {/* Restrictions */}
                    {props.values.useQuotaCriteria === false ? (
                      <Row type="flex" justify="start" gutter={8}>
                        {/* Input minimumAgeSell and maximumAgeSell */}
                        <Col span={12}>
                          <Row type="flex" gutter={8} justify="start">
                            <label className="formLabel">
                              Idade para Venda
                            </label>
                            {renderStrongInput(
                              "strongAgeRestriction",
                              "Ao selecionar esta opção, o intervalo entre a idade mínima e máxima para venda, se tornará uma restrição forte, desta forma, o sistema buscará a melhor oportunidade de venda, respeitando esta premissa. Caso, esta opção, esteja desmarcada, o sistema  irá considerar o intervalo, porém, caso exista uma possibilidade melhor, mesmo que fora deste intervalo de idade, o sistema retornará com esta melhor opção.",
                              props
                            )}
                          </Row>
                          <Row type="flex" gutter={8} justify="start">
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <Row>
                                <label
                                  className={
                                    props.errors.minimumAgeSell &&
                                    props.submitCount > 0
                                      ? "formLabel error"
                                      : "formLabel"
                                  }
                                >
                                  Idade mínima para venda*
                                </label>
                              </Row>
                              <Row>
                                <InputGroup compact>
                                  <Input
                                    type="number"
                                    style={{ width: 80, textAlign: "center" }}
                                    value={props.values.minimumAgeSell || null}
                                    name="ageDay"
                                    min="0"
                                    placeholder="Dias"
                                    onChange={(e) => {
                                      props.setFieldValue(
                                        "minimumAgeSell",
                                        e.target.value
                                      );
                                    }}
                                  />
                                  <Input
                                    style={{
                                      width: 80,
                                      textAlign: "center",
                                      borderLeft: "1px solid #c4c4c4",
                                    }}
                                    type="number"
                                    name="ageMonth"
                                    min="0"
                                    placeholder="Meses"
                                    value={
                                      props.values.minimumAgeSell
                                        ? Number(
                                            props.values.minimumAgeSell / 30.417
                                          ).toFixed(0)
                                        : null
                                    }
                                    onChange={(e) => {
                                      props.setFieldValue(
                                        "minimumAgeSell",
                                        e.target.value > 0
                                          ? Math.floor(e.target.value * 30.417)
                                          : 0
                                      );
                                    }}
                                  />
                                  <Input
                                    style={{
                                      width: 80,
                                      textAlign: "center",
                                      borderLeft: "1px solid #c4c4c4",
                                    }}
                                    type="number"
                                    name="ageYear"
                                    min="0"
                                    placeholder="Anos"
                                    value={
                                      props.values.minimumAgeSell
                                        ? props.values.minimumAgeSell >= 365
                                          ? Math.floor(
                                              props.values.minimumAgeSell / 365
                                            )
                                          : 0
                                        : null
                                    }
                                    onChange={(e) => {
                                      props.setFieldValue(
                                        "minimumAgeSell",
                                        e.target.value > 0
                                          ? Math.floor(e.target.value * 365)
                                          : 0
                                      );
                                    }}
                                  />
                                </InputGroup>
                              </Row>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <Row>
                                <label
                                  className={
                                    props.errors.maximumAgeSell &&
                                    props.submitCount > 0
                                      ? "formLabel error"
                                      : "formLabel"
                                  }
                                >
                                  Idade máxima para venda*
                                </label>
                              </Row>
                              <Row>
                                <InputGroup compact>
                                  <Input
                                    type="number"
                                    style={{ width: 80, textAlign: "center" }}
                                    value={props.values.maximumAgeSell || null}
                                    name="ageDay"
                                    min="0"
                                    placeholder="Dias"
                                    onChange={(e) => {
                                      props.setFieldValue(
                                        "maximumAgeSell",
                                        e.target.value
                                      );
                                    }}
                                  />
                                  <Input
                                    style={{
                                      width: 80,
                                      textAlign: "center",
                                      borderLeft: "1px solid #c4c4c4",
                                    }}
                                    type="number"
                                    name="ageMonth"
                                    min="0"
                                    placeholder="Meses"
                                    value={
                                      props.values.maximumAgeSell
                                        ? Number(
                                            props.values.maximumAgeSell / 30.417
                                          ).toFixed(0)
                                        : null
                                    }
                                    onChange={(e) => {
                                      props.setFieldValue(
                                        "maximumAgeSell",
                                        e.target.value > 0
                                          ? Math.floor(e.target.value * 30.417)
                                          : 0
                                      );
                                    }}
                                  />
                                  <Input
                                    style={{
                                      width: 80,
                                      textAlign: "center",
                                      borderLeft: "1px solid #c4c4c4",
                                    }}
                                    type="number"
                                    name="ageYear"
                                    min="0"
                                    placeholder="Anos"
                                    value={
                                      props.values.maximumAgeSell
                                        ? props.values.maximumAgeSell >= 365
                                          ? Math.floor(
                                              props.values.maximumAgeSell / 365
                                            )
                                          : 0
                                        : null
                                    }
                                    onChange={(e) => {
                                      props.setFieldValue(
                                        "maximumAgeSell",
                                        e.target.value > 0
                                          ? Math.floor(e.target.value * 365)
                                          : 0
                                      );
                                    }}
                                  />
                                </InputGroup>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={12}>
                          {/* Input Minimum Weight and Maximum Weight*/}
                          <Row type="flex" gutter={8} justify="start">
                            <label className="formLabel">Peso para Venda</label>
                            {renderStrongInput(
                              "strongWeightRestriction",
                              "Ao selecionar esta opção, o intervalo entre o peso mínimo e máximo para venda, se tornará uma restrição forte, desta forma, o sistema buscará a melhor oportunidade de venda, respeitando esta premissa. Caso, esta opção, esteja desmarcada, o sistema  irá considerar o intervalo, porém, caso exista uma possibilidade melhor, mesmo que fora deste intervalo de peso, o sistema retornará com esta melhor opção.",
                              props
                            )}
                          </Row>
                          <Row type="flex" gutter={8} justify="start">
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <Row>
                                <label
                                  className={
                                    props.errors.minimumWeightSell &&
                                    props.submitCount > 0
                                      ? "formLabel error"
                                      : "formLabel"
                                  }
                                >
                                  Peso mínimo para venda*
                                </label>
                              </Row>
                              <Row>
                                <NumberFormat
                                  customInput={Input}
                                  value={props.values.minimumWeightSell || ""}
                                  placeholder={translation.defaultPlaceholder}
                                  name="minimumWeightSell"
                                  addonAfter="Kg"
                                  decimalScale={2}
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  fixedDecimalScale={true}
                                  onValueChange={({ floatValue }) => {
                                    props.setFieldValue(
                                      "minimumWeightSell",
                                      floatValue >= 0
                                        ? floatValue < 1000
                                          ? floatValue
                                          : 999.99
                                        : null
                                    );
                                  }}
                                />
                              </Row>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <Row>
                                <label
                                  className={
                                    props.errors.maximumWeightSell &&
                                    props.submitCount > 0
                                      ? "formLabel error"
                                      : "formLabel"
                                  }
                                >
                                  Peso máximo para venda*
                                </label>
                              </Row>
                              <Row>
                                <NumberFormat
                                  customInput={Input}
                                  value={props.values.maximumWeightSell || ""}
                                  placeholder={translation.defaultPlaceholder}
                                  name="maximumWeightSell"
                                  addonAfter="Kg"
                                  decimalScale={2}
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  fixedDecimalScale={true}
                                  onValueChange={({ floatValue }) => {
                                    props.setFieldValue(
                                      "maximumWeightSell",
                                      floatValue >= 0
                                        ? floatValue < 1000
                                          ? floatValue
                                          : 1000
                                        : null
                                    );
                                  }}
                                />
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ) : null}
                    {/* Boitel inputs */}
                    <Row type="flex" justify="start" gutter={8}>
                      {/* BoitelPriceValidityId */}
                      <Col xs={24} sm={24} md={8} lg={10} xl={10}>
                        <Row>
                          <label
                            className={
                              props.errors.boitelPriceValidityId &&
                              props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            Tabela de Diárias*{" "}
                            <InfoTooltip title="As Tabelas de Preços das Diárias de Boitel, devem ser previamente cadastradas em [Parâmetros/ Curvas de Preços/ Boitel – Diárias]" />
                          </label>
                        </Row>
                        <Row>
                          <Select
                            name="boitelPriceValidityId"
                            value={
                              props.values.boitelPriceValidityId &&
                              boitelPrice != null
                                ? props.values.boitelPriceValidityId
                                : undefined
                            }
                            placeholder={translation.defaultSelectPlaceholder}
                            onChange={(value) => {
                              handleChangeBoitelPriceValidity(value, props);
                            }}
                          >
                            {boitelPrice != null &&
                              boitelPrice
                                .filter((bp) => bp.status === "Active")
                                .map((el) => (
                                  <Select.Option
                                    value={el?.idBoitelPriceValidity}
                                    key={el?.idBoitelPriceValidity}
                                  >
                                    {`${
                                      el?.validityDescription
                                        ? `${el?.validityDescription} - `
                                        : ""
                                    }${moment(el.initialValidity).format(
                                      dateFormat
                                    )} à ${moment(el.finalValidity).format(
                                      dateFormat
                                    )} - Atualização: ${moment
                                      .utc(el.createdAt)
                                      .local()
                                      .format(dateTimeFormat)}`}
                                  </Select.Option>
                                ))}
                          </Select>
                        </Row>
                      </Col>
                      {/* BitelPriceValidityWeightRangeId */}
                      <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                        <Row>
                          <label
                            className={
                              props.errors.boitelPriceValidityWeightRangeId &&
                              props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            Peso de entrada*
                          </label>
                        </Row>
                        <Row>
                          <Select
                            name="boitelPriceValidityWeightRangeId"
                            value={
                              props.values.boitelPriceValidityWeightRangeId &&
                              boitelPrice != null
                                ? props.values.boitelPriceValidityWeightRangeId
                                : undefined
                            }
                            placeholder={translation.defaultSelectPlaceholder}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) => {
                              props.setFieldValue(
                                "boitelPriceValidityWeightRangeId",
                                value
                              );
                              if (
                                !haveChangeLimitTimeSimulation ||
                                props.values.limitTimeSimulation === null
                              ) {
                                setHaveChangeLimitTimeSimulation(false);
                                props.setFieldValue(
                                  "limitTimeSimulation",
                                  allWeightRanges.find((w) => w.id === value)
                                    ?.limitTime
                                );
                              }
                              if (
                                !haveChangePriceSimulation ||
                                props.values.priceSimulation === null
                              ) {
                                setHaveChangePriceSimulation(false);
                                props.setFieldValue(
                                  "priceSimulation",
                                  props.values?.mixedMilk
                                    ? allWeightRanges.find(
                                        (w) => w.id === value
                                      )?.dailyValueMixedMilk
                                    : allWeightRanges.find(
                                        (w) => w.id === value
                                      )?.dailyValueCut
                                );
                              }
                            }}
                          >
                            {boitelPrice != null &&
                              props.values.boitelPriceValidityId != null &&
                              allWeightRanges.map((w) => (
                                <Select.Option
                                  key={w.id}
                                  value={w.id}
                                >{`${numberMask(
                                  w.initialWeightRange,
                                  false
                                )} Kg - ${numberMask(
                                  w.finalWeightRange,
                                  false
                                )} Kg`}</Select.Option>
                              ))}
                          </Select>
                        </Row>
                      </Col>
                      {/* Sobre taxa */}
                      <Col xs={24} sm={24} md={8} lg={4} xl={4}>
                        <Row>
                          <label
                            className={
                              props.errors.boitelPriceValidityId &&
                              props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            Sobretaxa Diária
                          </label>
                        </Row>
                        <Row>
                          <NumberFormat
                            customInput={Input}
                            value={renderValueOverTax(props)}
                            placeholder={translation.defaultPlaceholder}
                            name="dailySurchase"
                            addonBefore="R$"
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            fixedDecimalScale={true}
                            disabled
                          />
                        </Row>
                      </Col>
                      {/* Valor Protocolo */}
                      <Col xs={24} sm={24} md={8} lg={4} xl={4}>
                        <Row>
                          <label
                            className={
                              props.errors.boitelPriceValidityWeightRangeId &&
                              props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            Valor Protocolo
                          </label>
                        </Row>
                        <Row>
                          <NumberFormat
                            customInput={Input}
                            value={
                              props.values?.boitelPriceValidityId !== null &&
                              props.values?.boitelPriceValidityWeightRangeId !==
                                null
                                ? boitelPrice.find(
                                    (w) =>
                                      w.idBoitelPriceValidity ===
                                      props.values?.boitelPriceValidityId
                                  )?.inputProtocol
                                : ""
                            }
                            placeholder={translation.defaultPlaceholder}
                            name="inputProtocol"
                            addonBefore="R$"
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            fixedDecimalScale={true}
                            disabled
                          />
                        </Row>
                      </Col>
                    </Row>
                    <Row type="flex" justify="start" gutter={8}>
                      {/* iDDailyPriceDays  */}
                      <Col xs={24} sm={24} md={8} lg={4} xl={4}>
                        <Row>
                          <label
                            className={
                              props.errors.boitelPriceValidityWeightRangeId &&
                              props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            Perman. Tab.*
                          </label>
                        </Row>
                        <Row>
                          <NumberFormat
                            customInput={Input}
                            value={
                              props.values?.boitelPriceValidityWeightRangeId !==
                              null
                                ? allWeightRanges.find(
                                    (w) =>
                                      w.id ===
                                      props.values
                                        ?.boitelPriceValidityWeightRangeId
                                  )?.limitTime
                                : ""
                            }
                            placeholder={translation.defaultPlaceholder}
                            name="iDDailyPriceDays"
                            addonAfter="dias"
                            decimalScale={0}
                            decimalSeparator=","
                            thousandSeparator="."
                            fixedDecimalScale={false}
                            disabled
                          />
                        </Row>
                      </Col>
                      {/* limitTimeSimulation */}
                      <Col xs={24} sm={24} md={8} lg={4} xl={4}>
                        <Row>
                          <label
                            className={
                              props.errors.limitTimeSimulation &&
                              props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            Perman. Negoc.*
                          </label>
                        </Row>
                        <Row>
                          <NumberFormat
                            customInput={Input}
                            value={
                              props.values?.limitTimeSimulation !== null
                                ? props.values?.limitTimeSimulation
                                : ""
                            }
                            placeholder={translation.defaultPlaceholder}
                            name="limitTimeSimulation"
                            addonAfter="dias"
                            decimalScale={0}
                            decimalSeparator=","
                            thousandSeparator="."
                            fixedDecimalScale={false}
                            onKeyPress={() =>
                              setHaveChangeLimitTimeSimulation(true)
                            }
                            onValueChange={({ floatValue }) => {
                              props.setFieldValue(
                                "limitTimeSimulation",
                                floatValue
                                  ? floatValue < 1000
                                    ? floatValue
                                    : 999
                                  : null
                              );
                            }}
                          />
                        </Row>
                      </Col>
                      {/* iDDailyPrice  */}
                      <Col xs={24} sm={24} md={8} lg={4} xl={4}>
                        <Row>
                          <label
                            className={
                              props.errors.boitelPriceValidityWeightRangeId &&
                              props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            Preço Dia Tab.*
                          </label>
                        </Row>
                        <Row>
                          <NumberFormat
                            customInput={Input}
                            value={
                              props.values?.boitelPriceValidityWeightRangeId !==
                              null
                                ? props.values?.mixedMilk
                                  ? allWeightRanges.find(
                                      (w) =>
                                        w.id ===
                                        props.values
                                          ?.boitelPriceValidityWeightRangeId
                                    )?.dailyValueMixedMilk
                                  : allWeightRanges.find(
                                      (w) =>
                                        w.id ===
                                        props.values
                                          ?.boitelPriceValidityWeightRangeId
                                    )?.dailyValueCut
                                : ""
                            }
                            placeholder={translation.defaultPlaceholder}
                            name="iDDailyPrice"
                            addonBefore="R$"
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            fixedDecimalScale={true}
                            disabled
                          />
                        </Row>
                      </Col>
                      {/* PriceSimulation */}
                      <Col xs={24} sm={24} md={8} lg={4} xl={4}>
                        <Row>
                          <label
                            className={
                              props.errors.priceSimulation &&
                              props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            Preço Dia Negoc.*
                          </label>
                        </Row>
                        <Row>
                          <NumberFormat
                            customInput={Input}
                            value={
                              props.values?.priceSimulation !== null
                                ? props.values?.priceSimulation
                                : ""
                            }
                            placeholder={translation.defaultPlaceholder}
                            name="priceSimulation"
                            addonBefore="R$"
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            fixedDecimalScale={true}
                            onKeyPress={() =>
                              setHaveChangePriceSimulation(true)
                            }
                            onValueChange={({ floatValue }) => {
                              props.setFieldValue(
                                "priceSimulation",
                                floatValue
                                  ? floatValue < 10000
                                    ? floatValue
                                    : 9999
                                  : null
                              );
                            }}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </CollapseContainer.Panel>
                </CollapseContainer>
                {/* Negociation Collapse */}
                <CollapseContainer
                  defaultActiveKey={["1"]}
                  expandIconPosition="right"
                  expandIcon={({ isActive }) =>
                    isActive ? <Icon type="minus" /> : <Icon type="plus" />
                  }
                >
                  <CollapseContainer.Panel header="Negociação" key="1">
                    <Row type="flex" justify="start" gutter={8}>
                      {/* AnimalsAmout */}
                      <Col xs={24} sm={24} md={8} lg={4} xl={4}>
                        <Row>
                          <label
                            className={
                              props.errors.amountAnimals &&
                              props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            Quantidade*
                          </label>
                        </Row>
                        <Row>
                          <NumberFormat
                            customInput={Input}
                            value={
                              props.values?.amountAnimals !== null
                                ? props.values?.amountAnimals
                                : ""
                            }
                            placeholder={translation.defaultPlaceholder}
                            name="amountAnimals"
                            addonAfter="cab"
                            decimalScale={0}
                            decimalSeparator=","
                            thousandSeparator="."
                            fixedDecimalScale={true}
                            onValueChange={({ floatValue }) => {
                              props.setFieldValue(
                                "amountAnimals",
                                floatValue
                                  ? floatValue > 9999
                                    ? 9999
                                    : floatValue
                                  : null
                              );
                            }}
                          />
                        </Row>
                      </Col>
                      {/* breedId */}
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Row>
                          <label
                            className={
                              props.errors.breedId && props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            Raça*
                          </label>
                        </Row>
                        <Row>
                          <Select
                            name="breedId"
                            value={
                              props.values.breedId
                                ? props.values.breedId
                                : undefined
                            }
                            placeholder={translation.defaultSelectPlaceholder}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) => {
                              props.setFieldValue("breedId", value);
                              props.setFieldValue("dietStrategyId", null);
                              props.setFieldValue("mixedMilk", false);
                              filterDietStrategies(value, props.values.gender);
                            }}
                          >
                            {breeds.map((b) => (
                              <Select.Option key={b.id} value={b.id}>
                                {b.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Row>
                      </Col>
                      {/* Mestiços de leite */}
                      {props.values.breedId === ID_BREED_COMPOSTO && (
                        <Col xs={24} sm={24} md={8} lg={4} xl={4}>
                          <Row>
                            <label
                              className={
                                props.errors.mixedMilk && props.submitCount > 0
                                  ? "formLabel error"
                                  : "formLabel"
                              }
                            >
                              Mestiços de leite*
                            </label>
                          </Row>
                          <Row>
                            <Radio.Group
                              name="mixedMilk"
                              style={{ marginTop: 5 }}
                              value={props.values?.mixedMilk}
                              onChange={(e) => {
                                props.setFieldValue(
                                  "mixedMilk",
                                  e.target.value
                                );
                                if (
                                  !haveChangePriceSimulation ||
                                  props.values.priceSimulation === null
                                ) {
                                  setHaveChangePriceSimulation(false);
                                  props.setFieldValue(
                                    "priceSimulation",
                                    e.target.value
                                      ? allWeightRanges.find(
                                          (w) =>
                                            w.id ===
                                            props.values
                                              ?.boitelPriceValidityWeightRangeId
                                        )?.dailyValueMixedMilk
                                      : allWeightRanges.find(
                                          (w) =>
                                            w.id ===
                                            props.values
                                              ?.boitelPriceValidityWeightRangeId
                                        )?.dailyValueCut
                                  );
                                }
                              }}
                            >
                              <Radio value={true}>
                                {translation.buttons.yes}
                              </Radio>
                              <Radio value={false}>
                                {translation.buttons.no}
                              </Radio>
                            </Radio.Group>
                          </Row>
                        </Col>
                      )}
                      {/* Gender */}
                      <Col xs={24} sm={24} md={8} lg={4} xl={4}>
                        <Row>
                          <label
                            className={
                              props.errors.gender && props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            Gênero*
                          </label>
                        </Row>
                        <Row>
                          <Select
                            name="gender"
                            value={props.values.gender}
                            placeholder={translation.defaultSelectPlaceholder}
                            onChange={(value) => {
                              props.setFieldValue("gender", value);
                              props.setFieldValue("dietStrategyId", null);
                              filterDietStrategies(props.values.breedId, value);
                            }}
                          >
                            <Select.Option value="Male">Macho</Select.Option>
                            <Select.Option value="Female">Fêmea</Select.Option>
                          </Select>
                        </Row>
                      </Col>
                      {/* negotiatedAgeInMonths  */}
                      <Col xs={24} sm={24} md={8} lg={4} xl={4}>
                        <Row>
                          <label
                            className={
                              props.errors.negotiatedAgeInMonths &&
                              props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            Idade (Meses)*
                          </label>
                        </Row>
                        <Row>
                          <NumberFormat
                            customInput={Input}
                            value={
                              props.values?.negotiatedAgeInMonths !== null
                                ? props.values?.negotiatedAgeInMonths
                                : ""
                            }
                            placeholder={translation.defaultPlaceholder}
                            name="negotiatedAgeInMonths"
                            addonAfter="Mêses"
                            decimalScale={0}
                            onValueChange={({ floatValue }) => {
                              props.setFieldValue(
                                "negotiatedAgeInMonths",
                                floatValue ? floatValue : null
                              );
                            }}
                          />
                        </Row>
                      </Col>
                    </Row>
                    <Row type="flex" justify="start" gutter={8}>
                      {/* dietStrategyId */}
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Row>
                          <label
                            className={
                              props.errors.dietStrategyId &&
                              props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            Estratégia de dieta*
                          </label>
                        </Row>
                        <Row>
                          <Select
                            name="dietStrategyId"
                            value={
                              props.values.dietStrategyId
                                ? props.values.dietStrategyId
                                : undefined
                            }
                            placeholder={translation.defaultSelectPlaceholder}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) => {
                              props.setFieldValue("dietStrategyId", value);
                            }}
                          >
                            {dietStrategies.map((d) => (
                              <Select.Option key={d.id} value={d.id}>
                                {d.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Row>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <Row>
                          <label className="formLabel">
                            Informar novo % de Aprov. de Carcaça?{" "}
                            <InfoTooltip title="Por padrão, será utilizado o % de Aproveitamento de Carcaça da Estratégia de Dieta selecionada. Nesta opção, poderá informar um novo % a ser considerado neste Cenário." />
                          </label>
                        </Row>
                        <Row>
                          <RadioGroup
                            name="useCustomCarcassHarnessing"
                            value={props.values?.useCustomCarcassHarnessing}
                            onChange={(e) => {
                              props.setFieldValue(
                                "useCustomCarcassHarnessing",
                                e.target.value
                              );
                              if (e.target.value === false) {
                                props.setFieldValue("carcassHarnessing", null);
                              }
                            }}
                          >
                            <Radio value={true}>
                              {translation.buttons.yes}
                            </Radio>
                            <Radio value={false}>
                              {translation.buttons.no}
                            </Radio>
                          </RadioGroup>
                        </Row>
                      </Col>
                      {/* carcassHarnessing */}
                      {props.values?.useCustomCarcassHarnessing && (
                        <Col xs={24} sm={24} md={8} lg={4} xl={4}>
                          <Row>
                            <label
                              className={
                                props.errors.carcassHarnessing &&
                                props.submitCount > 0
                                  ? "formLabel error"
                                  : "formLabel"
                              }
                            >
                              Aprov. carcaça*
                            </label>
                          </Row>
                          <Row>
                            <NumberFormat
                              customInput={Input}
                              value={
                                props.values?.carcassHarnessing !== null
                                  ? props.values?.carcassHarnessing
                                  : ""
                              }
                              placeholder={translation.defaultPlaceholder}
                              name="carcassHarnessing"
                              addonAfter="%"
                              decimalScale={2}
                              decimalSeparator=","
                              thousandSeparator="."
                              fixedDecimalScale={true}
                              onValueChange={({ floatValue }) => {
                                props.setFieldValue(
                                  "carcassHarnessing",
                                  floatValue
                                    ? floatValue < 100
                                      ? floatValue
                                      : 100
                                    : null
                                );
                              }}
                            />
                          </Row>
                        </Col>
                      )}
                      {/* expectedMargin  */}
                      <Col xs={24} sm={24} md={8} lg={4} xl={4}>
                        <Row>
                          <label
                            className={
                              props.errors.expectedMargin &&
                              props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            Lucro Esperado Cliente*
                          </label>
                        </Row>
                        <Row>
                          <NumberFormat
                            customInput={Input}
                            value={
                              props.values?.expectedMargin !== null
                                ? props.values?.expectedMargin
                                : ""
                            }
                            placeholder={translation.defaultPlaceholder}
                            name="expectedMargin"
                            addonAfter="%"
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            fixedDecimalScale={true}
                            onValueChange={({ floatValue }) => {
                              props.setFieldValue(
                                "expectedMargin",
                                floatValue
                                  ? floatValue < 100
                                    ? floatValue
                                    : 100
                                  : null
                              );
                            }}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </CollapseContainer.Panel>
                </CollapseContainer>
              </Body>
              <Footer>
                <Row type="flex" justify="space-between">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row type="flex" justify="end" gutter={24}>
                      <Col>
                        <ButtonStandard
                          type="button"
                          buttonType="secondary"
                          onClick={handleClearFields}
                        >
                          Cancelar
                        </ButtonStandard>
                      </Col>
                      <Col>
                        <ButtonStandard type="submit" buttonType="principal">
                          Simular
                        </ButtonStandard>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Footer>
            </Spin>
          </form>
        )}
      />
    </Container>
  );
};

export default NewEditBoitelScenarioForm;
