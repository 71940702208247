import { Formik } from "formik";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Creators as FarmActions } from "../../../store/ducks/farm";
/* Components */
import {
  Checkbox,
  Col,
  Divider,
  Icon,
  Input,
  notification,
  Radio,
  Row,
  Select,
  Spin,
} from "antd";
import imagePlaceHolder from "../../../assets/images/image_place_holder.png";
import ButtonStandard from "../../utils/button";
import InfoTooltip from "../../utils/infoTooltip";
import DocumentInput from "../../utils/input/documentInput";
import ZipCodeInput from "../../utils/input/zipCodeInput";
import RadioButton from "../../utils/radioButton";
import { Container, Footer, Table } from "./styles";

// Icons
import PencilIcon from "../../utils/icons/pencil";
import PlusBlueIcon from "../../utils/icons/plus/blue";
import AlertIcon from "../alert/icons/alert";
import SuccessIcon from "../pasture/displayPastureSeason/icons/success";

/* Services */
import { getCitiesByState, getStatesInfo } from "../../../services/cityService";
import {
  getCep,
  validateCNPJ,
  validateCPF,
} from "../../../services/helpersMethodsService";
import { findAll as findAllWeatherStations } from "../../../services/weatherStationService";
import HectareInput from "../../utils/input/hectareInput";
import FarmOwner from "../farmOwner";
import useModuleContext from "../../../hooks/useModuleContext";

const DrawerFarm = () => {
  const { module } = useModuleContext();

  const [initialCountries, setInitialCountries] = useState([]);

  const [initialStates, setInitialStates] = useState([]);

  const [initialCities, setInitialCities] = useState([]);

  const [formFarm, setFormFarm] = useState({
    id: null,
    name: null,
    companyName: "",
    document: "",
    documentType: "CPF",
    zipCode: null,
    address: "",
    state: null,
    city: null,
    country: null,
    status: "Active",
    logo: null,
    totalArea: 0,
    livestockArea: 0,
    cultivationArea: 0,
    improvementsArea: 0,
    nonProductiveArea: 0,
    rentAreaOutSourced: 0,
    checkVisibleForAllSystem: false,
    boitel: false,
    boitelVisibleToAllSystem: false,
    confinement: false,
    farmSisbov: false,
    weatherStationId: null,
  });
  const [isLoadingCityStateCountry, setIsLoadingCityStateCountry] =
    useState(false);
  const [loadingCep, setLoadingCep] = useState(false);
  const [zipCodeValid, setZipCodeValid] = useState(null);
  const [statesArray, setStatesArray] = useState([]);
  const [citiesArray, setCitiesArray] = useState([]);
  const [countriesArray, setCountriesArray] = useState([]);
  const [weatherStationArray, setWeatherStationArray] = useState([]);
  const [saveOptions, setSaveOptions] = useState("only_save");
  const [imageUrl, setImageUrl] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [drawerOwnersVisible, setDrawerOwnersVisible] = useState(false);

  const inputFileRef = useRef(null);
  const formik = useRef(null);

  const {
    app: {
      translation,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
    farm: { farmData, drawerVisible, isLoadingRequest, owners, isOwnersValid },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    zipCode: Yup.string().min(8).max(9),
    weatherStationId: Yup.string().required(),
  });

  const { Option } = Select;

  const { Group: RadioGroup } = Radio;

  const closeDrawer = () => {
    setFormFarm({
      id: null,
      name: null,
      companyName: "",
      document: "",
      documentType: "CPF",
      zipCode: "",
      address: "",
      state: null,
      city: null,
      country: null,
      status: "Active",
      logo: null,
      totalArea: 0,
      livestockArea: 0,
      cultivationArea: 0,
      improvementsArea: 0,
      nonProductiveArea: 0,
      rentAreaOutSourced: 0,
      checkVisibleForAllSystem: false,
      boitel: false,
      boitelVisibleToAllSystem: false,
      confinement: false,
      farmSisbov: false,
    });
    setZipCodeValid(null);
    setImageUrl(null);
    setLogoFile(null);
    setIsLoadingCityStateCountry(false);
    setDrawerOwnersVisible(false);
    dispatch(FarmActions.hideDrawer());
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleSubmitModalForm = async (
    values,
    { setSubmitting, setErrors, setStatus, resetForm }
  ) => {
    if (
      values.document !== null &&
      values.document.trim() !== "" &&
      values.documentType === "CNPJ" &&
      !validateCNPJ(values.document)
    ) {
      notification.error({
        message: "CNPJ incorreto !",
        description: "Por favor, informe um CNPJ válido.",
      });
    } else if (
      values.document !== null &&
      values.document.trim() !== "" &&
      values.documentType === "CPF" &&
      !validateCPF(values.document)
    ) {
      notification.error({
        message: "CPF incorreto !",
        description: "Por favor, informe um CPF válido.",
      });
    } else if (!isOwnersValid) {
      notification.error({
        message: "Proprietários inválidos !",
        description: "Participações dos proprietários inválidas.",
      });
    } else if (values.weatherStationId == null) {
      notification.error({
        message: "Estação Metereológica inválida !",
        description: "Por favor, informe um Estação válida.",
      });
    } else {
      values.logoFile = logoFile;
      if (values.id === null) {
        await handleNewFarm(values);
      } else {
        await handleUpdateFarm(values, values.id);
      }
    }
  };

  const handleNewFarm = async (farm) => {
    try {
      const body = { ...farm, owners };
      const isSaveOptionsCreateNew = saveOptions === "only_save" ? false : true;

      dispatch(
        FarmActions.saveFarm(groupId, null, body, isSaveOptionsCreateNew)
      );
      setSaveOptions("only_save");
    } catch (error) {}
  };

  const handleUpdateFarm = async (farm, id) => {
    try {
      const body = { ...farm, owners };
      const isSaveOptionsCreateNew = saveOptions === "only_save" ? false : true;
      dispatch(FarmActions.saveFarm(groupId, id, body, isSaveOptionsCreateNew));
      setSaveOptions("only_save");
    } catch (error) {}
  };

  const handleCityChange = async (cityName) => {
    const city = initialCities.filter((s) => s.name === cityName);
    let station = null;

    try {
      if (city != null) {
        station = weatherStationArray.find(
          (ws) => ws.nameIbge.toLowerCase() === cityName.toLowerCase()
        )?.id;
      }
      formik.current.setFieldValue("weatherStationId", station);
    } catch (error) {
      console.error(error);
    }
  };

  const handleZipCodeSearch = async (zipCode, props) => {
    if (zipCode.length === 9) {
      setZipCodeValid(false);
      setLoadingCep(true);
      try {
        const {
          data: {
            erro,
            logradouro: street,
            uf: state,
            localidade: city,
            complemento: complementAddress,
          },
        } = await getCep(zipCode);
        if (erro) {
          setZipCodeValid(false);
          setLoadingCep(false);
        } else {
          const countries = initialCountries.filter((c) => c.name === "Brasil");
          const states = initialStates.filter((s) => s.name === state);

          const { data } = await getCitiesByState(states[0].id);

          if (data) {
            const cities = data.results.filter(
              (c) => c.name.toLowerCase() === city.toLowerCase()
            );
            setCitiesArray(data.results);
            props.setFieldValue("city", cities[0].name);
            handleCityChange(cities[0].name);
          }

          props.setFieldValue("address", street);
          props.setFieldValue("state", states[0].name);
          props.setFieldValue("complementAddress", complementAddress);
          props.setFieldValue("country", countries[0].name);

          setZipCodeValid(true);
          setLoadingCep(false);
        }
      } catch (error) {
        setZipCodeValid(false);
        setLoadingCep(false);
      }
    } else {
      setZipCodeValid(false);
      setLoadingCep(false);
    }
  };

  const handleDrawerVisible = async (visible) => {
    if (visible) {
      setIsLoadingCityStateCountry(true);
      const {
        data: { results: stations },
      } = await findAllWeatherStations({ groupId, farmId });

      const { data } = await getStatesInfo();

      setIsLoadingCityStateCountry(false);
      setWeatherStationArray(stations);

      if (data) {
        setInitialStates(data.results);
        setInitialCountries([
          {
            id: data.results[0].countryId,
            name: data.results[0].countryName,
          },
        ]);
        setStatesArray(initialStates);
        setCountriesArray(initialCountries);

        if (farmData) {
          const filteredStates = initialStates.filter(
            (s) => s.name === farmData.state
          );

          if (filteredStates && filteredStates.length > 0) {
            const { data } = await getCitiesByState(filteredStates[0].id);
            if (data) {
              setCitiesArray(data.results);
            }
          }
        }
      }
    } else {
      formik.current.resetForm({
        id: null,
        name: null,
        companyName: "",
        document: "",
        documentType: "CPF",
        zipCode: "",
        address: "",
        state: null,
        city: null,
        country: null,
        status: "Active",
        logo: null,
        totalArea: 0,
        livestockArea: 0,
        cultivationArea: 0,
        improvementsArea: 0,
        nonProductiveArea: 0,
        rentAreaOutSourced: 0,
        checkVisibleForAllSystem: false,
        boitel: false,
        boitelVisibleToAllSystem: false,
        confinement: false,
        farmSisbov: false,
      });
      setFormFarm({
        id: null,
        name: null,
        companyName: "",
        document: "",
        documentType: "CPF",
        zipCode: "",
        address: "",
        state: null,
        city: null,
        country: null,
        status: "Active",
        logo: null,
        totalArea: 0,
        livestockArea: 0,
        cultivationArea: 0,
        improvementsArea: 0,
        nonProductiveArea: 0,
        rentAreaOutSourced: 0,
        checkVisibleForAllSystem: false,
        boitel: false,
        boitelVisibleToAllSystem: false,
        confinement: false,
        farmSisbov: false,
      });
      setZipCodeValid(null);
      setImageUrl(null);
      setLogoFile(null);
      setIsLoadingCityStateCountry(false);
      setDrawerOwnersVisible(false);

      inputFileRef.current.value = "";
    }
  };

  const handleCountryChange = (countryName) => {
    const country = initialCountries.filter((c) => c.name === countryName);
    const filteredStates = initialStates.filter(
      (s) => s.countryId === country[0].id
    );
    setStatesArray(filteredStates);
  };

  const handleStateChange = async (stateName) => {
    const state = initialStates.filter((s) => s.name === stateName);

    const { data } = await getCitiesByState(state[0].id);

    if (data) {
      setCitiesArray(data.results);
    }
    formik.current.setFieldValue("city", undefined);
  };

  const handleCalculateTotalArea = (props, field, currentValue) => {
    let {
      livestockArea,
      cultivationArea,
      improvementsArea,
      nonProductiveArea,
      rentAreaOutSourced,
    } = props.values;
    switch (field) {
      case "livestockArea":
        livestockArea = currentValue;
        break;
      case "cultivationArea":
        cultivationArea = currentValue;
        break;
      case "improvementsArea":
        improvementsArea = currentValue;
        break;
      case "nonProductiveArea":
        nonProductiveArea = currentValue;
        break;
      case "rentAreaOutSourced":
        rentAreaOutSourced = currentValue;
        break;
      default:
        break;
    }
    const totalArea =
      livestockArea +
      cultivationArea +
      improvementsArea +
      nonProductiveArea +
      rentAreaOutSourced;
    props.setFieldValue("totalArea", totalArea);
  };

  const handleAvatarBeforeUpload = ({ target: { files } }) => {
    const file = files[0];
    if (file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        notification.error({ message: "You can only upload JPG/PNG file!" });
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        notification.error({ message: "Image must smaller than 2MB!" });
        return;
      }
      if (isJpgOrPng && isLt2M) {
        getBase64(file, (imageUrl) => {
          setImageUrl(imageUrl);
          setLogoFile(file);
        });
        inputFileRef.current.value = "";
      }
    }
  };

  const handleShowOwnersDrawer = () => {
    setDrawerOwnersVisible(true);
  };

  const handleCloseOwnersDrawer = () => {
    setDrawerOwnersVisible(false);
  };

  return (
    <Container
      title={
        farmData != null
          ? translation.farm.drawerTitleEdit
          : translation.farm.drawerTitle
      }
      width={511}
      onClose={closeDrawer}
      maskClosable={false}
      visible={drawerVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <Spin spinning={isLoadingCityStateCountry || isLoadingRequest}>
        <Formik
          ref={formik}
          enableReinitialize={true}
          initialValues={farmData != null ? farmData : formFarm}
          initialErrors={{}}
          onSubmit={handleSubmitModalForm}
          validationSchema={validationSchema}
          render={(props) => (
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <div className="drawerForm">
                <Row type="flex">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <span style={{ fontSize: 14, color: "#000" }}>
                      {farmData != null
                        ? translation.farm.drawerSubTitleEdit
                        : translation.farm.drawerSubTitle}
                    </span>
                  </Col>
                </Row>
                {Object.entries(props.errors).length > 0 &&
                  props.submitCount > 0 && (
                    <Row type="flex" justify="center" align="middle">
                      <label className="error">
                        {translation.error.formError}
                      </label>
                    </Row>
                  )}
                {/* Input logo */}
                <Row type="flex" align="middle">
                  <label>{translation.farm.form.logo}</label>
                </Row>
                <Row type="flex" align="middle">
                  <Col span={24}>
                    <div className="personal-image">
                      <label className="label">
                        <input
                          name="logo"
                          type="file"
                          ref={inputFileRef}
                          accept="image/png, image/jpeg"
                          onChange={handleAvatarBeforeUpload}
                        />
                        <figure className="personal-figure">
                          <figcaption className="personal-figcaption-with-image">
                            <img
                              src="https://raw.githubusercontent.com/ThiagoLuizNunes/angular-boilerplate/master/src/assets/imgs/camera-white.png"
                              alt="camera"
                            />
                          </figcaption>
                          {props.values.logo !== null && imageUrl === null ? (
                            <img
                              src={props.values.logo}
                              alt="avatar"
                              className="personal-avatar"
                            />
                          ) : imageUrl ? (
                            <img
                              src={imageUrl}
                              alt="avatar"
                              className="personal-avatar"
                            />
                          ) : (
                            <img
                              src={imagePlaceHolder}
                              className="personal-avatar"
                              alt="Logo"
                            />
                          )}
                        </figure>
                      </label>
                    </div>
                  </Col>
                </Row>
                {/* Input companyName */}
                <Row type="flex" style={{ marginTop: 13 }} align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <label>{translation.farm.form.companyName}</label>
                    </Row>
                    <Row>
                      <Input
                        placeholder={
                          translation.farm.form.companyNamePlaceHolder
                        }
                        value={props.values.companyName}
                        name="companyName"
                        autoComplete="chrome-off"
                        onChange={(e) => {
                          props.setFieldValue("companyName", e.target.value);
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                {/* Input farm */}
                <Row type="flex" style={{ marginTop: 13 }} align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <label
                        className={
                          props.errors.name && props.submitCount > 0
                            ? "error"
                            : ""
                        }
                      >
                        {translation.farm.form.name}*
                      </label>
                    </Row>
                    <Row>
                      <Input
                        name="name"
                        placeholder={translation.farm.form.namePlaceHolder}
                        autoComplete="chrome-off"
                        value={props.values.name}
                        type="text"
                        onChange={(e) => {
                          props.setFieldValue("name", e.target.value);
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                {/* Input document */}
                <Row type="flex" style={{ marginTop: 13 }} align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <label
                        className={
                          props.errors.document && props.submitCount > 0
                            ? "error"
                            : ""
                        }
                      >
                        {translation.supplier.form.document}
                      </label>
                    </Row>
                    <Row>
                      <DocumentInput
                        kind="mixed"
                        placeholder={
                          translation.supplier.form.documentPlaceHolder
                        }
                        value={props.values.document}
                        name="document"
                        onChange={(e) => {
                          let value = e.target.value
                            ?.replace(/\./g, "")
                            .replace(/\//g, "")
                            .replace(/-/g, "");
                          props.setFieldValue("document", value);
                          props.setFieldValue(
                            "documentType",
                            value.length <= 11 ? "CPF" : "CNPJ"
                          );
                        }}
                      />
                    </Row>
                  </Col>
                </Row>

                <Divider>{translation.farm.form.farmOwner}</Divider>

                {/* Proprietários */}
                <Row style={{ marginTop: "13px" }}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Table>
                      <tbody>
                        <tr>
                          <td>
                            <span>{translation.farm.form.farmOwner}*</span>
                          </td>
                          <td>
                            {owners === null ||
                            owners === undefined ||
                            owners?.length === 0 ||
                            !isOwnersValid ? (
                              <span>
                                <AlertIcon /> {translation.status.pendingData}
                              </span>
                            ) : (
                              <span className="success">
                                <SuccessIcon />{" "}
                                {translation.status.informedData}
                              </span>
                            )}
                          </td>
                          <td>
                            {owners === null ||
                            owners === undefined ||
                            owners?.length === 0 ? (
                              <button
                                className="add"
                                type="button"
                                onClick={handleShowOwnersDrawer}
                              >
                                {translation.buttons.addInfo}
                                <PlusBlueIcon />
                              </button>
                            ) : (
                              <button
                                className="edit"
                                type="button"
                                style={{ marginLeft: "60px" }}
                                onClick={handleShowOwnersDrawer}
                              >
                                {translation.buttons.edit}
                                <PencilIcon />
                              </button>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>

                {/* Confinamento / Boitel */}
                <Row
                  type="flex"
                  style={{
                    marginTop: 13,
                  }}
                  align="middle"
                >
                  {/* Input confinement */}
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Row>
                      <label>{translation.farm.form.confinement}</label>
                    </Row>
                    <Row>
                      <RadioGroup
                        name="confinement"
                        value={props.values?.confinement}
                        defaultValue={false}
                        onChange={(e) => {
                          props.setFieldValue("confinement", e.target.value);
                          if (!e.target.value) {
                            props.setFieldValue("boitel", false);
                          }
                        }}
                      >
                        <RadioButton value={true}>Sim</RadioButton>
                        <RadioButton value={false}>Não</RadioButton>
                      </RadioGroup>
                    </Row>
                  </Col>

                  {/* Input boitel */}
                  {props.values?.confinement && module === "AVANCADO" && (
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Row>
                        <label>{translation.farm.form.boitel}</label>
                      </Row>
                      <Row>
                        <RadioGroup
                          name="boitel"
                          value={props.values?.boitel}
                          defaultValue={false}
                          onChange={(e) => {
                            props.setFieldValue("boitel", e.target.value);
                            if (!e.target.value) {
                              props.setFieldValue(
                                "boitelVisibleToAllSystem",
                                false
                              );
                            }
                          }}
                        >
                          <RadioButton value={true}>Sim</RadioButton>
                          <RadioButton value={false}>Não</RadioButton>
                        </RadioGroup>
                      </Row>
                    </Col>
                  )}
                </Row>
                {props.values?.boitel && (
                  <Row type="flex" style={{ marginTop: 13 }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row></Row>
                      <Row>
                        <Checkbox
                          name="boitelVisibleToAllSystem"
                          checked={props.values?.boitelVisibleToAllSystem}
                          onChange={() =>
                            props.setFieldValue(
                              "boitelVisibleToAllSystem",
                              !props.values?.boitelVisibleToAllSystem
                            )
                          }
                          style={{
                            color: "#4B4B4B",
                            fontWeight: "normal",
                            fontSize: 14,
                            textTransform: "none",
                            marginTop: 10,
                          }}
                        >
                          {translation.farm.form.boitelVisibleToAllSystem}
                        </Checkbox>
                      </Row>
                    </Col>
                  </Row>
                )}
                {/* Input add farm client */}
                <Row type="flex" style={{ marginTop: 13 }} align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Checkbox
                      checked={props.values.checkVisibleForAllSystem}
                      onChange={(e) =>
                        props.setFieldValue(
                          "checkVisibleForAllSystem",
                          e.target.checked
                        )
                      }
                      style={{
                        color: "#4B4B4B",
                        fontWeight: "normal",
                        fontSize: 14,
                        textTransform: "none",
                        marginTop: 10,
                      }}
                    >
                      {translation.farm.form.checkVisibleForAllSystem}
                    </Checkbox>
                  </Col>
                </Row>
                {/* Confinamento / Boitel */}
                <Row
                  type="flex"
                  style={{
                    marginTop: 13,
                  }}
                  align="middle"
                >
                  {/* Input farm sisbov */}
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <label style={{ marginRight: 5 }}>
                        {translation.farm.form.farmSisbov}{" "}
                        <InfoTooltip
                          title={translation.farm.form.farmSisbovInfoTooltip}
                        />
                      </label>
                    </Row>
                    <Row>
                      <RadioGroup
                        name="farmSisbov"
                        value={props.values?.farmSisbov}
                        defaultValue={false}
                        onChange={(e) => {
                          props.setFieldValue("farmSisbov", e.target.value);
                        }}
                      >
                        <RadioButton value={true}>
                          {translation.buttons.yes}
                        </RadioButton>
                        <RadioButton value={false}>
                          {translation.buttons.no}
                        </RadioButton>
                      </RadioGroup>
                    </Row>
                  </Col>
                </Row>

                <Divider />

                {/* Input zipcode */}
                <Row type="flex" style={{ marginTop: 13 }} align="middle">
                  <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                    <Row>
                      <label
                        className={
                          props.errors.zipCode && props.submitCount > 0
                            ? "error"
                            : ""
                        }
                      >
                        {translation.farm.form.zipCode}*
                      </label>
                    </Row>
                    <Row>
                      <ZipCodeInput
                        placeholder={translation.farm.form.zipCodePlaceHolder}
                        type="text"
                        value={props.values.zipCode}
                        name="zipCode"
                        onChange={(e) => {
                          props.setFieldValue("zipCode", e.target.value);
                        }}
                        autoComplete="chrome-off"
                        onBlur={(e) =>
                          handleZipCodeSearch(e.target.value, props)
                        }
                      />
                    </Row>
                  </Col>
                  <Col xs={14} sm={14} md={14} lg={14} xl={14} push={1}>
                    {loadingCep === true ? (
                      <Row style={{ marginTop: 18 }}>
                        <span
                          style={{
                            color: "#4b4b4b",
                            fontWeight: "bold",
                          }}
                        >
                          <Icon type="loading" style={{ marginRight: 10 }} />
                          Processando
                        </span>
                      </Row>
                    ) : zipCodeValid != null && zipCodeValid === true ? (
                      <Row style={{ marginTop: 18 }}>
                        <span
                          style={{
                            color: "#33C162",
                            fontWeight: "bold",
                          }}
                        >
                          <Icon
                            type="check-circle"
                            style={{
                              marginRight: 10,
                              color: "#33C162",
                              fontSize: 12,
                            }}
                          />
                          {translation.zipCodeValid}
                        </span>
                      </Row>
                    ) : zipCodeValid != null && zipCodeValid === false ? (
                      <Row style={{ marginTop: 18 }}>
                        <span style={{ color: "#D44C4C", fontWeight: "bold" }}>
                          <Icon
                            type="close-circle"
                            style={{
                              marginRight: 10,
                              color: "#D44C4C",
                              fontSize: 12,
                            }}
                          />
                          {translation.zipCodeInvalid}
                        </span>
                      </Row>
                    ) : null}
                  </Col>
                </Row>
                {/* Input address */}
                <Row type="flex" style={{ marginTop: 13 }} align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <label>{translation.farm.form.address}</label>
                    </Row>
                    <Row>
                      <Input
                        placeholder={translation.farm.form.addressPlaceHolder}
                        value={props.values.address}
                        name="address"
                        disabled={loadingCep}
                        autoComplete="chrome-off"
                        onChange={(e) => {
                          props.setFieldValue("address", e.target.value);
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                {/* Input complementAddress */}
                <Row type="flex" style={{ marginTop: 13 }} align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <label>{translation.farm.form.complementAddress}</label>
                    </Row>
                    <Row>
                      <Input.TextArea
                        placeholder={
                          translation.farm.form.complementAddressPlaceHolder
                        }
                        value={props.values.complementAddress}
                        name="complementAddress"
                        disabled={loadingCep}
                        maxLength={1000}
                        style={{
                          width: "100%",
                          height: 249,
                          padding: "10px 10px",
                        }}
                        onChange={(e) => {
                          props.setFieldValue(
                            "complementAddress",
                            e.target.value
                          );
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                {/* Input city and state, country */}
                <Row type="flex" style={{ marginTop: 13 }} align="middle">
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Row>
                      <label>{translation.supplier.form.country}</label>
                    </Row>
                    <Row>
                      <Select
                        placeholder={
                          translation.supplier.form.countryPlaceHolder
                        }
                        value={
                          props.values.country != null
                            ? props.values.country
                            : undefined
                        }
                        name="country"
                        disabled={loadingCep || zipCodeValid} //loadingCep}
                        onChange={(value) => {
                          props.setFieldValue("country", value);
                          handleCountryChange(value);
                        }}
                      >
                        {countriesArray.length > 0 &&
                          countriesArray.map((c, index) => (
                            <Option key={c.id} value={c.name}>
                              {c.name}
                            </Option>
                          ))}
                      </Select>
                    </Row>
                  </Col>
                  <Col xs={5} sm={5} md={5} lg={5} xl={5} offset={1}>
                    <Row>
                      <label>{translation.farm.form.state}</label>
                    </Row>
                    <Row>
                      <Select
                        placeholder={translation.farm.form.statePlaceHolder}
                        value={
                          props.values.state != null
                            ? props.values.state
                            : undefined
                        }
                        name="state"
                        disabled={
                          loadingCep ||
                          zipCodeValid ||
                          props.values.country == null
                        } //loadingCep}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          props.setFieldValue("state", value);
                          handleStateChange(value);
                        }}
                      >
                        {statesArray.length > 0 &&
                          statesArray.map((state) => (
                            <Option key={state.id} value={state.name}>
                              {state.name}
                            </Option>
                          ))}
                      </Select>
                    </Row>
                  </Col>
                  <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
                    <Row>
                      <label>{translation.supplier.form.city}</label>
                    </Row>
                    <Row>
                      <Select
                        placeholder={translation.supplier.form.cityPlaceHolder}
                        value={
                          props.values.city != null
                            ? props.values.city
                            : undefined
                        }
                        name="city"
                        disabled={
                          loadingCep ||
                          zipCodeValid ||
                          props.values.state == null
                        } //loadingCep}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          props.setFieldValue("city", value);
                          handleCityChange(value);
                        }}
                      >
                        {citiesArray.length > 0 &&
                          citiesArray.map((c) => (
                            <Option key={c.id} value={c.name}>
                              {c.name}
                            </Option>
                          ))}
                      </Select>
                    </Row>
                  </Col>
                </Row>
                <Divider />
                {/* Weather Station */}
                <Row type="flex" style={{ marginTop: 13 }} align="middle">
                  <Col span={24}>
                    <Row>
                      <label
                        className={
                          props.errors.weatherStationId && props.submitCount > 0
                            ? "error"
                            : ""
                        }
                      >
                        {translation.farm.form.weatherStation}*
                      </label>
                    </Row>
                    <Row>
                      <Select
                        placeholder={
                          translation.farm.form.weatherStationPlaceHolder
                        }
                        value={
                          props.values.weatherStationId != null
                            ? props.values.weatherStationId
                            : undefined
                        }
                        name="state"
                        disabled={loadingCep || props.values.city == null}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          props.setFieldValue("weatherStationId", value);
                        }}
                      >
                        {weatherStationArray.length > 0 &&
                          weatherStationArray.map((ws) => (
                            <Option key={ws.id} value={ws.id}>
                              {`(${ws.codeWmo}) - ${ws.nameIbge} (${ws.state})`}
                            </Option>
                          ))}
                      </Select>
                    </Row>
                  </Col>
                </Row>
                <Divider />
                {/* Input total area and rentAreaOutSourced */}
                <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Row type="flex">
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        className="colLabelForm"
                      >
                        <label>{translation.farm.form.totalArea}</label>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <HectareInput
                          name="totalArea"
                          disabled
                          value={props.values.totalArea || ""}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={14} lg={14} xl={14} offset={2}>
                    <Row type="flex">
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        className="colLabelForm"
                      >
                        <label>
                          {translation.farm.form.rentAreaOutSourced}
                        </label>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <HectareInput
                          name="rentAreaOutSourced"
                          value={props.values.rentAreaOutSourced || ""}
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue(
                              "rentAreaOutSourced",
                              floatValue
                            );
                            handleCalculateTotalArea(
                              props,
                              "rentAreaOutSourced",
                              floatValue
                            );
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* Input livestockArea and nonProductiveArea */}
                <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Row type="flex">
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        className="colLabelForm"
                      >
                        <label>{translation.farm.form.livestockArea}</label>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <HectareInput
                          name="livestockArea"
                          value={props.values.livestockArea || ""}
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue("livestockArea", floatValue);
                            handleCalculateTotalArea(
                              props,
                              "livestockArea",
                              floatValue
                            );
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={14} lg={14} xl={14} offset={2}>
                    <Row type="flex">
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        className="colLabelForm"
                      >
                        <label>{translation.farm.form.nonProductiveArea}</label>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <HectareInput
                          name="nonProductiveArea"
                          value={props.values.nonProductiveArea || ""}
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue(
                              "nonProductiveArea",
                              floatValue
                            );
                            handleCalculateTotalArea(
                              props,
                              "nonProductiveArea",
                              floatValue
                            );
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* Input cultivationArea and improvementsArea */}
                <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Row type="flex">
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        className="colLabelForm"
                      >
                        <label>{translation.farm.form.cultivationArea}</label>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <HectareInput
                          name="cultivationArea"
                          value={props.values.cultivationArea || ""}
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue("cultivationArea", floatValue);
                            handleCalculateTotalArea(
                              props,
                              "cultivationArea",
                              floatValue
                            );
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={14} lg={14} xl={14} offset={2}>
                    <Row type="flex">
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        className="colLabelForm"
                      >
                        <label>{translation.farm.form.improvementsArea}</label>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <HectareInput
                          name="improvementsArea"
                          value={props.values.improvementsArea || ""}
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue("improvementsArea", floatValue);
                            handleCalculateTotalArea(
                              props,
                              "improvementsArea",
                              floatValue
                            );
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              {/* Footer */}
              <Footer>
                <Row type="flex" justify="space-between">
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="selectDiv"
                  >
                    {props.values.id === null ? (
                      <Select
                        value={saveOptions}
                        name="saveOptions"
                        onChange={(value) => setSaveOptions(value)}
                      >
                        <Option key="1" value="only_save">
                          {translation.farm.saveOptions.only_save}
                        </Option>
                        <Option key="2 " value="create_new">
                          {translation.farm.saveOptions.save_and_create_new}
                        </Option>
                      </Select>
                    ) : null}
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="buttonsDiv"
                  >
                    <ButtonStandard
                      type="button"
                      buttonType="type7"
                      onClick={closeDrawer}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>

                    <ButtonStandard
                      buttonType="type6"
                      type="submit"
                      disabled={loadingCep}
                    >
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Footer>
            </form>
          )}
        />
      </Spin>

      <FarmOwner
        drawerVisible={drawerOwnersVisible}
        onClose={handleCloseOwnersDrawer}
      />
    </Container>
  );
};
export default DrawerFarm;
