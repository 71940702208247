import React, { createContext, useCallback, useState } from "react";
import { useSelector } from "react-redux";

// Services
import { findAllAnimalsForSell } from "../../services/farmSellService";

export const FarmAnimalSellContext = createContext({
  allAnimals: null,
  selectedAnimalsKeys: [],
  isLoadingAnimals: false,
});

export const FarmAnimalSellContextProvider = ({ children }) => {
  const [allAnimals, setAllAnimals] = useState(null);
  const [selectedAnimalsKeys, setSelectedAnimalsKeys] = useState([]);
  const [isLoadingAnimals, setIsLoadingAnimals] = useState(false);
  const [pagination, setPagination] = useState({
    page: 0,
    sorter: null,
    filters: null,
    size: 10,
    ids: null,
    tableSorters: null,
    tableFilters: null,
  });
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const getAnimals = useCallback(
    async (justIds = false) => {
      const { page, sorter, filters, size } = pagination;

      setIsLoadingAnimals(true);

      let newFilters = filters;

      try {
        const {
          data: { results },
        } = await findAllAnimalsForSell({
          groupId,
          farmId,
          page,
          sorter,
          filters: newFilters,
          size,
          withoutPagination: false,
          justIds,
        });

        setAllAnimals(results);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingAnimals(false);
      }
    },
    [farmId, groupId, pagination]
  );

  const updatePagination = useCallback((pagination) => {
    setPagination(pagination);
  }, []);

  const updateSelectedAnimalsKeys = useCallback(
    (newSelectedAnimalsKeys = [], newSelectedAnimalsData = []) => {
      setSelectedAnimalsKeys(newSelectedAnimalsKeys);
    },
    []
  );

  const switchIsLoadingAnimals = () => {
    setIsLoadingAnimals((old) => !old);
  };

  return (
    <FarmAnimalSellContext.Provider
      value={{
        allAnimals,
        selectedAnimalsKeys,
        isLoadingAnimals,
        pagination,
        updatePagination,
        getAnimals,
        updateSelectedAnimalsKeys,
        switchIsLoadingAnimals,
      }}
    >
      {children}
    </FarmAnimalSellContext.Provider>
  );
};
