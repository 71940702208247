import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import logo_image from "../../../../../assets/images/datagroFull.png";

import { Col, DatePicker, Icon, Row, Spin, Tooltip } from "antd";
import AlertIcon from "../../../../drawers/alert/icons/alert";
import { Container, Logo, Slide, SliderTrack } from "./styles";

// Services
import moment from "moment";
import { getDailyLiveCattleByDate } from "../../../../../services/dataAgroService";
import { numberMask } from "../../../../../services/helpersMethodsService";

// import { Container } from './styles';
//GO, MG, MT, MS, SP, RO, TO, BA e PA
const DataAgro = () => {
  const [minPriceDate, setMinPriceDate] = useState(null);
  const [searchDate, setSearchDate] = useState(moment());
  const [isLoading, setIsLoading] = useState(false);
  const [isObsolete, setIsObsolete] = useState(null);
  const [dataDate, setDataDate] = useState(null);
  const [dailyLiveCattles, setDailyLiveCattles] = useState([]);

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      try {
        const {
          data: { results },
        } = await getDailyLiveCattleByDate({
          groupId,
          farmId,
          searchDate: moment(searchDate).format("YYYY-MM-DD"),
        });
        const { isObsolete, datas, minimumSyncDateData, syncDateData } =
          results;

        const filterIndicatorData = datas.filter((r) =>
          r.code.includes("D_PEPR_")
        );

        setIsObsolete(isObsolete);
        setDataDate(syncDateData);
        setDailyLiveCattles(filterIndicatorData);
        setMinPriceDate(minimumSyncDateData);
      } catch (error) {
        console.error(error);
        setIsObsolete(true);
      } finally {
        setIsLoading(false);
      }
    }

    fetch();
  }, [groupId, farmId, searchDate]);

  return (
    <Container>
      <Logo>
        <Tooltip
          title={
            dataDate ? `Data: ${moment(dataDate).format("DD/MM/YYYY")}` : ""
          }
          placement="bottom"
        >
          <img src={logo_image} alt="Logo Data Agro" />
        </Tooltip>
        {isObsolete === true && (
          <div className="alert-icon">
            <AlertIcon />
          </div>
        )}
      </Logo>
      <Row type="flex" align="middle" gutter={8}>
        <Col>
          <strong>{translation.arrobaPrice.dataAgro.selectDate}</strong>
        </Col>
        <Col>
          <DatePicker
            value={searchDate}
            format={"DD/MM/YYYY"}
            placeholder={translation.defaultDatePickerPlaceholder}
            disabledDate={(date) =>
              minPriceDate
                ? !date.isBetween(
                    minPriceDate,
                    moment().add(1, "days"),
                    "days",
                    "()"
                  )
                : date.isAfter(moment())
            }
            allowClear={false}
            onChange={(date) => setSearchDate(date)}
          />
        </Col>
      </Row>
      <Spin spinning={isLoading}>
        <SliderTrack>
          {dailyLiveCattles.map((dlc) => (
            <Slide key={dlc.id}>
              <Icon
                type={dlc.var > 0 ? "caret-up" : "caret-down"}
                className={dlc.var > 0 ? "green" : "red"}
              />
              <span className="code">
                {translation.dailyLiveCattlesDataAgro.codes[dlc.code]}
              </span>
              <span
                className={dlc.var > 0 ? "value green" : "value red"}
              >{`${numberMask(dlc.ult, false)} (${
                dlc.var > 0 ? "+" : "-"
              } ${numberMask(Math.abs(dlc.var), false)}%)`}</span>
            </Slide>
          ))}
        </SliderTrack>
      </Spin>
    </Container>
  );
};

export default DataAgro;
