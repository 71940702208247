import styled from "styled-components";

export const MenuNavigationContainer = styled.div`
  width: 100%;

  padding: 0.5rem;
  display: flex;
  flex-direction: column;

  .button-rollback-navigation {
    all: unset;
    cursor: pointer;
  }

  .menu-level-container {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .principal-menu-container {
    min-width: 700px;
    height: 50px;
    padding-left: 1rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.25rem;

    .anticon {
      font-size: 1.25rem;
    }

    @media screen and (max-width: 576px) {
      gap: 0.25rem;
    }
  }

  .sub-menu-container {
    width: 100%;
    background: #f5f5f5;
    padding: 0.5rem 1rem;

    border-radius: 8px;

    display: flex;
    flex-direction: row;

    & > .menu-level-container {
      flex: 1;
    }

    .options-buttons {
      display: flex;
      align-items: center;
      gap: 1rem;

      .buttons-options {
        all: unset;
        position: relative;
        cursor: pointer;

        text-align: center;
        align-items: center;

        font-size: 2rem;
        font-weight: 700;

        color: #4b4b4b;
        border-radius: 5px;

        width: 45px;
        height: 45px;

        transition: all 0.5s ease;

        path,
        g {
          fill: #4b4b4b;
          stroke: #4b4b4b;
        }

        & > img {
          width: 30px;
          height: 30px;
        }

        &:hover {
          box-shadow: 0px 10px 5px -3px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .menu-navigation-divider {
    width: 2px !important;
    height: 2rem !important;
  }
`;

export const SearchDropDownMenu = styled.ul`
  display: none;
  opacity: 0;
  transition: opacity 1s linear;
  &.active {
    opacity: 1;
    display: flex;
    background: #f5f5f5;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 90%;
    width: 240px;
    border: none;
    border-radius: 5px;
    -webkit-box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    -moz-box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    z-index: 99999;
    li {
      display: flex;
      position: relative;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 100%;
      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        text-decoration: none;
        color: #a6bd32;
        &:hover {
          cursor: pointer;
          color: #82932a;
        }
      }
      button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        border: none;
        background: transparent;
        text-decoration: none;
        color: #a6bd32;
        height: 100%;
        cursor: pointer;

        svg {
          margin-left: 12px;
        }

        &:hover {
          color: #82932a;
        }
        &.active {
          color: #82932a;
        }
      }

      &:hover {
        cursor: pointer;
      }
      svg {
        margin-left: 12px;
      }

      &:last-child {
        margin-right: 10px;
        /* &:before {
        content: '|';
        color: #ebebf2;
        margin-right: 20px;
      } */
      }
      &.divider {
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        height: 29px !important;
        margin: 0 5px 0 25px;
      }
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin: 0;
      border-bottom: 1px solid #e8e8e8;
      color: #38383838 !important;
      height: 50px;

      &:last-child {
        margin: 0;
        border: none;
      }
      button {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: flex-start;
        color: #4b4b4b !important;
        border: none;
        font-size: 14px;
        padding-left: 13px;
        padding-right: 25px;
        &:hover {
          font-weight: bold;
        }
      }
      div {
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: #c4c4c4 !important;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        padding-left: 13px;
        padding-right: 25px;
        .title {
          flex: 1;
        }
        &:hover {
          cursor: auto;
        }
      }

      &.download {
        flex-direction: column;
        padding-right: 8px;
        button {
          justify-content: space-between;
        }
      }

      &.disable {
        &::before {
          content: "";
          width: 100%;
          height: 100%;
          z-index: 2;
          background: rgba(0, 0, 0, 0.5);
          cursor: not-allowed;

          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 768px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
    &.active {
      left: -200px;
    }
  }
`;
