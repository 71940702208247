import { Col, Modal, notification, Radio, Row, Select, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import PencilIcon from "../../../components/utils/icons/pencil";
import ButtonStandard from "../../../components/utils/button";
import { CardCustom, FarmDayScaleTreatmentParameterTable } from "./styles";
import { EditButton } from "../../reproductionParameters/cards/styles";
import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import {
  findFarmReadFeederTreatmentParameterData,
  saveFarmReadFeederTreatmentParameterData,
} from "../../../services/farmReadFeederTreatmentParameterService";

// import { Container } from './styles';

function FarmReadFeederTreatmentParameter() {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState([]);

  const [form, setForm] = useState({
    scale: null,
    scalePercents: {},
    readOnceADay: false,
    readAtEveryTreat: false,
    readInTreat: false,
    readTreats: [],
    readerReferenceType: null,
    applicationType: null,
  });

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const {
        data: { results },
      } = await findFarmReadFeederTreatmentParameterData({
        groupId,
        farmId,
      });
      setForm(results);
    } catch (error) {
      notification.error({
        message: "Erro ao pegar os parâmetros de Leitura de Cocho",
      });
    } finally {
      setIsLoading(false);
    }
  }, [farmId, groupId]);

  const calculateAdjustBasedOnScale = useCallback(
    (maxAdjust, prevValue, scale) => {
      return scale > 0
        ? prevValue - maxAdjust / scale
        : (prevValue - maxAdjust / scale) * -1;
    },
    []
  );

  const validateForm = useCallback((data) => {
    let errorsArray = [];
    if (data.scale === null || data.scale === 0) {
      errorsArray.push("scale");
      notification.error({
        message:
          "É necessário informar uma escala e especificar as porcentagens.",
      });
    }
    if (Object.entries(data.scalePercents).length > 0) {
      const hasScalesWithoutPercents = Object.entries(data.scalePercents).some(
        ([key, value]) =>
          key === "0"
            ? value === null || value === undefined
            : value === null || value === undefined || value === 0
      );
      if (hasScalesWithoutPercents) {
        errorsArray.push("scale");
        notification.error({
          message:
            "É necessário informar uma porcentagem para cada escala definida.",
        });
      }
    }
    if (data.applicationType === null) {
      errorsArray.push("applicationType");
    }
    if (data.readerReferenceType === null) {
      errorsArray.push("readerReferenceType");
    }
    if (
      data.readAtEveryTreat === false &&
      data.readOnceADay === false &&
      data.readInTreat === false
    ) {
      errorsArray.push("readFrequency");
    }
    if (
      data.readInTreat === true &&
      (data.readTreats == null || data.readTreats.length === 0)
    ) {
      errorsArray.push("readFrequency");
    }

    setErrors(errorsArray);

    return errorsArray.length === 0;
  }, []);

  const handleChangeScale = useCallback((scale) => {
    let scalePercents = [];
    for (let i = scale; i >= 0; i--) {
      scalePercents = {
        ...scalePercents,
        [i]: scalePercents[i] > 0 ? scalePercents[i] : 0,
      };
    }
    for (let i = 1; i <= scale; i++) {
      scalePercents = {
        ...scalePercents,
        [i * -1]: scalePercents[i * -1] < 0 ? scalePercents[i * -1] : 0,
      };
    }
    setForm((prev) => ({
      ...prev,
      scalePercents,
    }));
  }, []);

  const handleCompleteScalesBasedOnMaxAdjust = useCallback(() => {
    const scale = form.scale;
    const maxValue = form.scalePercents[scale];
    let prevValue = maxValue;

    let newPercents = {};

    if (maxValue && maxValue > 0) {
      Object.entries(form.scalePercents)
        .sort((entry1, entry2) => {
          const num1 = Number(entry1[0]);
          return num1 >= 0 ? -1 : 1; // Positives come before negatives
        })
        .forEach(([key, value], index) => {
          const numberKey = Number(key);
          if (index === 0) {
            newPercents = {
              ...newPercents,
              [key]: value,
            };
          } else if (index > 0 && numberKey > 0) {
            const newValue = calculateAdjustBasedOnScale(
              maxValue,
              prevValue,
              scale
            );
            prevValue = newValue;

            newPercents = {
              ...newPercents,
              [key]: newValue,
            };
          } else if (numberKey === 0) {
            newPercents = {
              ...newPercents,
              [key]: 0,
            };
          } else if (numberKey < 0) {
            newPercents = {
              ...newPercents,
              [key]: newPercents[String(numberKey * -1)],
            };
          }
        });
      setForm((prev) => ({
        ...prev,
        scalePercents: newPercents,
      }));
    }
  }, [calculateAdjustBasedOnScale, form.scale, form.scalePercents]);

  const handleValidateScalePercentsValue = useCallback(
    (key, value, index) => {
      if (value > 25) {
        Modal.confirm({
          centered: true,
          width: 600,
          content: (
            <h2>{`O valor da porcentagem da escala "${key}" ultrapassou 25%, deseja confirmar?`}</h2>
          ),
          okText: "Confirmar",
          onOk: () => {
            if (index === 0) {
              handleCompleteScalesBasedOnMaxAdjust();
            }
          },
          onCancel: () => {
            setForm((prev) => ({
              ...prev,
              scalePercents: {
                ...prev.scalePercents,
                [key]: null,
              },
            }));
          },
        });
      } else if (index === 0) {
        handleCompleteScalesBasedOnMaxAdjust();
      }
      return;
    },
    [handleCompleteScalesBasedOnMaxAdjust]
  );

  async function handleSubmit() {
    const isFormValid = validateForm(form);
    if (isFormValid) {
      setIsLoading(true);
      try {
        await saveFarmReadFeederTreatmentParameterData({
          groupId,
          farmId,
          body: form,
        });
        setIsEditing(false);
      } catch (error) {}
      setIsLoading(false);
    }
  }

  function handleCancel() {
    setIsEditing(false);
    setErrors([]);
    fetchData();
  }

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <CardCustom>
      <Spin spinning={isLoading}>
        <div className="body-container">
          <Row type="flex" justify="end" gutter={8}>
            {isEditing ? (
              <>
                <Col>
                  <ButtonStandard
                    type="submit"
                    name="buttonSaveFinancialTransactionsForm"
                    buttonType="principal"
                    onClick={handleSubmit}
                  >
                    {translation.buttons.save}
                  </ButtonStandard>
                </Col>
                <Col>
                  <ButtonStandard
                    name="buttonSaveFinancialTransactionsForm"
                    buttonType="secondary"
                    type="button"
                    onClick={handleCancel}
                  >
                    {translation.buttons.cancel}
                  </ButtonStandard>
                </Col>
              </>
            ) : (
              <Col>
                <EditButton
                  type="button"
                  onClick={() => setIsEditing((old) => !old)}
                  disabled={isEditing}
                >
                  {isEditing
                    ? `${translation.buttons.editing}...`
                    : `${translation.buttons.edit}`}
                  <PencilIcon />
                </EditButton>
              </Col>
            )}
          </Row>
          {/* Error message */}
          {errors.length > 0 && (
            <Row type="flex" justify="center" align="middle">
              <label className="error">{translation.error.formError}</label>
            </Row>
          )}
          <Row type="flex" gutter={32}>
            <Col span={6}>
              <FarmDayScaleTreatmentParameterTable>
                <tbody>
                  <tr>
                    <td>ESCALA</td>
                    <td className={isEditing ? "edit" : ""}>
                      <NumberFormat
                        disabled={!isEditing}
                        value={form.scale || ""}
                        decimalScale={0}
                        allowLeadingZeros={false}
                        allowNegative={false}
                        onValueChange={({ floatValue }) => {
                          setForm((prev) => ({
                            ...prev,
                            scale: floatValue,
                          }));
                        }}
                        onBlur={() => {
                          handleChangeScale(form.scale);
                        }}
                      />
                    </td>
                  </tr>
                  {Object.entries(form.scalePercents).length > 0 &&
                    Object.entries(form.scalePercents)
                      .sort((entry1, entry2) => {
                        const num1 = Number(entry1[0]);
                        return num1 >= 0 ? -1 : 1; // Positives come before negatives
                      })
                      .map(([scaleKey, scaleValue], index) => {
                        return (
                          <tr key={`${scaleKey}_${index}`}>
                            <td>{scaleKey}</td>
                            <td className={isEditing ? "edit" : ""}>
                              <NumberFormat
                                disabled={!isEditing}
                                value={scaleValue === null ? "" : scaleValue}
                                prefix={
                                  Number(scaleKey) > 0
                                    ? "+"
                                    : Number(scaleKey) < 0
                                    ? "-"
                                    : ""
                                }
                                suffix="%"
                                decimalScale={0}
                                allowLeadingZeros={true}
                                allowNegative={false}
                                onValueChange={({ floatValue }) => {
                                  setForm((prev) => ({
                                    ...prev,
                                    scalePercents: {
                                      ...prev.scalePercents,
                                      [scaleKey]: floatValue,
                                    },
                                  }));
                                }}
                                onBlur={() => {
                                  handleValidateScalePercentsValue(
                                    scaleKey,
                                    scaleValue,
                                    index
                                  );
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </FarmDayScaleTreatmentParameterTable>
            </Col>
            <Col className="body-container" span={18}>
              <Row type="flex" gutter={8} align="middle">
                <Col span={6}>
                  <label
                    className={errors.includes("readFrequency") ? "error" : ""}
                  >
                    Frequência de Leitura de Cocho:*
                  </label>
                </Col>
                <Col>
                  <Row type="flex" gutter={8} align="middle">
                    <Col>
                      <Radio
                        disabled={!isEditing}
                        checked={form.readOnceADay}
                        onChange={(e) =>
                          setForm((prev) => ({
                            ...prev,
                            readOnceADay: !prev.readOnceADay,
                            readAtEveryTreat: false,
                            readInTreat: false,
                            readTreats: [],
                          }))
                        }
                      >
                        1x ao dia
                      </Radio>
                    </Col>
                    <Col>
                      <Radio
                        disabled={!isEditing}
                        checked={form.readAtEveryTreat}
                        onChange={(e) =>
                          setForm((prev) => ({
                            ...prev,
                            readOnceADay: false,
                            readAtEveryTreat: !prev.readAtEveryTreat,
                            readInTreat: false,
                            readTreats: [],
                          }))
                        }
                      >
                        A cada Trato
                      </Radio>
                    </Col>
                    <Col>
                      <Radio
                        disabled={!isEditing}
                        checked={form.readInTreat}
                        onChange={(e) =>
                          setForm((prev) => ({
                            ...prev,
                            readOnceADay: false,
                            readAtEveryTreat: false,
                            readInTreat: !prev.readInTreat,
                            readTreats: [],
                          }))
                        }
                      >
                        Nos Tratos
                      </Radio>
                    </Col>
                    {form.readInTreat && (
                      <Col>
                        <Select
                          disabled={!isEditing}
                          style={{ width: 200 }}
                          mode="multiple"
                          value={form.readTreats}
                          placeholder={translation.defaultSelectPlaceholder}
                          allowClear
                          onChange={(value) => {
                            setForm((prev) => ({
                              ...prev,
                              readTreats: value,
                            }));
                          }}
                        >
                          <Select.Option value={"T1"}>T1</Select.Option>
                          <Select.Option value={"T2"}>T2</Select.Option>
                          <Select.Option value={"T3"}>T3</Select.Option>
                          <Select.Option value={"T4"}>T4</Select.Option>
                          <Select.Option value={"T5"}>T5</Select.Option>
                          <Select.Option value={"T6"}>T6</Select.Option>
                          <Select.Option value={"T7"}>T7</Select.Option>
                          <Select.Option value={"T8"}>T8</Select.Option>
                        </Select>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
              <Row type="flex" gutter={8} align="middle">
                <Col span={6}>
                  <label
                    className={
                      errors.includes("applicationType") ? "error" : ""
                    }
                  >
                    Ajuste da Quantidade deve aplicar-se:*
                  </label>
                </Col>
                <Col>
                  <Row>
                    <Radio
                      disabled={!isEditing}
                      checked={
                        form.applicationType === "apply_equal_for_all_treats"
                      }
                      onChange={(e) =>
                        setForm((prev) => ({
                          ...prev,
                          applicationType: "apply_equal_for_all_treats",
                        }))
                      }
                    >
                      Uniforme por todos tratos
                    </Radio>
                  </Row>
                  <Row>
                    <Radio
                      disabled={!isEditing}
                      checked={
                        form.applicationType === "concentrated_on_last_treat"
                      }
                      onChange={(e) =>
                        setForm((prev) => ({
                          ...prev,
                          applicationType: "concentrated_on_last_treat",
                        }))
                      }
                    >
                      Concentrada no último trato
                    </Radio>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Spin>
    </CardCustom>
  );
}

export default FarmReadFeederTreatmentParameter;
