import { Input } from "antd";
import React from "react";
import NumberFormat from "react-number-format";

const HectareInput = (props) => {
  return (
    <NumberFormat
      customInput={Input}
      placeholder={"0 ha"}
      allowNegative={false}
      defaultValue={0}
      addonAfter="ha"
      decimalScale={2}
      decimalSeparator=","
      thousandSeparator="."
      fixedDecimalScale={true}
      {...props}
    />
  );
};

export default HectareInput;
