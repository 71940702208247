import React, { useEffect, useState } from "react";

import moment from "moment";
import CanvasJSReact from "../../../../../assets/canvasjs.react";

import { Container } from "./styles";

// Modifica os separadores de milhar e decimal
CanvasJSReact.CanvasJS.addCultureInfo("br", {
  decimalSeparator: ",",
  digitGroupSeparator: ".",
});

const { CanvasJSChart } = CanvasJSReact;

const BalanceHidricBarChart = ({ chartData }) => {
  const [dataChart, setDataChart] = useState([]);

  useEffect(() => {
    let newChartData = [];

    if (chartData) {
      let baseChart = {
        type: "column",
        showInLegend: true,
        yValueFormatString: "#0.## mm",
        visible: true,
      };
      const chartDateHist = chartData.filter((data) => !data.futureValue);
      const chartDataFuture = chartData.filter((data) => data.futureValue);

      let dataPoints = [];

      baseChart.id = "accomplished";
      baseChart.name = "Calculado";
      baseChart.color = "#9074bf";
      dataPoints = chartDateHist.map((data) => ({
        label: data.label,
        y: data.balanceHidric,
        x: data.x * -1,
        toolTipContent: `Data: ${moment(data.refDate).format(
          "DD/MM/YYYY"
        )} <br /> <span style='\"'color: {color};'\"'>{name}:</span> <strong>{y}</strong>`,
      }));
      baseChart = { ...baseChart, dataPoints: dataPoints };
      newChartData.push(baseChart);

      // Future
      baseChart = {
        type: "column",
        showInLegend: true,
        yValueFormatString: "#0.## mm",
        visible: true,
      };

      dataPoints = [];

      baseChart.id = "future";
      baseChart.name = "Previsto";
      baseChart.color = "#FE8D2A";
      dataPoints = chartDataFuture.map((data) => ({
        label: data.label,
        y: data.balanceHidric,
        x: data.x,
        toolTipContent: `Data: ${moment(data.refDate).format(
          "DD/MM/YYYY"
        )} <br /> <span style='\"'color: {color};'\"'>{name}:</span> <strong>{y}</strong>`,
      }));
      baseChart = { ...baseChart, dataPoints: dataPoints };
      newChartData.push(baseChart);
    }

    setDataChart(newChartData);
  }, [chartData]);

  const options = {
    culture: "br",
    animationEnabled: true,
    zoomEnabled: true,
    exportEnabled: true,
    backgroundColor: "transparent",
    height: 200,
    axisY: {
      valueFormatString: "# mm",
      includeZero: true,
    },
    toolTip: {
      fontFamily: "Asap",
      shared: true,
    },
    legend: {
      verticalAlign: "bottom",
      horizontalAlign: "bottom",
      cursor: "pointer",
      itemclick: (e) => toggleDataSeries(e),
    },
    data: dataChart,
  };

  const toggleDataSeries = (e) => {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    e.chart.render();
  };

  return (
    <Container>{dataChart && <CanvasJSChart options={options} />}</Container>
  );
};

export default BalanceHidricBarChart;
