import { Divider } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 200px;
  background: #fcfcfc;
  border-radius: 6px;
  box-shadow: 0px 3px 6px #d8d8d8;
  padding: 10px;

  .rain-info-label {
    font-weight: bold;
  }

  .rain-info-content {
    font-weight: bold;
    font-size: x-large;
  }

  .icon-svg svg {
    width: 40px;
    height: 40px;
  }

  .col-vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .button-farm-rain-form {
    border: none;
    background: transparent;
    font-family: Asap;
    font-weight: normal;
    font-size: 12px;
    display: flex;
    color: #9386aa;
    cursor: pointer;

    svg {
      margin-left: 5px;
      path {
        fill: #9386aa !important;
      }
    }
  }
`;

export const CustomDivider = styled(Divider)`
  margin: 5px 0 5px 0 !important;
  min-height: 1px;

  .ant-divider-horizontal.ant-divider-with-text-center,
  .ant-divider-horizontal.ant-divider-with-text-left,
  .ant-divider-horizontal.ant-divider-with-text-right {
    margin: 8px 0 8px 0 !important;
  }

  &.ant-divider-dashed {
    border-color: #e8e5e5 !important;
  }
  &.ant-divider-with-text-center::before,
  &.ant-divider-with-text-left::before,
  &.ant-divider-with-text-right::before,
  &.ant-divider-with-text-center::after,
  &.ant-divider-with-text-left::after,
  &.ant-divider-with-text-right::after {
    border-top: 1px dashed #e8e5e5 !important;
  }
  .ant-divider-inner-text {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #a5a5a5;
  }
`;
